import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';

const baseURL = process.env.REACT_APP_BASE_URL;

export const exportAsPDF = (data: any[], assessmentBatchName: string, facility_name: string) => {
  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'portrait'; // portrait or landscape

  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  // const title = "Responses";
  const headers = [['Field Code', 'Field Name', 'Response']];

  const tobeexported = data.map((elt) => {
    return [elt.field_code, elt.field_name, elt.response];
  });

  let content = {
    margin: { top: 90, bottom: 70 },
    head: headers,
    body: tobeexported,
  };

  // doc.text(title, marginLeft, 40);
  autoTable(doc, content);

  // Get total number of pages
  const totalPages = doc.internal.pages.length;

  // Get page width and height
  const pageWidth = doc.internal.pageSize.getWidth();
  // const pageHeight = doc.internal.pageSize.getHeight();

  // Calculate center position
  const centerX = pageWidth / 2;
  // const centerY = pageHeight / 2;

  const text = facility_name;
  const subText = "Responses downloaded from WHO FRESAA eApp for " + facility_name;

  const textCenterX = (pageWidth - doc.getTextWidth(text)) / 2;
  const subTextCenterX = (pageWidth - doc.getTextWidth(subText)) + 20;

  // Add logo in the footer
  const logo = baseURL + '/assets/cphi_logo.png';
  const all_logos = baseURL + '/assets/four_logos.png';

  
  // Loop over each page
  for (let i = 1; i < totalPages; i++) {
    doc.setFontSize(15);
    doc.setTextColor(40);
    // doc.setCharSpace(10);
    doc.text(text, textCenterX, 70, { align: 'left' });

    doc.setFontSize(10);
    doc.setFont('Helvetica', 'italic');
    doc.text(subText, subTextCenterX, 80);

    doc.setFontSize(8);
    doc.setTextColor(40);
    doc.text(
      'Facility Readiness Service Availability Assessment (FRESAA) prepared by CP Health Innovations Inc.',
      textCenterX - 130,
      822.5,
      { align: 'left' }
    );
    doc.addImage(logo, 'PNG', centerX + 170, 809, 60, 16); // x, y, width, height

    doc.addImage(all_logos, 'PNG', centerX - 150, 10, 300, 40); // x, y, width, height

    // Set current page
    doc.setPage(i);
  }

  doc.save(assessmentBatchName.slice(0, 5) + ' - ' + facility_name + ' Responses.pdf');
};
