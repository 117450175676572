/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';

import { CustomForm } from 'components';
import { FieldType } from '../IndicatorBuilder';

type EditTextFieldProps = {
  edit: boolean;
  isSection?: boolean;
  isTableRow?: boolean;
  viewModeContent?: JSX.Element;
  handleSave: (value: string) => void;
  variant?: any;
  field?: FieldType;
  sectionNumber?: string;
  missing?: boolean;
};

const EditField: React.FC<EditTextFieldProps> = ({
  edit,
  isSection,
  isTableRow,
  viewModeContent,
  handleSave,
  variant,
  field,
  sectionNumber,
  missing,
}) => {
  const initialValues = {
    field_name: field?.field_name ?? field?.section_name ?? '',
    field_description: field?.field_description ?? field?.section_description ?? '',
  };

  if (!field) return <></>;

  if (edit) {
    return (
      <Box width={isTableRow ? '100%' : '80%'}>
        <CustomForm
          gap="10px"
          onSubmit={(data) => {
            if (field) {
              if (isSection) field.section_description = data.field_description;
              else field.field_description = data.field_description;

              field.field_name = data.field_name;
            }
            handleSave(data);
          }}
          fields={[
            {
              field_name: 'field_name',
              display_name: isSection ? 'Section Name' : 'Field Name',
              type: 'text',
              multiline: true,
              rows: 1,
              span: 4,
            },
            {
              field_name: 'field_description',
              display_name: isSection ? 'Section Description' : 'Field Description',
              type: 'text',
              multiline: true,
              rows: 3,
              span: 4,
            },
          ]}
          initialValues={initialValues}
          submitButtonText="Save"
        />
      </Box>
    );
  }

  return (
    <Box width="100%">
      {isSection ? (
        <Typography variant={variant} fontWeight={'bold'}>
          {sectionNumber}. {field.section_name}
        </Typography>
      ) : (
        <Box width="100%" display="grid" gridTemplateColumns="50px 1fr">
          <Typography
            variant={variant}
            fontWeight={field.type === 'field' ? undefined : 'bold'}
            color={missing ? 'red' : undefined}
          >
            {field.field_code}.
          </Typography>
          <Typography
            variant={variant}
            fontWeight={field.type === 'field' ? undefined : 'bold'}
            color={missing ? 'red' : undefined}
          >
            {field.field_name}
          </Typography>
        </Box>
      )}
      {(field.section_description || field.field_description) && (
        <Typography variant="body2" p={1} style={{ textIndent: '30px' }}>
          {isSection ? field.section_description : field.field_description}
        </Typography>
      )}

      {viewModeContent && (
        <Box width="100%" mt={1}>
          {viewModeContent}
        </Box>
      )}
    </Box>
  );
};

export default EditField;
