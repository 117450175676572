module.exports = {
  section_number: 1,
  section_name: 'FHSIS Report - Q1',
  section_body: [
    {
      type: 'sub-section',
      section_number: '1.1',
      section_name: 'Family Planning Services and Deworming for Women of Reproductive Age',
      section_content: [
        {
          type: 'table',
          field_name: 'No. of WRA with unmet need for modern FP',
          field_code: '1',
          columns: [
            {
              name: 'Age',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'Proportion of WRA with unmet need for modern FP',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Number of WRA with unmet need for MFP',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Total No. of Estimated WRA',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: 'Use of  FP Method',
          field_code: '1',
          columns: [
            {
              name: 'Current Users (Beginning Month)',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            {
              name: 'New Acceptors (Previous Month)',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            {
              name: 'Other Acceptors (Present Month)',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            {
              name: 'Drop-outs (Present Month)',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            {
              name: 'Current Users (End of Month)',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            {
              name: 'New Acceptors (Present Month)',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'BTL',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'NSV',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Condom',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Pills-POP',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Pills-COC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Injectables (DMPA/POI)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Implant',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'IUD',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'IUD-I',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'IUD-PP',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'NFP-LAM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'NFP-BBT',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'NFP-CMM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'NFP-STM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'NFP-SDM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Total Current Users',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: 'Deworming for Women of Reproductive Age',
          field_code: '1',
          columns: [
            {
              name: 'Age',
              type: 'parent',
              children: [{ name: '20-49 yrs', type: 'numeric', code: '3' }],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of WRA given one dose of deworming tablet',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of WRA given two doses of deworming tablet',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.2',
      section_name: 'Maternal Care and Services',
      section_content: [
        {
          type: 'table',
          field_name: 'Prenatal Care',
          field_code: '1',
          columns: [
            {
              name: 'Age',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of pregnant women who gave birth with at least 4 prenatal check-ups',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women assessed of their nutritional status during the 1st trimester',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Number of pregnant women seen in the first trimester who have normal BMI',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women seen in the first trimester who have low BMI',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women seen in the first trimester who have high BMI',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women for the first time given at least 2 doses of Td vaccination',
              field_code: '1',
            },
            {
              type: 'field',
              field_name:
                'No. of pregnant women for the 2nd or more times given at least 3 doses of Td vaccination (Td2 Plus)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women who completed the dose of iron with folic acid supplementation',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women who completed doses of calcium carbonate supplementation',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women given iodine capsules',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of pregnant women given one dose of deworming tablet',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women screened for syphilis',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women tested positive for syphilis',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women screened for Hepatitis B',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women tested positive for Hepatitis B',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women screened for HIV',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women tested for CBC or Hgb & Hct count',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women tested for CBC or Hgb & Hct count diagnosed with anemia',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pregnant women screened for gestational diabetes',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of pregnant women tested positive for gestational diabetes',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: 'Intrapartum Care and Delivery Outcome',
          field_code: '1',
          columns: [
            {
              name: 'Age',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of deliveries',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of live births',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of live births with normal birth weight',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of live births with low birth weight',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of live births with unknown birth weight',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of deliveries attended by skilled health professionals',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of deliveries attended by a doctor',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of deliveries attended by a nurse',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of deliveries attended by midwives',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of health facility-based deliveries',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of deliveries in public health facility-Totalo',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of deliveries in private health facility-Tot.',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of non-facility-based deliveries',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of vaginal deliveries',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of deliveries by cesarean section',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of full-term births',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of pre-term births',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of fetal deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of abortion/miscarriage',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: 'Postpartum and Newborn Care',
          field_code: '1',
          columns: [
            {
              name: 'Age',
              type: 'parent',
              children: [
                { name: '10-14 yrs', type: 'numeric', code: '1' },
                { name: '15-19 yrs', type: 'numeric', code: '2' },
                { name: '20-49 yrs', type: 'numeric', code: '3' },
                { name: 'Total', type: 'total', code: '4' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name:
                'No. of postpartum women together with their newborn who completed at least 2 postpartum check-ups',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of postpartum women who completed iron with folic acid supplementation',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of postpartum women with Vitamin A supplementation',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.3',
      section_name: 'Child Care and Services',
      section_content: [
        {
          type: 'table',
          field_name: ' Immunization Services for Newborns, Infants and School-Aged Children/Adolescents',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'CPAB',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'BCG',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'HepB, within 24 hours.',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'DPT-HiB-HepB 1',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'DPT-HiB-HepB 2',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'DPT-HiB-HepB 3',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'OPV 1',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'OPV 2',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'OPV 3',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'IPV 1',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'IPV 2 (routine)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'IPV 2 (catch-up)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' PCV 1',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'PCV 2',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'PCV 3',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MCV 1',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MCV 2',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'FIC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'CIC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Td, Grade 1',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MR, Grade 1',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Td, Grade 7',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' MR, Grade 7',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: ' Nutrition Services for Infants and Children',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'Newborns initiated on breastfeeding imme-diately after birth lasting to 90 mins',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Preterm/LBW infants given iron supplementation',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Infants exclusively breastfed until 5th month and 29 days',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Infants 6 mos. old initiated to complementary feeding with continued breastfeeding',
              field_code: '1',
            },
            {
              type: 'field',
              field_name:
                ' Infants 6 mos. old initiated to complementary feeding but no longer or never been breastfed',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Infants 6-11 mos. given 1 dose of  Vitamin A 100,000 I.U',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Children 12-59 mos. old  given 2  doses of Vitamin A 200,000 I.U.',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Infant 6-11 months old who completed MNP supplementation',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Children 12-23 mos. who completed MNP supplementation',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Stunted',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Wasted',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MAM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MAM-admitted in SFTB',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MAM-cured in SF',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MAM-defaulted in SFT',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'MAM-died in SFT',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' SAM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'SAM-admitted in OTC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'SAM-cured in OTC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'SAM-defaulted in OTC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'SAM-died in OTC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Overweight and Obese',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Normal',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: ' Deworming Services for Infants, Children and Adolescents',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: '1-19 y/o given 2 doses of  deworming drug',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'PSAC, 1-4 y/o dewormed (2 doses)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' SAC, 5-9 y/o dewormed (2 doses)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Adolescents, 10-19 y/o dewormed (2 doses)',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: 'Management of Sick Infants and Children',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: ' Sick Infants 6-11 mos. old seen',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Sick Infants 6-11 mos. old received Vitamin A',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Sick Children 12-59 mos. old seen',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Sick Children 12-59 mos. old received Vitamin A',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Diarrhea cases 0-59 months old seen',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Diarrhea cases 0-59 months old received  ORS',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Diarrhea cases 0-59 mos. received ORS w/ zinc',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Pneumonia cases 0-59 mos. old seen',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Pneumonia cases 0-59 mos. old completed  treatment',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.4',
      section_name: 'Oral Care and Services',
      section_content: [
        {
          type: 'table',
          field_name: ' Oral Care and Services',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'Orally fit children 12-59 months old upon oral exam plus orally fit after rehab',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Clients 5 years old and above with cases of DMFT',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Infants 0-11 months old who received BOHC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Children 1-4 years old who received BOHC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Children 5-9 years old who received BOHC ',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Adolescents who received BOHC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Adolescents 10-14 years old who received  BOHC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Adolescents 15-19 years old who received BOHC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Adults 20-59 years old who received BOHC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Senior citizens 60 years old and above who received BOHC',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Pregnant women who received BOHC',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.5',
      section_name: 'Infectious Disease Prevention and Control Services',
      section_content: [
        {
          type: 'table',
          field_name: ' Schistosomiasis Prevention and Control',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of patients seen',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of suspected cases seen',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of acute clinically diagnosed cases',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of confirmed acute cases',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of chronic clinically diagnosed cases',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of confirmed chronic cases',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of  confirmed cases (acute and chronic)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of cases treated',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of confirmed chronic cases referred to a hospital facility',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: ' Soil Transmitted Helminthiasis Prevention and Control',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of WRA, 20-49 years old, who completed 2 doses of deworming tablet',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: ' Leprosy Prevention and Control',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: ' No. of Leprosy cases on treatment during reporting period',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of newly detected cases during reporting period',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of animal bites',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of deaths due to Rabies',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.6',
      section_name: 'Non-Communicable Disease Prevention and Control Services',
      section_content: [
        {
          type: 'table',
          field_name: ' Non-Communicable Disease Prevention and Control Services',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of adults risk-assessed using PhilPEN ',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Current smokers',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Alcohol binge drinkers',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Overweight/Obese',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of adult women screened for Cervical Cancer using VIA/Pap Smear',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of adult women found positive / suspect for Cervical Cancer using VIA or Pap Smear',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of adult women screened for breast mass',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of adult women with suspicious breast mass',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of identified hypertensive adults',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of identified adults with Type 2 DM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of senior citizens screened for visual acuity',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of  senior citizens diagnosed with eye  disease/s',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of senior citizens who received one (1) dose of PPV',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of senior citizens who received one (1) dose of influenza vaccine',
              field_code: '1',
            },
          ],
        },
      ],
    },

    {
      type: 'sub-section',
      section_number: '1.7',
      section_name: 'Environmental Health and Sanitation Services',
      section_content: [
        {
          type: 'table',
          field_name: 'Environmental Health and Sanitation Services',
          field_code: '1',
          columns: [
            { name: 'Total', type: 'numeric', code: '1' },
            { name: 'Remarks', type: 'text', code: '2' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of HHs with access to basic safe water supply',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of HHs with Level I',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of HHs with Level II',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of HHs with Level III',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of HHs using safely managed drinking-water services',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No of HHs with basic sanitation facility',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of HH with pour/flush toilet connected to septic tank',
              field_code: '1',
            },
            {
              type: 'field',
              field_name:
                'No. of HHs with pour/flush toilet  connected to community sewer/sewerage system or any other approved treatment systemo',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of HHs with ventilated  improved pit latrine (VIP)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of HHs using safely managed sanitation services',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of industrial establishments issued with sanitary permit',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of barangays declared ZOD',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.8',
      section_name: 'Mortality',
      section_content: [
        {
          type: 'table',
          field_name: ' Mortality',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'Total Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Maternal Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Under-five Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Infant Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Neonatal Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Fetal Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' Early Neonatal Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Perinatal Deaths',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.9',
      section_name: 'Natality',
      section_content: [
        {
          type: 'table',
          field_name: ' Natality',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'Livebirths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Livebirths among 15-19 y/o women-Tot.',
              field_code: '1',
            },
          ],
        },
      ],
    },
  ],
};
