/*
  ArticleContent
    - A simple component for adding/modfying article contents
  Usage:
    ArticleContent
  Parameters:
    contents - contains an array of contents to be previewed in article.
  Format:
    contents: [
      { title, steps },
      ...
    ];

  title - subtitle of the article.
  steps - array of steps (which may include images for instruction purposes)
  steps:
    step - string of step for article.
    image - path of image to be used for the article.
    imageType - can be 'modal', 'web', or 'icon'.

  Example:
    <ArticleContent contents={[
      {
        title: 'This is a subtitle',
        steps: [
          { step: 'Step 1. this is a step', image: '...' },
          { step: 'Step 2. this is a step', image: '...' }
          ...
        ]
      },
      {
        title: 'A subtitle 2',
        steps: [
          { step: 'Step 1. ....' },
          { step: 'Step 2. ....' }
        ]
      }
    ]} />
*/

import { Box, Divider } from '@mui/material';
import styled from 'styled-components';

interface Content {
  title?: string;
  divider?: boolean;
  steps?: {
    step: string;
    image?: string;
    imageType?: 'modal' | 'web' | 'icon';
  }[];
}

type Props = {
  contents: Content[];
};

export const imagePath = '/assets/instructions';
const ArticleContent: React.FC<Props> = (props) => {
  const { contents } = props;

  return (
    <Box>
      {contents.map((content) => (
        <>
          {content.divider ? <Divider sx={{ marginTop: '1.5em', marginBottom: '1.5em' }} /> : <></>}
          {content.title ? <Title>{content.title}</Title> : <></>}
          {content.steps?.map((step) => (
            <>
              <Step children={step.step} />
              {step.image ? (
                <Image
                  src={step.image}
                  style={{
                    maxWidth: step.imageType === 'modal' ? '400px' : step.imageType === 'icon' ? '100px' : '800px',
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ))}
        </>
      ))}
    </Box>
  );
};

export default ArticleContent;

const Step = styled.p`
  margin-top: 8px;
  font-size: 14px;
`;
const Title = styled.p`
  margin-top: 8px;
  font-size: 17px;
  font-weight: bold;
`;
const Image = styled.img`
  margin-top: 10px;
  border: 1px solid #ddd;

  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
