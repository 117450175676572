import { Table, Typography, useTheme } from '@mui/material';

import { Box } from '@mui/system';
import { XAxisDataKey } from './chartDataProcessor';
import { formatNumber } from 'utils';
import { tokens } from 'context/theme.context';
import { useMemo } from 'react';

interface DashboardTableProps {
  data: any[];
  title: string;
  tableKey: string;
  columns: XAxisDataKey[];
  colSpan?: number;
  rowSpan?: number;
  visibleColumns?: string[];
}

const DashboardTable: React.FC<DashboardTableProps> = ({
  title,
  tableKey,
  data,
  columns,
  colSpan = 6,
  rowSpan = 2,
  visibleColumns,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const finalColumns = useMemo(() => {
    if (!visibleColumns) return [{ key: tableKey }, ...columns];
    return visibleColumns.map((columnKey) =>
      columns.find((col) => col.key === columnKey || col.displayName === columnKey)
    );
  }, [columns, tableKey, visibleColumns]);

  const displayValue = (rowData: any, column: any) => {
    const key = column.displayName ?? column.key;
    let value = rowData[key];
    if (column.renderValue) {
      value = column.renderValue(rowData);
    }

    const result = column.type === 'number' ? formatNumber(value, 0) : value;
    if (column.formatValue) {
      return column.formatValue(result);
    }

    return result;
  };

  return (
    <Box
      gridColumn={'span ' + colSpan}
      gridRow={'span ' + rowSpan}
      p="30px"
      sx={{ backgroundColor: colors.secondary_background }}
      borderRadius={2}
    >
      <Typography variant="h5" fontWeight="600" paddingBottom="20px">
        {title}
      </Typography>
      <Box sx={{ overflow: 'auto', height: 'calc(100% - 40px)', borderRadius: 2 }}>
        <Table
          sx={{
            '& tbody tr:nth-of-type(odd)': {
              backgroundColor: colors.solidHoverBackground,
              '& th:first-child, & td:first-child': {
                backgroundColor: colors.solidHoverBackground,
              },
            },
            '& thead th': {
              backgroundColor: colors.primary,
              color: '#fff',
              position: 'sticky',
              top: 0,
              zIndex: 2,
            },
            '& th': {
              padding: '10px 10px',
            },
            '& td': {
              padding: '5px 10px',
            },
            '& tr td:not(:first-child)': {
              width: '10%',
              minWidth: '100px',
            },
            '& tr td:first-child': {
              width: '10%',
              minWidth: '400px',
              position: 'sticky',
              left: 0,
              zIndex: 1,
              backgroundColor: colors.secondary_background,
            },
            '& tr th:first-child': {
              position: 'sticky',
              left: 0,
              zIndex: 3,
            },
            '& tr:hover td': {
              backgroundColor: colors.background + ' !important',
            },
          }}
        >
          <thead>
            <tr>
              {finalColumns?.map((column) => {
                return <th key={column?.key}>{column?.displayName ?? column?.key}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {finalColumns?.map((column) => {
                  return (
                    <td
                      key={column?.key}
                      align={column?.type === 'number' || column?.type === 'percentage' ? 'right' : undefined}
                    >
                      {displayValue(rowData, column)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default DashboardTable;
