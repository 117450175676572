import DashboardBarChart from './DashboardBarChart';
import DashboardPieChart from './DashboardPieChart';
import DashboardTable from './DashboardTable';
import { ReactNode } from 'react';
import { formatNumber } from 'utils';

export interface XAxisDataKey {
  key: string;
  displayName?: string;
  type?: string | 'number';
  renderValue?: (row: any) => any;
  formatValue?: (value: string) => any;
}

export interface ReferenceLineProps {
  label: string;
  yValue: number;
  position?: any;
  stroke?: string;
}

export interface YAxisDataKey {
  key: string;
  displayName?: string;
  chartColor: string;
  sourceFields?: string[];
}

export interface YAxisBreakdownDataKey {
  key: string;
  chartColor: string;
  displayName?: string;
}

export interface ChartLabel {
  xAxisLabel?: string;
  yAxisLabel?: string;
}

export interface TableColumn {
  key: string;
  displayName?: string;
  type?: string | 'number';
  renderValue?: (row: any) => any;
  formatValue?: (value: string) => any;
}

export interface DashboardWidget {
  type: 'bar' | 'table' | 'pie';
  title: string;
  description?: ReactNode;
  label: ChartLabel;
  containerDimnesion?: {
    colSpan?: number;
    rowSpan?: number;
  };
  xAxisDataKeys: XAxisDataKey[];
  yAxisDataKeys: YAxisDataKey[];

  aggregation?: 'sum' | 'count';
  yAxisBreakdown?: {
    breakdownAggregation?: 'sum' | 'count';
    breakdownKey: string;
    yAxisBreakdownKeys: YAxisBreakdownDataKey[];
  };
  yAxisReferenceLine?: ReferenceLineProps;
  dataPreprocessor?: (data: any[]) => any[];
  customTooltipFormatter?: (value: any) => string;
  conditionalBarColorGetter?: (value: number, extra: any) => string;

  //Config
  useXAxisDataKeyResponseAsXAxis?: boolean;
  displayAsPercentage?: boolean;
  addPercentSignOnly?: boolean;
  stacked?: boolean;
  hideLegend?: boolean;

  // Filters
  facilityTypeFilter?: string[];

  //Table
  tableKey?: {
    key: string;
    displayKey?: string;
    displayName?: string;
  };
  columns?: TableColumn[];
  tableSettings?: {
    visibleColumns?: string[];
    sortBy?: string;
  };
}

export interface RawDataFilter {
  field: string;
  values: string[];
}

export const filterRawData = (raw_data: any[], filter: RawDataFilter[]) => {
  const getFilter = (data: any) => {
    const results = filter.map((item) => item.values.some((i) => i === data[item.field]));

    return results.every((result) => result);
  };
  raw_data = raw_data.filter((data) => getFilter(data));
  return raw_data;
};

export const getChartData = (rawData: any[], widget: DashboardWidget, locationFilters?: any) => {
  const {
    xAxisDataKeys,
    yAxisDataKeys,
    yAxisBreakdown,
    facilityTypeFilter,
    displayAsPercentage,
    dataPreprocessor,
    tableKey,
    tableSettings,
    type,
  } = widget;

  // for bar chart and table
  const { yAxisBreakdownKeys } = yAxisBreakdown ?? {};

  let dataToBeProcessed: any[] = rawData;
  if (facilityTypeFilter) {
    const withSBF = facilityTypeFilter.includes('Safe Birthing Facility');
    dataToBeProcessed = dataToBeProcessed.filter((data) => {
      return facilityTypeFilter.includes(data['Facility Type']) || (withSBF && data['With SBF'] === 1);
    });
  }

  if (locationFilters) {
    dataToBeProcessed = dataToBeProcessed.filter((data) => {
      if (locationFilters.region_code && data['Region Code'] !== locationFilters.region_code) {
        return false;
      }
      if (locationFilters.province_code && data['Province Code'] !== locationFilters.province_code) {
        return false;
      }
      if (locationFilters.municipality_code && data['Municipality Code'] !== locationFilters.municipality_code) {
        return false;
      }
      if (locationFilters.facility_id && data['Facility ID'] !== locationFilters.facility_id) {
        return false;
      }
      return true;
    });
  }

  if (dataPreprocessor) {
    dataToBeProcessed = dataPreprocessor(dataToBeProcessed);
  }

  const totalResponses = dataToBeProcessed.length;
  const tableData: any = {};
  let finalXKeys: XAxisDataKey[] = xAxisDataKeys;
  if (validUseXAxisDataKeyResponseAsXAxis(widget)) {
    const uniqueKeys: any[] = [];
    finalXKeys = dataToBeProcessed.reduce((result, row) => {
      const response = row[xAxisDataKeys[0].key];
      let displayName = undefined;
      if (xAxisDataKeys[0].displayName) {
        displayName = row[xAxisDataKeys[0].displayName];
      }
      if (!uniqueKeys.includes(response)) {
        uniqueKeys.push(response);
        result.push({ key: response, displayName: displayName });
      }
      return result;
    }, []);
  }

  let data = finalXKeys.flatMap((dataKey) => {
    const xDataKeyString = dataKey.key;
    const xDataKeyDisplayString = dataKey.displayName ?? dataKey.key;

    const result = dataToBeProcessed.reduce((result, item) => {
      let response = item[xDataKeyString];
      if (validUseXAxisDataKeyResponseAsXAxis(widget)) {
        response = item[xAxisDataKeys[0].key];
      }
      const yAxisData: any = processChartData(response, item, widget, dataKey);

      if (tableKey) {
        let tableKeyString = tableKey.displayName ?? tableKey.key;
        const tableMainColumn = item[tableKey.key];
        if (tableData[tableMainColumn])
          tableData[tableMainColumn] = combineResults([
            tableData[tableMainColumn],
            { [xDataKeyDisplayString]: yAxisData[tableKeyString] },
          ]);
        else tableData[tableMainColumn] = { [xDataKeyDisplayString]: yAxisData[tableKeyString] };
      }
      return combineResults([result, yAxisData]);
    }, {});

    let extra: any = { total: totalResponses };
    extra = displayResultAsPercentage(result, extra, !!displayAsPercentage);

    return { 'chart-key': xDataKeyDisplayString, ...result, extra };
  });

  if (type === 'table' && tableKey) {
    let tableKeyString = tableKey.displayName ?? tableKey.key;
    return processTableData(tableData, tableKeyString, tableSettings, widget);
  }

  const yAxisDataKeyFinal = yAxisBreakdownKeys?.length
    ? yAxisBreakdownKeys.map((breakdown) => {
        const finalKey = breakdown.displayName ?? breakdown.key;
        return { dataKey: finalKey, chartColor: breakdown.chartColor };
      })
    : yAxisDataKeys.map((yAxis) => {
        const finalKey = yAxis.displayName ?? yAxis.key;
        return { dataKey: finalKey, chartColor: yAxis.chartColor };
      });

  const dataKeys = yAxisDataKeyFinal.map((item) => item.dataKey);
  const chartColors = yAxisDataKeyFinal.reduce((result, item) => ({ ...result, [item.dataKey]: item.chartColor }), {});

  if (type === 'pie' && data.length === 1) {
    data = yAxisDataKeys.map((yAxisDataKey) => ({ key: yAxisDataKey.key, value: data[0][yAxisDataKey.key] }));
    return (
      <DashboardPieChart
        title={widget.title}
        description={widget.description}
        data={data}
        chartColors={chartColors}
        colSpan={widget.containerDimnesion?.colSpan}
        rowSpan={widget.containerDimnesion?.rowSpan}
        hideLegend={widget.hideLegend}
      />
    );
  }

  return (
    <DashboardBarChart
      title={widget.title}
      description={widget.description}
      data={data}
      chartColors={chartColors}
      dataKeys={dataKeys}
      colSpan={widget.containerDimnesion?.colSpan}
      rowSpan={widget.containerDimnesion?.rowSpan}
      xAxisLabel={widget.label.xAxisLabel}
      yAxisLabel={widget.label.yAxisLabel}
      stacked={widget.stacked}
      displayAsPercentage={widget.displayAsPercentage || widget.addPercentSignOnly}
      tickFormatter={widget.displayAsPercentage || widget.addPercentSignOnly ? (tick) => `${tick}%` : undefined}
      displayTotal={!!widget.yAxisBreakdown || (widget.aggregation === 'sum' && widget.stacked)}
      customTooltipFormatter={widget.customTooltipFormatter}
      conditionalBarColorGetter={widget.conditionalBarColorGetter}
      hideLegend={widget.hideLegend}
      referenceLine={widget.yAxisReferenceLine}
    />
  );
};

const processTableData = (
  tableData: any,
  tableKey: string,
  tableSettings: { sortBy?: string } | undefined,
  widget: DashboardWidget
) => {
  const data = Object.keys(tableData).map((key) => {
    return { [tableKey]: key, ...tableData[key] };
  });

  const sortBy = tableSettings?.sortBy ?? tableKey;
  data.sort((a, b) => {
    const nameA = a[sortBy].toUpperCase();
    const nameB = b[sortBy].toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    return 0;
  });

  return (
    <DashboardTable
      title={widget.title}
      tableKey={widget.tableKey!.displayName ?? widget.tableKey!.key}
      data={data}
      columns={widget.xAxisDataKeys!}
      colSpan={widget.containerDimnesion?.colSpan}
      rowSpan={widget.containerDimnesion?.rowSpan}
      visibleColumns={widget.tableSettings?.visibleColumns}
    />
  );
};

const displayResultAsPercentage = (result: any, extra: any, displayAsPercentage: boolean) => {
  for (let key in result) {
    if (displayAsPercentage && typeof result[key] === 'number') {
      const percent = extra.total > 0 ? formatNumber((result[key] / extra.total) * 100, 0) : 0;
      extra = { ...extra, [key]: result[key] };
      result[key] = percent;
    } else {
      extra = { ...extra, [key]: result[key] };
    }
  }
  return extra;
};

// UTILS
// const multipleYAxisWithBreakdown = (widget: DashboardWidget) => {
//   return !!(widget.yAxisDataKeys.length > 1 && widget.yAxisBreakdown?.yAxisBreakdownKeys.length);
// };

const validUseXAxisDataKeyResponseAsXAxis = (widget: DashboardWidget) => {
  const { useXAxisDataKeyResponseAsXAxis, xAxisDataKeys } = widget;
  return useXAxisDataKeyResponseAsXAxis && xAxisDataKeys?.length === 1;
};

const processChartData = (response: string, item: any, widget: DashboardWidget, xAxisDataKey: XAxisDataKey) => {
  const { yAxisDataKeys, yAxisBreakdown, aggregation, tableKey } = widget;
  const { breakdownKey, yAxisBreakdownKeys, breakdownAggregation } = yAxisBreakdown ?? {};
  return yAxisDataKeys?.reduce((result, yAxis) => {
    let count = 0;

    if (validUseXAxisDataKeyResponseAsXAxis(widget) && response === xAxisDataKey.key) {
      if (aggregation === 'sum') {
        count = isNaN(parseFloat(item[yAxis.key])) ? 0 : parseFloat(item[yAxis.key]);
      } else {
        count = 1;
      }
    } else {
      if (aggregation === 'sum') {
        count = isNaN(parseFloat(response)) ? 0 : parseFloat(response);
      } else {
        count = response === yAxis.key ? 1 : 0;
      }
    }

    let subResult: any = {};
    if (aggregation !== 'sum' && yAxisBreakdownKeys?.length && breakdownKey) {
      // With Breakdown - will only apply if main aggregation !== 'sum'
      if (count === 1) {
        let breakdownResponse = item[breakdownKey];

        subResult = yAxisBreakdownKeys.reduce((breakdownResult, breakdown) => {
          let breakdownCount = 0;
          if (breakdownAggregation === 'sum') {
            breakdownCount = isNaN(parseFloat(breakdownResponse)) ? 0 : parseFloat(breakdownResponse);
          } else {
            breakdownCount = breakdownResponse === breakdown.key ? 1 : 0;
          }
          breakdownResult[breakdown.key] = breakdownCount;
          return breakdownResult;
        }, subResult);
      }
    } else {
      // Without Breakdown
      let yAxisKey = yAxis.displayName ?? yAxis.key;
      if (tableKey) {
        yAxisKey = tableKey.displayName ?? tableKey.key;
        if (xAxisDataKey.type !== 'number') {
          subResult[yAxisKey] = response;
        } else {
          subResult[yAxisKey] = count;
        }
      } else {
        subResult[yAxisKey] = count;
      }
    }

    return { ...result, ...subResult };
  }, {});
};

const combineResults = (arrayOfObjects: any[]) => {
  const result = arrayOfObjects.reduce((acc, obj) => {
    for (const key in obj) {
      if (acc.hasOwnProperty(key)) {
        acc[key] += obj[key];
      } else {
        acc[key] = obj[key];
      }
    }
    return acc;
  }, {});
  return result;
};
