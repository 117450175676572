import { Box, Typography } from '@mui/material';
import { CustomIconButton, CustomTextField } from 'components';
import { useEffect, useState } from 'react';

import { FieldType } from '../IndicatorBuilder';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

type EditTextFieldProps = {
  initialValue?: string;
  edit: boolean;
  viewModeContent?: JSX.Element;
  fieldName?: string;
  handleSave: (value: string) => void;
  variant?: any;
  field?: FieldType;
};

const EditTextField: React.FC<EditTextFieldProps> = ({
  edit,
  initialValue,
  viewModeContent,
  fieldName = 'Field Name',
  handleSave,
  variant,
  field,
}) => {
  const [value, setValue] = useState('');

  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      handleSave(value);
    }
  };

  useEffect(() => {
    if (initialValue || initialValue === '') setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      {edit ? (
        <Box width="100%" display="flex" alignItems="flex-start">
          <CustomTextField
            fieldName={fieldName}
            label={`Edit ${fieldName}`}
            value={value}
            handleChange={(event) => setValue(event.target.value)}
            onKeyDown={keyPress}
            multiline
          />
          {edit && <CustomIconButton icon={<SaveOutlinedIcon />} tooltip="Save" onClick={() => handleSave(value)} />}
        </Box>
      ) : (
        viewModeContent ?? (
          <Typography variant={variant} fontWeight={variant ? 'bold' : undefined}>
            {value}
          </Typography>
        )
      )}
      {field && (field.field_description || field.section_description) && (
        <Typography variant="body2" p={1} style={{ textIndent: '30px' }}>
          {field.field_description ?? field.section_description}
        </Typography>
      )}
    </>
  );
};

export default EditTextField;
