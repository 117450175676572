import { PCFItem } from 'data/DashboardTemplates';

export const pcf_facility_info = {
  "Telephone/Fax No": "001",
  "Email": "002",
  "PCF Application Status": "003",
  "Existing License No": "004",
  "Date Issued": "005",
  "Expiry Date": "006",
  "Managing Authority": "009",
  "Name of Head": "011",
  "Ownership": "008"
};

export const pcf_items: PCFItem[] = [
  {
    item_number: 1,
    required_response: 'Yes',
    items: ['021'],
  },
  {
    item_number: 2,
    required_response: 'Yes',
    items: ['034'],
  },
  {
    item_number: 3,
    required_response: 'Yes',
    items: ['035', '035'],
  },
  {
    item_number: 4,
    required_response: 'Yes',
    items: ['016A', '016B', '016C', '016D'],
  },
  {
    item_number: 5,
    required_response: 'Yes',
    items: ['017A', '017B', '017C', '017D', '017E', '017F', '017G'],
  },
  {
    item_number: 6,
    required_response: 'Yes',
    items: ['022A', '022B', '022C', '022D', '022E'],
  },
  {
    item_number: 7,
    required_response: 'Yes',
    items: ['023', '024'],
  },
  {
    item_number: 8,
    required_response: 'Yes',
    items: ['025A', '025B', '025C'],
  },
  {
    item_number: 9,
    required_response: 'Yes',
    items: ['026A', '026B', '026C', '026D', '026E', '026F', '026G'],
  },
  {
    item_number: 10,
    required_response: 'Yes',
    items: ['027'],
  },
  {
    item_number: 11,
    required_response: 'Yes',
    items: ['037'],
  },
  {
    item_number: 12,
    required_response: 'Yes',
    items: ['038'],
  },
  {
    item_number: 14,
    required_response: 'Yes',
    items: ['028'],
  },
  {
    item_number: 15,
    required_response: 'Yes',
    items: ['126A', '126B'],
  },
  {
    item_number: 16,
    required_response: 'Yes',
    items: ['127'],
  },
  {
    item_number: 17,
    required_response: 'Yes',
    items: ['128A-7'],
  },
  {
    item_number: 18,
    required_response: 'Yes',
    items: ['128B-7'],
  },
  {
    item_number: 19,
    required_response: 'Yes',
    items: ['128C-7'],
  },
  {
    item_number: 20,
    required_response: 'Yes',
    items: ['128E-7'],
  },
  {
    item_number: 21,
    required_response: 'Yes',
    items: ['128G-7'],
  },
  {
    item_number: 22,
    required_response: 'Yes',
    items: ['128H-7'],
  },
  {
    item_number: 23,
    required_response: 'Yes',
    items: ['128I-7'],
  },
  {
    item_number: 24,
    required_response: 'Yes',
    items: ['128J-7'],
  },
  {
    item_number: 25,
    required_response: 'Yes',
    items: ['130A-7'],
  },
  {
    item_number: 26,
    required_response: 'Yes',
    items: ['156A', '156B'],
  },
  {
    item_number: 27,
    required_response: 'Yes',
    items: ['157'],
  },
  {
    item_number: 28,
    required_response: 'Yes',
    items: ['158'],
  },
  {
    item_number: 29,
    required_response: 'Yes',
    items: ['159A', '159B'],
  },
  {
    item_number: 30,
    required_response: 'Yes',
    items: ['160'],
  },
  {
    item_number: 31,
    required_response: 'Yes',
    items: ['161'],
  },
  {
    item_number: 32,
    required_response: 'Yes',
    items: ['070'],
  },
  {
    item_number: 33,
    required_response: 'Yes',
    items: ['071A', '071B', '071C'],
  },
  {
    item_number: 34,
    required_response: 'Yes',
    items: ['072'],
  },
  {
    item_number: 35,
    required_response: 'Yes',
    items: ['073A', '073B'],
  },
  {
    item_number: 37,
    required_response: 'Yes',
    items: ['074A', '074B', '074C'],
  },
  {
    item_number: 38,
    required_response: 'Yes',
    items: ['075A', '075B', '075C', '075D', '075E'],
  },
  {
    item_number: 39,
    required_response: 'Yes',
    items: ['076'],
  },
  {
    item_number: 40,
    required_response: 'Yes',
    items: ['077'],
  },
  {
    item_number: 41,
    required_response: 'Yes',
    items: ['078A', '078B', '078C', '078D', '078E', '078F'],
  },
  {
    item_number: 42,
    required_response: 'Yes',
    items: ['079'],
  },
  {
    item_number: 43,
    required_response: 'Yes',
    items: ['080'],
  },
  {
    item_number: 44,
    required_response: 'Yes',
    items: ['081A', '081B', '081C', '081D', '081E'],
  },
  {
    item_number: 45,
    required_response: 'Yes',
    items: ['082A', '082B', '082C'],
  },
  {
    item_number: 46,
    required_response: 'Yes',
    items: ['083'],
  },
  {
    item_number: 47,
    required_response: 'Yes',
    items: ['062'],
  },
  {
    item_number: 48,
    required_response: 'Yes',
    items: ['063'],
  },
  {
    item_number: 49,
    required_response: 'Yes',
    items: ['030A', '030B', '030C'],
  },
  {
    item_number: 50,
    required_response: 'Yes',
    items: ['032'],
  },
  {
    item_number: 51,
    required_response: 'Yes',
    items: ['033A', '033B', '033C'],
  },
  {
    item_number: 52,
    required_response: 'Yes',
    items: ['148A'],
  },
  {
    item_number: 53,
    required_response: 'Yes',
    items: ['148B'],
  },
  {
    item_number: 54,
    required_response: 'Yes',
    items: ['148C'],
  },
  {
    item_number: 55,
    required_response: 'Yes',
    items: ['148D'],
  },
  {
    item_number: 56,
    required_response: 'Yes',
    items: ['148E', '148F'],
  },
  {
    item_number: 57,
    required_response: 'Yes',
    items: ['148G'],
  },
];

export const pcf_equipment = [
  //default required_response = "Available & Functional"
  {
    equipment_type: 'MEDICAL EQUIPMENT AND INSTRUMENTS IN PCF',
    equipment: [
      { field_code: ['118AW'], name: 'Autoclave, 20 L' },
      {
        field_code: ['117A'],
        name: 'BP apparatus, non-mercurial, with adult and pediatric cuffs',
      },
      {
        field_code: ['118AB', '118AC', '118AD'],
        name: 'Cervical Inspection Set/Vaginal Speculum Set',
        variant: ['Small size', 'Medium size', 'Large size'],
      },
      {
        field_code: ['124A', '124B', '124C', '124D', '124E', '124F', '124G', '124H'],
        name: 'Dressing set (minor surgical set)',
        variant: [
          'Surgical scissors straight',
          'Surgical scissors curved',
          'Bandage scissors',
          'Pick up (ovum) forceps',
          'Mosquito forceps',
          'Tissue forceps with teeth',
          'Tissue forceps without teeth',
          'Suture removal scissors',
        ],
      },
      {
        field_code: ['124I', '124J'],
        name: 'EENT Diagnostic Set',
        variant: ['Ophthalmoscope', 'Otoscope'],
      },
      { field_code: ['075E'], name: 'Emergency light', required_response: 'Yes' },
      { field_code: ['118F'], name: 'Examining light' },
      { field_code: ['117G'], name: 'Examining table' },
      { field_code: ['117J'], name: 'Foot stool' },
      { field_code: ['124AM'], name: 'Instrument table' },
      { field_code: ['118AO'], name: 'IV stand' },
      { field_code: ['123G'], name: 'Nebulizer' },
      { field_code: ['124K'], name: 'Neurohammer' },
      { field_code: ['117H'], name: 'Non-mercurial thermometer' },
      { field_code: ['122A'], name: 'Salter scale' },
      { field_code: ['121H'], name: 'Safety/Sharps collector box' },
      { field_code: ['124L'], name: "Snellen's Chart, Visual Acuity Chart" },
      { field_code: ['117B'], name: 'Stethoscope' },
      { field_code: ['117C'], name: 'Weighing scale with height measuring stick, adult' },
      { field_code: ['122C'], name: 'Weighing scale, infant' },
      { field_code: ['118N'], name: 'Wheelchair' },
      { field_code: ['118M'], name: 'Wheeled stretcher' },
      { field_code: ['121B'], name: 'Vaccine carrier with cold dog' },
      { field_code: ['121C'], name: 'Vaccine carrier thermometer' },
      { field_code: ['121F'], name: 'Vaccine refrigerator' },
    ],
  },
  {
    equipment_type: 'DENTAL EQUIPMENT AND INSTRUMENTS',
    type_comment:
      '(Outsourced dental service located outside PCF shallstill be inspected and comply with the following licensing requirements)',
    equipment: [
      { field_code: ['118AW'], name: 'Autoclave, 20 L' },
      {
        field_code: ['124N'],
        name: 'Dental Unit and chair with compressor and complete accessories, with high and low speed hand pieces',
      },
      {
        field_code: ['124O', '124P', '124Q', '124R'],
        name: 'Dental prophylaxis instrumentset:',
        variant: [
          'Universal scaler, non-magnetic hollow handle',
          'Peri Curette, non-magnetic hollow handle',
          'Periodontal probe',
          'Gracey curette, set of 6 different tips, non-magnetic hollow handle',
        ],
      },
      {
        field_code: ['124S'],
        name: 'Dental instruments: mouth mirror, cotton plier, explorer, spoon excavator (1 set)',
      },
      {
        field_code: [
          '124T',
          '124U',
          '124V',
          '124W',
          '124X',
          '124Y',
          '124Z',
          '124AA',
          '124AB',
          '124AC',
          '124AD',
          '124AE',
          '124AF',
          '124AG',
          '124AH',
        ],
        name: 'Basic Dental Surgery Set',
        variant: [
          'Extraction forcep, #16 with cross serration (for better grip)',
          'Ergonomic for better comfort designed instrument',
          'Extraction forcep, #17',
          'Extraction forcep, #18L',
          'Extraction forcep, #18R',
          'Extraction forcep, #44',
          'Extraction forcep, #69',
          'Extraction forcep, #150',
          'Extraction forcep, #151',
          'Pedo forcep, #150',
          'Pedo forcep, #151',
          'Pedo forcep, #17S',
          'Pedo forcep, #16S',
          'Pedo forcep, #18R',
          'Pedo forcep, #18L',
        ],
      },
      { field_code: ['124AI'], name: 'Aspirating syringe (2), stainless steel, with locking mechanism' },
      { field_code: ['124AJ'], name: 'Minnesota retractor, stainless steel' },
      { field_code: ['124AK'], name: 'Bone file, stainless steel' },
      { field_code: ['124AL'], name: 'Dental instrument cabinet' },
      { field_code: ['124AM'], name: 'Instrument table' },
      { field_code: ['124AN'], name: 'Sterilizing unit, table top' },
      { field_code: ['124AO'], name: 'Sharp waste disposal unit' },
    ],
  },
  {
    equipment_type: 'DENTAL EQUIPMENT AND INSTRUMENTS',
    equipment: [
      { field_code: ['125A'], name: 'Computer/laptop with internet connection (mobile data, Ethernet)' },
      { field_code: ['125D'], name: 'Printer' },
      { field_code: ['125B'], name: 'Mobile phone/cellphone' },
      { field_code: ['125C'], name: 'Fire extinguisher' },
      { field_code: ['125E'], name: 'Standby generator set' },
    ],
  },
];
