import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'assessment';

export const getFacilityAssessment = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getFacilityAssessments = (batch_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/batch/${batch_id}`, { params: query });
};

export const getActiveApprovedAssessments = (batch_id: number) => {
  return axios.get(`${baseURL}/${path}/list/pho/${batch_id}`);
};

export const getActiveAssessments = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/active`, { params: query });
};

export const getActiveAssessmentsByStatus = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/by_status`, { params: query });
};

export const getAllAssessments = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/all`, { params: query });
};

export const getAllAssessmentsByFacility = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/by_facility`, { params: query });
};

export const createFacilityAssessment = (batch_id: number, data: { facilities: any }) => {
  return axios.post(`${baseURL}/${path}/${batch_id}`, data);
};

export const updateAssessor = (id: number, asessor_id: number) => {
  return axios.patch(`${baseURL}/${path}/assessor/${id}/${asessor_id}`);
};

export const approveAssessment = (id: number) => {
  return axios.patch(`${baseURL}/${path}/approve/${id}`);
};

export const batchApproveAssessments = (ids: number[]) => {
  return axios.patch(`${baseURL}/${path}/batch-approve`, { ids });
};

export const returnToAssessor = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/return-assessment/${id}`, data);
};

export const deleteFacilityAssessment = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const removeFacilityFromBatch = (data: any) => {
  return axios.delete(`${baseURL}/${path}/batch/remove-facility`, { data: data });
};
