import { Box } from '@mui/material';
import EditTextField from './EditTextField';
import { RowAction } from 'components';
import { useState } from 'react';

type TableFieldColumnProps = {
  column: any;
  index: number;
  handleDeleteOption: (index: number) => void;
  handleAddNewOption: (index: number) => void;
  handleSaveOption: (index: number, newValue: string) => void;
};

const TableFieldColumn: React.FC<TableFieldColumnProps> = ({
  column,
  index,
  handleDeleteOption,
  handleAddNewOption,
  handleSaveOption,
}) => {
  const [edit, setEdit] = useState(false);
  const [newName, setNewName] = useState(column.name);

  const handleEditSaveClick = (value: string) => {
    setNewName(value);
    setEdit(false);
    handleSaveOption(index, value);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <EditTextField
        fieldName="Column"
        edit={edit}
        handleSave={(value) => handleEditSaveClick(value)}
        initialValue={newName}
      />
      <RowAction
        actions={[
          {
            label: 'Edit',
            action: () => setEdit(true),
            hidden: edit,
          },
          {
            label: 'Delete',
            action: () => handleDeleteOption(index),
          },
        ]}
      />
    </Box>
  );
};

export default TableFieldColumn;
