/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useTheme } from '@mui/material';
import { DashboardWidget, getChartData } from './charts/chartDataProcessor';
import { CustomContainer, FacilityLocationFilter, Header, RegularButton } from 'components';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';

import { AssessmentBatchModel } from 'model/Entities';
import { AklanDashboard } from './charts/chartConfiguration';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomAutoCompleteSelect from 'components/CustomAutoComplete';
import LoadingScreen from 'screens/Helper/loading';
import { UserContext } from 'context/user.context';
import { getAssessmentBatches } from 'api/assessment-batch';
import { getBatchDashboardData } from 'api/assessment-batch-facilities';
import { tokens } from '../../context/theme.context';
import { useNavigate } from 'react-router-dom';
import { aklan_dashboards } from '../../data/DashboardTemplates/Aklan';

const Home = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const colors = tokens(theme.palette.mode);

  const [responses, setResponses] = useState<any[]>([]);
  const [assessmentBatches, setAssessmentBatches] = useState<any[]>([]);
  // const [selectedBatch, setSelectedBatch] = useState<number>(0); // Initial state set to 0
  const [selectedBatch, setSelectedBatch] = useState<string | number>(''); // Now it can be a string or a number
  const [widgets, setWidgets] = useState<DashboardWidget[]>(AklanDashboard);
  // const [widgets, setWidgets] = useState<DashboardWidget[]>([]);
  

  const [loading, setLoading] = useState(false);
  const [locationFilters, setLocationFilters] = useState<any>({});

  // Set breadcrumb on component mount
  useEffect(() => {
    setBreadcrumb([]);
    console.log("Breadcrumb set to empty");
  }, []);


  // Fetch responses based on selectedBatch
  const getResponses = async (selectedBatch: AssessmentBatchModel) => {
    try {
      setLoading(true);
      const res = await getBatchDashboardData(selectedBatch.id!, selectedBatch.indicator_id);
      console.log("Fetched responses:", res);
      setResponses(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching batch data", error);
    }
  };

  
  useEffect(() => {
  // Check if selectedBatch is undefined or falsy, and retrieve from localStorage if so
  if (!selectedBatch) {
      const savedBatch = localStorage.getItem('selectedBatch');
      if (savedBatch) {
        // Parse the saved batch from localStorage and set it as selectedBatch
        setSelectedBatch(savedBatch);
      }
    }

    console.log("Selected Batch in useEffect:", selectedBatch);
    console.log("User data : ", user);
    user.dashboard_templates = aklan_dashboards;

    if (selectedBatch) {
      // Find the selected batch in the assessmentBatches array
      const selected = assessmentBatches.find((batch) => batch.id === selectedBatch);

      console.log("Found selected batch in assessmentBatches:", selected);
      console.log("user.dashboard_templates:", user.dashboard_templates);

      // Handle case where dashboard_templates may be undefined
      if (user && user.dashboard_templates) {
        if (selected) {
          // Find the template from user.dashboard_templates based on dashboard_code
          const template = user.dashboard_templates.find(
            (template) => template.dashboard_code === selected.dashboard_code
          );

          console.log("Found template for selected batch:", template);

          if (template) {
            console.log("Found dashboard template for selected batch");
            setWidgets(template.template);
            getResponses(selected); // Call the API to fetch the responses for the selected batch
          } else {
            console.log("No template found for selected batch");
            setWidgets([]);
            setResponses([]);
            setLoading(false);
          }
        } else {
          console.log("Selected batch not found in assessmentBatches");
          setLoading(false);
        }
      } else {
        console.log("User dashboard templates are undefined or not loaded.");
        // Handle default or fallback template behavior if needed
        setWidgets([]);  // Default fallback behavior
        setResponses([]);
        setLoading(false);
      }
    }
  }, [user, selectedBatch, assessmentBatches]); // Re-run when selectedBatch or assessmentBatches change

  // Update widgets and responses based on selectedBatch
  // useEffect(() => {
  //   console.log("Selected Batch in useEffect:", selectedBatch);
  //   if (selectedBatch) {
  //     const selected = assessmentBatches.find((batch) => batch.id === selectedBatch);
  //     console.log("Found selected batch in assessmentBatches:", selected);
  //     console.log("user.dashboard_templates:", user.dashboard_templates);
  //     if (selected) {
  //       const template = user.dashboard_templates.find(
  //         (template) => template.dashboard_code === selected.dashboard_code
  //       );
  //       console.log("Found template for selected batch:", template);
  //       if (template) {
  //         console.log("Found dashboard template for selected batch");
  //         setWidgets(template.template);
  //         getResponses(selected); // Call the API to fetch the responses for the selected batch
  //       } else {
  //         console.log("No template found for selected batch");
  //         setWidgets([]);
  //         setResponses([]);
  //         setLoading(false);
  //       }
  //     } else {
  //       console.log("Selected batch not found in assessmentBatches");
  //       setLoading(false);
  //     }
  //   }
  // }, [user, selectedBatch, assessmentBatches]); // Re-run when selectedBatch or assessmentBatches change


  // Fetch assessment batches
  useEffect(() => {
    const fetchAssessmentBatches = async () => {
      try {
        const response = await getAssessmentBatches({ length: 1000, status: ['active', 'closed'] });
        if (response && response.data && response.data.rows) {
          setAssessmentBatches(response.data.rows);
          const savedBatch = localStorage.getItem('selectedBatch');

          if (savedBatch && response.data.rows.some((batch: any) => batch.value === savedBatch)) {
            setSelectedBatch(savedBatch);
          } else if (response.data.rows.length > 0) {
            const defaultBatch = response.data.rows[0].value;
            setSelectedBatch(defaultBatch); // Make sure this is a string if necessary
            localStorage.setItem('selectedBatch', defaultBatch); // Save to localStorage
          }else{
            setSelectedBatch('0'); // Default to '0' as a string
          }
        }
      } catch (error) {
        console.error('Failed to fetch assessment batches:', error);
      }
    };

    fetchAssessmentBatches();
  }, []);

  
  // Handle changes to selected batch
  const handleBatchChange = (value: any) => {
    if (value) {
      const selectedValue = value.value;
      console.log("Batch selected:", selectedValue);
      setSelectedBatch(selectedValue);
      localStorage.setItem('selectedBatch', selectedValue); // Save to localStorage
    } else {
      setSelectedBatch(0);
    }
  };

  
  // Generate chart based on widgets and location filters
  // const chart = useMemo(() => {
  //   return (
  //     <>
  //       {widgets.length ? (
  //         widgets.map((chart) => {
  //           return getChartData(responses, chart, locationFilters);
  //         })
  //       ) : (
  //         <Typography variant="h5" fontWeight="bold">
  //           No widgets to display for this batch.
  //         </Typography>
  //       )}
  //     </>
  //   );
  // }, [widgets, responses, locationFilters]); // Recalculate when widgets, responses, or locationFilters change
  const chart = useMemo(() => {
    return (
      <>
        {widgets.map((chart, index) => {
          return <Fragment key={index}>{getChartData(responses, chart, locationFilters)}</Fragment>;
        })}
      </>
    );
  }, [widgets, responses, locationFilters]);

  // Clear all location filters
  const clearFilter = () => {
    const resetFilters = {};
    setLocationFilters(resetFilters);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <Header title="DASHBOARD" subtitle="Welcome to your dashboard" mb="0" />
      <Box width="350px">
        <RegularButton label="Aklan PCF Table" onClick={() => navigate('/test-table')} />
      </Box>
      <CustomContainer>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" gap="10px" width="100%" alignItems="center">
            <FacilityLocationFilter
              handleChangeFilter={(data) => setLocationFilters({ ...locationFilters, ...data })}
              otherFilters={locationFilters}
              showFacilityDropdown
              hideFacilityType
            />
            <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
          </Box>
          <Box width="350px">
            <CustomAutoCompleteSelect
              label="Assessment Batch"
              fieldName="assessment_batch"
              value={selectedBatch}
              handleChangeCallback={(value) => handleBatchChange(value)}
              options={assessmentBatches.map((batch) => ({
                key: batch.batch_name, // or the correct field name from the API response
                value: batch.id, // same here
              }))}
            />
          </Box>
        </Box>
      </CustomContainer>
      {selectedBatch ? (
        <>
          {widgets.length ? (
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="180px" gap="20px">
              {chart}
            </Box>
          ) : (
            <CustomContainer>
              <Typography variant="h5" fontWeight="bold">
                The selected batch has no dashboard.
              </Typography>
            </CustomContainer>
          )}
        </>
      ) : (
        <CustomContainer>
          <Typography variant="h5" fontWeight="bold">
            Please select a batch
          </Typography>
        </CustomContainer>
      )}
    </Box>
  );
};

export default Home;




