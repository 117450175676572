import * as React from 'react';

import { Box, Button, Divider, IconButton, Popover, Tooltip, Typography, useTheme } from '@mui/material';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { tokens } from 'context/theme.context';

export type DropdownOptionProps = {
  label: string;
  action?: (data?: any) => void;
  hidden?: boolean;
  hideCallback?: (data: any) => boolean;
  startIcon?: any;
};

type DropdownProps = {
  optionList: DropdownOptionProps[];
  data?: any;
  buttonLabel?: any;
  isIcon?: boolean;
  icon?: any;
  tooltip?: string;
  tooltipPlacement?: any;
  disabled?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
  optionList,
  data,
  buttonLabel,
  isIcon,
  icon,
  tooltip,
  tooltipPlacement,
  disabled,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        {isIcon ? (
          <Tooltip title={tooltip} placement={tooltipPlacement ?? 'left'} arrow>
            <IconButton
              size="small"
              ref={anchorRef}
              id="composition-button"
              onClick={handleToggle}
              sx={{ padding: '2px' }}
              disabled={disabled}
            >
              {icon}
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            variant="contained"
            onClick={handleToggle}
            ref={anchorRef}
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            sx={{
              backgroundColor: colors.blueAccent[600],
              textTransform: 'none',
              color: 'white',
              // marginRight: '1rem',
            }}
            startIcon={icon}
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        )}
        <Popover
          open={open}
          id="popover"
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="dropdown-menu" onKeyDown={handleListKeyDown}>
                {optionList.map((option) =>
                  option.label === 'divider' ? (
                    <Divider key={option.label} />
                  ) : (
                    <div key={option.label}>
                      {!option.hidden && (!option.hideCallback || !option.hideCallback(data)) && (
                        <MenuItem
                          onClick={() => {
                            option.action && option.action(data);
                            setOpen(false);
                          }}
                        >
                          <Box display="flex" gap="10px" alignItems="center">
                            {option.startIcon && (
                              <Typography sx={{ lineHeight: 1, '& .MuiSvgIcon-root': { fontSize: '15px' } }}>
                                {option.startIcon}
                              </Typography>
                            )}
                            <Typography>{option.label}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                    </div>
                  )
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popover>
      </div>
    </Stack>
  );
};

export default Dropdown;
