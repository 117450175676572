/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Table, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { NotApplicable } from '.';
import { tokens } from 'context/theme.context';

type SimpleTableProps = {
  rows: any[];
  headers: any[];
  headerCount: number;
  headerWidth: number;
  handleBodyClick: (field: any) => void;
  search: string;
  getHighlightedText: (text: string, searckKey: string) => any;
  fieldsWithComments: string[];
  level: number;
};

const SimpleTable: React.FC<SimpleTableProps> = ({
  headers,
  headerCount,
  headerWidth,
  rows,
  handleBodyClick,
  search,
  getHighlightedText,
  fieldsWithComments,
  level,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const headerRef: any = useRef();

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = headerRef.current;
      const rect = header.getBoundingClientRect();
      setIsSticky(rect.top - 50 <= 0 && rect.bottom - 150 >= 0 && rows.length > 5);
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return (
    <Box sx={{ overflowX: 'auto', border: '1px solid ' + colors.border }}>
      <Table
        ref={headerRef}
        sx={{
          border: '1px solid ' + colors.border,
          '& th, & td': {
            border: '1px solid ' + colors.border,
            padding: '5px',
          },
          '& tr:hover': {
            background: colors.hoverBackground,
          },
          '& tbody tr:nth-of-type(odd)': {
            backgroundColor: colors.solidHoverBackground,
          },
        }}
      >
        <thead>
          <tr>
            <th></th>
            {headers.map((item: string, index: number) => {
              return (
                <th style={{ width: `${headerWidth}%` }} key={index}>
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows
            .filter((row) => row.field_name.toLowerCase().includes(search.toLowerCase()))
            .map((row: any, index: number) => {
              return (
                <tr key={index} onClick={() => handleBodyClick(row)}>
                  <td style={{ minWidth: '340px' }}>
                    <Box display="flex" justifyContent="space-between" gap="20px">
                      <Box display="grid" gridTemplateColumns="45px 1fr">
                        <Typography variant="body1" color={colors.text}>
                          {getHighlightedText(row.field_code, search)}.
                        </Typography>
                        <Typography variant="body1" color={colors.text} style={{ whiteSpace: 'pre-wrap' }}>
                          {getHighlightedText(row.field_name, search)}
                        </Typography>
                      </Box>

                      {(row.remarks || fieldsWithComments.includes(row.field_code)) && (
                        <ModeCommentOutlinedIcon style={{ color: colors.main }} />
                      )}
                    </Box>
                    {row.field_description && (
                      <Typography
                        variant="body2"
                        color={colors.text}
                        p={1}
                        style={{ textIndent: '30px', whiteSpace: 'pre-wrap' }}
                      >
                        {row.field_description}
                      </Typography>
                    )}
                  </td>
                  <>
                    {row.indicator_not_applicable ? (
                      <td colSpan={headerCount}>
                        <NotApplicable color={colors} />
                      </td>
                    ) : (
                      <>
                        {headers.map((item: string, index: number) => {
                          return (
                            <td style={{ textAlign: 'center' }} key={index}>
                              {item === row.response ? '✘' : ''}
                            </td>
                          );
                        })}
                      </>
                    )}
                  </>
                </tr>
              );
            })}
        </tbody>
      </Table>

      {/* {isSticky && (
        <Box
          width="100%"
          display="grid"
          gridTemplateColumns={`${100 - headerWidth * headers.length}% repeat(${headers.length}, 1fr)`}
          className="sticky-header fixed"
          sx={{
            backgroundColor: colors.secondary_background,
            zIndex: 1000,
            borderBottom: `2px solid ${colors.lightGreen3}`,
            // width: `calc(100% - 235px)`,
            // minWidth: 'calc(900px - 50px)',
            width: `calc(100% - ${135 + level * 52}px)`,
          }}
        >
          <Box></Box>
          {headers.map((column, index) => (
            <Box
              key={index}
              sx={{ padding: '10px', borderLeft: '0.5px solid ' + colors.border }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography fontWeight="bold" textAlign="center">
                {column} {level}
              </Typography>
            </Box>
          ))}
        </Box>
      )} */}
    </Box>
  );
};

export default SimpleTable;
