import { Box, Button, Modal, TextField, useTheme } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { ConfirmationDialog } from 'components';
import React from 'react';
import { styled } from '../stiches.config';
import { tokens } from 'context/theme.context';
import { useState } from 'react';

interface ColumnProps {
  list: any[];
  column_id: string;
  startEditingWidget: (widget: any) => void;
  duplicateWidget: (widget: any) => void;
  confirmDeleteWidget: (
    widget_id: number,
    column_id: string,
    setShowDeleteConfirmation: (val: boolean) => void
  ) => void;
}

const Column: React.FC<ColumnProps> = ({
  list,
  column_id,
  startEditingWidget,
  duplicateWidget,
  confirmDeleteWidget,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [widgetToDelete, setWidgetToDelete] = useState<number>(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [editedTitle, setEditedTitle] = useState<string>('');

  const handleConfirmDelete = async () => {
    confirmDeleteWidget(widgetToDelete, column_id, setShowDeleteConfirmation);
  };

  const StyledList = styled('div', {
    border: '1px dashed ' + colors.dark,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '20px',
  });

  const getComponent = (widget: any, index: number, isDragging: boolean) => {
    return <></>;
  };

  return (
    <Droppable droppableId={column_id}>
      {(provided) => (
        <Box display="flex">
          {list && (
            <StyledList {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((widget, index) => {
                return (
                  <Draggable key={widget.id} draggableId={(widget.id ?? index).toString()} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {getComponent(widget, index, snapshot.isDragging)}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </StyledList>
          )}
          <ConfirmationDialog
            open={showDeleteConfirmation}
            setOpen={setShowDeleteConfirmation}
            message="Are you sure you want to delete this widget?"
            onConfirm={handleConfirmDelete}
            cancelButtonLabel="No"
            confirmButtonLabel="Yes"
          />
          <Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
            <Box>
              <TextField label="Title" value={editedTitle} onChange={(e) => setEditedTitle(e.target.value)} />

              <Button
                onClick={() => {
                  setIsEditModalOpen(false);
                }}
              >
                Save
              </Button>
              <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
            </Box>
          </Modal>
        </Box>
      )}
    </Droppable>
  );
};

export default Column;
