/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, InputBase, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRowParams, GridSelectionModel, GridSortDirection } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';

import { ApiQuery } from 'model/interface';
import CustomIconButton from './buttons/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from 'context/theme.context';

type CustomTableProps = {
  rows: any[];
  rowCount: number;
  columns: GridColDef[];
  checkboxSelection?: boolean;
  loading?: boolean;
  rowId?: string;
  getData: (query: ApiQuery, others?: any) => void;
  isRowSelectable?: boolean;
  customSelectableRow?: (params: GridRowParams) => boolean;
  handleSelectRow?: (selectionModel: GridSelectionModel) => void;
  hideSearch?: boolean;
  searchKeys?: string;
  disableSelectionOnClick?: boolean;
  keepNonExistentRowsSelected?: boolean;
  tableHeight?: string | number;
  rowHeight?: number;
  forceRefresh?: number;
  clearSelected?: number;
  headerComponent?: React.ReactNode;
  filterComponent?: React.ReactNode;
  initialPage?: number;
  initialPageSize?: number;
  initialOrder?: GridSortDirection;
  initialOrderBy?: string;
  otherFilters?: any;
};

const CustomTable: React.FC<CustomTableProps> = ({
  rows,
  rowCount,
  columns,
  checkboxSelection,
  loading,
  rowId = 'id',
  getData,
  isRowSelectable,
  customSelectableRow,
  handleSelectRow,
  hideSearch,
  searchKeys,
  tableHeight,
  rowHeight = 40,
  forceRefresh,
  clearSelected,
  disableSelectionOnClick,
  keepNonExistentRowsSelected,
  headerComponent,
  filterComponent,
  initialPage,
  initialPageSize,
  initialOrder,
  initialOrderBy,
  otherFilters,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [firstLoad, setFirsLoad] = useState(true);
  const [page, setPage] = useState<number>(initialPage ?? 0);
  const [pageSize, setPageSize] = useState<number>(initialPageSize ?? 10);
  const [order, setOrder] = useState<GridSortDirection>(initialOrder ?? 'asc');
  const [orderBy, setOrderBy] = useState<string>(initialOrderBy ?? '');
  const [search, setSearch] = useState<string>('');
  const prevSearchKeyRef = useRef('');

  const handleClickRow = (newSelectionModel: GridSelectionModel) => {
    if (JSON.stringify(newSelectionModel) === JSON.stringify(selectionModel)) {
      setSelectionModel([]);
      handleSelectRow && handleSelectRow([]);
    } else {
      setSelectionModel(newSelectionModel);
      handleSelectRow && handleSelectRow(newSelectionModel);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (search !== prevSearchKeyRef.current) {
        updatePageOrRefresh();
      }
    }
  };
  const updatePageOrRefresh = () => {
    if (page === 0) refreshData();
    else setPage(0);
    prevSearchKeyRef.current = search;
  };

  const refreshData = () => {
    getData({
      page: page + 1,
      length: pageSize,
      order_by: orderBy,
      order: order,
      search: search,
      ...otherFilters,
    });
  };

  const getTableHeight = () => {
    if (tableHeight) return tableHeight;

    const row = rowHeight;
    const multiplier = Math.min(Math.max(5, rowCount), 10);
    return row * multiplier + (85 - multiplier * 3);
  };

  useEffect(() => {
    if (clearSelected) setSelectionModel([]);
  }, [clearSelected]);

  useEffect(() => {
    if (!firstLoad) {
      updatePageOrRefresh();
    }
  }, [pageSize]);

  useEffect(() => {
    if (!firstLoad) {
      refreshData();
    }
  }, [forceRefresh]);

  useEffect(() => {
    refreshData();
    setFirsLoad(false);
  }, [page, order, orderBy, otherFilters]);

  return (
    <Box sx={{ width: '100%' }}>
      {filterComponent && headerComponent && <Box marginBottom="20px">{headerComponent}</Box>}
      <Box
        display="grid"
        gridTemplateColumns={hideSearch ? '1fr' : '1fr 30%'}
        columnGap="20px"
        alignItems="flex-end"
        sx={{ mb: '20px' }}
      >
        <Box>{filterComponent ?? headerComponent}</Box>
        {!hideSearch && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              background: 'transparent',
              border: '1px solid ' + colors.dark,
              borderRadius: '6px',
              height: '40px',
            }}
          >
            <InputBase
              sx={{ ml: 1.5, flex: 1, fontSize: '12px' }}
              placeholder={`Search${searchKeys ? ' by ' + searchKeys : ''}`}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <CustomIconButton
              icon={<SearchIcon />}
              onClick={() => {
                if (search !== prevSearchKeyRef.current) {
                  updatePageOrRefresh();
                }
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        height={getTableHeight()}
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-row': {
            fontSize: 13,
          },
          '& .emphasis': {
            color: colors.accent,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '2px solid ' + colors.dark,
            fontSize: 13,
          },
          '& .MuiDataGrid-menuIcon': {
            display: 'none !important',
          },
          '& .MuiDataGrid-virtualScroller': {
            // backgroundColor: colors.primary,
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
          },
          '& .MuiCheckbox-root': {
            color: `${colors.secondary} !important`,
            padding: '1px',
          },
          '& .MuiCheckbox-root.Mui-disabled': {
            color: `${colors.dark} !important`,
            padding: '1px',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.text} !important`,
          },
          '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none !important',
          },
          '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
            backgroundColor: theme.palette.mode === 'light' ? '#beddc6' : undefined,
          },
          '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: theme.palette.mode === 'light' ? '#beddc6' : undefined,
          },
          '& .MuiCircularProgress-root': {
            color: colors.dark,
          },
          '& .MuiDataGrid-cell': {
            paddingBlock: '5px !important',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
          '& .MuiDataGrid-cellContent': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& .MuiDataGrid-columnSeparator': {
            color: `${colors.dark} !important`,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 13,
            fontWeight: 'bold',
          },
        }}
      >
        <DataGrid
          rows={rows}
          rowBuffer={10}
          rowCount={rowCount}
          loading={loading}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          sortModel={[{ field: orderBy, sort: order }]}
          checkboxSelection={checkboxSelection}
          isRowSelectable={customSelectableRow ? customSelectableRow : () => !!isRowSelectable || !!checkboxSelection}
          onSelectionModelChange={checkboxSelection ? handleClickRow : undefined}
          selectionModel={selectionModel}
          columns={columns}
          density="compact"
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          onSortModelChange={(model) => {
            if (model.length) {
              setOrder(model[0].sort);
              setOrderBy(model[0].field);
            }
          }}
          getRowId={(row: any) => row[rowId]}
          getRowHeight={() => 'auto'}
          disableSelectionOnClick={disableSelectionOnClick}
          keepNonExistentRowsSelected={keepNonExistentRowsSelected}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          onCellClick={(params) => {
            if (params.field !== 'action' && isRowSelectable && !checkboxSelection) {
              handleClickRow([params.id]);
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomTable;
