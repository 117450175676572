import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'group-indicator-assessor';

export const updateGroupIndicatorAssessor = (groupID: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${groupID}`, data);
};

export const getGroupIndicatorAssessors = (groupID: number) => {
  return axios.get(`${baseURL}/${path}/${groupID}`);
};
