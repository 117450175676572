/* eslint-disable react-hooks/exhaustive-deps */

import { CustomContainer, CustomGridCell } from 'components';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import { GridColDef } from '@mui/x-data-grid';
import Header from 'components/Header';
import { UserModel } from 'model/Entities';
import { formatDateTime } from 'utils';
import { getActivityLogs } from 'api/activity-log';

const ActivityLogs = () => {
  const [rows, setRows] = useState<UserModel[]>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const getActLog = async (query: ApiQuery) => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getActivityLogs(query);
        setRows(res.data.rows);
        setUserCount(res.data.count);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      }
    };

    fetchData();
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Activity Logs' }]);
  }, []);

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'username',
      sortable: false,
      headerName: 'Username',
      flex: 1.5,
      renderCell: (params) => <CustomGridCell>{params.row.user.username}</CustomGridCell>,
    },
    {
      field: 'activity',
      sortable: false,
      headerName: 'Activity',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{params.row.activity}</CustomGridCell>,
    },
    {
      field: 'date_and_time',
      sortable: false,
      headerName: 'Date and Time',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.updated_at)}</CustomGridCell>,
    },
  ];

  return (
    <Box>
      <CustomContainer>
        <CustomTable
          columns={columns}
          rows={rows}
          rowCount={userCount}
          loading={loading}
          getData={getActLog}
          headerComponent={<Header title="Activity Logs" mb="0" />}
          hideSearch
        />
      </CustomContainer>
    </Box>
  );
};

export default ActivityLogs;
