// to be added: notes for table data
export const pcf_document = [
  {
    section: 1,
    section_name: 'PATIENT RIGHTS AND ORGANIZATION ETHICS',
    standard:
      "Organizational Policies and procedures which respect and support patients' rights to quality care and their responsibilities in that care",
    criteria: [
      {
        field_name: 'Informed consent is obtained from patients prior to procedures.',
        document_review: [
          'All patient charts have signed consent for procedures (example: minor surgery, immunization).',
        ],
      },
      {
        field_name: "Policies which identify and address patients' rights and responsibilities are documented",
        observe: ["Posted patients' rights in conspicuous places."],
      },
    ],
  },
  {
    section: 2,
    section_name: 'PATIENT CARE',
    standard:
      'The organization informs the community about the services it provides and the hours of their availability.',
    criteria: [
      {
        field_name: 'Clinical services are appropnate to patients\' needs and the former\'s availability 1s consistent with the organization\'s service capability and role',
        observe: [
          'List of services and schedule of operation posted in a conspicuous area.',
          'DOH LTO (updated, valid and original) posted in a conspicuous area.'
        ],
      },
      {
        field_name: 'Population-based Primary Care Services (Mandatory for Government PCFs)',
        document_review: [
          'Written policies and procedures based on DOH issued guidelines:',
          [
            'Helth promotion',
            'Epidemiologic surveillance',
            'Health Protection (vector control environmental health, occupational safety, and food safety measures)',
            'Emergency preparedness and response'
          ]
        ]
      }
    ],
  },
  {
    standard:
      'The organization uniquely identifies all patients and creates a specific patient record for each patient that is readily accessible to authorized personnel.',
    criteria: [
      {
        field_name:
          'All patients are correctly identified by their records. An appropriate history and patient physical examination performed on patient. The history includes present illness, past medical, family, social and personal history',
        document_review: [
          'Written policies and procedures based on DOH guidelines and Manual of Procedures:',
          [
            'Maternal and Newborn Care',
            'Family Planning Services',
            'Nutrition Services',
            'Dental Services (may be outsourced and/or located outside PCF through a valid MOA. Outsourced dental services shall comply with the licensing standards in this Assessment Tool)',
            'Referral services* to:',
            'Community-based Rehabilitation Services (example: physical therapy, occupational therapy, speech therapy)',
            'Developmental and mental health evaluation',
            'Substance abuse services',
          ]
        ],
        document_review_note: 'May be provided by PCF'
      },
    ],
  },
  {
    standard: 'The care plan addresses patient\'s relevant clinical needs.',
    criteria: [
      {
        field_name: 'Coordinated plan of care with goals',
        document_review: [
          'Proof of implementation of adopted/developed protocols, and DOH approved CPGs once available'
        ]
      }
    ]
  },
  {
    section: 3,
    section_name: 'LEADERSHIP AND MANAGEMENT',
    standard: 'The PCF\'s management team provides leadership, acts according to the organization\'s policies and has overall responsibility for the organization\'s operation, and the quality of its services and its resources',
    criteria: [
      {
        field_name: 'Organizational Structure/Chart',
        observe: [
          'Observe if the organizational structure/chart is posted in conspicuous area'
        ]
      },
      {
        field_name: 'The organization and its services develop their vision and mission',
        document_review: ['Writen vision and Mission'],
        observe: ['Posted vision and mission in a conspicuous area']
      },
      {
        field_name: 'Evaluation and monitoring activities to assess management and organizational performance (Required for government-owned PCFs. Recommended only for privately-owned PCFs.)',
        document_review: ['Accomplishment reports or other annual reports, as applicable.']
      }
    ]
  },
  {
    section: 4,
    section_name: 'HUMAN RESOURCE MANAGEMENT',
    standard: 'Workload is monitored and appropriate guidelines consulted to ensure that appropriate staff numbers and skill mix are available to achieve desired patient and organizational outcomes.',
    criteria: [
      {
        field_name: 'The organization documents and follows policies and procedures for hiring and credentialing of its staff.',
        document_review: ['Policies and procedures for hiring and credentialing of staff'],
        interview: ['Administrative Officer or Head of PCF']
      }
    ]
  },
  {
    standard: 'There are relevant activities related to orientation of new personnel',
    criteria: [
      {
        field_name: 'New personnel, new graduates and external contractors- are adequately supervised by qualified staff',
        document_review: ['Documentation of orientation conducted']
      }
    ]
  },
  {
    standard: ': There shall be an adequate number of qualified, trained and competent staff to ensure efficient and effective delivery of quality primary care services. The staff composition and number/ratio shall depend on. the workload and services being usted based on applicable workload assessment tools set by DOH.',
    criteria: [
      {
        field_name: 'Physician (Full time)',
        document_review: [
          'Proof of qualifications:',
          [
            'Resume',
            'Updated PRC ID',
            'Primary Care Worker Certificate',
            'Proof of relevant trainings',
            'Proof of Employment/ Appointment'
          ]
        ]
      },
      {
        field_name: 'Nurse (Full time)',
        document_review: [
          'Proof of qualifications:',
          [
            'Resume',
            'Updated PRC ID',
            'Primary Care Worker Certificate',
            'Proof of relevant trainings',
            'Proof of Employment/ Appointment'
          ]
        ]
      },
      {
        field_name: 'Midwife (Full time)',
        document_review: [
          'Proof of qualifications:',
          [
            'Resume',
            'Updated PRC ID',
            'Primary Care Worker Certificate',
            'Proof of relevant trainings',
            'Proof of Employment/ Appointment'
          ]
        ]
      },
      {
        field_name: 'Dentist',
        document_review: [
          'Proof of qualifications:',
          [
            'Resume',
            'Updated PRC ID',
            'Primary Care Worker Certificate',
            'Proof of Employment/ Appointment',
            'Valid Memorandum of Agreement (MOA), if outsourced'
          ]
        ]
      },
      {
        field_name: 'Sanitation Inspector (Government PCFs only)',
        document_review: [
          'Proof of qualifications:',
          [
            'Resume',
            'Updated PRC ID or Certificate of Career Service Eligibility',
            'Valid Memorandum of Agreement (MOA), if outsourced'
          ]
        ]
      },
      {
        field_name: 'Information Technology Office \n*may be allowed to handle administrative roles at a time',
        document_review: [
          'Proof of qualifications:',
          [
            'Resume',
            'Proof of Employment/ Appointment',
          ]
        ]
      },
      {
        field_name: 'Utility Worker',
        document_review: [
          'Proof of qualifications:',
          [
            'Resume',
            'Proof of Employment/ Appointment',
          ]
        ]
      }
    ]
  },
  {
    section: 5,
    section_name: 'INFORMATION MANAGEMENT',
    standard: 'Relevant, accurate, quantitative and qualitative data are collected and used in a timely and efficient manner for delivery of patient care and management of services',
    criteria: [
      {
        field_name: 'Records are stored, retained and disposed of in accordance with the guidelines set by National Archives of the Philippines (NAP)',
        document_review: ['Logbooks on record storage, retention and disposal'],
        observe: ['Proper Storage of records']
      },
      {
        field_name: 'The organization defines data sets, collection and aggregation methods and the qualified staff who are involved in each stage',
        document_review: ['Policies and procedures on record storage, safekeeping and maintenance, retention and displosal.']
      }
    ]
  },
  {
    standard: 'Clinical records are readily accessible to facilitate patient care, are kept confidential and safe, and comply with all relevant statutory requirements and codes of practice',
    criteria: [
      {
        field_name: 'Patient records documenting any previous care can be quickly retrieved for review, updating and concurrent use',
        observe: ['Patioent records are easily retrievable within 10-15 minutes']
      },
      {
        field_name: 'The organization has policies and procedures, and devotes resources, including infrastructure, to protect records and patient charts against loss, destruction, tampering and unauthorized access or use. Only authorized individuals make entries in the patient records',
        document_review: ['Logbooks for borrowing and retrieval of records'],
        observe: ['Access to records']
      },
      {
        field_name: 'Validated Electronic Medical Records',
        observe: ['EMR implementation includes, but is not limited to, primary care benefits, maternal and neonatal deaths, injury, and confirmed cases of diagnosis'],
      },
      {
        field_name: 'National Database of Human Resources for Health Information System (NDHRHIS)',
        document_review: ['Proof of submissoin of data to NDHRHIS']
      }
    ]
  },
  {
    section: 6,
    standard: 'The organization plans a safe and effective environment of care consistent with its mission, services, and with laws and regulations',
    criteria: [
      {
        field_name: 'An incident reporting system identifies potential harms, evaluates causal and contributing factors for the necessary corrective and preventive action',
        document_review: ['Record of incident reports']
      },
      {
        field_name: 'Presence of a management plan, policies and procedures addressing safety',
        document_review: [
          'Management plan, policies and procedures on safety',
          'Proof of implementation of the following:',
          [
            'Fire drill conducted in the past 12 months',
            'Earthquake drill conducted in the past 12 months'
          ]
        ]
      },
      {
        field_name: 'Building Maintenance Program is in place ensuring facilities are in state of good repair',
        document_review: ['Routine program of work for preventive maintenance and record of corrective maintenance are available']
      },
      {
        field_name: 'Policies and procedures for the safe and efficient use of medical equipment according to specifications are documented and implemented',
        document_review: [
          'Presence of operating manuals of the medical equipment',
          'Preventive and corrective maintenance logbook and plan for replacement'
        ]
      },
      {
        field_name: 'A coordinated security arrangement in the organization assures protection of patients and staff',
        document_review: ['Designation of person in charge of security.'],
        interview: ['Ask the personnel in charge of security what the policies on security are.'],
        observe: ['Security measures']
      }
    ]
  },
  {
    standard: 'Emergency light and/or power supply, water and ventilation systems are provided for, in keeping with relevant statutory requirements and codes of practice.',
    criteria: [
      {
        field_name: 'Generator, emergency light, water system, adequate ventilation or air conditioning',
        document_review: [
          'Bacterial water analysis done every 6 months.',
          'Proof of corrective measures done for failed bacterial water analysis.',
          'Preventive and corrective maintenance logbooks of generator, emergency light, ventilation and conditioning'
        ],
        observe: [
          'Test if faucets and water closets are working',
          'Functional emergency lights and generators'
        ]
      },
      {
        field_name: 'Non-medical equipment are regularly maintained with plan for replacement according to expected life span or when no longer serviceable',
        document_review: ['Records of preventive and corrective maintenance and plan for replacement']
      },
      {
        field_name: 'Operating manuals of nonmedical equipment',
        document_review: ['Operating manuals of equipment, generators, air conditioners and other nonmedical equipment']
      }
    ]
  },
  {
    standard: 'The handling collection and disposal of waste conform with relevant statutory requirements and code of practice',
    criteria: [
      {
        field_name: 'Policies and procedures on Waste Disposal Management',
        document_review: [
          'Issuances — laws, memos, guidelines on waste segregation, collection, treatment and disposal',
          'Contracts with service providers, waste handlers or disposal contractors (if applicable)'
        ],
        observe: [
          'Location of waste holding area',
          'Segregation of waste (use of color coded garbage plastic and/or bins)',
          'Proper labelling of waste receptacles',
          'Proper management of temporary storage areas prior to hauling for disposal'
        ]
      }
    ]
  },
  {
    standard: 'An interdisciplinary infection control program ensures the prevention and control of infection in all services.',
    criteria: [
      {
        field_name: 'Infection Prevention and Control (IPC) Program',
        document_review: ['IPC Manual']
      },
      {
        field_name: 'Policies and procedures on cleaning, disinfecting, drying, packaging and sterilizing of equipment, instruments and supplies',
        document_review: ['Policies and procedures on cleaning, disinfecting, drying, packaging and sterilizing of equipment, instruments and supplies']
      }
    ]
  },
  {
    standard: 'The organization uses a coordinated system-wide approach to reduce the risks of healthcare-associated infections.',
    criteria: [
      {
        field_name: 'Organization takes steps to prevent and control outbreaks of healthcare associated infections.',
        document_review: [
          'Validate PCF policies on infection control such as use of PPEs, isolation precautions and hand washing.',
          'Written policies and proceduresin accordance with DOH issuances.'
        ],
        observe: [
          'Use of gloves, surgical masks, etc., as needed',
          'Sinks or lavatories or designated areas for hand washing or dispenser for sanitizers',
          'Ask a PCF staff to demonstrate hand washing'
        ]
      },
      {
        field_name: 'There are programs for prevention and treatment of needle stick injuries, and policies and procedures for the safe disposal of used needles are documented and monitored',
        document_review: ['Reports of needle stick injuries'],
        interview: ['Ask staff their policies on needle stick injury'],
        observe: ['Use of PPEs in doing minor surgeries, handling patients with infectious diseases etc']
      }
    ]
  },
  {
    standard: 'When needed, the organization reports information about infections to personnel and public health agencies.',
    criteria: [
      {
        field_name: 'Policies and procedures in reporting notifiable diseases (Refer to AO No. 2008-0009 and AO No. 2020- 0013).',
        document_review: [
          'Copies of reports submitted to PIDSR and other applicable DOH recording and notification systems.'
        ]
      }
    ]
  },
  {
    section: 7,
    section_name: 'IMPROVING PERFORMANCE',
    standard: 'The organization has a planned systematic organization-wide approact to process design and performance measurement, assessment and improvement.',
    criteria: [
      {
        field_name: 'Continuous Quality Improvement (CQI) Program',
        document_review: [
          'CQI plan and proof of implementation'
        ],
        interview: [
          'Ask about their activities on CQI.'
        ],
      }
    ]
  },
  {
    standard: 'The organization provides better care service as a result of continuous quality improvement activities',
    criteria: [
      {
        field_name: 'Customer satisfaction survey',
        document_review: [
          'Domains of the survey form used.',
          'Survey results and how complaints/comments are acted upon.'
        ]
      }
    ]
  },
  {
    section: 8,
    section_name: 'PHYSICAL PLANT',
    criteria: [
      {
        field_name: 'Entrances and exits are clearly and prominently marked, free of any obstruction and readily accessible.',
        observe: [
          'Posted entrance and exit signs',
          'Entrances and exits are accessible and free from any obstruction',
          // note here
        ]
      },
      {
        field_name: 'Directional signs are prominently posted to help locate service areas within the organization.',
        observe: ['Directional signs are prominently posted.']
      },
      {
        field_name: 'Ramps for patients with special needs are available, clearly and prominently marked and free of any obstruction.',
        observe: [
          'Ramps for patients with special needs',
          [
            'Prominently marked',
            'Free from obstruction'
          ]
        ]
      }
    ],
  },
  {
    section: 9,
    section_name: 'PUBLIC ACCESS TO PRICE INFORMATION',
    standard: 'Pursuant to Administrative Order No. 2021-0008: “Guidelines in Public Access to Price Information of All Health Services and Goods in Health Facilities in the Philippines”',
    criteria: [
      {
        field_name: 'The price list shall be readily available to the public in a conspicuous area.',
        observe: [
          'Availability of the price list in a conspicuous area, such as, but not limited to, the lobby, reception area, information kiosk and business office.',
          'The price list may be presented in any form but not limited to, the following:',
          [
            'Printed handout',
            'Menu booklet',
            'Interactive digital form',
            'Posters or tarpaulins'
          ]
        ]
      },
      {
        field_name: 'The price list of all health services shall be itemized comprehensively and all fees indicated clearly, including outsourced services, if applicable.',
        document_review: [
          'The price list shall include, but not limited to, the following:',
          [
            'Price per type of accomodation, critical care units and emergency room',
            'Fees for medical and surgical procedures',
            'Price of laboratory tests',
            'Professional fees',
            'Proce of drugs, medicines, and medical supplies',
            'Bundle/package price of health services',
            'Corresponding PhilHealth case rate packages and Z-package rates, if applicable',
            'Corresponding Health Maintenance Organization (HMO) rates, if applicable'
          ]
        ],
        document_review_note: 'May be presented in ranges, if deemed appropriate'
      },
      {
        field_name: 'For health facilities that have official website, the price list shall be readily available and regularly updated',
        observe: [
          'Updated price list is available on the health facility\'s official website.',
          'The date when the price list was last updated shall be indicated.'
        ]
      },
      {
        field_name: 'The health facility shall update the proce list at least annually, or as needed.',
        document_review: [
          'Price list is updated at least annually.',
          'The date when the price list was last updated shall be indicated.'
        ]
      },
      {
        field_name: 'The patient or patient\'s guardian shall be informed of the price list upon admission or before provision of outpatient services or procedures.',
        document_review: [
          'Documented proof that the price list, including the No Balance Billing policy for basic accommodation, was presented and explained.',
          'Documented proof that the information was understood and accepted by the patient or patient\'s guardian.'
        ]
      },
      {
        field_name: 'All health facilities shall submit information regarding its prices and charges for goods and health services, including profressional fees to PhilHealth.',
        document_review: ['Proof of submission of data to the information system of DOH and PhilHealth, once the system is fully functional.']
      }
    ]
  }
];

export const pcf_equipment_document = [
  {
    equipment_type: 'MEDICAL EQUIPMENT AND INSTRUMENTS IN PCF',
    equipments: [
      { name: 'Autoclave, 20 L' },
      { name: 'BP apparatus, non-mercurial, with adult and pediatric cuffs' },
      {
        name: 'Cervical Inspection Set/Vaginal Speculum Set',
        variant: [
          'Small size',
          'Medium size',
          'Large size',
        ]
      },
      {
        name: 'Dressing set (minor surgical set)',
        variant: [
          'Surgical scissors straight',
          'Surgical scissors curved',
          'Bandage scissors',
          'Pick up (ovum) forceps',
          'Mosquito forceps',
          'Tissue forceps with teeth',
          'Tissue forceps without teeth',
          'Suture removal scissors',
        ]
      },
      {
        name: 'EENT Diagnostic Set',
        variant: [
          'Ophthalmoscope',
          'Otoscope'
        ]
      },
      { name: 'Emergency light' },
      { name: 'Examining light' },
      { name: 'Examining table' },
      { name: 'Foot stool' },
      { name: 'Instrument table' },
      { name: 'IV stand' },
      { name: 'Nebulizer' },
      { name: 'Neurohammer' },
      { name: 'Non-mercurial thermometer' },
      { name: 'Salter scale' },
      { name: 'Safety/Sharps collector box' },
      { name: 'Snellen\'s Chart, Visual Acuity Chart' },
      { name: 'Stethoscope' },
      { name: 'Weighing scale with height measuring stick, adult' },
      { name: 'Weighing scale, infant' },
      { name: 'Wheelchair' },
      { name: 'Wheeled stretcher' },
      { name: 'Vaccine carrier with cold dog' },
      { name: 'Vaccine carrier thermometer' },
      { name: 'Vaccine refrigerator' }
    ]
  },
  {
    equipment_type: 'DENTAL EQUIPMENT AND INSTRUMENTS',
    type_comment: '(Outsourced dental service located outside PCF shallstill be inspected and comply with the following licensing requirements)',
    equipments:[
      { name: 'Autoclave, 20 L' },
      { name: 'Dental Unit and chair with compressor and complete accessories, with high and low speed hand pieces' },
      {
        name: 'Dental prophylaxis instrumentset:',
        variant: [
          'Universal scaler, non-magnetic hollow handle',
          'Peri Curette, non-magnetic hollow handle',
          'Periodontal probe',
          'Gracey curette, set of 6 different tips, non-magnetic hollow handle',
        ]
      },
      { name: 'Dental instruments: mouth mirror, cotton plier, explorer, spoon excavator (1 set)' },
      {
        name: 'Basic Dental Surgery Set',
        variant: [
          'Extraction forcep, #16 with cross serration (for better grip)',
          'Ergonomic for better comfort designed instrument',
          'Extraction forcep, #17',
          'Extraction forcep, #18L',
          'Extraction forcep, #18R',
          'Extraction forcep, #44',
          'Extraction forcep, #69',
          'Extraction forcep, #150',
          'Extraction forcep, #151',
          'Pedo forcep, #150',
          'Pedo forcep, #151',
          'Pedo forcep, #17S',
          'Pedo forcep, #16S',
          'Pedo forcep, #18R',
          'Pedo forcep, #18L',
        ]
      },
      { name: 'Aspirating syringe (2), stainless steel, with locking mechanism' },
      { name: 'Minnesota retractor, stainless steel' },
      { name: 'Bone file,stainless steel' },
      { name: 'Dental instrument cabinet' },
      { name: 'Instrument table' },
      { name: 'Sterilizing unit, table top' },
      { name: 'Sharp waste disposal unit' },
    ]
  },
  {
    equipment_type: 'DENTAL EQUIPMENT AND INSTRUMENTS',
    equipments: [
      { name: 'Computer/laptop with internet connection (mobile data, Ethernet)' },
      { name: 'Printer' },
      { name: 'Mobile phone/cellphone' },
      { name: 'Fire extinguisher' },
      { name: 'Standby generator set' },
    ]
  }
]