import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'assessment-comment';

export const getAssessmentComment = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const createAssessmentComment = (comment: any) => {
  return axios.post(`${baseURL}/${path}`, comment);
};

export const updateAssessmentComment = (id: number, comment: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, { comment });
};

export const updateAssessmentCommentStatus = (id: number, status: string) => {
  return axios.patch(`${baseURL}/${path}/${id}`, { status });
};

export const updateAllAssessmentCommentStatus = (assessment_id: number, field_code: string) => {
  return axios.patch(`${baseURL}/${path}/`, { assessment_id, field_code });
};

export const deleteAssessmentComment = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
