import { Box, Typography, useTheme } from '@mui/material';

import { tokens } from 'context/theme.context';

type HeaderProps = {
  title: string;
  subtitle?: string;
  mb?: string;
};

const Header: React.FC<HeaderProps> = ({ title, subtitle, mb }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb={mb ?? '30px'}>
      <Typography
        variant="h3"
        component="h2"
        color={colors.text}
        fontWeight="bold"
        sx={{ m: '0 0 5px 0' }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="subtitle1" color={colors.accent}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
