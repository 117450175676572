import { Box, Typography, useTheme } from '@mui/material';

import ProgressCircle from './ProgressCircle';
import { tokens } from 'context/theme.context';

type CounterBoxProps = {
  count: number;
  total: number;
  title: string;
};

const CounterBox: React.FC<CounterBoxProps> = ({ count, total, title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      borderRadius={2}
      paddingY={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: colors.secondary_background,
      }}
    >
      <Box width="100%" m="0 30px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="10px"
        >
          <Box>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.text }}
            >
              {count}/{total}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: colors.accent, marginTop: '5px' }}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <ProgressCircle progress={(count / total).toFixed(2)} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CounterBox;
