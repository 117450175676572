import { UserModel } from 'model/Entities';
import { Credentials } from 'model/LoginModel';
import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'user';

export const login = (credentials: Credentials) => {
  return axios.post(`${baseURL}/auth/login`, credentials);
};

export const logout = () => {
  const refresh_token = localStorage.getItem('refresh_token');
  return axios.post(`${baseURL}/auth/logout`, { refresh_token });
};

export const refreshToken = () => {
  const refresh_token = localStorage.getItem('refresh_token');
  return axios.post(`${baseURL}/auth/refresh-token`, { refresh_token });
};

export const getCurrentUser = () => {
  return axios.get(`${baseURL}/${path}`);
};

export const registerUser = (user: UserModel) => {
  return axios.post(`${baseURL}/${path}`, user);
};

export const updateUser = (id: number, user: UserModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, user);
};

export const deleteUser = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getAccounts = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/all-accounts`, { params: query });
};

export const getFieldAssessors = (type: string, facility_id: number) => {
  return axios.get(`${baseURL}/${path}/list/dropdown`, {
    params: { type, facility_id },
  });
};
export const getUsersByFacility = (facility_id: number) => {
  return axios.get(`${baseURL}/${path}/list/facility-accounts/${facility_id}`, {
    params: { facility_id },
  });
};
