import { Dropdown } from 'components';
import { DropdownOptionProps } from 'components/Dropdown';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

type EditFieldDropdownProps = {
  optionList: DropdownOptionProps[];
};

const EditFieldDropdown: React.FC<EditFieldDropdownProps> = ({ optionList }) => {
  return (
    <Dropdown
      isIcon
      icon={<SettingsOutlinedIcon color="primary" />}
      tooltip="Edit"
      tooltipPlacement="top"
      optionList={optionList}
    />
  );
};

export default EditFieldDropdown;
