/* eslint-disable react-hooks/exhaustive-deps */

import 'reactflow/dist/style.css';
import './components/style.css';

import { Box, useTheme } from '@mui/material';
import { CustomForm, CustomModal, RegularButton } from 'components';
import { FieldInput, SelectOption } from 'model/interface';
import { FormValidationWorkflowModel, UserGroupModel } from 'model/Entities';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  Edge,
  MarkerType,
  Panel,
  ReactFlowProvider,
  addEdge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { getAllWorkflows, getWorkflow, updateWorkflow } from 'api/form-validation-workflow';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomConnectionLine from './components/CustomConnectionLine';
import CustomNode from './components/CustomNode';
import FloatingEdge from './components/FloatingEdge';
import { getAllUserGroups } from 'api/user-group';
import { tokens } from 'context/theme.context';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const getNodeId = () => `randomnode_${+new Date()}`;

type WorkflowInput = {
  validator: string;
};

interface WorkflowFieldInput extends FieldInput {
  field_name: keyof WorkflowInput;
}

const initialNodes = [
  {
    id: 'start',
    type: 'custom',
    data: { label: 'Assessment Submitted' },
    position: { x: 100, y: 120 },
    // sourcePosition: 'right',
    // targetPosition: 'left',
  },
  {
    id: 'end',
    type: 'custom',
    data: { label: 'Complete' },
    position: { x: 400, y: 120 },
    //    sourcePosition: 'right',
    //    targetPosition: 'left'
  },
];

const initialEdges: any[] = [
  {
    id: 'e1-2',
    source: '1',
    target: '2',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 20,
    },
    style: {
      strokeWidth: 3,
    },
    label: 'Pass to',
  },
];

const connectionLineStyle = {
  strokeWidth: 3,
  stroke: 'black',
};

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes: any = {
  floating: FloatingEdge,
};

const defaultEdgeOptions = {
  style: { strokeWidth: 3, stroke: 'black' },
  type: 'floating',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: 'black',
  },
};

const SaveRestore = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes as any);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [rfInstance, setRfInstance] = useState<any>(null);
  const [name, setName] = useState<string>('');
  const [openConfirmAddModal, setOpenConfirmAddModal] = useState<any>(false);
  const [validationWorkflows, setValidationWorkflow] = useState<SelectOption[]>([]);
  const [steps, setSteps] = useState<string[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroupModel[]>([]);
  // const [stepLabel, setStepLabel] = useState<string>('');

  useEffect(() => {
    setBreadcrumb([{ label: 'Validation Workflow', link: '/validation-workflow' }, { label: name }]);
  }, [name]);

  const fields: WorkflowFieldInput[] = [
    {
      field_name: 'validator',
      display_name: 'Validator',
      type: 'select',
      required: true,
      span: 4,
      options: validationWorkflows,
    },
  ];

  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 15,
              height: 15,
            },
            style: {
              strokeWidth: 3,
            },
            label: 'Pass to',
          },
          eds
        )
      ),
    [setEdges]
  );

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();

      let edge = flow.edges.find((edge: any) => edge.source === 'start');
      const node = flow.nodes.find((node: any) => node.id === edge?.source);
      const steps = [node?.data.label];

      while (edge) {
        const target = edge.target;
        const node = flow.nodes.find((node: any) => node.id === target);
        edge = flow.edges.find((edge: any) => edge.source === target);
        steps.push(node?.data.label);
      }

      if (steps[0] !== 'Assessment Submitted' || steps[steps.length - 1] !== 'Complete') {
        enqueueSnackbar('Invalid Workflow. It should start at "Assessment Submitted" and end with "Complete".', {
          variant: 'error',
        });
        return;
      }

      id &&
        updateWorkflow(parseInt(id), { process: JSON.stringify(flow), steps: JSON.stringify(steps) }).then(() => {
          enqueueSnackbar('Workflow successfully saved!', { variant: 'success' });
        });
    }
  }, [rfInstance]);

  const handleAdd = () => {
    setOpenConfirmAddModal(true);
  };

  const onAdd = useCallback(
    (data: any) => {
      var randomNumber = Math.random() * (0.7 - 0.2) + 0.2;
      console.log(data.validator);
      setSteps((prev) => {
        prev.push(data.validator);
        return [...prev];
      });

      const newNode: any = {
        id: getNodeId(),
        type: 'custom',
        data: { label: data.validator },
        position: {
          x: randomNumber * window.innerWidth - 100,
          y: 40,
        },
      };
      setNodes((nds) => nds.concat(newNode));
      setOpenConfirmAddModal(false);
    },
    [setNodes]
  );

  const onNodeClick = (event: any, node: any) => console.log('click node', node);

  const isValidConnection = (connection: any) => {
    return (
      connection.source !== connection.target &&
      !edges.find((edge) => edge.target === connection.target || edge.source === connection.source) &&
      !checkIfCyclic(connection.source, connection)
    );
  };

  const checkIfCyclic = (startNode: String, edge: Edge): boolean => {
    const _edge: any = edges.find((_edge) => _edge.source === edge.target);
    if (!_edge) return startNode === edge.target;
    else return checkIfCyclic(startNode, _edge);
  };

  useEffect(() => {
    if (id) {
      getWorkflow(parseInt(id!)).then((res) => {
        const process = JSON.parse(res.data.process);
        setName(res.data.name);
        if (process) {
          setNodes(process.nodes);
          setEdges(process.edges);
          setSteps(JSON.parse(res.data.steps));
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getAllUserGroups(true).then((res) => {
      setUserGroups(res.data.rows);
    });
  }, []);

  useEffect(() => {
    console.log(steps);
    const options: any[] = userGroups
      .filter((userGroup: UserGroupModel) => !steps.includes(userGroup.group_name))
      .map((userGroup: UserGroupModel) => {
        return { value: userGroup.group_name, key: userGroup.description };
      });
    setValidationWorkflow(options);
  }, [userGroups, steps]);

  const onNodesDelete = useCallback(
    (deleted: any) => {
      setSteps((prev) => {
        return prev.filter((item) => item !== deleted[0].data.label);
      });
      setEdges(
        deleted.reduce((acc: any, node: any) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);

          const remainingEdges = acc.filter((edge: any) => !connectedEdges.includes(edge));

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
              },
              style: {
                strokeWidth: 3,
              },
              label: 'Pass to',
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  return (
    <Box sx={{ height: '80vh' }}>
      <CustomModal open={openConfirmAddModal} setOpen={setOpenConfirmAddModal} header="Add Validator" width={500}>
        <CustomForm
          initialValues={{ validator: '' }}
          fields={fields}
          onSubmit={(data) => {
            onAdd(data);
          }}
        />
      </CustomModal>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setRfInstance}
        onNodeClick={onNodeClick}
        isValidConnection={isValidConnection}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionLineComponent={CustomConnectionLine as any}
        connectionLineStyle={connectionLineStyle}
        onNodesDelete={onNodesDelete}

        // fitView
      >
        <Background />
        <Panel position="top-right">
          <Box display="flex" gap="10px">
            <RegularButton onClick={handleAdd} label="Add Validation Step" />
            <RegularButton onClick={onSave} label="Save" />
          </Box>
        </Panel>
      </ReactFlow>
    </Box>
  );
};

const ValidationWorkflowEditorEditor = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box borderRadius={2} sx={{ backgroundColor: colors.secondary_background }}>
      <ReactFlowProvider>
        <SaveRestore />
      </ReactFlowProvider>
    </Box>
  );
};

export default ValidationWorkflowEditorEditor;
