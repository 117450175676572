import { Box } from '@mui/material';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';

type LayoutProps = {
  children: JSX.Element;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Sidebar />
      <main className="content">
        <Topbar />
        <Box m="30px">{children}</Box>
      </main>
    </>
  );
};

export default Layout;
