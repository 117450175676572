type CustomGridCellProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const CustomGridCell: React.FC<CustomGridCellProps> = ({ children, style }) => {
  return (
    <div style={style} className="MuiDataGrid-cellContent">
      {children}
    </div>
  );
};

export default CustomGridCell;
