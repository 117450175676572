import { DashboardWidget } from './chartDataProcessor';
import { formatNumber } from 'utils';

export interface DataKeyCombination {
  keys: string[];
  requiredResponse: string[];
  requiredAll?: boolean;
  minimunCount?: number;
}

export interface DataKey {
  key: string;
  displayName?: string;
  requiredResponse?: string[];
  combination?: DataKeyCombination[];
  combinationRequiredAll?: boolean;
  combinationMinimunCount?: number;
}

export interface DataKeyBreakdown {
  key: string;
  chartColor: string;
  displayName?: string;
}

export interface TableColumn {
  key: string;
  displayName?: string;
  type?: string | 'number';
  renderValue?: (row: any) => any;
  formatValue?: (value: string) => any;
}

export const AklanDashboard: DashboardWidget[] = [
  {
    type: 'bar',
    title: 'PhilHealth Accreditation',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'count',
    xAxisDataKeys: [
      { key: '018A', displayName: 'Konsulta Package' },
      { key: '018B', displayName: 'Maternity Care Package' },
      { key: '018C', displayName: 'Newborn Care Package' },
      { key: '018D', displayName: 'Family Planning Package' },
      { key: '018E', displayName: 'Z-Benefit Package: Dialysis' },
      { key: '018F', displayName: 'OHAT' },
      { key: '018G', displayName: 'TB-DOTS' },
      { key: '018H', displayName: 'Animal Bite Treatment Center' },
      { key: '018I', displayName: 'Out-Patient Malaria Package' },
      { key: '018J', displayName: 'CCIBP' },
    ],
    yAxisDataKeys: [{ key: 'Yes', chartColor: '#0088FE' }],
    yAxisBreakdown: {
      breakdownKey: 'Facility Type',
      yAxisBreakdownKeys: [
        { key: 'Safe Birthing Facility', chartColor: '#0088FE' },
        { key: 'Infirmary', chartColor: 'green' },
        { key: 'Rural Health Unit', chartColor: '#00C49F' },
        { key: 'Barangay Health Station', chartColor: '#FFBB28' },
      ],
    },
  },
  {
    type: 'bar',
    title: 'PhilHealth Accreditation',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'count',
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: '018A', displayName: 'Konsulta Package' },
      { key: '018B', displayName: 'Maternity Care Package' },
      { key: '018C', displayName: 'Newborn Care Package' },
      { key: '018D', displayName: 'Family Planning Package' },
      { key: '018E', displayName: 'Z-Benefit Package: Dialysis' },
      { key: '018F', displayName: 'OHAT' },
      { key: '018G', displayName: 'TB-DOTS' },
      { key: '018H', displayName: 'Animal Bite Treatment Center' },
      { key: '018I', displayName: 'Out-Patient Malaria Package' },
      { key: '018J', displayName: 'CCIBP' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: '#0088FE' },
      { key: 'No', chartColor: 'red' },
    ],
  },
  {
    type: 'bar',
    title: 'Service Capability of RHU',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    facilityTypeFilter: ['Rural Health Unit'],
    displayAsPercentage: true,
    stacked: true,
    xAxisDataKeys: [
      { key: 'Antenatal Care', type: 'number' },
      { key: 'Delivery Services', type: 'number' },
      { key: 'Postnatal Care', type: 'number' },
    ],
    yAxisDataKeys: [
      { key: 'Yes', chartColor: '#0088FE' },
      { key: 'No', chartColor: 'red' },
    ],
    dataPreprocessor: (data) => {
      const antenatalCareAvailableFields = ['083A', '083B', '083C'];
      const antenatalCareYesFields = ['086', '087'];
      const deliveryAvailableFields = ['088A', '088B', '088C', '088D', '088E', '088F'];
      const deliveryYesFields = ['089A', '089B', '089C'];

      const postnatalCareYesFields = ['092A', '092B', '092C', '093A', '093B', '093C'];
      const getValue = (row: any, availableFields?: string[], yesFields?: string[]) => {
        return (!availableFields || availableFields.every((field) => row[field] === 'Available')) &&
          (!yesFields || yesFields.every((field) => row[field] === 'Yes'))
          ? 'Yes'
          : 'No';
      };

      data.forEach((row) => {
        row['Antenatal Care'] = getValue(row, antenatalCareAvailableFields, antenatalCareYesFields);
        row['Delivery Services'] = getValue(row, deliveryAvailableFields, deliveryYesFields);
        row['Postnatal Care'] = getValue(row, undefined, postnatalCareYesFields);
      });
      return data;
    },
  },
  {
    type: 'table',
    title: 'Health Workforce',
    label: { xAxisLabel: 'PhilHealth Packages', yAxisLabel: 'No. of Facilities with Packages' },
    containerDimnesion: { colSpan: 12 },
    aggregation: 'sum',
    facilityTypeFilter: ['Rural Health Unit'],
    tableKey: { key: 'Facility Code' },
    xAxisDataKeys: [
      { key: 'Facility Name' },
      { key: '007A', displayName: 'Population', type: 'number' },
      { key: '007C', displayName: 'Household', type: 'number' },
      { key: 'Doctors', type: 'number' },
      { key: 'Nurses', type: 'number' },
      { key: 'Midwives', type: 'number' },
      { key: 'Dentists', type: 'number' },
      { key: 'Pharmacists', type: 'number' },
      { key: 'IT', type: 'number' },
      { key: 'BHWs', type: 'number' },

      { key: 'Doctor-Population Ratio' },
      { key: 'Nurse-Population Ratio' },
    ],
    yAxisDataKeys: [{ key: 'Count', chartColor: '#0088FE' }],
    tableSettings: {
      visibleColumns: [
        'Facility Name',
        'Population',
        'Household',
        'Doctors',
        'Doctor-Population Ratio',
        'Nurses',
        'Nurse-Population Ratio',
        'Midwives',
        'Pharmacists',
        'IT',
        'BHWs',
      ],
      sortBy: 'Facility Name',
    },
    dataPreprocessor: (data) => {
      const doctorSourceFields = ['128A-1', '128A-2', '128A-3', '128A-4', '128A-5', '128A-6'];
      const nursesSourceFields = ['128B-1', '128B-2', '128B-3', '128B-4', '128B-5', '128B-6'];
      const midwivesSourceFields = ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6'];
      const dentistsSourceFields = ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6'];
      const pharmacistsSourceFields = ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6'];
      const itSourceFields = ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6'];
      const bhwSourceFields = ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6'];

      const counter = (sourceFields: string[], row: any) => {
        return sourceFields.reduce((result, field) => {
          const response = row[field];
          return result + (isNaN(parseFloat(response)) ? 0 : parseFloat(response));
        }, 0);
      };

      const getRatio = (count: number, population: number) => {
        return count ? `1 : ${formatNumber(population / count, 0)}` : 'N/A';
      };

      // key: 'Doctor-Population Ratio',
      //       renderValue: (row: any) => (!row['Total Doctors'] ? 0 : row['Total Population'] / row['Total Doctors']),
      //       formatValue: (value: string) => (value ? '1 : {value}'.replace('{value}', formatNumber(value, 0)) : 'N/A'),

      data.forEach((row) => {
        row['Doctors'] = counter(doctorSourceFields, row);
        row['Nurses'] = counter(nursesSourceFields, row);
        row['Midwives'] = counter(midwivesSourceFields, row);
        row['Dentists'] = counter(dentistsSourceFields, row);
        row['Pharmacists'] = counter(pharmacistsSourceFields, row);
        row['IT'] = counter(itSourceFields, row);
        row['BHWs'] = counter(bhwSourceFields, row);

        const population = row['007A'];
        row['Doctor-Population Ratio'] = getRatio(row['Doctors'], population);
        row['Nurse-Population Ratio'] = getRatio(row['Nurses'], population);
      });
      return data;
    },
  },

  // {
  //   type: 'table',
  //   title: 'RHU Table',
  //   containerDimnesion: {
  //     rowSpan: 12
  //   },
  //   xAxisDataKeys: [],
  //   yAxisDataKeys: [],
  //   facilityTypeFilter: ['Rural Health Unit'],
  //   breakdownDimension: 'Facility Name',
  //   useXaxisAsSourceField: true,
  //   metrics: [
  //     {
  //       name: 'Total Doctors',
  //       aggregation: 'sum',
  //       sourceFields: ['128A-1', '128A-2', '128A-3', '128A-4', '128A-5', '128A-6'],
  //     },
  //     {
  //       name: 'Total Nurses',
  //       aggregation: 'sum',
  //       sourceFields: ['128B-1', '128B-2', '128B-3', '128B-4', '128B-5', '128B-6'],
  //     },
  //     {
  //       name: 'Total Midwives',
  //       aggregation: 'sum',
  //       sourceFields: ['128C-1', '128C-2', '128C-3', '128C-4', '128C-5', '128C-6'],
  //     },
  //     {
  //       name: 'Total Household',
  //       aggregation: 'sum',
  //       sourceFields: ['Household'],
  //     },
  //     {
  //       name: 'Total Population',
  //       aggregation: 'sum',
  //       sourceFields: ['Population'],
  //     },
  //     {
  //       name: 'Total BEmONC Trained Doctors',
  //       aggregation: 'sum',
  //       sourceFields: ['Basic Emergency Obstetric and Neonatal Care (BEmONC) - Doctor'],
  //     },
  //     {
  //       name: 'Total BEmONC Trained Nurses',
  //       aggregation: 'sum',
  //       sourceFields: ['Basic Emergency Obstetric and Neonatal Care (BEmONC) - Burse'],
  //     },
  //     {
  //       name: 'Total Antenatal Medicine',
  //       aggregation: 'count',
  //       requiredResponse: 'Yes',
  //       sourceFields: [
  //         'Iron + Folic Acid Tablet (1 box) - Available',
  //         'Calcium Carbonate (1 box) - Available',
  //         'Amoxicillin Capsule',
  //       ],
  //     },
  //   ],
  //   columns: [
  //     { key: 'name', displayName: 'Facility Name' },
  //     { key: 'Total Population', type: 'number' },
  //     { key: 'Total Household', type: 'number' },
  //     { key: 'Total Doctors', type: 'number' },
  //     { key: 'Total Nurses', type: 'number' },
  //     { key: 'Total Midwives', type: 'number' },
  //     {
  //       key: 'Doctor-Population Ratio',
  //       renderValue: (row: any) => (!row['Total Doctors'] ? 0 : row['Total Population'] / row['Total Doctors']),
  //       formatValue: (value: string) => (value ? '1 : {value}'.replace('{value}', formatNumber(value, 0)) : 'N/A'),
  //     },
  //     // { key: 'Total BEmONC Trained Doctors', type: 'number' },
  //     // { key: 'Total BEmONC Trained Nurses', type: 'number' },
  //     // { key: 'Total Antenatal Medicine', type: 'number' },
  //   ],
  // },
];
