/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'model/interface';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomDrawer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  PrimaryButton,
  ProtectedComponent,
  RowAction,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { changeNullToBlank, formatDateTime, tranformFormErrors } from 'utils';
import { createReport, deleteReport, getAllReports, updateReport } from 'api/report';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DropdownOptionProps } from 'components/Dropdown';
import { FormikHelpers } from 'formik';
import Header from 'components/Header';
import { HealthIndicatorDropdown } from 'screens/Assessments';
import ReportEntries from './ReportEntries';
import { ReportToolQueryResponse } from 'model/Entities';
import { UserContext } from 'context/user.context';
import { create_report_schema } from 'model/schema';
import { getAssessmentIndicators } from 'api/assessment-indicator';
import { useSnackbar } from 'notistack';

type ReportInput = {
  name: string;
  frequency: string;
  indicator_id: string;
};

interface ReportFieldInput extends FieldInput {
  field_name: keyof ReportInput;
}

const initialValues: ReportInput = {
  name: '',
  frequency: '',
  indicator_id: '',
};

const Reports = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<ReportToolQueryResponse[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [targetID, setTargetID] = useState(-1);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [healthIndicators, setHealthIndicators] = useState<HealthIndicatorDropdown[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(0);
  const [reportValues, setReportValues] = useState<ReportInput>(initialValues);
  const [selectedRow, setSelectedRow] = useState<number>();
  const { user } = useContext(UserContext);

  const updateReportModal = (data: ReportToolQueryResponse) => {
    data = changeNullToBlank(data);
    setTargetID(data.id);
    setIsEdit(true);

    // can use state here
    setReportValues(data);
    setOpenModal(true);
  };

  const deleteReportRow = (data: any) => {
    setTargetID(data.id);
    setOpenDeleteConfirmation(true);
  };

  const actions: DropdownOptionProps[] = [
    {
      label: 'Update',
      action: updateReportModal,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete',
      action: deleteReportRow,
      startIcon: <DeleteOutlinedIcon />,
    },
  ];

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 3,
      renderCell: (params) => <CustomGridCell>{params.row.name}</CustomGridCell>,
    },
    {
      field: 'indicators',
      sortable: false,
      headerName: 'Indicators',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{params.row.indicator.name}</CustomGridCell>,
    },
    {
      field: 'frequency',
      sortable: false,
      headerName: 'Frequency',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.frequency}</CustomGridCell>,
    },
    {
      field: 'created_at',
      sortable: false,
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      hide: !user?.authorities?.includes('MANAGE_REPORTS'),
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const frequency_types = [
    { value: 'monthly', key: 'Monthly' },
    { value: 'yearly', key: 'Yearly' },
    { value: 'quarterly', key: 'Quarterly' },
  ];

  // Form Fields
  const formFields: ReportFieldInput[] = [
    {
      field_name: 'name',
      display_name: 'Name',
      type: 'text',
      span: 4,
    },
    {
      field_name: 'frequency',
      display_name: 'Frequency',
      type: 'select',
      span: 4,
      options: frequency_types?.map((h) => {
        return { key: h.key, value: h.value };
      }),
    },
    {
      field_name: 'indicator_id',
      display_name: 'Health Indicators',
      type: 'autocomplete',
      span: 4,
      options: healthIndicators?.map((h) => {
        return { key: h.key, value: h.value };
      }),
      hidden: isEdit,
    },
  ];

  const getHealthIndicators = async () => {
    try {
      const { data } = await getAssessmentIndicators({ length: 10000 });
      if (data.rows.length) {
        setHealthIndicators(
          data.rows.map((ind: any) => {
            return { value: ind?.id, key: ind?.name };
          })
        );
      } else {
        setHealthIndicators([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Functions
  const getReports = (query?: ApiQuery) => {
    setLoading(true);
    getAllReports({
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSelectedRow = (selected: GridSelectionModel) => {
    if (selected.length && selectedRow !== selected[0]) {
      setSelectedRow(selected[0] as number);
    } else {
      setSelectedRow(undefined);
    }
  };

  const handleClose = () => {
    setSelectedRow(undefined);
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true); //when button submit is clicked turns on the loading animation

    if (isEdit) {
      return updateReport(targetID, data)
        .then(() => {
          successFormSubmit(formikHelpers, true);
          setIsEdit(false);
        })
        .catch((error) => {
          setButtonLoading(false);

          // error for corrupted request
          if (error.response.data) formikHelpers.setErrors(tranformFormErrors(error.response.data));
          // error for other backend problems
          if (error) enqueueSnackbar('A Server Error Occured while updating', { variant: 'error' });
        });
    }

    return createReport(data)
      .then(() => {
        successFormSubmit(formikHelpers);
      })
      .catch((error) => {
        setButtonLoading(false);
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      });
  };

  const successFormSubmit = ({ resetForm }: FormikHelpers<any>, from_edit?: boolean) => {
    enqueueSnackbar(`Report successfully ${from_edit ? 'updated' : 'created'}!`, {
      variant: 'success',
    });

    setButtonLoading(false);
    setOpenModal(false);
    resetForm();
    setRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    getHealthIndicators();
    setBreadcrumb([{ label: 'Reports' }]);
  }, []);

  return (
    <>
      <CustomDrawer
        gridTemplateColumns="30% 1fr"
        showChild={!!selectedRow}
        parentComponent={
          <CustomContainer>
            <Header title="Reports" subtitle="Click a report to display report entries" mb="0" />
            <CustomTable
              searchKeys="Name"
              columns={columns}
              rows={rows}
              rowCount={rowCount}
              loading={loading}
              getData={getReports}
              forceRefresh={refresh}
              isRowSelectable
              handleSelectRow={handleSelectedRow}
              headerComponent={
                <ProtectedComponent requiredAuth={['MANAGE_REPORTS']}>
                  <PrimaryButton
                    onClick={() => {
                      setIsEdit(false);
                      setReportValues(initialValues);
                      setOpenModal(true);
                    }}
                    label={'Add Report'}
                  />
                </ProtectedComponent>
              }
            />
          </CustomContainer>
        }
        childComponent={
          selectedRow ? (
            <ReportEntries
              heandleClose={handleClose}
              reportID={selectedRow}
              reportDetails={rows.find((row) => row.id === selectedRow)}
            />
          ) : (
            <></>
          )
        }
      />
      <CustomModal
        header={isEdit ? 'Edit Report' : 'Create Report'}
        open={openModal}
        setOpen={setOpenModal}
        width={500}
      >
        <CustomForm
          initialValues={reportValues}
          onSubmit={handleSubmit}
          fields={formFields}
          schema={create_report_schema}
          loading={buttonLoading}
        />
      </CustomModal>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message={'Delete this report?'}
        onConfirm={() => {
          deleteReport(targetID)
            .then((response: any) => {
              enqueueSnackbar(`Report Deleted`, {
                variant: 'info',
              });
              getReports();
            })
            .catch((error) => {
              enqueueSnackbar(error, {
                variant: 'default',
              });
            });
        }}
      />
    </>
  );
};

export default Reports;
