import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'form-validation-workflow';

export const getWorkflow = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAllWorkflows = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createWorkflow = (workflow: any) => {
  return axios.post(`${baseURL}/${path}`, workflow);
};

export const updateWorkflow = (id: number, workflow: any) => {
  return axios.put(`${baseURL}/${path}/${id}`, workflow);
};

export const deleteWorkflow = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
