import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.div`
  margin: 20px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableCell = styled.td`
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;

const TableHeaderCell = styled.th`
  /* border: 1px solid */
  padding: 8px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  text-align: center;
`;
const TableHeaderCells = styled.th`
  /* border: 1px solid */
  padding: 8px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  text-align: center;
`;

type TableData = string[][];

interface SubColumn {
  title: string;
}

interface DashboardTableProps {
  combinedData: {
    tableData: TableData;
    mockColumnData: (SubColumn | string)[];
  };
}
const calculatePercentageColor = (content: string) => {
  const hasPercentage = content.includes('%');
  if (content === '1:15793' || content === '1:5057' || content === '1:4825' || content === '1:1,000') {
    return 'green';
  } else if (content === ' 1:10,000' || content === '1:30,000' || content === '1:12,000' || content === '1:20,000') {
    return 'red';
  }

  if (hasPercentage) {
    const percentage = parseFloat(content.replace('%', ''));

    if (!isNaN(percentage)) {
      if (percentage >= 90) {
        return 'green';
      } else if (percentage >= 50 && percentage < 90) {
        return 'yellow';
      } else {
        return 'red';
      }
    }
  }

  return '';
};
const DashboardTable: React.FC<DashboardTableProps> = ({ combinedData }) => {
  const { tableData, mockColumnData } = combinedData;

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            {mockColumnData.map((header, colIndex) => (
              <React.Fragment key={colIndex}>
                {typeof header === 'object' ? (
                  <>
                    <TableHeaderCell>{(header as SubColumn).title}</TableHeaderCell>
                  </>
                ) : (
                  <TableHeaderCells colSpan={2}>{header}</TableHeaderCells>
                )}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {rowData.map((cellText, colIndex) => {
                const backgroundColor = calculatePercentageColor(cellText);
                return (
                  <TableCell key={colIndex} style={{ backgroundColor }}>
                    {cellText}
                  </TableCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default DashboardTable;
