import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { CustomContainer, RegularButton } from 'components';
import React, { useState } from 'react';

import { AssessmentSectionModel } from 'model/Entities';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tokens } from '../../../context/theme.context';

type Props = {
  sections: AssessmentSectionModel[];
  setSelected?: any;
};

const TableOfContents: React.FC<Props> = ({ sections, setSelected }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return sections.length > 1 ? (
    <CustomContainer p="0" m="0 0 20px">
      <Box sx={{ width: '100%', maxWidth: 900, margin: '20px auto' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h5" fontWeight="bold" sx={{ fontSize: '1.2rem', marginRight: '10px' }}>
            Table of Contents
          </Typography>
          <RegularButton
            variant="text"
            onClick={() => setOpen(!open)}
            label={open ? 'Hide' : 'Show'}
            startIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          />
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box display="flex" flexDirection="column" alignItems="center">
            {sections.map((section, index) => {
              return (
                <Box
                  key={section.section_number}
                  textAlign="center"
                  marginTop={2}
                  width="100%"
                  sx={{
                    borderBottom: '1px solid #f0f0f0',
                    paddingBottom: '8px',
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1.1rem',
                      textAlign: 'left',
                      '&:hover': {
                        color: colors.primary,
                        textDecoration: 'underline',
                      },
                    }}
                    onClick={() => setSelected(index)}
                  >
                    Section {section.section_number}.{' '}
                    <span style={{ textDecoration: 'none' }}>{section.section_name}</span>
                  </Typography>
                  {section.section_body[0]?.section_number &&
                    section.section_body.map((section_body, bodyIndex) => {
                      return (
                        <Box
                          key={`${section.section_number}-${bodyIndex}`}
                          sx={{ paddingLeft: '20px', textAlign: 'left' }}
                        >
                          <Typography variant="body1" fontWeight="none">
                            {section_body.section_number}. {section_body.section_name}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              );
            })}
          </Box>
        </Collapse>
      </Box>
    </CustomContainer>
  ) : (
    <></>
  );
};

export default TableOfContents;
