import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function LinearProgressWithLabel(props: LinearProgressProps & { total: number; progress: number }) {
  const displayPercentage = () => {
    return props.total ? Math.round((props.progress / props.total) * 100) : 0;
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ flex: 1, minWidth: 105, textAlign: 'right' }}>
        <Typography variant="body2" color="text.secondary">{`${props.progress} / ${
          props.total
        } ( ${displayPercentage()}% )`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
