import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'facility-report-entry-comment';

interface CommentFormat {
  comment: string;
  field_code: string;
  field_name: string;
  facility_report_entry_id: number;
}

export const getFacilityReportEntryComments = (facility_report_entry_id: number) => {
  return axios.get(`${baseURL}/${path}/${facility_report_entry_id}`);
};

export const createFacilityReportEntryComment = (facilityReportEntryData: CommentFormat) => {
  return axios.post(`${baseURL}/${path}`, facilityReportEntryData);
};

export const removeFacilityReportEntryComment = (facility_report_entry_id: number) => {
  return axios.delete(`${baseURL}/${path}/${facility_report_entry_id}`);
};

export const setFacilityReportEntryCommentStatus = (facility_report_entry_id: number, status: string) => {
  return axios.patch(`${baseURL}/${path}/set-status/${status}/${facility_report_entry_id}`);
};

export const updateAllFacilityReportEntryComment = (facility_report_entry_id: number, field_code: string) => {
  return axios.patch(`${baseURL}/${path}/`, { facility_report_entry_id, field_code });
};

export const updateFacilityReportEntryComment = (facility_report_entry_id: number, comment: string) => {
  return axios.put(`${baseURL}/${path}/${facility_report_entry_id}`, { comment });
};