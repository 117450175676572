import { Box, FormControlLabel, Radio, RadioGroup, SxProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type CustomRadioGroupProps = {
  options: any[];
  label: string;
  value?: string;
  fieldName: string;
  span?: number;
  handleChange?: (value: any) => void;
  handleChangeCallback?: (value: any) => void;
  disabled?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  row?: boolean;
  style?: SxProps;
  lock?: boolean;
  missing?: boolean;
};

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  options,
  label,
  value = '',
  fieldName,
  handleChange,
  handleChangeCallback,
  disabled,
  setFieldValue,
  span,
  row,
  style,
  lock,
  missing,
}) => {
  const [selectedOption, setSelectedOption] = useState<any>(value);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    handleChange && handleChange(newValue);
    setFieldValue && setFieldValue(fieldName, newValue);
    handleChangeCallback && handleChangeCallback(newValue);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <Box sx={{ gridColumn: 'span ' + span }}>
      <Typography style={{ color: missing && !value ? 'red' : undefined }}>{label}</Typography>
      <RadioGroup
        row={row ? row : false}
        value={selectedOption}
        onChange={lock ? undefined : handleSelectChange}
        sx={{
          gridColumn: 'span ' + span,
          justifyContent: 'center',
          alignContent: 'center',
          ...style,
        }}
      >
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.key}
            disabled={disabled}
            style={{
              color: missing && !value ? 'red' : '',
            }}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default CustomRadioGroup;
