import { Municipality, Province, Region } from 'model/Entities';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  getAllFacilityTypes,
  getAvailableMunicipality,
  getAvailableProvince,
  getAvailableRegion,
  getFacilities,
} from 'api/facility';

import DropdownFilter from './DropdownFilter';
import { SelectOption } from 'model/interface';
import { UserContext } from 'context/user.context';

type LocationFilter = {
  region_code: string;
  province_code: string;
  municipality_code: string;
  facility_id?: string;
  facility_type?: string;
};
type FacilityLocationFilterProps = {
  handleChangeFilter: (locationFilter: LocationFilter) => void;
  otherFilters?: LocationFilter;
  hideFacilityType?: boolean;
  showFacilityDropdown?: boolean;
  hideRegionDefault?: boolean;
  hideProvinceDefault?: boolean;
  hideMunicipalityDefault?: boolean;
  defaultProvince?: string;
};

interface FacilityTypeDropdown {
  value: any;
  key: string;
}

const FacilityLocationFilter: React.FC<FacilityLocationFilterProps> = ({
  handleChangeFilter,
  otherFilters,
  hideFacilityType = false,
  showFacilityDropdown,
  hideRegionDefault = false,
  hideProvinceDefault = false,
  hideMunicipalityDefault = false,
  defaultProvince,
}) => {
  const { user } = useContext(UserContext);

  const dropdownMaxWidth = '200px';
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedMunicipality, setSelectedMunicipality] = useState('');
  const [selectedFacility, setSelectedFacility] = useState('');
  const [selectedFacilityType, setSelectedFacilityType] = useState('');

  const [regions, setRegions] = useState<SelectOption[]>([]);
  const [provinces, setProvinces] = useState<SelectOption[]>([]);
  const [municipalities, setMunicipalities] = useState<SelectOption[]>([]);
  const [facilities, setFacilities] = useState<SelectOption[]>([]);
  const [facilityTypes, setFacilityTypes] = useState<FacilityTypeDropdown[]>([]);

  const [hideRegion, setHideRegion] = useState(false);
  const [hideProvince, setHideProvince] = useState(false);
  const [hideMunicipality, setHideMunicipality] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const getRegions = async () => {
    try {
      const res = await getAvailableRegion();
      let regions = res.data.map((item: Region) => {
        return {
          value: item.region_code,
          key: item.region_name,
        };
      });
      setRegions(regions);
    } catch (e) {
      console.log(e);
    }
  };

  const getFacilityTypes = async () => {
    try {
      getAllFacilityTypes().then((res) => {
        if (res.data.length) {
          res.data.forEach((data: any) => {
            const add: FacilityTypeDropdown = {
              value: data?.id,
              key: data?.type,
            };
            setFacilityTypes((prevArray) => [...(prevArray ?? []), add]);
          });
        } else {
          setFacilityTypes([]);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setFacilityTypes([]);
    getFacilityTypes();
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!(user.regional_account || user.provincial_account || user.municipal_account) || user.facility_account) {
      otherFilters?.region_code === '' && setSelectedRegion('');
    }

    if (user?.regional_account) {
      otherFilters?.province_code === '' && setSelectedProvince(defaultProvince ?? '');
    }

    if (user?.regional_account || user?.provincial_account) {
      otherFilters?.municipality_code === '' && setSelectedMunicipality('');
    }

    otherFilters?.facility_id === '' && setSelectedFacility('');
    otherFilters?.facility_type === '' && setSelectedFacilityType('');
  }, [otherFilters, user]);

  const getProvinces = async () => {
    try {
      const res = await getAvailableProvince(selectedRegion);
      let regions = res.data.map((item: Province) => {
        return {
          value: item.province_code,
          key: item.province_name,
        };
      });
      setProvinces(regions);
    } catch (e) {
      console.log(e);
    }
  };

  const getMunicipalities = async () => {
    try {
      const res = await getAvailableMunicipality({
        region_code: selectedRegion,
        province_code: selectedProvince,
      });
      let regions = res.data.map((item: Municipality) => {
        return {
          value: item.municipality_code,
          key: item.municipality_name,
        };
      });
      setMunicipalities(regions);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchFacilities = async () => {
    if (!showFacilityDropdown) return;
    try {
      const res = await getFacilities({
        region_code: selectedRegion,
        province_code: selectedProvince,
        municipality_code: selectedMunicipality,
        facility_type: selectedFacilityType,
        length: 200,
      });

      const facilitiesData = res.data.rows.map((facility: any) => ({
        value: facility.id,
        key: facility.facility_name,
      }));

      setFacilities(facilitiesData);
    } catch (e) {
      console.log('Error fetching facilities:', e);
    }
  };

  useEffect(() => {
    setProvinces([]);
    if (!hideProvince && selectedRegion) getProvinces();
  }, [selectedRegion]);

  useEffect(() => {
    setMunicipalities([]);
    if (!hideMunicipality && (selectedProvince || selectedRegion)) getMunicipalities();
  }, [selectedProvince, selectedRegion]);

  useEffect(() => {
    if (!firstLoad)
      handleChangeFilter({
        region_code: selectedRegion,
        municipality_code: selectedMunicipality,
        province_code: selectedProvince,
        facility_id: selectedFacility,
        facility_type: selectedFacilityType,
      });
  }, [selectedRegion, selectedProvince, selectedMunicipality, selectedFacility, selectedFacilityType]);

  useEffect(() => {
    if (user) {
      setHideRegion(!user.national_account || hideRegionDefault);
      setHideProvince(!(user.national_account || user.regional_account) || hideProvinceDefault);
      setHideMunicipality(user.municipal_account || user.facility_account || hideMunicipalityDefault);

      setSelectedRegion(user?.region_code ?? '');
      setSelectedProvince(defaultProvince ?? user?.province_code ?? '');
      setSelectedMunicipality(user?.municipality_code ?? '');
    }
  }, [user]);

  useEffect(() => {
    if (!hideRegion) getRegions();
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (selectedRegion || selectedProvince || selectedMunicipality || selectedFacilityType) {
      fetchFacilities();
    } else {
      setFacilities([]); // Clear the facilities when the location filters are empty
    }
  }, [selectedRegion, selectedProvince, selectedMunicipality, selectedFacilityType]);

  return (
    <>
      {!hideFacilityType && (
        <DropdownFilter
          maxWidth={dropdownMaxWidth}
          label="FacilityType"
          handleChange={(e) => setSelectedFacilityType(e.target.value)}
          value={selectedFacilityType}
          options={facilityTypes}
        />
      )}

      {!hideRegion && (
        <DropdownFilter
          maxWidth={dropdownMaxWidth}
          label="Region"
          handleChange={(e) => {
            setSelectedMunicipality('');
            setSelectedProvince(defaultProvince ?? '');
            setSelectedFacility('');
            setSelectedRegion(e.target.value);
          }}
          value={selectedRegion}
          options={regions}
        />
      )}

      {!hideProvince && (
        <DropdownFilter
          maxWidth={dropdownMaxWidth}
          label="Province"
          handleChange={(e) => {
            setSelectedMunicipality('');
            setSelectedFacility('');
            setSelectedProvince(e.target.value);
          }}
          value={selectedProvince}
          options={provinces}
        />
      )}

      {!hideMunicipality && (
        <DropdownFilter
          maxWidth={dropdownMaxWidth}
          label="Municipality"
          handleChange={(e) => {
            setSelectedFacility('');
            setSelectedMunicipality(e.target.value);
          }}
          value={selectedMunicipality}
          options={municipalities}
        />
      )}

      {showFacilityDropdown && (
        <DropdownFilter
          maxWidth={dropdownMaxWidth}
          label="Facility"
          handleChange={(e) => setSelectedFacility(e.target.value)}
          value={selectedFacility}
          options={facilities}
        />
      )}
    </>
  );
};

export default FacilityLocationFilter;
