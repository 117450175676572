import ArticleContent, { imagePath } from '../ArticleContent';

const CreateDeleteValidationWorkflowArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Creating a simple validation flow',
          steps: [
            { step: '1. Click the "Create Workflow" button.', image: `${imagePath}/create-validation-workflow-1.png` },
            {
              step: '2. Kindly input the name of the workflow.',
              image: `${imagePath}/create-validation-workflow-2.png`,
            },
            {
              step: '3. After creating a new workflow the page will redirect to an editor with two square-like figures with labels "Assessment Submitted" and "Complete".',
              image: `${imagePath}/create-validation-workflow-3.png`,
            },
            {
              step: '4. Kindly click and hold to the center of the "Assessment Submitted" figure until a dot/line shows up, and drag the line to the center of the "Complete" figure.',
            },
            { step: '5. Click "Save".', image: `${imagePath}/create-validation-workflow-4.png` },
          ],
        },
        {
          title: 'Deleting a workflow',
          steps: [
            {
              step: '1. On the action button, click "delete".',
              image: `${imagePath}/delete-validation-workflow-1.png`,
            },
            { step: '2. Click "yes" to delete.', image: `${imagePath}/delete-validation-workflow-2.png` },
          ],
        },
      ]}
    />
  );
};

export default CreateDeleteValidationWorkflowArticle;
