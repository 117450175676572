import ArticleContent, { imagePath } from '../ArticleContent';

const CreateFacilityGroupArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click the "Create Group" button.', image: `${imagePath}/facility-group-1.png` },
            { step: '2. Fill out the form.' },
            { step: '3. Click "Submit".', image: `${imagePath}/facility-group-2.png` },
          ],
        },
      ]}
    />
  );
};

export default CreateFacilityGroupArticle;
