/* eslint-disable react-hooks/exhaustive-deps */
import { Box, List, ListItem, Typography } from '@mui/material';
import {
  CustomCheckbox,
  CustomContainer,
  CustomModal,
  DraggableList,
  Dropdown,
  Header,
  RegularButton,
  RowAction,
} from 'components';
import { getFacilityReportEntry, saveReportEntry, submitFacilityReportEntry } from 'api/facility-report-entry';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { AssessmentSectionModel } from 'model/Entities';
import CustomTabs from 'components/CustomTabs';
import { DropResult } from 'react-beautiful-dnd';
import EditTextField from './components/EditTextField';
import FieldGroups from './fields/FieldGroups';
import LinearProgressWithLabel from 'components/LinearProgressBar';
import LoadingScreen from 'screens/Helper/loading';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { getAssessmentIndicator } from 'api/assessment-indicator';
import { useSnackbar } from 'notistack';

// const aklan_indicators = require('../../../data/Aklan/main');
// const afhf_indicators = require('../../../data/ReportingTool/main');
// const ev_indicators = require('../../../data/Eastern Visayas/main');

export interface FieldType {
  type: 'field' | 'section' | 'parent' | 'sub-section' | 'table' | 'conditional-field';
  field_name?: string;
  field_description?: string;
  section_name?: string;
  section_description?: string;
  field_code?: string;
  response_type?: 'text' | 'numeric' | 'radio' | 'y/n' | '';
  disabled?: boolean;
  children?: FieldType[];
  rows?: FieldType[];
  columns?: any[];
  options?: string[];
  applicable?: string[];
  not_applicable?: string[];
  table_type?: 'simple-table';
  section_number?: string;
  section_body?: any[];
  section_content?: any[];
  response?: string;
  show_missing_items?: boolean;
}

type IndicatorBuilderProps = {
  respond?: boolean;
};

const IndicatorBuilder: React.FC<IndicatorBuilderProps> = ({ respond }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [indicators, setIndicators] = useState<AssessmentSectionModel[]>([]);
  const [indicatorName, setIndicatorName] = useState<string>();
  const [sectionTabs, setSectionTabs] = useState<any>([]);
  const [selectedSectionNumber, setSelectedSectionNumber] = useState(0);
  const [showMissingItems, setShowMissingItems] = useState(false);
  const [missingItems, setMissingItems] = useState<FieldType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openMissingItemsModal, setOpenMissingItemsModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedFieldName, setEditedFieldName] = useState('');
  const [selectedField, setSelectedField] = useState<FieldType>();
  const [totalFieldsCount, setTotalFieldsCount] = useState<number>(0);
  const [totalFieldsWithReponseCount, setTotalFieldsWithReponseCount] = useState<number>(0);
  const [totalFieldsCountSections, setTotalFieldsCountSections] = useState<number[]>([]);
  const [totalFieldsWithReponseCountSections, setTotalFieldsWithReponseCountSections] = useState<number[]>([]);
  const [status, setStatus] = useState<any>(undefined);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTotalFieldsCount(
      totalFieldsCountSections.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
    setTotalFieldsWithReponseCount(
      totalFieldsWithReponseCountSections.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
  }, [totalFieldsCountSections, totalFieldsWithReponseCountSections]);

  const getIndicators = async () => {
    setIndicatorName('Assessment');
    if (!respond) {
      const { data } = await getAssessmentIndicator(parseInt(id!));
      const sections: AssessmentSectionModel[] = JSON.parse(data.indicators);
      setIndicatorName(data.name);
      setIndicators(sections);
      setStatus(data.status);
    } else {
      const { data } = await getFacilityReportEntry(parseInt(id!));
      const sections: AssessmentSectionModel[] = JSON.parse(data.responses);
      setIndicators(sections);
      setIndicatorName(`${data.report_entry.report.name} - ${data.report_entry.month}`);
      setStatus(data.status);
    }
    // setIndicators(ev_indicators);
  };

  useEffect(() => {
    const sectionTabs: any = indicators.map((section, index) => {
      return {
        label: 'Section ' + section.section_number,
        content: (
          <Box width="100%" style={{ position: 'relative' }}>
            <CustomContainer>
              <Box height="calc(100vh - 150px)" width="100%" padding="20px" overflow="auto">
                <FieldGroups
                  items={[section as any]}
                  droppableId="test"
                  deleteMainSection={deleteMainSection}
                  mainIndex={index}
                  index={index}
                  sectionNumber={section.section_number + ''}
                  parentObject={section}
                  parentProperty="section_body"
                  respond={respond}
                  selectedField={selectedField}
                  setSelectedField={setSelectedField}
                  showMissingItems={showMissingItems}
                  setMissingItems={setMissingItems}
                  mainSetTotalFieldsCount={setTotalFieldsCountSections}
                  mainSetTotalFieldsWithReponseCount={setTotalFieldsWithReponseCountSections}
                />
              </Box>
            </CustomContainer>
          </Box>
        ),
      };
    });
    setSectionTabs(sectionTabs);
  }, [indicators, showMissingItems]);

  useEffect(() => {
    getIndicators();
    if (status && ['submitted', 'ongoing-approval', 'completed'].includes(status)) {
      navigate(`/facility-reports/view/${id}`);
    }
  }, [id, status]);

  const addNewSection = () => {
    const body = {
      section_number: indicators.length,
      section_name: 'New Section',
      section_body: [
        {
          type: 'field',
          field_name: 'New Field',
          field_code: '1',
          response_type: 'text',
        },
      ],
    };
    setIndicators([...indicators, body]);
    setTimeout(() => {
      setSelectedSectionNumber(indicators.length);
    }, 500);
  };

  const deleteMainSection = (index: number) => {
    const newList = Array.from(indicators);

    newList.splice(index, 1);
    setIndicators(newList);
    setTimeout(() => {
      setSelectedSectionNumber(indicators.length - 2);
    }, 500);
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const newList = Array.from(indicators);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    newList.map((section, index) => (section.section_number = index));
    setIndicators(newList);
  };

  const handleEditSection = (section: any, index: number) => {
    setEditMode(true);
    setEditedFieldName(section.section_name || '');
    setSelectedSectionNumber(index);
  };

  const handleSaveEditedSection = (editedValue: string, index: number) => {
    setEditMode(false);

    // Update the selected field based on user input (editedValue)
    const updatedIndicators = indicators.map((section, i) => {
      if (i === index) {
        return {
          ...section,
          section_name: editedValue,
        };
      }
      return section;
    });

    setIndicators(updatedIndicators);
  };

  const displaySectionName = (field: any, index: number) => {
    const actions = [
      {
        label: 'Edit',
        action: () => handleEditSection(field, index),
      },
    ];

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        {editMode && index === selectedSectionNumber ? (
          <>
            <EditTextField
              edit
              initialValue={editedFieldName}
              fieldName="Section Name"
              handleSave={(editedValue) => handleSaveEditedSection(editedValue, index)}
            />
          </>
        ) : (
          <>
            <Typography>{field.section_name}</Typography>
            <RowAction actions={actions} />
          </>
        )}
      </Box>
    );
  };

  const handleSaveFacilityResponse = () => {
    if (id) {
      setLoadingSave(true);
      const payload = { responses: JSON.stringify(indicators) };
      saveReportEntry(parseInt(id), payload)
        .then(() => {
          enqueueSnackbar('Save successful!', { variant: 'success' });
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Save unsuccessful!', { variant: 'error' });
        })
        .finally(() => {
          setLoadingSave(false);
        });
    }
  };

  const handleSubmitFacilityReportEntry = () => {
    if (id) {
      setLoadingSubmit(true);
      const payload = { responses: JSON.stringify(indicators) };
      submitFacilityReportEntry(parseInt(id), payload)
        .then(() => {
          enqueueSnackbar('Submit successful!', { variant: 'success' });
          setStatus('submitted');
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Submit unsuccessful!', { variant: 'error' });
        })
        .finally(() => {
          setLoadingSubmit(false);
        });
    }
  };

  useEffect(() => {
    if (!showMissingItems) setMissingItems([]);
  }, [showMissingItems]);

  if (!indicatorName) {
    return <LoadingScreen />;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px" gap="20px">
        <Box width="100%">
          <Header title={indicatorName} mb="0" />
          <LinearProgressWithLabel
            total={totalFieldsCount}
            progress={totalFieldsWithReponseCount}
            value={(totalFieldsWithReponseCount / totalFieldsCount) * 100}
          />
          <Box display="flex" gap="10px">
            <CustomCheckbox
              label="Check Missing Items"
              handleChange={(ev) => {
                setShowMissingItems(!showMissingItems);
              }}
              fieldName="show_missing_items"
            />

            {showMissingItems && (
              <RegularButton
                onClick={() => setOpenMissingItemsModal(true)}
                label="View Missing Items"
                disabled={!missingItems.length}
              />
            )}
          </Box>
        </Box>
        <Box display="flex" gap="10px">
          {respond ? (
            <>
              <RegularButton
                label="Save"
                onClick={handleSaveFacilityResponse}
                startIcon={<SaveOutlinedIcon />}
                loading={loadingSave}
              />
              <RegularButton
                disabled={totalFieldsCount > totalFieldsWithReponseCount}
                label="Submit"
                onClick={handleSubmitFacilityReportEntry}
                startIcon={<SendOutlinedIcon />}
                loading={loadingSubmit}
              />
            </>
          ) : (
            <>
              {/* <RegularButton label="Save" onClick={handleSaveFacilityResponse} startIcon={<SaveOutlinedIcon />} /> */}
              <Dropdown
                buttonLabel="Section Settings"
                optionList={[
                  {
                    label: 'Add New Section',
                    action: addNewSection,
                    startIcon: <AddCircleOutlineOutlinedIcon />,
                  },
                  {
                    label: 'Edit Sections',
                    action: () => setOpenModal(true),
                    startIcon: <PanToolOutlinedIcon />,
                  },
                ]}
                icon={<TuneOutlinedIcon />}
              />
            </>
          )}
        </Box>
      </Box>
      <CustomTabs tabs={sectionTabs} selected={selectedSectionNumber} />

      <CustomModal header="Edit Sections" open={openModal} setOpen={setOpenModal} width={500}>
        <DraggableList
          list={indicators}
          droppableId="edit-sections"
          handleDragEnd={handleDragEnd}
          displayItem={displaySectionName}
          style={{ padding: '5px' }}
          fromModal
          dragIconPaddingTop="5px"
        />
      </CustomModal>
      <CustomModal
        header={'Missing Items: ' + missingItems.length}
        open={openMissingItemsModal}
        setOpen={setOpenMissingItemsModal}
        width={600}
        pt={2}
      >
        <List disablePadding>
          {missingItems.map((item) => {
            const labelId = `checkbox-list-label-${item.field_name}`;
            return (
              <ListItem key={labelId} disablePadding>
                <Box display="grid" gridTemplateColumns="50px 1fr" width="100%" mb="8px">
                  <Typography>{item.field_code + '. '}</Typography>
                  <Typography>{item.field_name}</Typography>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </CustomModal>
    </Box>
  );
};

export default IndicatorBuilder;
