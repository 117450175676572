module.exports = {
  section_number: 1,
  section_name: 'FHSIS Report - M2 and A1',
  section_body: [
    {
      type: 'sub-section',
      section_number: '1.1',
      section_name: 'Program F1 PLUS',
      section_content: [
        {
          type: 'table',
          field_name: 'Indicators',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of pregnant women who gave birth with at least 4 prenatal check-ups',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of livebirths among 15-19 y/o women',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of live births with low birth weight',
              field_code: '1',
            },

            {
              type: 'field',
              field_name: ' No. of deliveries attended by skilled health professionals',
              field_code: '1',
            },
            {
              type: 'field',
              field_name:
                'No. of postpartum women together with their newborn who completed at least 2 postpartum check-ups ',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of health facility-based deliveries',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of fully immunized children',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.2',
      section_name: 'Mortality F1 PLUS Indicator',
      section_content: [
        {
          type: 'table',
          field_name: 'Indicators',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'Maternal Deaths',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Infant Deaths',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.3',
      section_name: 'Mortality F1 PLUS Indicator',
      section_content: [
        {
          type: 'table',
          field_name: 'Nutritional Status Assessment of Children 0-59 mos. old',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'Stunted',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Wasted',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Wasted-MAM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Wasted-SAM',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Overweight/Obese',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Normal',
              field_code: '1',
            },
          ],
        },
        {
          type: 'table',
          field_name: 'School-Based Deworming Services',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: '1-19 y/o given 2 doses of  deworming drug',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'PSAC, 1-4 y/o dewormed (2 doses)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'SAC, 5-9 y/o dewormed (2 doses)',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'Adolescents, 10-19 y/o dewormed (2 doses)',
              field_code: '1',
            },
          ],
        },
      ],
    },
    {
      type: 'sub-section',
      section_number: '1.4',
      section_name: 'Infectious Diseases',
      section_content: [
        {
          type: 'table',
          field_name: 'Filariasis Prevention and Control',
          field_code: '1',
          columns: [
            {
              name: 'Count',
              type: 'parent',
              children: [
                { name: 'Male', type: 'numeric', code: '1' },
                { name: 'Female', type: 'numeric', code: '2' },
                { name: 'Total', type: 'total', code: '3' },
              ],
            },
            { name: 'Remarks', type: 'text', code: '5' },
          ],
          rows: [
            {
              type: 'field',
              field_name: 'No. of individuals examined for lymphatic filariasis',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: 'No. of individuals examined found positive for lymphatic filariasis',
              field_code: '1',
            },
            {
              type: 'field',
              field_name: ' No. of lymphatic filariasis cases examined with manifestations',
              field_code: '1',
            },
          ],
        },
      ],
    },
  ],
};
