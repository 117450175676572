/* eslint-disable react-hooks/exhaustive-deps */

import { Box, useTheme } from '@mui/material';
import { CustomContainer, CustomGridCell, DropdownFilter, Header, RegularButton } from 'components';
import { formatDateTime, getStatusChip } from 'utils';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import { FacilityAssessmentModel } from 'model/Entities';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { getAllAssessmentsByFacility } from 'api/assessment';
import { tokens } from 'context/theme.context';

const FacilityAssessments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [rows, setRows] = useState<FacilityAssessmentModel[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [otherFilters, setOtherFilters] = useState<any>({});

  // Table Columns

  const columns: GridColDef[] = [
    {
      field: 'id',
      sortable: false,
      headerName: 'ID',
      flex: 0.2,
    },
    {
      field: 'batch',
      sortable: false,
      headerName: 'Assessment Batch',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          <Link
            to={`/assessment-files/${params.row.id}`}
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
          >
            {`${params.row.assessment_batch.batch_name} (${params.row.assessment_batch.year})`}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'assessor_id',
      sortable: false,
      headerName: 'Assessor',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.assessor?.title} {params.row.assessor?.first_name} {params.row.assessor?.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'approved_at',
      sortable: false,
      headerName: 'Date Approved',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.approved_at)}</CustomGridCell>,
    },
    {
      field: 'indicator_id',
      sortable: false,
      headerName: 'Indicators',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.assessment_indicator?.name}</CustomGridCell>,
    },
  ];

  // Functions
  const getAssessments = (query?: ApiQuery) => {
    setLoading(true);
    getAllAssessmentsByFacility({
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }

    setOtherFilters({ ...otherFilters });
    setStatusFilter('');
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Assessment Files' }]);
  }, []);

  return (
    <Box>
      <CustomContainer>
        <CustomTable
          searchKeys="Batch Name or Indicator Name"
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          getData={getAssessments}
          rowHeight={35}
          otherFilters={otherFilters}
          headerComponent={<Header title="Facility Assessments" />}
          filterComponent={
            <Box display="flex" gap="10px" width="100%" alignItems="center">
              <DropdownFilter
                maxWidth="200px"
                label="Status"
                handleChange={(e) => {
                  setStatusFilter(e.target.value);
                  setOtherFilters({
                    ...otherFilters,
                    status: e.target.value,
                  });
                }}
                value={statusFilter}
                options={[
                  { key: 'Open', value: 'open' },
                  { key: 'Started', value: 'started' },
                  { key: 'Submitted', value: 'submitted' },
                  { key: 'Ongoing Approval', value: 'ongoing-approval' },
                  { key: 'Completed', value: 'completed' },
                ]}
              />
              <RegularButton label="Clear" onClick={() => clearFilter()} />
            </Box>
          }
        />
      </CustomContainer>
    </Box>
  );
};

export default FacilityAssessments;
