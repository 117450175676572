import { Box, useTheme } from '@mui/material';

import { tokens } from '../context/theme.context';

type ProgressCircleProps = {
  progress: string | number;
  size?: string | number;
};

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  progress,
  size = '40',
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const angle = parseFloat(progress.toString()) * 360;
  return (
    <Box
      sx={{
        background: `radial-gradient(${colors.secondary_background} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${colors.blueAccent} ${angle}deg 360deg),
            ${colors.accent}`,
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export default ProgressCircle;
