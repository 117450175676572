import ArticleContent, { imagePath } from '../ArticleContent';

const CreateFacilityArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click the "Create Facility"', image: `${imagePath}/create-facility-1.png` },
            { step: '2. Fill out the form' },
            { step: '3. Click Submit', image: `${imagePath}/create-facility-2.png` },
          ],
        },
      ]}
    />
  );
};

export default CreateFacilityArticle;
