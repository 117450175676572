import ArticleContent, { imagePath } from '../ArticleContent';

const DeleteIndicatorArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. On the actions, click "Delete".', image: `${imagePath}/delete-indicator-1.png` },
            {
              step: '2. Click "Yes" to delete the indicator, and "No" to cancel.',
              image: `${imagePath}/delete-indicator-2.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default DeleteIndicatorArticle;
