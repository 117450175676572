import { Box, Radio, TableCell, TableRow, useTheme } from '@mui/material';
import { ConditionalHideComponent, CustomRadioGroup, RowAction } from 'components';
import { useEffect, useState } from 'react';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { tokens } from 'context/theme.context';
import { FieldType } from '../IndicatorBuilder';
import { TableColumnProps } from '../fields/TableField';
import EditField from './EditField';

type TableFieldRowProps = {
  index: number;
  row: FieldType;
  tableColumns: TableColumnProps[];
  columnCount: number;
  onAddRowBelow: (selectedRow: FieldType) => void;
  onRemoveRow: () => void;
  respond?: boolean;
  simple: boolean;
  showMissingItems: boolean;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
  handleChange: (response: string) => void;
  setFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  setFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
};

const TableFieldRow: React.FC<TableFieldRowProps> = ({
  index,
  row,
  tableColumns,
  columnCount,
  onAddRowBelow,
  onRemoveRow,
  respond,
  simple,
  showMissingItems,
  setMissingItems,
  handleChange,
  setFieldsCount,
  setFieldsWithReponseCount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [edit, setEdit] = useState(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [filteredColumns, setFilteredColumns] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<string>(row.response ?? '');
  const [selectedValueComplex, setSelectedValueComplex] = useState<any>(row.response ?? {});
  const response: any = row.response ?? {};

  const handleEditSaveClick = (value: string) => {
    setEdit(false);
  };

  useEffect(() => {
    if (!simple && columns.some((col) => col.type === 'total')) {
      setTotal(
        columns.reduce((total, column) => {
          if (column.type === 'numeric') {
            const value = selectedValueComplex[column.key] ? selectedValueComplex[column.key] : '0';
            return total + parseInt(value);
          }
          return total;
        }, 0)
      );
    }
  }, [simple, selectedValueComplex, columns]);

  useEffect(() => {
    if (!simple) {
      const column = columns.find((col) => col.type === 'total');
      if (column) {
        const response = { ...selectedValueComplex, [column.key]: total };
        handleChange(response);
      }
    }
  }, [simple, total, columns, selectedValueComplex, handleChange]);

  useEffect(() => {
    if (simple) setColumns(tableColumns);
    else {
      setColumns(
        tableColumns.flatMap((col) => {
          if (col.children) {
            col.children.forEach((child) => (child.key = col.name + ' | ' + child.name));
            return col.children;
          }

          col.key = col.name;
          return col;
        })
      );
    }
  }, [columnCount, tableColumns, simple]);

  useEffect(() => {
    if (!simple) {
      setFilteredColumns(
        columns.filter((column) => {
          const columnName = (column.key.split(' | ')[1] ?? column.key.split(' | ')[0]).toLowerCase();
          return !['remarks', 'others', 'total'].includes(columnName);
        })
      );
    }
    //
  }, [simple, columns]);

  useEffect(() => {
    if (simple) {
      if (!selectedValue && showMissingItems) {
        setMissingItems((prev) => [...prev, row]);
      }
      if (setFieldsCount && setFieldsWithReponseCount) {
        setFieldsCount((prev: any) => {
          const newValue = [...prev];
          newValue[index] = 1;
          return newValue;
        });
        setFieldsWithReponseCount((prev: any) => {
          const newValue = [...prev];
          newValue[index] = !selectedValue ? 0 : 1;
          return newValue;
        });
      }
    } else {
      if (setFieldsCount && setFieldsWithReponseCount) {
        const hasEmptyCell = filteredColumns.some((column) => {
          const value = selectedValueComplex[column.key];
          return !value;
        });

        if (hasEmptyCell && showMissingItems) {
          setMissingItems((prev) => [...prev, row]);
        }

        setFieldsCount((prev: any) => {
          const newValue = [...prev];
          newValue[index] = filteredColumns.length;
          return newValue;
        });
        setFieldsWithReponseCount((prev: any) => {
          const newValue = [...prev];
          newValue[index] = filteredColumns.reduce((total, column) => {
            const value = selectedValueComplex[column.key];
            return total + (!value ? 0 : 1);
          }, 0);
          return newValue;
        });
      }
    }
  }, [
    filteredColumns,
    index,
    row,
    selectedValue,
    selectedValueComplex,
    setFieldsCount,
    setFieldsWithReponseCount,
    setMissingItems,
    showMissingItems,
    simple,
  ]);

  const displayMissingItem = (column: TableColumnProps) => {
    const columnName = (column.key.split(' | ')[1] ?? column.key.split(' | ')[0]).toLowerCase();
    return (
      showMissingItems &&
      !selectedValueComplex[column.key] &&
      column.type !== 'total' &&
      columnName !== 'remarks' &&
      columnName !== 'others'
    );
  };

  const displayField = (column: TableColumnProps) => {
    switch (column.type) {
      case 'numeric':
      case 'text':
      case 'total':
        return (
          <input
            disabled={column.type === 'total'}
            style={{
              padding: '10px 10px 10px 20px',
              borderRadius: '5px',
              border: '1px solid ' + colors.border,
              width: '100%',
              minWidth: '180px',
              backgroundColor: 'transparent',
              borderColor: displayMissingItem(column) ? 'red' : undefined,
              color: colors.text,
            }}
            placeholder={column.key.split(' | ')[1] ?? column.key.split(' | ')[0]}
            defaultValue={response[column.key]}
            value={column.type === 'total' ? total : undefined}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (column.type === 'numeric') {
                const previousValue = event.target.value;
                event.target.value = previousValue.replace(/\D/g, '');
                if (previousValue !== event.target.value) {
                  return;
                }
              }
              const value = event.target.value;
              const response = { ...selectedValueComplex, [column.key]: value };
              setSelectedValueComplex((prev: any) => {
                return { ...prev, [column.key]: value };
              });
              handleChange(response);
            }}
          />
        );
      case 'radio':
      case 'y/n':
        return (
          <CustomRadioGroup
            missing={showMissingItems && !selectedValueComplex[column.key]}
            label={column.key}
            fieldName={column.key}
            options={
              column.options
                ? column.options.map((option) => {
                    return { key: option, value: option };
                  })
                : column.type === 'y/n'
                ? ['Yes', 'No'].map((option) => {
                    return { key: option, value: option };
                  })
                : []
            }
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              paddingInline: '10px',
              minWidth: '180px',
              '& .MuiRadio-root': {
                padding: '5px 10px',
              },
            }}
            lock={!respond}
            value={selectedValueComplex[column.key]}
            handleChange={(value) => {
              const response = { ...selectedValueComplex, [column.key]: value };
              setSelectedValueComplex((prev: any) => {
                return { ...prev, [column.key]: value };
              });
              handleChange(response);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  const isMissing = () => {
    if (showMissingItems) {
      if (simple) {
        return !selectedValue;
      } else {
        const hasEmptyCell = filteredColumns.some((column) => {
          const value = selectedValueComplex[column.key];
          return !value;
        });
        return hasEmptyCell;
      }
    }
    return false;
  };

  return (
    <TableRow key={`row-${row.field_name}`}>
      <TableCell style={{ minWidth: '300px' }}>
        <EditField
          missing={isMissing()}
          edit={edit}
          handleSave={(value) => handleEditSaveClick(value)}
          field={row}
          isTableRow
        />
      </TableCell>
      {columns.map((column, index) => (
        <TableCell
          key={`column-${column.name}-${index}`}
          style={{ minWidth: respond && !simple ? '100px' : '70px', padding: '3px' }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            {simple ? (
              <Radio
                checked={selectedValue === column.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedValue(event.target.value);
                  handleChange(event.target.value);
                }}
                value={column.name}
                name="radio-buttons"
              />
            ) : (
              <>{displayField(column)}</>
            )}
          </Box>
        </TableCell>
      ))}
      <ConditionalHideComponent hide={respond}>
        <TableCell>
          <RowAction
            actions={[
              {
                label: 'Edit row',
                startIcon: <DriveFileRenameOutlineOutlinedIcon />,
                action: () => setEdit(true),
                hidden: edit,
              },
              {
                label: 'Add row below',
                startIcon: <AddBoxOutlinedIcon />,
                action: () => onAddRowBelow(row),
              },
              {
                label: 'divider',
              },
              {
                label: 'Remove row',
                startIcon: <DeleteOutlinedIcon />,
                action: () => onRemoveRow(),
              },
            ]}
          />
        </TableCell>
      </ConditionalHideComponent>
    </TableRow>
  );
};

export default TableFieldRow;
