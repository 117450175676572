/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Collapse } from '@mui/material';
import { ConditionalHideComponent, RegularButton } from 'components';
import FieldGroups, { FIELD_TYPES, getFieldObject } from './FieldGroups';
import { useEffect, useState } from 'react';

import AddFieldDropdown from '../components/AddFieldDropdown';
import { AssessmentSubSectionModel } from 'model/Entities';
import EditField from '../components/EditField';
import EditFieldDropdown from '../components/EditFieldDropdown';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FieldType } from '../IndicatorBuilder';
import LinearProgressWithLabel from 'components/LinearProgressBar';

type SectionProps = {
  section: AssessmentSubSectionModel;
  droppableId: string;
  level?: number;
  index: number;
  mainSection?: boolean;
  subSection?: boolean;
  deleteSection: () => void;
  addField: (field_type: FIELD_TYPES) => void;
  sectionNumber: string;
  respond?: boolean;
  selectedField?: FieldType;
  showMissingItems: boolean;
  setSelectedField?: (value: FieldType) => void;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
  mainIndex?: number;
  mainSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  mainSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
};

const Section: React.FC<SectionProps> = ({
  section,
  droppableId,
  level,
  index,
  mainSection,
  subSection,
  sectionNumber,
  deleteSection,
  addField,
  respond,
  selectedField,
  showMissingItems,
  setSelectedField,
  setMissingItems,
  mainIndex,
  mainSetTotalFieldsCount,
  mainSetTotalFieldsWithReponseCount,
  childSetTotalFieldsCount,
  childSetTotalFieldsWithReponseCount,
}) => {
  const [open, setOpen] = useState(true);
  const [edit, setEdit] = useState(false);
  const [sectionBody, setSectionBody] = useState<any[]>([]);
  const [, setBodyCount] = useState(0);

  const [totalFieldsCount, setTotalFieldsCount] = useState<number>(0);
  const [totalFieldsWithReponseCount, setTotalFieldsWithReponseCount] = useState<number>(0);
  const [fieldsCount, setFieldsCount] = useState<number[]>([]);
  const [fieldsWithReponseCount, setFieldsWithReponseCount] = useState<number[]>([]);

  useEffect(() => {
    setSectionBody(subSection ? section.section_content! : section.section_body!);
    setBodyCount(subSection ? section.section_content!.length : section.section_body!.length);
  }, [section]);

  useEffect(() => {
    setTotalFieldsCount(
      fieldsCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
    setTotalFieldsWithReponseCount(
      fieldsWithReponseCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
  }, [fieldsCount, fieldsWithReponseCount]);

  useEffect(() => {
    if (
      mainIndex !== undefined &&
      mainIndex >= 0 &&
      mainSection &&
      mainSetTotalFieldsCount &&
      mainSetTotalFieldsWithReponseCount
    ) {
      mainSetTotalFieldsCount((prev: any) => {
        const newValue = [...prev];
        newValue[mainIndex] = totalFieldsCount;
        return newValue;
      });
      mainSetTotalFieldsWithReponseCount((prev: any) => {
        const newValue = [...prev];
        newValue[mainIndex] = totalFieldsWithReponseCount;
        return newValue;
      });
    }

    if (childSetTotalFieldsCount && childSetTotalFieldsWithReponseCount) {
      childSetTotalFieldsCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsCount;
        return newValue;
      });
      childSetTotalFieldsWithReponseCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsWithReponseCount;
        return newValue;
      });
    }
  }, [totalFieldsCount, totalFieldsWithReponseCount]);

  const variant = mainSection ? 'h4' : subSection ? 'h6' : 'h5';

  const handleEditSaveClick = (value: string) => {
    setEdit(!edit);
  };

  const addChildField = (field_type: FIELD_TYPES) => {
    let newField: FieldType = getFieldObject(field_type);

    sectionBody.push(newField);
    if (subSection) section.section_content = sectionBody;
    else section.section_body = sectionBody;
    setSectionBody([...sectionBody]);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap="20px">
        <EditField
          edit={edit}
          handleSave={(value) => handleEditSaveClick(value)}
          variant={variant}
          field={section as any}
          isSection
          sectionNumber={mainSection ? 'Section ' + sectionNumber : sectionNumber}
        />

        <ConditionalHideComponent hide={respond}>
          <Box display="flex" gap="2px">
            <EditFieldDropdown
              optionList={[
                {
                  label: 'Edit Field',
                  action: () => setEdit(true),
                  hidden: edit,
                },
                {
                  label: 'divider',
                },
                {
                  label: 'Add Child Field - Section',
                  action: () => addChildField(FIELD_TYPES.SECTION),
                },
                {
                  label: 'Add Child Field - Subsection',
                  action: () => addChildField(FIELD_TYPES.SUB_SECTION),
                },
                {
                  label: 'Add child field - Parent',
                  action: () => addChildField(FIELD_TYPES.PARENT),
                },
                {
                  label: 'divider',
                },
                {
                  label: 'Add child field - Text',
                  action: () => addChildField(FIELD_TYPES.TEXT),
                },
                {
                  label: 'Add child field - Numeric',
                  action: () => addChildField(FIELD_TYPES.NUMERIC),
                },
                {
                  label: 'Add child field - Radio',
                  action: () => addChildField(FIELD_TYPES.RADIO),
                },
                {
                  label: 'Add child field - Yes or No',
                  action: () => addChildField(FIELD_TYPES.YES_OR_NO),
                },
              ]}
            />

            {!mainSection && <AddFieldDropdown addField={addField} />}
          </Box>
        </ConditionalHideComponent>
      </Box>
      {mainSection && (
        <LinearProgressWithLabel
          total={totalFieldsCount}
          progress={totalFieldsWithReponseCount}
          value={(totalFieldsWithReponseCount / totalFieldsCount) * 100}
        />
      )}
      <Box>
        <ConditionalHideComponent hide={mainSection || respond}>
          <RegularButton
            startIcon={open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            label={open ? 'Hide' : 'Show'}
            onClick={() => setOpen(!open)}
            size="small"
            variant="text"
            color="inherit"
          />
        </ConditionalHideComponent>
        <Collapse in={mainSection ? true : open || respond} timeout="auto" sx={{ marginTop: '10px' }}>
          <FieldGroups
            items={sectionBody}
            droppableId={droppableId}
            level={level}
            index={index}
            sectionNumber={sectionNumber}
            parentObject={section}
            parentProperty={subSection ? 'section_content' : 'section_body'}
            respond={respond}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            showMissingItems={showMissingItems}
            setMissingItems={setMissingItems}
            childSetTotalFieldsCount={setFieldsCount}
            childSetTotalFieldsWithReponseCount={setFieldsWithReponseCount}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default Section;
