import * as React from 'react';

import { Box } from '@mui/material';

type CustomDrawerProps = {
  parentComponent: JSX.Element;
  childComponent: JSX.Element;
  gridTemplateColumns: string;
  showChild: boolean;
};

const CustomDrawer: React.FC<CustomDrawerProps> = ({
  parentComponent,
  childComponent,
  gridTemplateColumns,
  showChild,
}) => {
  return (
    <Box
      // m="20px"
      sx={{
        '& .show-drawer': {
          gridTemplateColumns,
          gap: '20px',
        },
      }}
    >
      <Box
        display="grid"
        gridTemplateColumns="100% 100px"
        className={showChild ? 'show-drawer' : ''}
        sx={{ transition: 'all ease-in-out 0.25s', gap: 0 }}
      >
        <Box>{parentComponent}</Box>

        <Box style={!showChild ? { height: 0, overflow: 'hidden' } : undefined}>{childComponent}</Box>
      </Box>
    </Box>
  );
};

export default CustomDrawer;
