/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Menu, MenuItem } from '@mui/material';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import { WidgetGroupModel, WidgetModel } from 'model/Entities';
import { createWidgetGroup, deleteWidgetGroup, getWidgetGroups, reorderWidgetGroup } from 'api/dashboard';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Row from './components/Row';
import { useParams } from 'react-router-dom';

type ColumnType = {
  id: string;
  list: number[];
};

const DashboardEditor = () => {
  const { indicator_id } = useParams();
  const [widgetGroups, setWidgetGroups] = useState<WidgetGroupModel[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (indicator_id) {
      getGroups();
    }
  }, [indicator_id]);

  const getGroups = () => {
    getWidgetGroups(parseInt(indicator_id!)).then((res) => {
      setWidgetGroups(res.data);
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const reorderedGroups = Array.from(widgetGroups);
    const [removed] = reorderedGroups.splice(result.source.index, 1);
    reorderedGroups.splice(result.destination.index, 0, removed);
    await reorderWidgetGroup(parseInt(indicator_id!), {
      from: widgetGroups.map((group) => group.id),
      to: reorderedGroups.map((group) => group.id),
    });
    getGroups();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddGroup = async (layout: string) => {
    const newGroup: any = {
      layout: layout,
      column_type: layout,
      indicator_id: indicator_id,
      widgets: [],
    };

    handleClose();
    await createWidgetGroup(newGroup);
    getGroups();
  };
  const handleDeleteGroup = async (groupId: number) => {
    await deleteWidgetGroup(groupId);
    getGroups();
  };

  const processColumns = (columns: WidgetModel[], layout: string) => {
    if (!columns) return [];
    return JSON.parse(layout).map((col: any, columnIndex: number) => {
      return {
        id: `column_${columnIndex + 1}`,
        list: col.map((item: any) => columns.find((widget) => widget.id === item)),
      };
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '7rem', padding: '20px 0 0 0' }}>
        <Button
          aria-controls="layout-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{ fontSize: '1em' }}
        >
          Add Widget Group
        </Button>
      </div>
      <Menu id="layout-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleAddGroup('3-3-3-3')}>3-3-3-3 Layout</MenuItem>
        <MenuItem onClick={() => handleAddGroup('4-4-4')}>4-4-4 Layout</MenuItem>
        <MenuItem onClick={() => handleAddGroup('6-6')}>6-6 Layout</MenuItem>
        <MenuItem onClick={() => handleAddGroup('8-4')}>8-4 Layout</MenuItem>
        <MenuItem onClick={() => handleAddGroup('4-8')}>4-8 Layout</MenuItem>
      </Menu>

      <Droppable droppableId="widgetGroupsList">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {widgetGroups.map((group, index: number) => {
              const final: ColumnType[] = processColumns(group.widgets, group.layout);
              return (
                <Draggable key={group.id} draggableId={group.id!.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps} // Continue to spread draggableProps here
                      style={{
                        ...provided.draggableProps.style,
                        margin: '1rem 0',
                        padding: '10px',
                        backgroundColor: snapshot.isDragging ? 'lightgreen' : 'white',
                        boxShadow: snapshot.isDragging ? '0 0 .4rem rgba(0,0,0,0.25)' : 'none',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          {...provided.dragHandleProps}
                          style={{ cursor: 'grab', marginRight: '1rem', display: 'inline-flex' }}
                        >
                          <DragIndicatorIcon />
                        </div>
                        <span>Reorder Widget Group</span>
                      </div>
                      <Row
                        group_id={group.id}
                        initialColumns={final}
                        layout={group.column_type}
                        onDelete={() => handleDeleteGroup(group.id)}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DashboardEditor;
