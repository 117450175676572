import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

import { Box } from '@mui/material';
import { CSSProperties } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import uuid from 'react-uuid';

type DraggableListProps = {
  droppableId: string;
  handleDragEnd: (result: DropResult) => void;
  list: any[];
  style?: CSSProperties;
  displayItem: (item: any, index: number) => any;
  hideDragIcon?: boolean;
  fromModal?: boolean;
  itemBackgroundColor?: any;
  dragIconPaddingTop?: string;
};

const DraggableList: React.FC<DraggableListProps> = ({
  droppableId,
  handleDragEnd,
  list,
  style,
  displayItem,
  hideDragIcon,
  fromModal,
  itemBackgroundColor,
  dragIconPaddingTop,
}) => {
  const positionStyle = fromModal
    ? {
        left: 'auto !important',
        top: 'auto !important',
      }
    : {};

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {list.map((item, index: number) => {
              if (item && !item.item_id) item.item_id = uuid();
              return (
                <Draggable key={item.item_id} draggableId={item.item_id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps} // Continue to spread draggableProps here
                      style={{
                        ...provided.draggableProps.style,
                        borderRadius: '10px',
                        backgroundColor: snapshot.isDragging ? 'lightgreen' : itemBackgroundColor,
                        ...positionStyle,
                        ...style,
                      }}
                    >
                      <Box display="flex" gap="10px" width="100%">
                        {!hideDragIcon && (
                          <div {...provided.dragHandleProps} style={{ cursor: 'grab', paddingTop: dragIconPaddingTop }}>
                            <DragIndicatorIcon />
                          </div>
                        )}
                        <Box width="100%">{displayItem(item, index)}</Box>
                      </Box>
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
