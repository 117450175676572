import { Box } from '@mui/material';
import { CustomContainer, RegularButton } from 'components';
import { CustomPCFEquipmentTable, CustomPCFTable, PageStyle, Header } from 'components/CustomPCFTable';
import { pcf_document, pcf_equipment_document } from 'data/Aklan/dashboard/pcf_document';
import { useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

export const AklanPCFTable = () => {
  const [pcfRef, setpcfRef] = useState<HTMLDivElement | null>(null);
  const [toPrint, setToPrint] = useState(false);

  const generatePDF = useReactToPrint({
    documentTitle: 'PCF Table',
    content: () => pcfRef,
    pageStyle: PageStyle,
  });

  useEffect(() => {
    if (pcfRef) {
      generatePDF();
      setToPrint(false);
    }
  }, [toPrint]);

  return (
    <CustomContainer>
      <RegularButton label="Print Aklan PDF" onClick={() => setToPrint(true)} />
      <Box ref={(el: any) => setpcfRef(el)}>
        <Header>PART I. SERVICE CAPABILITY, PERSONNEL AND PHYSICAL PLANT</Header>
        <CustomPCFTable tableData={pcf_document} />
        <br />
        <Header>PART II. EQUIPMENT AND INSTRUMENTS</Header>
        <CustomPCFEquipmentTable tableData={pcf_equipment_document} />
      </Box>
    </CustomContainer>
  );
};
