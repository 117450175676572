import { HelpOutline } from '@mui/icons-material';
import { AccordionDetails, Box, Typography, useTheme } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { categories } from './categories';
import { tokens } from 'context/theme.context';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styled from 'styled-components';

const HelpDesk = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    setBreadcrumb([{ label: 'Help Desk' }]);
  }, []);

  const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
      color: ${colors.primary};
    }
  `;

  const categoryContainerStyle = {
    backgroundColor: colors.secondary_background,
    borderRadius: '10px',
    padding: '20px',
  };

  const titleStyle = {
    fontWeight: 'bold',
    fontSize: '2em',
    marginLeft: '0.3em',
  };

  return (
    <Box padding={3}>
      <TitleContainer>
        <HelpOutline fontSize="large" />
        <Typography sx={titleStyle}>Help Desk</Typography>
      </TitleContainer>

      {/* container */}
      <Box display="flex" flexDirection="column" gap="20px">
        {categories.map((category, categoryIndex) => (
          // individual container
          <Box sx={categoryContainerStyle} key={categoryIndex}>
            {/* clickable category container */}
            <StyledLink to={`/helpdesk/${category.categoryCode}`}>
              <Typography variant="h4" fontWeight="bold" mb="10px">
                {category.categoryName}
              </Typography>
            </StyledLink>

            {/* individual articles container */}
            {category.articles.map((article, articleIndex) => (
              <AccordionDetails key={articleIndex}>
                <Box display="flex" flexDirection="column" width="100%" gap="15px">
                  <Box key={0}>
                    <StyledLink to={`/helpdesk/${category.categoryCode}/${article.article_code}`}>
                      <FiberManualRecordIcon color="primary" />
                      <Typography variant="h5" marginLeft="10px">
                        {article.article_title}
                      </Typography>
                    </StyledLink>
                  </Box>
                </Box>
              </AccordionDetails>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export default HelpDesk;
