import 'styles/Components.css';

import { ChangeEvent, useEffect, useState } from 'react';
import { MenuItem, TextField, useTheme } from '@mui/material';

import { SelectOption } from 'model/interface';
import { tokens } from 'context/theme.context';

type DropdownFilterProps = {
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: unknown;
  disabled?: boolean;
  options?: SelectOption[];
  handleChangeCallback?: (value: string) => void;
  maxWidth?: string;
};

const DropdownFilter: React.FC<DropdownFilterProps> = ({
  label,
  handleBlur,
  handleChange,
  value,
  disabled,
  options,
  handleChangeCallback,
  maxWidth,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedOption, setSelectedOption] = useState<any>(value);
  options = options?.filter((option) => !option.hidden);

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedOption(event.target.value);
    if (handleChange) handleChange(event);
    if (handleChangeCallback) handleChangeCallback(event.target.value);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <TextField
      fullWidth
      select
      variant="filled"
      label={label}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleSelectChange}
      onSelect={(e) => console.log(e)}
      value={selectedOption}
      sx={{
        maxWidth,
        '.MuiInputLabel-root': {
          fontSize: '10px',
          top: '-3px',
        },
        '.MuiFilledInput-input.MuiSelect-select': {
          fontSize: '12px',
          // height: '0',
          minHeight: 'unset',
          background: 'transparent',
          paddingTop: '20px',
        },
        '.MuiFormLabel-root.Mui-focused': {
          color: colors.light,
        },
        '.MuiFilledInput-root:before': {
          borderBottom: 'none !important',
        },
        '.MuiFormHelperText-root': {
          position: 'absolute',
          bottom: '-18px',
        },
        '.MuiFilledInput-root': {
          borderRadius: '5px',
          height: '40px',
        },
      }}
    >
      <MenuItem value="" disabled={!options?.length}>
        Select {label}
      </MenuItem>
      {options?.map((val, index) => (
        <MenuItem key={index} value={val.value}>
          {val.key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default DropdownFilter;
