import { WidgetGroupModel, WidgetModel } from 'model/Entities';

import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const widget_group_path = 'widget-group';
const widget_path = 'widget';

export const getWidgetGroups = (indicator_id: number) => {
  return axios.get(`${baseURL}/${widget_group_path}/${indicator_id}`);
};

export const createWidgetGroup = (group: WidgetGroupModel) => {
  return axios.post(`${baseURL}/${widget_group_path}`, group);
};

export const reorderWidgetGroup = (indicator_id: number, order: { from: number[]; to: number[] }) => {
  return axios.put(`${baseURL}/${widget_group_path}/${indicator_id}/reorder`, order);
};

export const reorderWidgets = (group_id: number, layout: any) => {
  return axios.put(`${baseURL}/${widget_group_path}/${group_id}/reorder-widgets`, layout);
};

export const deleteWidgetGroup = (id: number) => {
  return axios.delete(`${baseURL}/${widget_group_path}/${id}`);
};

// WIDGET
export const createWidget = (widget: WidgetModel) => {
  return axios.post(`${baseURL}/${widget_path}`, widget);
};

export const updateWidget = (widget_id: number, widget: WidgetModel) => {
  return axios.put(`${baseURL}/${widget_path}/${widget_id}`, widget);
};

export const deleteWidget = (id: number) => {
  return axios.delete(`${baseURL}/${widget_path}/${id}`);
};
