module.exports = {
  section_number: 1,
  section_name: 'Adolescent Friendly Health Facility (AFHF) Certifiers Checklist',
  section_body: [
    {
      type: 'table',
      field_name: 'Adolescent Friendly Health Facility (AFHF) Certifiers Checklist',
      field_code: '1',
      table_type: 'simple-table',
      columns: ['Met', 'Unmet', 'Not Applicable'],
      rows: [
        {
          type: 'field',
          field_name: 'Welcome signage with the name of the facility posted visible at the entrance.',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of clinic schedule:

          a. Clinic hours must include schedule specifically for adolescents
          b. Schedule of clinic hours must be visible in the entrance area
          `,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Designated Person must:
          
          a. Have a copy of signed executive/ office order as designated person with access to client’s records
          b. With alternate trained designated person with access to records`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Certificates of the following minimum trainings:
          - Foundational Course
          - Adolescent Job Aid (Hospitals only)
          - FPCBT 1
          - FPCBT 2 - IIUD, PPIUD, PSI
          - Room/ space designated for consultation, treatment and/ or counseling
          - Must ensure privacy and confidentiality (separated by walls, curtains, blinds or dividers)`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of:
          - Adolescent Job Aid Manual
          - AHDP Manual of Operations
          - Adopted and signed protocols and guidelines for patient-provider interaction
          - Standard Operating Procedure for Facility Maintenance.
          - Presence of EO/ Resolution/ Ordinance/ Office order on the provision of services for adolescents.
          - List of health services for adolescents provided by the facility posted visible at the entrance.
          - Presence of ITR with complete data kept in separate envelopes and in a filing cabinet with a lock and key secured by the designated person in charge of the access to client’s records
          - Attached/ compiled Accomplishment on HEADSSS`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Directory of organizations for referral purpose must contain:
          a. Name of Organization
          b. Address
          c. Contact Person and Number`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Services provided and schedule of services`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Certificates of Additional Trainings:
          - Youth Peer Educators Orientation (HYO Plus)
          - Usapang Biba
          - Adolescent MHPSS
          - HIV Counseling and Testing
          - Bemonc Training (Birthing and Hospitals)
          - Training on MISP-ASRH (Hospitals)
          - 4 R’s Training
          - Cognitive Behavior Therapy
          - Adolescent Medicine/ Specialty`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Copy of IEC and/ or advocacy Plan with budget appropriation`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of IEC and educational materials for adolescents of diverse types/ platforms display and used by the facility as indicated in the plan`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of IEC Inventory and distribution list`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Complete documentation of advocacy and promotional activities conducted as stipulated in the plan`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Algorithm for patient flow must contain:

          a. Flow from admission/ registration to delivery of services up to follow-up
          b. Average time needed for each steps to be accomplished
          c. Section/ Department responsible`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Copy of signed Executive Order/ Resolution/ ordinance/ Officer Order on Flexible Time Schedule of Health Workers. Flexible time can be either:

          a. On weekdays, 8am-5pm
          b. On weekdays, 8-am-5pm with referral agreement with schools (especially in emergency cases)
          c. On weekdays, 1-2 hours after 5pm`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of atleast 1 policy ensuring privacy and confidentiality. Posted at a strategic area.`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of policy providing financial risk protection schemes specifically for adolescents. Posted at a strategic area.`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `List of patients provided with clinical or laboratory services including medicines and commodities should be reflected in the client’s registration and referral logbooks`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of Accomplishment Report`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Copy of completely signed and notarized MOA/ MOU for Service Delivery Network/ Healthcare Provider Network.`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Attached/Complied Return Referral Slip`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of Stock Cards with date and quantity of delivery and utilization of medicines and other commodities`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of Expiration Dates of medicines`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of suggestion box specific for adolescent health and survey forms with Summary of results and actions taken.`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Copy of signed policy for the creation or appointment of the TWG or adaption of the Local Health Board with indicated roles/ responsibilities of members and schedule of TWG Meeting.`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Complete minutes of the meeting with attendance sheet and documentation.`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Presence of Youth Peer Educators:

          - Signed policy/ Office order of peer educators and their list of schedule assisting in clinic operations
          - Certificates of training of peer educators
          - Complete documentation of activities conducted/ participated by peer educators`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Copy of either of the following:
          a. LIPH
          b. LDP
          c. AOP`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Copy and designed budget Utilization report`,
          field_code: '1',
        },
        {
          type: 'field',
          field_name: `Good Practice in:
          - Advocacy and Promotional Activities
          - Health Facility Policies and Guidelines
          - Delivery of Health Services
          - Partnerships and Collaboration`,
          field_code: '1',
        },
      ],
    },
  ],
};
