/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, IconButton, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import { categories } from './categories';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HelpDeskSidebar from './HelpDeskSidebar';

const HelpDeskArticle = () => {
  const navigate = useNavigate();
  const { category, article } = useParams() as any;
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [content, setContent] = useState<any>();
  const [categoryName, setCategoryName] = useState<string>('');
  const [nextArticle, setNextArticle] = useState<string>('');
  const [prevArticle, setPrevArticle] = useState<string>('');
  const [prevArticleTitle, setPrevArticleTitle] = useState<string>('');
  const [nextArticleTitle, setNextArticleTitle] = useState<string>('');

  useEffect(() => {
    if (category && article) {
      const cat = categories.find((cat) => cat.categoryCode === category);
      setCategoryName(cat?.categoryName ?? '');
      const articles = cat?.articles;

      if (articles) {
        const currArticle = articles.find((art) => art.article_code === article);

        if (currArticle) {
          setContent(currArticle);

          const currIndex = articles.indexOf(currArticle);
          if (currIndex !== -1) {
            setPrevArticle(currIndex - 1 >= 0 ? articles[currIndex - 1].article_code : '');
            setPrevArticleTitle(currIndex - 1 >= 0 ? articles[currIndex - 1].article_title : '');

            setNextArticle(currIndex + 1 < articles.length ? articles[currIndex + 1].article_code : '');
            setNextArticleTitle(currIndex + 1 < articles.length ? articles[currIndex + 1].article_title : '');
          }
        }
      }
    }
  }, [category, article]);

  useEffect(() => {
    content &&
      setBreadcrumb([
        { label: 'Help Desk', link: '/helpdesk' },
        { label: categoryName, link: `/helpdesk/${category}` },
        { label: content.article_title },
      ]);
  }, [content]);

  return (
    <Con>
      <HelpDeskSidebar />
      <StyledBox>
        <Typography variant="h3" mb="1em">
          {content?.article_title}
        </Typography>
        <Content>{content?.content}</Content>
        <NavigationControl>
          {prevArticle && prevArticle !== 'null' ? (
            <Divider>
              <div>
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (prevArticle) {
                      navigate(`/helpdesk/${category}/${prevArticle}`);
                    }
                  }}
                  size="large"
                >
                  <ArrowForwardIcon fontSize="large" style={{ transform: 'rotate(180deg)' }} />
                </IconButton>
                <NavigationTextButton
                  onClick={() => {
                    if (prevArticle) {
                      navigate(`/helpdesk/${category}/${prevArticle}`);
                    }
                  }}
                >
                  Previous Article
                </NavigationTextButton>
              </div>
              <Typography variant="body1" align="center">
                {prevArticle ? `Previous: ${prevArticleTitle}` : 'Start of articles'}
              </Typography>
            </Divider>
          ) : (
            <Divider style={{ visibility: 'hidden' }}>
              <div>
                <IconButton size="large">
                  <ArrowForwardIcon fontSize="large" style={{ transform: 'rotate(180deg)' }} />
                </IconButton>
                <NavigationTextButton>Previous Article</NavigationTextButton>
              </div>
              <Typography variant="body1" align="center">
                Start of articles
              </Typography>
            </Divider>
          )}

          {nextArticle && nextArticle !== 'null' ? (
            <Divider>
              <div>
                <NavigationTextButton
                  onClick={() => {
                    if (nextArticle) {
                      navigate(`/helpdesk/${category}/${nextArticle}`);
                    }
                  }}
                >
                  Next Article
                </NavigationTextButton>
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (nextArticle) {
                      navigate(`/helpdesk/${category}/${nextArticle}`);
                    }
                  }}
                  size="large"
                >
                  <ArrowForwardIcon fontSize="large" />
                </IconButton>
              </div>

              <Typography variant="body1" align="center">
                {nextArticle ? `Next: ${nextArticleTitle}` : 'End of articles'}
              </Typography>
            </Divider>
          ) : (
            <Divider style={{ visibility: 'hidden' }}>
              <div>
                <NavigationTextButton>Next Article</NavigationTextButton>
                <IconButton size="large">
                  <ArrowForwardIcon fontSize="large" />
                </IconButton>
              </div>
              <Typography variant="body1" align="center">
                End of articles
              </Typography>
            </Divider>
          )}
        </NavigationControl>
      </StyledBox>
    </Con>
  );
};

export default HelpDeskArticle;
const Con = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const NavigationControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 55vw;
  margin-top: 20px;
  align-items: center;

  @media (max-width: 768px) {
    width: 90vw;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const NavigationTextButton = styled(Button)`
  && {
    color: #007bff;
    text-transform: none;
    font-size: 16px;
    margin-left: 8px;
    margin-right: 8px;
  }
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-style: italic;
  font-size: 15px;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const StyledBox = styled(Box)`
  margin-left: 40px;

  @media (max-width: 768px) {
    margin-left: 20px;
    width: 90vw;
  }
`;

const Content = styled.div`
  max-width: 80%;
`;
