import ArticleContent, { imagePath } from '../ArticleContent';

const ActivateReactivateBatch = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Activating Batch',
          steps: [
            {
              step: '1. Select the row of the assessment to be activated.',
              image: `${imagePath}/activate-assessment-1.png`,
            },
            {
              step: '2. Select the Assessments Tab on the upper-right part.',
            },
            {
              step: '3. Click "Activate Assessments".',
              image: `${imagePath}/activate-assessment-2.png`,
            },
            {
              step: '4. Upon clicking the button, the status of the assessment will be updated to "active".',
              image: `${imagePath}/active-assessment.png`,
            },
          ],
        },
        { divider: true },
        {
          title: 'Reactivating Batch',
          steps: [
            {
              step: '1. Select the row of the closed assessment to be reactivated.',
              image: `${imagePath}/reactivate-assessment-1.png`,
            },
            {
              step: '2. Select the Assessments Tab on the upper-right part.',
            },
            {
              step: '3. Click "Reactivate Assessments".',
              image: `${imagePath}/reactivate-assessment-2.png`,
            },
            {
              step: '4. Upon clicking the button, the status of the assessment will be updated to "active".',
              image: `${imagePath}/active-assessment.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default ActivateReactivateBatch;
