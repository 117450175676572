import { ReportEntryModel } from 'model/Entities';
import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'report-entry';

export const getReportEntriesByReportID = (report_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/${report_id}`, { params: query });
};

export const getReportEntrySummary = (id: number) => {
  return axios.get(`${baseURL}/${path}/summary/${id}`);
};

export const getAllReportEntries = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/list/all`, { params: query });
};

export const getActiveReportEntries = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/active/all`, { params: query });
};

export const createReportEntry = (reportEntry: ReportEntryModel) => {
  return axios.post(`${baseURL}/${path}`, reportEntry);
};

export const createReportEntryAssessAll = (reportEntry: ReportEntryModel) => {
  return axios.post(`${baseURL}/${path}/assess-all`, reportEntry);
};

export const duplicateReportEntry = (reportEntry: ReportEntryModel) => {
  return axios.post(`${baseURL}/${path}/duplicate`, reportEntry);
};

export const updateReportEntryByID = (id: number, reportEntry: ReportEntryModel) => {
  return axios.patch(`${baseURL}/${path}/${id}`, reportEntry);
};

export const updateReportEntryStatus = (id: number, status: 'open' | 'active' | 'closed') => {
  return axios.patch(`${baseURL}/${path}/${status}/${id}`);
};

export const deleteReportEntry = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
