/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ConditionalHideComponent,
  ConfirmationDialog,
  CustomIconButton,
  CustomModal,
  DraggableList,
  RegularButton,
} from 'components';
import React, { useEffect, useRef, useState } from 'react';

import AddFieldDropdown from '../components/AddFieldDropdown';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropResult } from 'react-beautiful-dnd';
import EditField from '../components/EditField';
import EditFieldDropdown from '../components/EditFieldDropdown';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FIELD_TYPES } from './FieldGroups';
import { FieldType } from '../IndicatorBuilder';
import TableFieldColumn from '../components/TableFieldColumn';
import TableFieldRow from '../components/TableFieldRow';
import { tokens } from 'context/theme.context';
import { v4 as uuidv4 } from 'uuid';

type TableFieldProps = {
  field: FieldType;
  droppableId: string;
  simple: boolean;
  level?: number;
  addField: (field_type: FIELD_TYPES) => void;
  deleteField: () => void;
  editApplicability: () => void;
  respond?: boolean;
  handleChange: (field: any, property: keyof FieldType, data: any) => void;
  index: number;
  showMissingItems: boolean;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
  childSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
};

export type TableColumnProps = {
  name: string;
  key: string;
  type: 'numeric' | 'y/n' | 'total' | 'text' | 'radio';
  code: string;
  children?: TableColumnProps[];
  options?: any[];
};

const TableField: React.FC<TableFieldProps> = ({
  field,
  droppableId,
  simple,
  level = 1,
  addField,
  deleteField,
  editApplicability,
  respond,
  handleChange,
  index,
  showMissingItems,
  setMissingItems,
  childSetTotalFieldsCount,
  childSetTotalFieldsWithReponseCount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const headerRef: any = useRef();

  const [open, setOpen] = useState(true);
  const [headerCount, setHeaderCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [rows, setRows] = useState<FieldType[]>(field.rows || []);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [reorderRowsModalOpen, setReorderRowsModalOpen] = useState(false);
  const [reorderedRows, setReorderedRows] = useState<FieldType[]>([]);
  const [editColumnsModalOpen, setEditColumnsModalOpen] = useState(false);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [confirmationDialogOpenColumn, setConfirmationDialogOpenColumn] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const [totalFieldsCount, setTotalFieldsCount] = useState<number>(0);
  const [totalFieldsWithReponseCount, setTotalFieldsWithReponseCount] = useState<number>(0);
  const [fieldsCount, setFieldsCount] = useState<number[]>([]);
  const [fieldsWithReponseCount, setFieldsWithReponseCount] = useState<number[]>([]);

  useEffect(() => {
    setTotalFieldsCount(
      fieldsCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
    setTotalFieldsWithReponseCount(
      fieldsWithReponseCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
  }, [fieldsCount, fieldsWithReponseCount]);

  useEffect(() => {
    if (childSetTotalFieldsCount && childSetTotalFieldsWithReponseCount) {
      childSetTotalFieldsCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsCount;
        return newValue;
      });
      childSetTotalFieldsWithReponseCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsWithReponseCount;
        return newValue;
      });
    }
  }, [totalFieldsCount, totalFieldsWithReponseCount]);

  const handleRenameClick = () => {
    setEdit(true);
  };

  const handleEditColumnsClick = () => {
    setEditColumnsModalOpen(true);
  };

  const handleChangeReponse = (row: FieldType, response: any) => {
    row.response = response;
    handleChange(field, 'rows', rows);
  };

  useEffect(() => {
    setHeaderCount(0);
    columns.forEach((item) => {
      if (item.children) {
        setHeaderCount((prev) => prev + item.children.length);
      } else {
        setHeaderCount((prev) => prev + 1);
      }
    });
  }, [columns]);

  useEffect(() => {
    setColumns(
      simple
        ? field.columns!.map((col) => {
            return { name: col };
          })
        : field.columns!
    );
  }, [field]);

  const headerWidth = headerCount > 3 ? 60 / headerCount : 40 / headerCount;

  const handleEditRowsClick = () => {
    setReorderedRows([...rows]);
    setReorderRowsModalOpen(true);
  };

  const handleDragEndModal = (result: DropResult) => {
    if (!result.destination) return;

    const updatedRows = Array.from(reorderedRows);
    const [removed] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, removed);

    setRows(updatedRows);
    setReorderedRows(updatedRows);
  };

  const handleAddRowBelow = (selectedRow: FieldType) => {
    const updatedRows = [...rows];

    const selectedIndex = updatedRows.findIndex((row) => row.field_name === selectedRow.field_name);

    if (selectedIndex !== -1) {
      const uniqueIdentifier = uuidv4();

      const newRow: FieldType = {
        type: 'field',
        field_name: 'New Row',
        field_code: `NewRow_${uniqueIdentifier}`,
      };

      const insertIndex = selectedIndex + 1;

      updatedRows.splice(insertIndex, 0, newRow);

      setRows(updatedRows);
    }
  };

  const handleRemoveRow = (selectedRow: FieldType) => {
    setConfirmationDialogOpen(true);
    setSelectedRowIndex(rows.findIndex((row) => row.field_name === selectedRow.field_name));
  };

  const handleDeleteColumn = (index: number) => {
    setConfirmationDialogOpenColumn(true);
    setSelectedColumnIndex(index);
  };

  const handleConfirmDeleteColumn = () => {
    setConfirmationDialogOpenColumn(false);

    if (selectedColumnIndex !== null) {
      const updatedColumns = [...columns];
      updatedColumns.splice(selectedColumnIndex, 1);

      setColumns(updatedColumns);
      setSelectedColumnIndex(null);
    }
  };

  const removeRow = (indexToRemove: number) => {
    const updatedRows = [...rows];
    updatedRows.splice(indexToRemove, 1);
    setRows(updatedRows);
  };

  const handleConfirmDeleteRow = () => {
    setConfirmationDialogOpen(false);

    if (selectedRowIndex !== null && selectedRowIndex !== -1) {
      removeRow(selectedRowIndex);
      setSelectedRowIndex(null);
    }
  };

  const handleDragEndColumns = (result: DropResult) => {
    if (!result.destination) return;

    const updatedColumns = Array.from(columns);
    const [removed] = updatedColumns.splice(result.source.index, 1);
    updatedColumns.splice(result.destination.index, 0, removed);

    setColumns(updatedColumns);
  };

  const handleSaveColumnName = (index: number, value: string) => {
    const updatedOptions = columns.map((col, i) => (index === i ? { name: value } : col));
    setColumns(updatedOptions);
  };
  const handleAddColumnBelow = () => {
    const newColumn = { name: 'New Column' };

    setColumns((prevColumns) => [...prevColumns, newColumn]);
  };

  const handleAddNewOption = () => {
    handleAddColumnBelow();
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = headerRef.current;
      const rect = header.getBoundingClientRect();
      setIsSticky(rect.top <= 0 && rect.bottom - 150 >= 0 && rows.length > 5);
    };

    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const displaySimpleTableHeaders = () => {
    return (
      <>
        <TableRow>
          <TableCell></TableCell>
          {columns.map((column, index) => (
            <TableCell align="center" key={index} sx={{ width: headerWidth + '%' }}>
              <Typography fontWeight="bold">{column.name}</Typography>
            </TableCell>
          ))}
          <ConditionalHideComponent hide={respond}>
            <TableCell></TableCell>
          </ConditionalHideComponent>
        </TableRow>
      </>
    );
  };
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap="20px">
        <Box>
          <EditField edit={edit} handleSave={(value) => setEdit(false)} field={field} />
          {headerCount > 4 && (
            <Typography fontSize="12px" mt="10px" fontStyle="italic">
              NOTE: Some columns may be hidden. Please scroll horizontally to view the other columns.
            </Typography>
          )}
        </Box>
        <ConditionalHideComponent hide={respond}>
          <Box display="flex" gap="2px">
            <EditFieldDropdown
              optionList={[
                {
                  label: 'Edit Field',
                  action: handleRenameClick,
                  hidden: edit,
                },
                {
                  label: 'Edit Columns',
                  action: handleEditColumnsClick,
                  hidden: !simple,
                },
                {
                  label: 'Reorder Rows',
                  action: handleEditRowsClick,
                },
                {
                  label: 'Edit Applicability',
                  action: editApplicability,
                },
              ]}
            />
            <AddFieldDropdown addField={addField} />
            <CustomIconButton
              icon={<DeleteOutlinedIcon />}
              tooltip="Remove field"
              onClick={deleteField}
              color="error"
            />
          </Box>
        </ConditionalHideComponent>
      </Box>
      <Box paddingX={1}>
        {!respond && (
          <RegularButton
            startIcon={open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            label={open ? 'Hide' : 'Show'}
            onClick={() => setOpen(!open)}
            size="small"
            variant="text"
            color="inherit"
          />
        )}
        <Collapse in={open || respond} timeout="auto">
          <TableContainer sx={{ borderRadius: 2, mt: respond ? 2 : undefined }}>
            <Table
              ref={headerRef}
              sx={{
                minWidth: 650,
                '& td, & th': {
                  borderBottom: `1px solid ${colors.hoverBackground}`,
                  border: respond ? `1px solid ${colors.hoverBackground}` : undefined,
                },
                '& thead tr:last-of-type th': {
                  borderBottom: `2px solid ${colors.lightGreen3}`,
                  backgroundColor: colors.secondary_background,
                  zIndex: 101,
                },
                '& tbody th:first-of-type, & tbody td:first-of-type, & thead th:first-of-type, & thead td:first-of-type':
                  {
                    position: 'sticky',
                    left: 0,
                    backgroundColor: colors.secondary_background,
                    zIndex: 100,
                  },
                '& thead': {
                  backgroundColor: colors.secondary_background,
                },

                '& tbody tr:nth-of-type(even)': {
                  backgroundColor: colors.solidHoverBackground,
                  '& th:first-of-type, & td:first-of-type': {
                    backgroundColor: colors.solidHoverBackground,
                  },
                },
              }}
              size="small"
            >
              <TableHead>
                {simple ? (
                  <>{displaySimpleTableHeaders()}</>
                ) : (
                  <>
                    <TableRow>
                      <TableCell></TableCell>
                      {columns.map((column, index) => (
                        <TableCell
                          align="center"
                          key={index}
                          colSpan={typeof column === 'object' && column.children ? column.children.length : 1}
                          sx={{ width: headerWidth + '%', minWidth: column.name === 'Remarks' ? '240px' : '100px' }}
                        >
                          <Typography fontWeight="bold">
                            {typeof column === 'object' ? column.name || `Column ${index + 1}` : column}
                          </Typography>
                        </TableCell>
                      ))}
                      <ConditionalHideComponent hide={respond}>
                        <TableCell></TableCell>
                      </ConditionalHideComponent>
                    </TableRow>
                    {columns.find((column) => typeof column === 'object' && column.children) ? (
                      <TableRow>
                        <TableCell></TableCell>
                        {columns.map((column, index) => (
                          <React.Fragment key={index}>
                            {typeof column === 'object' && column.children ? (
                              <>
                                {column.children?.map((child: any, index1: number) => (
                                  <TableCell key={`${index}-${index1}`} align="center">
                                    <Typography fontWeight="bold">{child.name}</Typography>
                                  </TableCell>
                                ))}
                              </>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </React.Fragment>
                        ))}
                        <ConditionalHideComponent hide={respond}>
                          <TableCell></TableCell>
                        </ConditionalHideComponent>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </TableHead>

              <TableBody>
                {rows.map((row, index) => (
                  <TableFieldRow
                    key={index}
                    index={index}
                    row={row}
                    tableColumns={columns}
                    columnCount={headerCount}
                    onAddRowBelow={handleAddRowBelow}
                    onRemoveRow={() => handleRemoveRow(row)}
                    respond={respond}
                    simple={simple}
                    showMissingItems={showMissingItems}
                    setMissingItems={setMissingItems}
                    handleChange={(response) => handleChangeReponse(row, response)}
                    setFieldsCount={setFieldsCount}
                    setFieldsWithReponseCount={setFieldsWithReponseCount}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isSticky && simple && (
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns={`${100 - headerWidth * columns.length}% repeat(${columns.length}, 1fr)`}
              // gridTemplateColumns={`60% repeat(${columns.length}, 1fr)`}
              className="sticky-header fixed"
              sx={{
                backgroundColor: colors.secondary_background,
                zIndex: 1000,
                borderBottom: `2px solid ${colors.lightGreen3}`,
                width: `calc(100% - ${120 + level * 40}px)`,
              }}
            >
              <Box></Box>
              {columns.map((column, index) => (
                <Box key={index} sx={{ padding: '10px', borderLeft: '0.5px solid ' + colors.border }}>
                  <Typography fontWeight="bold" textAlign="center">
                    {column.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Collapse>
        <ConfirmationDialog
          open={confirmationDialogOpenColumn}
          setOpen={setConfirmationDialogOpenColumn}
          message="Are you sure you want to delete this column?"
          onConfirm={handleConfirmDeleteColumn}
        />

        <CustomModal header="Reorder Rows" open={reorderRowsModalOpen} setOpen={setReorderRowsModalOpen} width={500}>
          <DraggableList
            list={reorderedRows}
            droppableId="reorder-rows"
            handleDragEnd={handleDragEndModal}
            displayItem={(row, index) => <Typography>{row.field_name || `Row ${index + 1}`}</Typography>}
            style={{ padding: '5px' }}
            fromModal
          />
        </CustomModal>

        <CustomModal header="Edit Columns" open={editColumnsModalOpen} setOpen={setEditColumnsModalOpen} width={500}>
          <Box paddingBottom="20px">
            <RegularButton label="Add Column" onClick={handleAddNewOption} />
          </Box>
          <DraggableList
            list={columns}
            droppableId="edit-columns"
            handleDragEnd={handleDragEndColumns}
            fromModal
            style={{ padding: '5px' }}
            displayItem={(column, index) => (
              <Box key={index}>
                {column.children ? (
                  <>
                    {column.children?.map((child: any, childIndex: number) => (
                      <Typography key={`${index}-${childIndex}`}>{child.name}</Typography>
                    ))}
                  </>
                ) : (
                  <TableFieldColumn
                    index={index}
                    column={column}
                    handleAddNewOption={handleAddNewOption}
                    handleDeleteOption={handleDeleteColumn}
                    handleSaveOption={handleSaveColumnName}
                  />
                )}
              </Box>
            )}
          />
        </CustomModal>
      </Box>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        setOpen={setConfirmationDialogOpen}
        message="Are you sure you want to delete this?"
        onConfirm={handleConfirmDeleteRow}
      />
    </Box>
  );
};

export default TableField;
