import { ApiQuery } from 'model/interface';
import { FacilityGroupModel } from 'model/Entities';
import axios from 'utils/custom_axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'facility-group';

export const getFacilityGroupOne = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getFacilitiesGroup = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createFacilityGroup = (facilityGroup: FacilityGroupModel) => {
  return axios.post(`${baseURL}/${path}`, facilityGroup);
};

export const updateFacilityGroup = (id: number, facilityGroup: FacilityGroupModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, facilityGroup);
};

export const getAvailableFacilityGroup = (batch_id: number) => {
  return axios.get(`${baseURL}/${path}/batch/${batch_id}`);
};

export const deleteFacilityGroup = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const deleteFacilityGroupWithAssignedFacility = (id: number) => {
  return axios.delete(`${baseURL}/${path}/delete-groups-with-assigned/${id}`);
};

export const getGroupWithFacility = (group_id: number, query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/get-groups-with-facilities/${group_id}`, { params: query });
};
