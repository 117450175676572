module.exports = {
  section_number: 1,
  section_name: 'National Safe Motherhood Program',
  section_body: [
    {
      type: 'table',
      field_name: 'National Safe Motherhood Program',
      field_code: '1',
      columns: [
        {
          name: 'Number of Adolescent Pregnant Mothers',
          type: 'parent',
          children: [
            { name: '10-14 yrs', type: 'numeric', code: '1' },
            { name: '15-19 yrs', type: 'numeric', code: '2' },
            { name: '20-49 yrs', type: 'numeric', code: '3' },
            { name: 'Total', type: 'total', code: '4' },
          ],
        },
      ],
      rows: [
        {
          type: 'field',
          field_name: 'Total Deliveries',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Pregnant tracked',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Women with Birth Plan',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Women with 4 ANC visits',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Facility Based Delivery',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Assisted by Skilled Health Professional',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Women with 2 PNC visits',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Live Births',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Maternal deaths (MMR)',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Early Newborn deaths (NMR)',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: 'Stillbirth (SBR)',
          field_code: '1',
        },
      ],
    },
  ],
};
