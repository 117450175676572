import * as yup from 'yup';

const user_schema_base = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  user_group_name: yup.string().required('User Group is required.'),
  municipality_code: yup.string().when(['user_group_name'], {
    is: (user_group_name: string) => {
      return ['Municipal', 'MHO/PHN'].includes(user_group_name);
    },
    then: yup.string().required().required('Municipality is required.'),
  }),
});

const create_user_schema_base = user_schema_base.shape({
  username: yup.string().matches(/^\S*$/, 'Username must not contain spaces').required('Username is required.'),
  password: yup
    .string()
    .required('Password is required.')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Contact Number is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  email: yup.string().email('Must be a valid email address'),
});

export const provincial_create_user_schema = create_user_schema_base;
export const provincial_update_user_schema = user_schema_base;
export const regional_create_user_schema = create_user_schema_base.shape({
  province_code: yup.string().when(['user_group_name'], {
    is: (user_group_name: string) => {
      // return !['RMTL', 'RMTM', 'CHD', 'DMO', 'National'].includes(user_group_name);
      return ['CPAL', 'CPAM', 'FA', 'Municipal', 'MHO/PHN', 'PHO'].includes(user_group_name);
    },
    then: yup.string().required().required('Province is required.'),
  }),
});
export const regional_update_user_schema = user_schema_base.shape({
  province_code: yup.string().when(['user_group_name'], {
    is: (user_group_name: string) => {
      return !['RMTL', 'RMTM', 'CHD', 'DMO', 'National'].includes(user_group_name);
    },
    then: yup.string().required().required('Province is required.'),
  }),
});

export const staff_user_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  user_group_name: yup.string().required('User Group is required.'),
  username: yup.string().required('Username is required.'),
  password: yup
    .string()
    .required('Password is required.')
    .test('len', 'Password must be at least 8 characters long.', (val) => !val || val.length >= 8),
});

export const create_assessment_batch_schema = yup.object().shape({
  batch_name: yup.string().required('Batch Name is required.'),
  year: yup.string().required('Year is required.'),
  indicator_id: yup.string().required('Health Indicators is required.'),
});

export const create_assessment_schema = yup.object().shape({
  create_all: yup.boolean(),
  facility_id: yup.string().when('create_all', {
    is: false,
    then: yup.string().required().required('Facility is required.'),
  }),
});

export const create_facility_schema = yup.object().shape({
  facility_name: yup.string().required('Facility Name is required.'),
  facility_code: yup.string().required('Facility Code is required.'),
  // gis_code: yup.string().required('GIS Code is required.'),
  facility_type_id: yup.string().required('Facility Type is required.'),
  managing_authority: yup.string().required('Managing Authority is required.'),
  province_code: yup.string().required('Province is required.'),
  municipality_code: yup.string().required('Municipality is required.'),
  barangay_name: yup.string().required('Barangay Name is required.'),
  barangay_code: yup.string().required('Barangay Code is required.'),
});

export const create_facility_group_schema = yup.object().shape({
  group_name: yup.string().required('Group Name is required.'),
  province_code: yup.string().required('Province is required.'),
});

export const provincial_create_facility_group_schema = create_facility_group_schema;

export const create_report_schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  frequency: yup.string().required('Frequency is required.'),
  indicator_id: yup.string().required('Health Indicators is required.'),
});

export const create_report_entry_schema = yup.object().shape({
  month: yup.string().required('Month is required.'),
  deadline: yup.string().required('Deadline is required.'),
});

export const create_user_group_validator_schema = yup.object().shape({
  group_name: yup.string().required('Group Name is required.'),
  level: yup.string().required('Access Level is required.'),
  description: yup.string().required('Description is required.'),
});
