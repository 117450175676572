import Dropdown, { DropdownOptionProps } from './Dropdown';

import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import { tokens } from 'context/theme.context';
import { useTheme } from '@mui/material';

type RowActionProps = {
  actions: DropdownOptionProps[];
  data?: any;
  disabled?: boolean;
};

const RowAction: React.FC<RowActionProps> = ({ actions, data, disabled }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Dropdown
      isIcon
      optionList={actions}
      data={data}
      icon={<ArrowDropDownCircleOutlinedIcon sx={{ color: disabled ? colors.border : colors.secondary }} />}
      tooltip="View Actions"
      tooltipPlacement="left-end"
      disabled={disabled}
    />
  );
};

export default RowAction;
