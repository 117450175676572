import {
  ActivityLogs,
  AssessmentFiles,
  AssessmentIndicators,
  AssessmentManagement,
  Dashboard,
  DashboardEditor,
  FacilityAssessmentDetailsScreen,
  FacilityAssessments,
  FacilityManagement,
  FacilityReportEntryScreen,
  FacilityReports,
  ForbiddenScreen,
  HelpDesk,
  Home,
  IndicatorBuilder,
  LoginForm,
  MonitorScreen,
  NotFoundScreen,
  Reports,
  UserManagement,
  ValidationWorkflow,
  ValidationWorkflowEditor,
} from 'screens';
import { AklanPCFTable } from 'screens/PCFTable/AklanPCFTable';
import { BreadcrumbContext, useBreadcrumb } from 'context/breadcrumb.context';
import { ColorModeContext, useMode } from './context/theme.context';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CustomScnackBarWrapper, ProtectedRoute } from 'components';
import { Route, Routes } from 'react-router-dom';
import { UserContext, useUser } from './context/user.context';
import Category from 'screens/HelpDesk/Category';
import HelpDeskArticle from 'screens/HelpDesk/HelpDeskArticle';

function App() {
  const [theme, colorMode] = useMode();
  const [user] = useUser();
  const [breadcrumb] = useBreadcrumb();

  return (
    <>
      <ColorModeContext.Provider value={colorMode as any}>
        <ThemeProvider theme={theme as any}>
          <UserContext.Provider value={user as any}>
            <BreadcrumbContext.Provider value={breadcrumb as any}>
              <CssBaseline />
              <div className="app">
                <CustomScnackBarWrapper>
                  <Routes>
                    <Route path="/login" element={<LoginForm />} />

                    <Route path="/" element={<ProtectedRoute />}>
                      <Route path="/helpdesk">
                        <Route index element={<HelpDesk />} />
                        <Route path=":category/:article" element={<HelpDeskArticle />} />
                        <Route path=":categoryCode" element={<Category />} />
                      </Route>
                      {/* Facility Staff Routes */}
                      <Route path="facility-reports">
                        <Route
                          index
                          element={<ProtectedRoute requiredAuth={['MANAGE_FACILITY']} element={<FacilityReports />} />}
                        />
                        <Route
                          path="edit/:id"
                          element={
                            <ProtectedRoute requiredAuth={['MANAGE_FACILITY']} element={<IndicatorBuilder respond />} />
                          }
                        />
                        <Route
                          path="view/:id"
                          element={
                            <ProtectedRoute
                              requiredAuth={[
                                'MANAGE_FACILITY',
                                'MANAGE_REPORTS',
                                'VIEW_FRESAA_BATCHES',
                                'REVIEW_FRESAA_QUESTIONNAIRE',
                              ]}
                              element={<FacilityAssessmentDetailsScreen fromReportingTool />}
                            />
                          }
                          // element={<ProtectedRoute requiredAuth={['MANAGE_FACILITY', 'MANAGE_REPORTS', 'REVIEW_FRESAA_QUESTIONNAIRE']} element={<IndicatorBuilder />} />}
                        />
                      </Route>

                      <Route path="facility-assessments">
                        <Route index element={<FacilityAssessments />} />
                        <Route
                          path=":id"
                          element={
                            <ProtectedRoute
                              requiredAuth={['MANAGE_FACILITY']}
                              element={<FacilityAssessmentDetailsScreen />}
                            />
                          }
                        />
                      </Route>
                      {/* End: Facility Staff Routes */}

                      <Route
                        index
                        element={
                          <ProtectedRoute
                            requiredAuth={[
                              'VIEW_REGIONAL_DASHBOARD',
                              'VIEW_PROVINCIAL_DASHBOARD',
                              'VIEW_NATIONAL_DASHBOARD',
                              'VIEW_MUNICIPAL_DASHBOARD',
                            ]}
                            element={<Home />}
                          />
                        }
                      />
                      <Route
                        path="test"
                        element={
                          <ProtectedRoute
                            requiredAuth={[
                              'VIEW_REGIONAL_DASHBOARD',
                              'VIEW_PROVINCIAL_DASHBOARD',
                              'VIEW_NATIONAL_DASHBOARD',
                              'VIEW_MUNICIPAL_DASHBOARD',
                            ]}
                            element={<Dashboard />}
                          />
                        }
                      />
                      <Route
                        path="user-management"
                        element={
                          <ProtectedRoute
                            requiredAuth={[
                              'MANAGE_ALL_ACCOUNTS',
                              'MANAGE_NATIONAL_REGIONAL_ACCOUNT',
                              'MANAGE_CITY_PROVINCIAL_ACCOUNT',
                            ]}
                            element={<UserManagement />}
                          />
                        }
                      />
                      <Route path="monitor">
                        <Route
                          index
                          element={
                            <ProtectedRoute
                              requiredAuth={[
                                'VIEW_FRESAA_BATCHES',
                                'REVIEW_FRESAA_QUESTIONNAIRE',
                                'ANSWER_FRESAA_QUESTIONNAIRE',
                              ]}
                              element={<MonitorScreen />}
                            />
                          }
                        />
                        <Route path=":id" element={<FacilityAssessmentDetailsScreen />} />
                      </Route>

                      <Route
                        path="facilities"
                        element={
                          <ProtectedRoute
                            requiredAuth={['MANAGE_FACILITIES', 'MANAGE_FACILITY_GROUP']}
                            element={<FacilityManagement />}
                          />
                        }
                      />
                      <Route
                        path="assessments"
                        element={
                          <ProtectedRoute
                            requiredAuth={['CREATE_DELETE_REGIONAL_FRESAA_QUESTIONNAIRE', 'VIEW_FRESAA_BATCHES']}
                            element={<AssessmentManagement />}
                          />
                        }
                      />

                      <Route path="/reports">
                        <Route
                          index
                          element={
                            <ProtectedRoute
                              requiredAuth={['MANAGE_REPORTS', 'VIEW_FRESAA_BATCHES']}
                              element={<Reports />}
                            />
                          }
                        />
                        <Route
                          path=":id"
                          element={
                            <ProtectedRoute
                              requiredAuth={['MANAGE_REPORTS', 'VIEW_FRESAA_BATCHES']}
                              element={<FacilityReportEntryScreen />}
                            />
                          }
                        />
                      </Route>

                      <Route path="/indicators">
                        <Route
                          index
                          element={
                            <ProtectedRoute
                              requiredAuth={['MANAGE_FRESAA_BATCHES']}
                              element={<AssessmentIndicators />}
                            />
                          }
                        />
                        <Route
                          path="editor/:id"
                          element={
                            <ProtectedRoute requiredAuth={['MANAGE_FRESAA_BATCHES']} element={<IndicatorBuilder />} />
                          }
                        />
                      </Route>

                      <Route path="validation-workflow">
                        <Route
                          index
                          element={
                            <ProtectedRoute requiredAuth={['MANAGE_FRESAA_BATCHES']} element={<ValidationWorkflow />} />
                          }
                        />
                        <Route
                          path=":id"
                          element={
                            <ProtectedRoute
                              requiredAuth={['MANAGE_FRESAA_BATCHES']}
                              element={<ValidationWorkflowEditor />}
                            />
                          }
                        />
                      </Route>

                      <Route
                        path="logs"
                        element={
                          <ProtectedRoute requiredRole={['RMTL', 'RMTM', 'CPAL', 'CPAM']} element={<ActivityLogs />} />
                        }
                      />

                      <Route path="assessment-files">
                        <Route index element={<AssessmentFiles />} />
                        <Route path=":id" element={<FacilityAssessmentDetailsScreen />} />
                      </Route>

                      {/* TO BE DELETED */}
                      <Route
                        path="/dashboard-editor/:indicator_id"
                        element={<ProtectedRoute requiredAuth={['MANAGE_DASHBOARD']} element={<DashboardEditor />} />}
                      />

                      <Route path="/test-table" element={<AklanPCFTable />} />

                      <Route path="not-found" element={<NotFoundScreen />} />
                      <Route path="forbidden" element={<ForbiddenScreen />} />
                      <Route path="/" element={<Dashboard />} />
                      <Route path="*" element={<NotFoundScreen />} />
                    </Route>
                  </Routes>
                </CustomScnackBarWrapper>
              </div>
            </BreadcrumbContext.Provider>
          </UserContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
