import FacilityReportEntryScreen from 'screens/Reports/FacilityReportEntry/FacilityReportEntryScreen';
import { GridColDef } from '@mui/x-data-grid';
import LoadingScreen from 'screens/Helper/loading';
import ReportEntriesWithFacilityReportEntries from 'screens/Reports/ReportEntriesWithFacilityReportEntries';
import { UserContext } from 'context/user.context';
import { useContext } from 'react';

const FacilityReports = () => {
  const { user } = useContext(UserContext);
  const customReportColumns: GridColDef[] = [
    {
      field: 'name',
      sortable: false,
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'frequency',
      sortable: false,
      headerName: 'Frequency',
      flex: 1,
    },
  ];
  return !user ? (
    <LoadingScreen />
  ) : (
    <>
      <FacilityReportEntryScreen facilityID={user.facility_id} />
      <ReportEntriesWithFacilityReportEntries
        facilityID={user.facility_id}
        customColumns={customReportColumns}
      ></ReportEntriesWithFacilityReportEntries>
    </>
  );
};

export default FacilityReports;
