import ArticleContent, { imagePath } from '../ArticleContent';

const UpdateIndicatorArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. On the actions, click "Edit Indicator".', image: `${imagePath}/update-indicator-1.png` },
            {
              step: '2. The page will redirect on a form editing page where the fields of the indicator can be updated.',
              image: `${imagePath}/update-indicator-2.png`,
            },
            {
              step: '3. Actions such as "Edit field" (a), "Add field" (b), and "Remove field" (c) can be used to the update fields of the selected indicator',
              image: `${imagePath}/update-indicator-3.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default UpdateIndicatorArticle;
