import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import Layout from 'screens/Layout/layout';
import { LoadingScreen } from 'screens';
import { UserContext } from 'context/user.context';
import { getCurrentUser } from 'api/user';

type ProtectedRouteProps = {
  requiredAuth?: string[];
  requiredRole?: string[];
  element?: JSX.Element;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ requiredAuth, requiredRole, element }) => {
  const auth = localStorage.getItem('access_token');
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth && !user) {
      getCurrentUser().then((res) => {
        if ([3, 4, 5, 12].includes(res.data.user_group_id)) {
          res.data.provincial_account = true;
        } else if ([6, 11].includes(res.data.user_group_id)) {
          res.data.municipal_account = true;
        } else if ([1, 2, 7, 13].includes(res.data.user_group_id)) {
          res.data.regional_account = true;
        } else if ([14].includes(res.data.user_group_id)) {
          res.data.facility_account = true;
        } else if ([9, 8].includes(res.data.user_group_id)) {
          res.data.national_account = true;
        }
        setUser(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, user]);

  useEffect(() => {
    checkUserAuthority();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, auth, requiredAuth, requiredRole]);

  const checkUserAuthority = () => {
    if (!requiredAuth && !requiredRole) {
      setLoading(false);
      setVerified(!!auth);
    } else {
      if (user) {
        if (requiredRole) {
          const verified_roles = requiredRole.filter((r) => user.user_group.group_name === r);
          setLoading(false);
          setVerified(!!verified_roles.length);
        } else if (requiredAuth) {
          const verified_auths = requiredAuth.filter((r) => user.authorities?.includes(r));
          setLoading(false);
          setVerified(!!verified_auths.length);
        }
      }
    }
  };

  const displayElement =
    requiredAuth || requiredRole ? (
      <>{element}</>
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    );

  if (!loading && !verified) {
    return <Navigate to={requiredAuth || requiredRole ? '/forbidden' : '/login'} state={{ redirectTo: location }} />;
  }

  return !loading ? displayElement : <LoadingScreen />;
};

export default ProtectedRoute;
