/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { ConditionalHideComponent, CustomIconButton, CustomTextField } from 'components';
import { useEffect, useState } from 'react';

import AddFieldDropdown from '../components/AddFieldDropdown';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditField from '../components/EditField';
import EditFieldDropdown from '../components/EditFieldDropdown';
import { FIELD_TYPES } from './FieldGroups';
import { FieldType } from '../IndicatorBuilder';

type TextFieldProps = {
  field: FieldType;
  deleteField: () => void;
  addField: (field_type: FIELD_TYPES) => void;
  editApplicability: () => void;
  respond?: boolean;
  missing?: boolean;
  handleChange: (field: any, property: keyof FieldType, data: any) => void;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
  index: number;
  childSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
};

const TextField: React.FC<TextFieldProps> = ({
  field,
  deleteField,
  addField,
  editApplicability,
  respond,
  missing,
  handleChange,
  setMissingItems,
  index,
  childSetTotalFieldsCount,
  childSetTotalFieldsWithReponseCount,
}) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState('');

  const handleEditSaveClick = (value: string) => {
    setEdit(!edit);
  };

  useEffect(() => {
    if (respond) {
      setValue(field.response ?? '');
    }
  }, [respond, field]);

  useEffect(() => {}, [value]);

  useEffect(() => {
    field.response = value;
    handleChange(field, 'response', value);
  }, [value]);

  useEffect(() => {
    if (!value && value !== '0' && missing) {
      setMissingItems((prev) => [...prev, field]);
    }
    if (childSetTotalFieldsCount && childSetTotalFieldsWithReponseCount) {
      childSetTotalFieldsCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = 1;
        return newValue;
      });
      childSetTotalFieldsWithReponseCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = !value ? 0 : 1;
        return newValue;
      });
    }
  }, [value, missing]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
      <ConditionalHideComponent hide={!respond}>
        <Box width="100%">
          <Box width="100%" display="grid" gridTemplateColumns="50px 1fr" mb="7px">
            <Typography fontWeight={'bold'} color={!value && missing ? 'red' : undefined}>
              {field.field_code}.
            </Typography>
            <Typography fontWeight={'bold'} color={!value && missing ? 'red' : undefined}>
              {field.field_name}
            </Typography>
          </Box>
          {/* <Typography fontWeight={'bold'}>{field.field_name}</Typography> */}
          {field.field_description && (
            <Typography variant="body2" p={1} style={{ whiteSpace: 'pre-wrap' }}>
              {field.field_description}
            </Typography>
          )}

          <CustomTextField
            label="Enter here"
            fieldName={field.field_code!}
            value={value}
            handleChange={(e) => setValue(e.target.value)}
            missing={missing}
            multiline
          />
        </Box>
      </ConditionalHideComponent>

      <ConditionalHideComponent hide={respond}>
        <>
          <EditField
            edit={edit}
            handleSave={(value) => handleEditSaveClick(value)}
            viewModeContent={
              <CustomTextField missing={missing} label="Enter here" fieldName="" value="" readOnly multiline />
            }
            field={field}
          />
          <Box display="flex" gap="2px">
            <EditFieldDropdown
              optionList={[
                {
                  label: 'Edit Field',
                  action: () => setEdit(true),
                  hidden: edit,
                },
                {
                  label: 'Edit Applicability',
                  action: editApplicability,
                },
              ]}
            />

            <AddFieldDropdown addField={addField} />
            <CustomIconButton
              icon={<DeleteOutlinedIcon />}
              tooltip="Remove field"
              onClick={deleteField}
              color="error"
            />
          </Box>
        </>
      </ConditionalHideComponent>
    </Box>
  );
};

export default TextField;
