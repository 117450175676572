export const user_group_all = [
  {
    value: 'RMTL',
    key: 'Regional Monitoring Team Leader',
  },
  {
    value: 'RMTM',
    key: 'Regional Monitoring Team Member',
  },
  {
    value: 'CPAL',
    key: 'City/Provincial Assessment Team Leader',
  },
  {
    value: 'CPAM',
    key: 'City/Provincial Assessment Team Member',
  },
  {
    value: 'PHO',
    key: 'Provincial Health Officer (PHO)',
  },
  {
    value: 'MHO/PHN',
    key: 'MHO/PHN',
  },
  {
    value: 'DMO',
    key: 'Development Management Officer (DMO)',
  },
  {
    value: 'FA',
    key: 'Field Assessors',
  },
  {
    value: 'Municipal',
    key: 'Municipal Account (Dashboard View only)',
  },
  {
    value: 'Provincial',
    key: 'Provincial Account (Dashboard View only)',
  },
  {
    value: 'CHD',
    key: 'Regional Acount (Dashboard View only)',
  },
  {
    value: 'National',
    key: 'National Acount (Dashboard View only)',
  },
];

export const user_group_provincial = [
  {
    value: 'CPAM',
    key: 'City/Provincial Assessment Team Member',
  },
  {
    value: 'PHO',
    key: 'PHO',
  },
  {
    value: 'MHO/PHN',
    key: 'MHO/PHN',
  },
  {
    value: 'FA',
    key: 'Field Assessors',
  },
  {
    value: 'Municipal',
    key: 'Municipal Account (Dashboard View only)',
  },
  {
    value: 'Provincial',
    key: 'Provincial Account (Dashboard View only)',
  },
];

export const managing_authority = [
  {
    value: 'Department of Health',
    key: 'Department of Health',
  },
  {
    value: 'Provincial Government',
    key: 'Provincial Government',
  },
  {
    value: 'Municipal Government',
    key: 'Municipal Government',
  },
  {
    value: 'Local Government Unit',
    key: 'Local Government Unit',
  },
  {
    value: 'Others',
    key: 'Others',
  },
];
