/* eslint-disable react-hooks/exhaustive-deps */
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { tokens } from 'context/theme.context';
import { useTheme } from '@mui/material';

type CustomDatePickerProps = {
  label: string;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: any;
  fieldName: string;
  disabled?: boolean;
  touched?: boolean;
  error?: any;
  span?: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  disablePast?: boolean;
  disableFuture?: boolean;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  handleBlur,
  handleChange,
  value,
  fieldName,
  disabled,
  touched,
  error,
  span,
  setFieldValue,
  disablePast,
  disableFuture,
  required,
  size = 'small',
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [date, setDate] = useState<Dayjs | null>(value ? dayjs(value) : null);

  useEffect(() => {
    if (value === '') {
      setDate(null);
    } else {
      setDate(dayjs(value));
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={date ? label + ' (' + dayjs(date).format('dddd') + ')' : label}
        value={date}
        onChange={(value) => setFieldValue(fieldName, value, true)}
        disabled={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        slotProps={{
          textField: {
            variant: 'filled',
            disabled: disabled,
            error: !!touched && !!error,
            helperText: touched ? error : '',
            required: required,
            size: size,
          },
        }}
        sx={{
          '.MuiFormLabel-root.Mui-focused': { color: colors.light },
          '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
          '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
          '.MuiFilledInput-root:before': { border: 'none' },
          '.Mui-disabled:before': { border: 'none !important' },
          '.MuiFilledInput-root:after': {
            borderBottom: `1px solid ${colors.primary}`,
            borderRadius: 2,
            height: '100%',
          },
          '.MuiFilledInput-input': { height: '22px' },
          '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
          gridColumn: span ? 'span ' + span : undefined,
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
