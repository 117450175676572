import ArticleContent, { imagePath } from '../ArticleContent';

const ViewFacilitiesInGroupArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click the "View Facilities" Button.', image: `${imagePath}/facility-group-3.png` },
            {
              step: '2. After Clicking "View Facilities" button, you will be redirected to the pop up below, where you can add and remove facilities',
              image: `${imagePath}/facility-group-4.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default ViewFacilitiesInGroupArticle;
