import { Button, CircularProgress, useTheme } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { tokens } from 'context/theme.context';

type PrimaryButtonProps = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  startIcon?: any;
  loading?: boolean;
};

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ label, onClick, disabled, loading }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Button
      onClick={() => onClick()}
      variant="contained"
      fullWidth={false}
      sx={{
        backgroundColor: colors.primary,
        textTransform: 'none',
      }}
      disabled={disabled || loading}
      startIcon={<AddIcon />}
    >
      {!loading ? label : <CircularProgress color="inherit" size="1.2rem" />}
    </Button>
  );
};

export default PrimaryButton;
