import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const MAX_SNACK = 3;
const AUTO_HIDE_DURATION = 5000;

type CustomScnackBarProps = {
  children: React.ReactNode;
};

const CustomScnackBarWrapper: React.FC<CustomScnackBarProps> = ({ children }) => {
  function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)} sx={{ color: '#e0e0e0' }}>
        <CloseIcon />
      </IconButton>
    );
  }

  return (
    <SnackbarProvider
      maxSnack={MAX_SNACK}
      autoHideDuration={AUTO_HIDE_DURATION}
      action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      style={{
        height: '80px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#e0e0e0',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomScnackBarWrapper;
