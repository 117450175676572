import { Box, Typography, useTheme } from '@mui/material';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { ReactNode } from 'react';
import { tokens } from 'context/theme.context';

interface DashboardPieChartProps {
  data: any[];
  title: string;
  description?: ReactNode;
  chartColors: any;
  colSpan?: number;
  rowSpan?: number;
  hideLegend?: boolean;
}

const DashboardPieChart: React.FC<DashboardPieChartProps> = ({
  title,
  data,
  description,
  chartColors,
  colSpan = 6,
  rowSpan = 2,
  hideLegend,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent < 0.1) return '';

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            borderRadius: '10px',
            padding: '15px',
            backgroundColor: colors.solidHoverBackground,
            border: 'none',
          }}
        >
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
        </Box>
      );
    }

    return null;
  };

  return (
    <Box
      gridColumn={'span ' + colSpan}
      gridRow={'span ' + rowSpan}
      p="30px"
      sx={{ backgroundColor: colors.secondary_background }}
      borderRadius={2}
    >
      <Typography variant="h5" fontWeight="600">
        {title}
      </Typography>
      {description}
      <Box height="100%" paddingY="20px">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Tooltip content={<CustomTooltip />} />
            {!hideLegend && <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: '20px' }} align="center" />}
            <Pie
              data={data}
              dataKey="value"
              nameKey="key"
              cx="50%"
              cy="50%"
              outerRadius={70}
              fill="#8884d8"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {data.map((item, index) => (
                <Cell key={`cell-${index}`} fill={chartColors[item.key]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default DashboardPieChart;
