import ArticleContent, { imagePath } from '../ArticleContent';

const AddFacilitiesToGroupArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            {
              step: '1. Inside the "View Facilities", click the "Add Faculty" button.',
              image: `${imagePath}/facility-group-3.png`,
            },
            { step: '2. Click the "Add Facility" button.' },
            { step: '', image: `${imagePath}/facility-group-4.png` },
            { step: '3. Select facility/facilities you want to add.' },
            { step: '4. Click "Add selected facility" button.' },
            {
              step: 'NOTE: You can also add facilities one by one by clicking "Add" button.',
              image: `${imagePath}/facility-group-5.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default AddFacilitiesToGroupArticle;
