import { ApiQuery } from 'model/interface';
import { AssessmentBatchFormModel } from 'model/Entities';
import axios from 'utils/custom_axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'assessment-batch-form';

export const getAssessmentBatchForm = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAssessmentBatchForms = (batch_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/all/${batch_id}`, { params: query });
};

export const getAvailableAssessmentBatchForms = (batch_id: number) => {
  return axios.get(`${baseURL}/${path}/available/${batch_id}`);
};

export const createAssessmentBatchForm = (batchForm: AssessmentBatchFormModel) => {
  return axios.post(`${baseURL}/${path}`, batchForm);
};

export const deleteAssessmentBatchForm = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
