import ArticleContent, { imagePath } from '../ArticleContent';

const DuplicateIndicatorArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. On the actions, click "Duplicate"', image: `${imagePath}/duplicate-indicator-1.png` },
            { step: '2. Click "yes" to duplicate the indicator', image: `${imagePath}/duplicate-indicator-2.png` },
            {
              step: '3. The duplicate of the indicator will be created with "- Copy" at the end of the name.',
              image: `${imagePath}/duplicate-indicator-3.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default DuplicateIndicatorArticle;
