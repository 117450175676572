/* eslint-disable react-hooks/exhaustive-deps */

import * as XLSX from 'xlsx';

import { AssessmentBatchModel, AssessmentCommentModel, FacilityAssessmentModel } from 'model/Entities';
import { Box, Chip, Grid, InputBase, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import {
  ConditionalHideComponent,
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomModal,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'components';
import { approveAssessment, getFacilityAssessment, returnToAssessor } from 'api/assessment';
import { approveFacilityReportEntry, getFacilityReportEntry } from 'api/facility-report-entry';
import {
  createAssessmentComment,
  deleteAssessmentComment,
  getAssessmentComment,
  updateAssessmentComment,
  updateAssessmentCommentStatus,
} from 'api/assessment-comment';
import {
  createFacilityReportEntryComment,
  getFacilityReportEntryComments,
  removeFacilityReportEntryComment,
  setFacilityReportEntryCommentStatus,
  updateFacilityReportEntryComment,
} from 'api/facility-report-entry-comment';
import { displayName, formatDateTime, getStatusChip } from 'utils';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FieldInput } from 'model/interface';
import IndicatorViewer from 'components/IndicatorViewer';
import LoadingScreen from 'screens/Helper/loading';
import NotFoundScreen from 'screens/Helper/NotFoundScreen';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { RemoveRedEye } from '@mui/icons-material';
import { UserContext } from 'context/user.context';
import { exportAsPDF } from 'utils/pdf_generator';
import { getAssessmentBatch } from 'api/assessment-batch';
import { getByBatchId } from 'api/assessment-batch-facilities';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { styled } from '@mui/system';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

// const aklan_indicators = require('../../../data/Aklan/main');
const afhf_indicators = require('../../../data/ReportingTool/main');

type InputProps = {
  note: string;
  return_to_fa: boolean;
};

interface NoteFieldInput extends FieldInput {
  field_name: keyof InputProps;
}

const _initialValues: InputProps = {
  note: '',
  return_to_fa: false,
};

type FacilityAssessmentDetailsScreenProps = {
  fromReportingTool?: boolean;
};

const FacilityAssessmentDetailsScreen: React.FC<FacilityAssessmentDetailsScreenProps> = ({ fromReportingTool }) => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [commentToDeleteId, setCommentToDeleteId] = useState<number | null>(null);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [assessment, setAssessment] = useState<FacilityAssessmentModel>();
  const [batch, setBatch] = useState<AssessmentBatchModel>();
  const [loading, setLoading] = useState(true);
  const [loadingComment, setLoadingComment] = useState(false);
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [status, setStatus] = useState('');
  const [exportRes, setExportRes] = useState(['']);
  const [sections, setSections] = useState<string>('');
  const [initialValues, setInitialValues] = useState<InputProps>(_initialValues);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openComments, setOpenComments] = useState(false);
  const [comments, setComments] = useState<AssessmentCommentModel[]>([]);
  const [commentValue, setCommentValue] = useState<any>({});
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [editedComment, setEditedComment] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formFields: NoteFieldInput[] = [
    {
      field_name: 'note',
      display_name: 'Notes',
      type: 'text',
      span: 4,
      rows: 7,
      multiline: true,
    },
    {
      field_name: 'return_to_fa',
      display_name: 'Return to Field Assessor',
      type: 'checkbox',
      span: 4,
      rows: 7,
      multiline: true,
    },
  ];

  const getResponses = async () => {
    try {
      let res: any;
      if (fromReportingTool) {
        res = await getFacilityReportEntry(parseInt(id!));
      } else {
        res = await getFacilityAssessment(parseInt(id!));
      }
      const assessment = res.data;
      setAssessment(assessment);
      setStatus(assessment.status);
      setSections(assessment.responses);
      setSections(JSON.stringify(afhf_indicators));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    assessment?.batch_id && getAssessmentBatch(assessment.batch_id).then((batch) => {
      setBatch(batch.data);
    });
  }, [assessment]);

  useEffect(() => {
    if (!id) return;

    const getResponses = async () => {
      try {
        let res: any;
        if (fromReportingTool) {
          res = await getFacilityReportEntry(parseInt(id!));
        } else {
          res = await getFacilityAssessment(parseInt(id!));
        }
        const assessment = res.data;
        setAssessment(assessment);
        setStatus(assessment.status);
        setSections(assessment.responses);
        // setSections(JSON.stringify(afhf_indicators));
      } finally {
        setLoading(false);
      }
    };
    getResponses();
    getComments();
  }, [id]);

  useEffect(() => {
    if (assessment) {
      const isAssessmentPath = location.pathname.includes('/assessment-files');
      let label = 'Monitor';
      let link = '/monitor';

      if (isAssessmentPath) {
        label = 'Assessments and Reports';
        link = '/assessment-files';
      } else if (fromReportingTool) {
        const reportData: any = assessment;
        label = `${reportData.report_entry.report.name} - ${reportData.report_entry.month}`;
        link = user?.user_group.group_name === 'FHS' ? '' : `/reports/${reportData.report_entry_id}`;
      }

      setBreadcrumb([
        { label, link },
        {
          label: assessment.facility.facility_name,
        },
      ]);
    }
  }, [assessment, location, user]);

  const exportToExcel = (exportRes: any, fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(exportRes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, fileName + '.xlsx');
  };

  const handleExport = (isExcel: boolean) => {
    setDownloadLoading(true);
    if (isExcel) {
      exportToExcel(exportRes, assessment?.facility.facility_name!);
    } else {
      batch && exportAsPDF(exportRes, batch.batch_name,  assessment?.facility.facility_name!);
    }
    setDownloadLoading(false);
  };

  const handleApprove = async (id: number) => {
    try {
      if (fromReportingTool) await approveFacilityReportEntry(id);
      else await approveAssessment(id);

      enqueueSnackbar('Assessment successfully approved!', { variant: 'success' });
      getResponses();
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  const handleReturn = async (data: any) => {
    try {
      setButtonLoading(true);
      await returnToAssessor(parseInt(id!), data);
      if (data.return_to_fa) enqueueSnackbar('FRESAA successfully returned to assessor!', { variant: 'success' });
      else enqueueSnackbar('Remarks successfully updated!', { variant: 'success' });
      setOpenReturnModal(false);
      if (assessment) {
        if (user?.user_group.group_name === 'PHO') {
          assessment.pho_notes = data.note;
        } else if (user?.user_group.group_name === 'DMO') {
          assessment.dmo_notes = data.note;
        } else {
          assessment.mho_notes = data.note;
        }
      }
      getResponses();
    } catch (error) {
      enqueueSnackbar('Cannot return!', { variant: 'error' });
      setOpenReturnModal(false);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSubmitComment = async (comment: AssessmentCommentModel) => {
    if (assessment) {
      try {
        const data = {
          comment: commentValue[comment.field_code],
          field_code: comment.field_code,
          field_name: comment.field_name,
        };
        setLoadingComment(true);
        if (fromReportingTool)
          await createFacilityReportEntryComment({ ...data, facility_report_entry_id: assessment.id! });
        else await createAssessmentComment({ ...data, assessment_id: assessment.id });

        setCommentValue((prev: any) => {
          return { ...prev, [comment.field_code]: '' };
        });
        getComments();
      } finally {
        setLoadingComment(false);
      }
    }
  };

  const getComments = async () => {
    let res: any;
    if (fromReportingTool) res = await getFacilityReportEntryComments(parseInt(id!));
    else res = await getAssessmentComment(parseInt(id!));

    const comments = res.data;
    setComments(comments.rows);
  };

  const handleResolve = async (commentId: number, status: string) => {
    fromReportingTool
      ? await setFacilityReportEntryCommentStatus(commentId, status)
      : await updateAssessmentCommentStatus(commentId, status);

    getComments();
  };

  const handleUpdateComment = async (comment_id: number) => {
    fromReportingTool
      ? await updateFacilityReportEntryComment(comment_id, editedComment)
      : await updateAssessmentComment(comment_id, editedComment);

    setIsEditing(null);
    setEditedComment('');
    getComments();
  };

  const isFirstOccurrence = (index: number, field_code: string) => {
    return comments && comments.findIndex((item) => item.field_code === field_code) === index;
  };

  const isLastOccurrence = (index: number, field_code: string) => {
    const restOfArray = comments && comments.slice(index + 1);

    const nextOccurrenceIndex = restOfArray!.findIndex((item) => item.field_code === field_code);

    return nextOccurrenceIndex === -1;
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (!assessment) {
    return <NotFoundScreen />;
  }
  const handleDeleteComment = (commentId: number) => {
    setCommentToDeleteId(commentId);
    setOpenDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    if (commentToDeleteId !== null) {
      fromReportingTool
        ? removeFacilityReportEntryComment(commentToDeleteId).then(() => {
            getComments();
          })
        : deleteAssessmentComment(commentToDeleteId)
            .then(() => {
              getComments();
            })
            .catch((error) => {
              console.error('Error deleting comment: ', error);
            });
    }

    setCommentToDeleteId(null);
    setOpenDeleteConfirmation(false);
  };
  return (
    <Box>
      {assessment && (
        <>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={5}>
              <Typography variant="h3" color={colors.text} fontWeight="bold" sx={{ m: '0 0 5px 0' }}>
                {assessment.facility.facility_name}
              </Typography>
              <Typography variant="h6" color={colors.text} sx={{ gridColumn: 'span 2' }}>
                {assessment.facility.facility_type.type}
                {assessment.facility.with_sbf && (
                  <Chip label="with SBF" size="small" variant="outlined" sx={{ marginLeft: '5px', fontSize: '10px' }} />
                )}
              </Typography>
              <Typography variant="subtitle1" color={colors.text}>
                {assessment.facility.municipality.municipality_name}
                {', '}
                {assessment.facility.province.province_name}
                {', '}
                {assessment.facility.barangay_name}
              </Typography>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="10px" marginTop="20px">
                <RegularButton
                  loading={downloadLoading}
                  label="Download Responses"
                  onClick={handleClick}
                  startIcon={<DownloadIcon />}
                  disabled={['open', 'assigned', 'started'].includes(status)}
                />
                <Menu
                  id="add-widget-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleExport(true)}>as Excel</MenuItem>
                  <MenuItem onClick={() => handleExport(false)}>as PDF</MenuItem>
                </Menu>
                <RegularButton
                  label="View All Comments"
                  onClick={() => {
                    setOpenComments(true);
                  }}
                  startIcon={<RemoveRedEye />}
                />
                <ConditionalHideComponent hide={!user.user_group.validator}>
                  <>
                    {!fromReportingTool && (
                      <RegularButton
                        label={'Return Assessment'}
                        startIcon={<AssignmentReturnOutlinedIcon />}
                        disabled={
                          ['open', 'assigned', 'started'].includes(status) ||
                          assessment.current_step !== user?.user_group.group_name
                        }
                        onClick={() => {
                          setInitialValues({
                            note:
                              user?.user_group.group_name === 'PHO'
                                ? assessment.pho_notes ?? ''
                                : user?.user_group.group_name === 'DMO'
                                ? assessment.dmo_notes ?? ''
                                : assessment.mho_notes ?? '',
                            return_to_fa: false,
                          });
                          setOpenReturnModal(true);
                        }}
                      />
                    )}
                    <RegularButton
                      label="Approve"
                      onClick={() => setOpenConfirmApproveModal(true)}
                      disabled={
                        ['open', 'assigned', 'started', 'completed'].includes(status) ||
                        assessment.current_step !== user?.user_group.group_name
                      }
                      startIcon={<DoneAllIcon />}
                    />
                  </>
                </ConditionalHideComponent>
                <ProtectedComponent requiredAuth={['MANAGE_FACILITY']}>
                  <ConditionalHideComponent
                    hide={['submitted', 'ongoing-approval', 'completed'].includes(status) || !fromReportingTool}
                  >
                    <RegularButton
                      label="Edit"
                      onClick={() => navigate(`/facility-reports/edit/${id}`)}
                      startIcon={<DriveFileRenameOutlineIcon />}
                    />
                  </ConditionalHideComponent>
                </ProtectedComponent>

                  {/* COMMENT BOX */}
                  <CustomModal header="All Comments" open={openComments} setOpen={setOpenComments} pt={1} width={750}>
                    <Box>
                      {comments.length ? (
                        comments.map((comment, index) => {
                          const showFieldCode = isFirstOccurrence(index, comment.field_code);
                          const showCommentInput = isLastOccurrence(index, comment.field_code);

                          return (
                            <Box display="flex" flexDirection="column" gap="10px" mt={2} key={index}>
                              {showFieldCode && (
                                <Box
                                  style={{ border: '1px solid ' + colors.border }}
                                  padding="10px 15px"
                                  borderRadius={2}
                                  mt={index > 0 ? 4 : 0}
                                >
                                  <Typography variant="h5" fontWeight="bold">
                                    {comment.field_code} - {comment.field_name}
                                  </Typography>
                                </Box>
                              )}

                              <Box display="flex" flexDirection="column" gap="15px" mx={1}>
                                <CommentBox>
                                  <Box display="flex" alignItems="flex-start" gap="10px" width="100%">
                                    <AccountCircleOutlinedIcon sx={{ fontSize: '30px' }} />
                                    <Box width="100%">
                                      <Box
                                        width="100%"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                      >
                                        <Box width="100%">
                                          <Box width="100%" display="flex" justifyContent="space-between">
                                            <Typography style={{ fontSize: '11px', color: '#888888' }}>
                                              {moment(comment.created_at).calendar()}{' '}
                                              {comment.status === 'resolved' && '- Resolved'}
                                            </Typography>
                                          </Box>
                                          <Typography fontWeight="bold">
                                            {comment.user.first_name + ' ' + comment.user.last_name}
                                          </Typography>
                                        </Box>
                                        <ProtectedComponent
                                          requiredAuth={[
                                            'REVIEW_FRESAA_QUESTIONNAIRE',
                                            'ANSWER_FRESAA_QUESTIONNAIRE',
                                            'MANAGE_FACILITY',
                                          ]}
                                        >
                                          <RowAction
                                            actions={[
                                              {
                                                label: comment.status === 'open' ? 'Resolve' : 'Reopen',
                                                action:
                                                  comment.status === 'open'
                                                    ? () => handleResolve(comment.id, 'resolved')
                                                    : () => handleResolve(comment.id, 'open'),
                                                startIcon:
                                                  comment.status === 'open' ? (
                                                    <CheckCircleOutlinedIcon />
                                                  ) : (
                                                    <RadioButtonUncheckedOutlinedIcon />
                                                  ),
                                                hidden: !user?.authorities?.includes('REVIEW_FRESAA_QUESTIONNAIRE'),
                                              },
                                              {
                                                label: 'Update',
                                                action: () => {
                                                  setIsEditing(comment.id);
                                                  setEditedComment(comment.comment);
                                                },
                                                startIcon: <DriveFileRenameOutlineIcon />,
                                                hidden: comment.user_id !== user.id,
                                              },
                                              {
                                                label: 'Delete',
                                                startIcon: <DeleteOutlinedIcon />,
                                                hidden: comment.user_id !== user.id,
                                                action: () => handleDeleteComment(comment.id),
                                              },
                                            ]}
                                          />
                                        </ProtectedComponent>
                                      </Box>
                                      {isEditing === comment.id ? (
                                        // Render the text input for editing comments
                                        <Box display="flex" gap="10px">
                                          <InputBase
                                            value={editedComment}
                                            onChange={(e) => setEditedComment(e.target.value)}
                                            placeholder="Update comment"
                                            fullWidth
                                            sx={{
                                              p: 2,
                                              backgroundColor: colors.background,
                                              color: theme.palette.text.primary,
                                              borderRadius: '5px',
                                            }}
                                          />
                                          <Box>
                                            <RegularButton
                                              label="Update"
                                              onClick={() => handleUpdateComment(comment.id)}
                                            />
                                          </Box>
                                        </Box>
                                      ) : (
                                        <Typography>{comment.comment}</Typography>
                                      )}
                                    </Box>
                                  </Box>
                                </CommentBox>
                              </Box>

                              {showCommentInput && (
                                <Box mx={1}>
                                  <ProtectedComponent
                                    requiredAuth={[
                                      'REVIEW_FRESAA_QUESTIONNAIRE',
                                      'ANSWER_FRESAA_QUESTIONNAIRE',
                                      'MANAGE_FACILITY',
                                    ]}
                                  >
                                    <Box display="flex" gap="10px">
                                      <InputBase
                                        multiline
                                        rows={2}
                                        value={commentValue[comment.field_code]}
                                        onChange={(e) => {
                                          setCommentValue((prev: any) => {
                                            return { ...prev, [comment.field_code]: e.target.value };
                                          });
                                        }}
                                        placeholder={'Add a comment'}
                                        fullWidth
                                        sx={{
                                          p: 2,
                                          backgroundColor: colors.background,
                                          color: theme.palette.text.primary,
                                          borderRadius: '5px',
                                        }}
                                      />
                                      <Box>
                                        <RegularButton
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                            handleSubmitComment(comment);
                                          }}
                                          disabled={!commentValue[comment.field_code]?.trim()}
                                          loading={loadingComment}
                                          label="Comment"
                                        />
                                      </Box>
                                    </Box>
                                  </ProtectedComponent>
                                </Box>
                              )}
                            </Box>
                          );
                        })
                      ) : (
                        <Box>No comments found</Box>
                      )}
                    </Box>
                  </CustomModal>

                  <CustomModal
                    header={'Return Assessment'}
                    open={openReturnModal}
                    setOpen={setOpenReturnModal}
                    width={700}
                  >
                    <CustomForm
                      initialValues={initialValues}
                      onSubmit={handleReturn}
                      fields={formFields}
                      loading={buttonLoading}
                    />
                  </CustomModal>
                  <ConfirmationDialog
                    message={`Approve this ${fromReportingTool ? 'report' : 'assessment'}?`}
                    open={openConfirmApproveModal}
                    setOpen={setOpenConfirmApproveModal}
                    onConfirm={() => handleApprove(assessment.id!)}
                  />
                </Box>
              </Grid>

              {/* Right section containing status summary */}
              <Grid item xs={7}>
                <CustomContainer>
                  <Box
                    display="grid"
                    gridTemplateColumns="120px 1fr 120px 1fr"
                    sx={{ rowGap: '3px', alignItems: 'center', columnGap: '20px' }}
                  >
                    <Typography variant="body1" color={colors.text}>
                      Status
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {getStatusChip(assessment?.status, theme, colors)}
                    </Box>

                    <Typography variant="body1" color={colors.text}>
                      {fromReportingTool ? '' : 'Date Started'}
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      {fromReportingTool ? '' : formatDateTime(assessment.started_at)}
                    </Typography>

                    <Typography
                      variant="h6"
                      color={colors.text}
                      fontWeight="bold"
                      sx={{ gridColumn: 'span 2' }}
                      mt="20px"
                    >
                      People
                    </Typography>
                    <Typography
                      variant="h6"
                      color={colors.text}
                      fontWeight="bold"
                      sx={{ gridColumn: 'span 2' }}
                      mt="20px"
                    >
                      Dates
                    </Typography>

                    <Typography variant="body1" color={colors.text}>
                      {fromReportingTool ? 'Staff' : 'Assessor'}
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      {displayName(assessment.assessor)}
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      Submitted
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      {formatDateTime(assessment.submitted_at)}
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      {'Approver'}
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      {displayName(assessment.approver)}
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      {fromReportingTool ? 'Last Update' : 'Approved'}
                    </Typography>
                    <Typography variant="body1" color={colors.text}>
                      {fromReportingTool
                        ? formatDateTime(assessment.updated_at)
                        : formatDateTime(assessment.approved_at)}
                    </Typography>
                  </Box>
                </CustomContainer>
              </Grid>
            </Grid>

            <IndicatorViewer
              assessment={assessment}
              init_sections={sections}
              setExportRes={setExportRes}
              comments={comments}
              getComments={getComments}
              fromReportingTool={fromReportingTool}
            />
          </>
        )}
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure you want to delete this comment?"
        onConfirm={handleDeleteConfirm}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />
    </Box>
  );
};

export default FacilityAssessmentDetailsScreen;

const CommentBox = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
}));
