import ArticleContent, { imagePath } from '../ArticleContent';

const ImportFacilityArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click the "Import Facilities" button', image: `${imagePath}/import-facility-1.png` },
            { step: '2. Click the "Upload File" button and choose your Excel File.' },
            {
              step: '3. The selected Excel File must contain the following template headers.',
              image: `${imagePath}/import-facility-3.png`,
            },
            { step: '4. After selecting the file, Click "import".', image: `${imagePath}/import-facility-2.png` },
          ],
        },
      ]}
    />
  );
};

export default ImportFacilityArticle;
