import React from 'react';
import styled from 'styled-components';

interface TableData {
  header1: string;
  header2: string;
  header3: string;
  header4: string;
  rows: { col1: string; col2: string; col3: string; col4: string }[];
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th<{ isFirstColumn?: boolean }>`
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
  width: ${(props) => (props.isFirstColumn ? '30%' : '5%')};
`;
const getColorBasedOnPercentage = (percentage: number): string => {
  if (percentage >= 80) {
    return 'green';
  } else if (percentage >= 50) {
    return 'yellow';
  } else if (percentage >= 25) {
    return 'orange';
  } else {
    return 'red';
  }
};

const Td = styled.td<{ isFirstColumn?: boolean; isCol4?: boolean; percentage?: number }>`
  padding: 15px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.isCol4 ? getColorBasedOnPercentage(props.percentage || 0) : 'inherit')};
`;

const DashboardTable3: React.FC<{ tableData: TableData }> = ({ tableData }) => {
  const { header1, header2, header3, header4, rows } = tableData;

  return (
    <Table>
      <thead>
        <tr>
          <Th isFirstColumn>{header1}</Th>
          <Th>{header2}</Th>
          <Th>{header3}</Th>
          <Th>{header4}</Th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <Td isFirstColumn>{row.col1}</Td>
            <Td>{row.col2}</Td>
            <Td>{row.col3}</Td>
            <Td isCol4 percentage={parseFloat(row.col4.replace('%', ''))}>
              {row.col4}
            </Td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DashboardTable3;
