type ConditionalHideComponentProps = {
  hide?: boolean;
  children: React.ReactNode;
};

const ConditionalHideComponent: React.FC<ConditionalHideComponentProps> = ({ hide, children }) => {
  return hide ? <></> : <>{children}</>;
};

export default ConditionalHideComponent;
