/* eslint-disable react-hooks/exhaustive-deps */

import * as xlsx from 'xlsx';

import { ApiQuery, FieldInput, Province } from 'model/interface';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  FacilityLocationFilter,
  Header,
  PrimaryButton,
  RegularButton,
  RowAction,
} from 'components';
import { FacilityModel, UserModel } from 'model/Entities';
import { UserFieldInput, UserInput } from 'screens/UserManagement';
import { changeNullToBlank, tranformFormErrors } from 'utils';
import {
  createFacility,
  deleteFacility,
  facilityImportCSV,
  getAllFacilities,
  getAllFacilityTypes,
  getAvailableMunicipality,
  getAvailableProvince,
  getFacilities,
  updateFacility,
} from 'api/facility';
import { create_facility_schema, staff_user_schema } from 'model/schema';
import { getUsersByFacility, registerUser } from 'api/user';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FacilityGroup from './FacilityGroup';
import FacilityImportModal from './FacilityImportModal';
import FileSaver from 'file-saver';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Link } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { UserContext } from 'context/user.context';
import { managing_authority } from 'data/select-options';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

type FacilityInput = {
  id: string;
  facility_name: string;
  facility_code: string;
  gis_code: string;
  region_code: string;
  province_code: string;
  municipality_code: string;
  barangay_name: string;
  barangay_code: string;
  facility_type_id: string;
  facility_head: string;
  facility_head_name: string;
  managing_authority: string;
  street_name: string;
  building_name: string;
  zip_code: string;
  with_sbf: boolean;
};

interface FacilityFieldInput extends FieldInput {
  field_name: keyof FacilityInput;
}

const initialValuesUser: UserInput = {
  username: '',
  password: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  title: '',
  user_group_name: '',
  email: '',
  mobile_number: '',
  region_code: '',
  province_code: '',
  municipality_code: '',
  facility_id: '',
};

const initialValues: FacilityInput = {
  id: '',
  facility_name: '',
  facility_code: '',
  gis_code: '',
  region_code: '',
  province_code: '',
  municipality_code: '',
  barangay_name: '',
  barangay_code: '',
  facility_type_id: '',
  facility_head: '',
  facility_head_name: '',
  managing_authority: '',
  street_name: '',
  building_name: '',
  zip_code: '',
  with_sbf: false,
};

interface FacilityTypeDropdown {
  value: any;
  key: string;
}

const FacilityManagement = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState<FacilityModel[]>([]);
  const [staffRows, setStaffRows] = useState<UserModel[]>([]);
  const [facilityCount, setFacilityCount] = useState<number>(0);
  const [facilityStaffCount, setFacilityStaffCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [openFacilityModal, setOpenFacilityModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openViewFacilityStaff, setOpenViewFacilityStaffModal] = useState(false);

  const [facilitySchema] = useState<any>(create_facility_schema);
  const [userSchema] = useState<any>(staff_user_schema);

  const [userModalHeader, setUserModalHeader] = useState('');
  const [facilityModalHeader, setFacilityModalHeader] = useState('');

  const [facilityInitialValues, setFacilityInitialValues] = useState<FacilityInput>(initialValues);
  const [userInitialValues, setUserInitialValues] = useState<UserInput>(initialValuesUser);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [facilityTypes, setFacilityTypes] = useState<FacilityTypeDropdown[]>([]);
  const [municipalities, setMunicipalities] = useState<any>([]);
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [otherFilters, setOtherFilters] = useState<any>({});
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [file, setFile] = useState<any>();
  const [provinceCode, setProvinceCode] = useState<string>('');
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(0);

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  const handleFileInputChange = async (event: any) => {
    setFile(event.target.files[0]);
  };

  const uploadFacilities = async (event: any) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      // Send an HTTP POST request to the server with the file data
      const response = await facilityImportCSV(formData);
      const data = response.data;

      if (data.hasOwnProperty('error')) {
        setErrorMessage(data.error);
        setOpenErrorModal(true);
      } else {
        enqueueSnackbar(`Facility successfully created!`, {
          variant: 'success',
        });
      }

      setOpenImportModal(false);
      refreshTable();
    } catch (error) {
      enqueueSnackbar('Error creating facilities.', {
        variant: 'error',
      });
    }
  };

  const getFacilityTypes = async () => {
    try {
      getAllFacilityTypes().then((res) => {
        if (res.data.length) {
          res.data.forEach((data: any) => {
            const add: FacilityTypeDropdown = {
              value: data?.id,
              key: data?.type,
            };
            setFacilityTypes((prevArray) => [...(prevArray ?? []), add]);
          });
        } else {
          setFacilityTypes([]);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setFacilityTypes([]);
    getFacilityTypes();
  }, []);

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'facility_code',
      sortable: false,
      headerName: 'Facility Code',
      flex: 1,
    },
    {
      field: 'facility_name',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
    },
    {
      field: 'facility_type_id',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1.2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility_type.type}
          {params.row.with_sbf && (
            <Chip label="with SBF" size="small" variant="outlined" sx={{ marginLeft: '5px', fontSize: '10px' }} />
          )}
        </CustomGridCell>
      ),
    },
    {
      field: 'managing_authority',
      sortable: false,
      headerName: 'Managing Authority',
      flex: 1,
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1.3,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.barangay_name}
          {', '}
          {params.row.municipality.municipality_name}
          {', '}
          {params.row.province.province_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const staffColumns: GridColDef[] = [
    {
      field: 'username',
      sortable: false,
      headerName: 'Username',
      flex: 1.5,
      renderCell: (params) => <CustomGridCell>{params.row.username}</CustomGridCell>,
    },
    {
      field: 'first_name',
      sortable: false,
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.title} {params.row.first_name} {params.row.middle_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
  ];

  // Facility Form Fields
  const formFields: FacilityFieldInput[] = [
    {
      field_name: 'facility_name',
      display_name: 'Facility Name',
      type: 'text',
    },
    {
      field_name: 'facility_code',
      display_name: 'Facility Code',
      type: 'text',
    },
    {
      field_name: 'gis_code',
      display_name: 'GIS Code',
      type: 'text',
    },
    {
      field_name: 'facility_type_id',
      display_name: 'Facility Type',
      type: 'select',
      options: facilityTypes.map((u) => {
        return { key: u.key, value: u.value };
      }),
    },
    {
      field_name: 'managing_authority',
      display_name: 'Managing Authority',
      type: 'select',
      options: managing_authority.map((u) => {
        return { key: u.key, value: u.value };
      }),
    },
    {
      field_name: 'province_code',
      display_name: 'Province',
      type: 'select',
      options: provinces.map((p) => {
        return { key: p.province_name, value: p.province_code };
      }),
      onChange: (value, setFieldValue) => {
        const selected = provinces.find((p) => p.province_code === value);

        setProvinceCode(selected?.province_code + '');
        setFieldValue && setFieldValue('municipality_code', '');
      },
      disabled: isUpdate,
      hidden: user.provincial_account,
    },
    {
      field_name: 'municipality_code',
      display_name: 'Municipality',
      type: 'select',
      options: municipalities.map((m: any) => {
        return { key: m.municipality_name, value: m.municipality_code };
      }),
    },
    {
      field_name: 'barangay_name',
      display_name: 'Barangay Name',
      type: 'text',
    },
    {
      field_name: 'barangay_code',
      display_name: 'Barangay Code',
      type: 'text',
    },
    {
      field_name: 'street_name',
      display_name: 'Street',
      type: 'text',
    },
    {
      field_name: 'building_name',
      display_name: 'Building',
      type: 'text',
    },
    {
      field_name: 'zip_code',
      display_name: 'ZIP Code',
      type: 'text',
    },
    {
      field_name: 'with_sbf',
      display_name: 'With SBF',
      type: 'checkbox',
    },
  ];

  // User Form Fields
  const userFields: UserFieldInput[] = [
    {
      field_name: 'username',
      display_name: 'Username',
      type: 'text',
    },
    {
      field_name: 'password',
      display_name: 'Password',
      type: 'text',
    },
    {
      field_name: 'title',
      display_name: 'Title (e.g. Dr.)',
      type: 'text',
    },
    {
      field_name: 'first_name',
      display_name: 'First Name',
      type: 'text',
    },
    {
      field_name: 'middle_name',
      display_name: 'Middle Name',
      type: 'text',
    },
    {
      field_name: 'last_name',
      display_name: 'Last Name',
      type: 'text',
    },
    {
      field_name: 'mobile_number',
      display_name: 'Mobile Number',
      type: 'text',
    },
    {
      field_name: 'email',
      display_name: 'Email',
      type: 'email',
    },
  ];

  const updateSelectedFacility = (data: any) => {
    setProvinceCode(data.province_code);
    setIsUpdate(true);

    data.with_sbf = Boolean(data.with_sbf);

    showFacilityModal(true, data);
  };

  const deleteSelectedFacility = (data: any) => {
    setSelectedFacility(data.id);
    setOpenDeleteConfirmation(true);
  };

  const createStaffForSelectedFacility = (data: any) => {
    setSelectedFacility(data.id);
    showCreateStaffModal(data);
  };

  const viewStaffForSelectedFacility = (data: any) => {
    setSelectedFacility(data.id);
    setOpenViewFacilityStaffModal(true);
  };

  const actions = [
    {
      label: 'Update Facility',
      action: updateSelectedFacility,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete Facility',
      action: deleteSelectedFacility,
      startIcon: <DeleteOutlinedIcon />,
    },
    { label: 'divider' },
    {
      label: 'View Staff',
      action: viewStaffForSelectedFacility,
      startIcon: <PeopleAltOutlinedIcon />,
    },
    {
      label: 'Create Staff',
      action: createStaffForSelectedFacility,
      startIcon: <PersonAddAltIcon />,
    },
  ];

  const getFacilityStaff = () => {
    setLoading(true);
    getUsersByFacility(selectedFacility)
      .then((res) => {
        setStaffRows(res.data.rows);
        setFacilityStaffCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getRegionalFacilities = (query?: ApiQuery) => {
    setLoading(true);
    getFacilities({
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setFacilityCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleDelete = (id: number) => {
    deleteFacility(id)
      .then((res: any) => {
        enqueueSnackbar('Facilty successfully deleted!', {
          variant: 'success',
        });
        refreshTable();
      })
      .catch((error) => console.log(error));
    return;
  };
  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if (data.id) {
      updateFacility(data.id, data)
        .then((res: any) => {
          setButtonLoading(false);
          successFormSubmit(true, formikHelpers);
        })
        .catch((error) => {
          setButtonLoading(false);
          formikHelpers.setErrors(tranformFormErrors(error.response.data));
        });

      return;
    }

    createFacility(data)
      .then((res: any) => {
        setButtonLoading(false);
        successFormSubmit(false, formikHelpers);
      })
      .catch((error) => {
        setButtonLoading(false);
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      });
  };

  const handleCreateStaffSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    registerUser(data)
      .then(() => {
        setButtonLoading(false);
        createStaffSuccessFormSubmit();
      })
      .catch((error) => {
        setButtonLoading(false);
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      });
  };

  const createStaffSuccessFormSubmit = () => {
    enqueueSnackbar(`Staff successfully created!`, {
      variant: 'success',
    });
    setOpenUserModal(false);
  };

  const successFormSubmit = (from_edit: boolean, { resetForm }: FormikHelpers<any>) => {
    enqueueSnackbar(`Facility successfully ${from_edit ? 'updated' : 'created'}!`, {
      variant: 'success',
    });
    if (from_edit) {
      setOpenFacilityModal(false);
    } else {
      resetForm();
    }

    refreshTable();
  };

  const showFacilityModal = (from_edit?: boolean, data?: FacilityInput) => {
    if (from_edit && data) {
      changeNullToBlank(data);
      setFacilityInitialValues(data);
      setFacilityModalHeader('Update Facility');
      // setUserSchema(update_user_schema);
    } else {
      setFacilityModalHeader('Create Facility');
      if (user.provincial_account) initialValues.province_code = user.province_code;
      setFacilityInitialValues(initialValues);

      // setUserSchema(create_user_schema);
    }

    setOpenFacilityModal(true);
  };

  const showCreateStaffModal = (data?: FacilityInput) => {
    setUserModalHeader('Create Staff');
    if (data) {
      initialValuesUser.user_group_name = 'FHS';
      initialValuesUser.region_code = data?.region_code;
      initialValuesUser.province_code = data?.province_code;
      initialValuesUser.municipality_code = data?.municipality_code;
      initialValuesUser.facility_id = data?.id;
    }

    setUserInitialValues(initialValuesUser);
    setOpenUserModal(true);
  };

  const getProvince = async (region_code: string) => {
    try {
      const dataProvince = await getAvailableProvince(region_code);
      setProvinces(dataProvince.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getMunicipality = async () => {
    try {
      if (provinceCode) {
        const dataProvince = await getAvailableMunicipality({
          province_code: provinceCode,
        });
        setMunicipalities(dataProvince.data);
      } else {
        setMunicipalities([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const saveFacilities = async () => {
    const allFacilities = await getAllFacilities();

    const ws = xlsx.utils.json_to_sheet(allFacilities.data.rows);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'Facilities' + fileExtension);
  };

  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
  };

  useEffect(() => {
    if (provinceCode) getMunicipality();
  }, [provinceCode]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Facility Management' }]);
  }, []);

  useEffect(() => {
    if (user?.region_code) {
      getProvince(user.region_code);
    }

    if (user.provincial_account) {
      setProvinceCode(user.province_code);
    }
  }, [user]);

  return (
    <Box>
      <Header title="Facilities" />
      <CustomContainer>
        <CustomTable
          searchKeys="Facility Code and Facility Name"
          columns={columns}
          rows={rows}
          rowCount={facilityCount}
          loading={loading}
          getData={getRegionalFacilities}
          forceRefresh={refresh}
          otherFilters={otherFilters}
          filterComponent={
            <Box display="flex" gap="10px" width="100%" alignItems="center">
              <FacilityLocationFilter
                handleChangeFilter={(data) => {
                  setOtherFilters({ ...otherFilters, ...data });
                }}
                otherFilters={otherFilters}
              />
              <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
            </Box>
          }
          headerComponent={
            <Box>
              <Box display="flex" justifyContent="space-between" width="100%" alignItems="flex-start">
                <Box gap="15px" display="flex">
                  <PrimaryButton
                    onClick={() => {
                      setIsUpdate(false);
                      showFacilityModal();
                    }}
                    label={'Create Facility'}
                  />
                  <RegularButton
                    onClick={() => setOpenImportModal(true)}
                    label={'Import Facilities'}
                    startIcon={<IosShareIcon />}
                  />
                </Box>
                <RegularButton
                  startIcon={<DownloadIcon />}
                  label="Export"
                  onClick={() => {
                    saveFacilities();
                  }}
                  size="small"
                  variant="text"
                  color="inherit"
                />
              </Box>
            </Box>
          }
        />
      </CustomContainer>

      <CustomModal header={facilityModalHeader} open={openFacilityModal} setOpen={setOpenFacilityModal}>
        <CustomForm
          initialValues={facilityInitialValues}
          onSubmit={handleSubmit}
          fields={formFields}
          schema={facilitySchema}
          loading={buttonLoading}
        />
      </CustomModal>

      <CustomModal header={userModalHeader} open={openUserModal} setOpen={setOpenUserModal}>
        <CustomForm
          initialValues={userInitialValues}
          onSubmit={handleCreateStaffSubmit}
          fields={userFields}
          schema={userSchema}
          loading={buttonLoading}
        />
      </CustomModal>

      <CustomModal header="Facility Staff" open={openViewFacilityStaff} setOpen={setOpenViewFacilityStaffModal}>
        <Typography variant="subtitle1" color={colors.text}>
          {'Go to '}
          <Link
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
            to={'/user-management'}
          >
            User Management
          </Link>
          {' tab to update or delete facility staff'}
        </Typography>
        <CustomTable
          columns={staffColumns}
          rows={staffRows}
          rowCount={facilityStaffCount}
          loading={loading}
          getData={getFacilityStaff}
          forceRefresh={refresh}
          hideSearch
        />
      </CustomModal>

      <FacilityGroup />
      <FacilityImportModal
        open={openImportModal}
        setOpen={setOpenImportModal}
        handleFileInputChange={handleFileInputChange}
        uploadFacilities={uploadFacilities}
      />

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure want to delete this facility?"
        onConfirm={() => handleDelete(selectedFacility)}
      />

      <CustomModal header="Hmmm, the file is invalid." open={openErrorModal} setOpen={setOpenErrorModal}>
        <Box marginTop="-20px">
          <ul>
            {errorMessage.slice(0, 15).map((error, index) => {
              return (
                <li key={index}>
                  <Typography>{error}</Typography>
                </li>
              );
            })}
          </ul>
          <Typography>
            {`In total, there are ${errorMessage.length} row${
              errorMessage.length > 1 ? 's' : ''
            } with errors in your sheet. We recommend reviewing these rows and correcting any issues before proceeding with importing.`}
          </Typography>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default FacilityManagement;
