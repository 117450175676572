import React, { useEffect, useState } from 'react';

import ComplexTable from './ComplexTable';
import SimpleTable from './SimpleTable';
import { Typography } from '@mui/material';

type Props = {
  headers: any[];
  rows: any[];
  isSimpleTable?: boolean;
  handleBodyClick: (field: any) => void;
  search: string;
  getHighlightedText: (text: string, searckKey: string) => any;
  fieldsWithComments: string[];
  level: number;
};

export const NotApplicable = ({ colors }: any) => (
  <Typography
    variant="body1"
    color={colors?.text}
    sx={{
      width: '100px',
      borderRadius: '10px',
      padding: '2px 10px',
      textAlign: 'center',
      fontSize: '11px',
      margin: '0 auto',
      backgroundColor: '#898989',
      color: 'white',
    }}
  >
    Not Applicable
  </Typography>
);

const AssessmentTable: React.FC<Props> = ({
  headers,
  rows,
  isSimpleTable,
  handleBodyClick,
  search,
  getHighlightedText,
  fieldsWithComments,
  level,
}) => {
  const [headerCount, setHeaderCount] = useState(0);

  useEffect(() => {
    setHeaderCount(0);
    headers.forEach((item) => {
      if (item.children) {
        setHeaderCount((prev) => prev + item.children.length);
      } else {
        setHeaderCount((prev) => prev + 1);
      }
    });
  }, [headers]);

  const headerWidth = headerCount === 1 ? 20 : headerCount > 3 ? 60 / headerCount : 40 / headerCount;

  return isSimpleTable ? (
    // table with checkbox
    <SimpleTable
      headers={headers}
      headerCount={headerCount}
      headerWidth={headerWidth}
      rows={rows}
      handleBodyClick={handleBodyClick}
      search={search}
      getHighlightedText={getHighlightedText}
      fieldsWithComments={fieldsWithComments}
      level={level}
    />
  ) : (
    // table without checkbox (table with text or number input box)
    <ComplexTable
      headers={headers}
      headerCount={headerCount}
      headerWidth={headerWidth}
      rows={rows}
      handleBodyClick={handleBodyClick}
      search={search}
      getHighlightedText={getHighlightedText}
      fieldsWithComments={fieldsWithComments}
    />
  );
};

export default AssessmentTable;
