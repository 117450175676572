import { Box, Chip, Typography, useTheme } from '@mui/material';
import { ConditionalHideComponent, CustomIconButton, ProtectedComponent, RegularButton } from 'components';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { DetailedReportFacilityDetails } from 'model/Entities';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { formatDateTime } from 'utils';
import { tokens } from 'context/theme.context';

type FacilityReportEntryProps = {
  selectedFacility?: DetailedReportFacilityDetails;
  handleClose: () => void;
};

const FacilityAssessmentDetails: React.FC<FacilityReportEntryProps> = (props: FacilityReportEntryProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [chipColor, setChipColor] = useState('');
  const [statusLabel, setStatusLabel] = useState('');

  useEffect(() => {
    switch (props.selectedFacility?.status) {
      case 'assigned':
        setChipColor('#a86e05');
        setStatusLabel('Assigned');
        break;
      case 'open':
        setChipColor(colors.text);
        setStatusLabel('Open');
        break;
      case 'started':
        setChipColor('#f86b02');
        setStatusLabel('Started');
        break;
      case 'submitted':
        setChipColor('#b7a300');
        setStatusLabel('Submitted');
        break;
      case 'ongoing-approval':
        setChipColor('#0393d3');
        setStatusLabel('Ongoing Approval');
        break;
      case 'approved':
        setChipColor('#419b44');
        setStatusLabel('Approved');
        break;
      case 'completed':
        setChipColor('#419b44');
        setStatusLabel('Completed');
        break;
      default:
        setChipColor('default');
        break;
    }
  }, [props.selectedFacility, colors.text]);

  return (
    <>
      {props.selectedFacility && (
        <Box>
          <Box sx={{ backgroundColor: colors.secondary_background }} p="25px" borderRadius={2}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box display="flex">
                <Typography
                  variant="h4"
                  component="span"
                  color={colors.accent}
                  fontWeight="bold"
                  sx={{ marginRight: '10px' }}
                >
                  {props.selectedFacility.facility.facility_name}
                </Typography>
                <Chip
                  label={statusLabel}
                  size="small"
                  variant="outlined"
                  style={{ borderColor: chipColor, color: chipColor }}
                />
              </Box>
              <CustomIconButton icon={<CloseIcon />} onClick={props.handleClose} />
            </Box>
            <Typography variant="h6" color={colors.text} sx={{ gridColumn: 'span 2' }}>
              {props.selectedFacility.facility.facility_type.type}
            </Typography>
            <Typography variant="subtitle1" color={colors.text}>
              {props.selectedFacility.facility.barangay_name}
              {', '}
              {props.selectedFacility.facility.municipality.municipality_name}
              {', '}
              {props.selectedFacility.facility.province.province_name}
            </Typography>

            <Box display="grid" gridTemplateColumns="150px 1fr" columnGap="10px" alignItems="center">
              <Typography variant="h6" color={colors.text} fontWeight="bold" sx={{ gridColumn: 'span 2' }} mt="20px">
                People
              </Typography>

              <Typography variant="body1" color={colors.text}>
                Staff
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body1" color={colors.text}>
                  {props.selectedFacility.assessor?.first_name}
                  {', '}
                  {props.selectedFacility.assessor?.last_name}
                </Typography>
              </Box>

              <Typography variant="body1" color={colors.text}>
                Approver
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body1" color={colors.text}>
                  {props.selectedFacility.approver?.first_name}
                  {', '}
                  {props.selectedFacility.approver?.last_name}
                </Typography>
              </Box>

              <Typography variant="h6" color={colors.text} fontWeight="bold" sx={{ gridColumn: 'span 2' }} mt="20px">
                Dates
              </Typography>

              <Typography variant="body1" color={colors.text}>
                Started
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body1" color={colors.text}>
                  {props.selectedFacility.started_at ? formatDateTime(props.selectedFacility.started_at) : '-'}
                </Typography>
              </Box>

              <Typography variant="body1" color={colors.text}>
                Submitted
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body1" color={colors.text}>
                  {props.selectedFacility.submitted_at ? formatDateTime(props.selectedFacility.submitted_at) : '-'}
                </Typography>
              </Box>

              <Typography variant="body1" color={colors.text}>
                Approved
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body1" color={colors.text}>
                  {props.selectedFacility.approved_at ? formatDateTime(props.selectedFacility.approved_at) : '-'}
                </Typography>
              </Box>
            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="10px" marginTop="20px">
              <Link to={'/facility-reports/view/' + props.selectedFacility.id} style={{ textDecoration: 'none' }}>
                <RegularButton
                  label="View"
                  onClick={() => undefined}
                  styles={{ width: '100%' }}
                  startIcon={<RemoveRedEyeIcon />}
                />
              </Link>
              <ConditionalHideComponent hide={!['open', 'started'].includes(props.selectedFacility?.status)}>
                <ProtectedComponent requiredAuth={['MANAGE_FACILITY']}>
                  <Link to={'/facility-reports/edit/' + props.selectedFacility.id} style={{ textDecoration: 'none' }}>
                    <RegularButton
                      label="Edit"
                      onClick={() => undefined}
                      styles={{ width: '100%' }}
                      startIcon={<EditOutlinedIcon />}
                    />
                  </Link>
                </ProtectedComponent>
              </ConditionalHideComponent>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FacilityAssessmentDetails;
