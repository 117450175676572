/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

import ConditionalField from './ConditionalField';
import { ConfirmationDialog } from 'components';
import { DraggableList } from 'components';
import { DropResult } from 'react-beautiful-dnd';
import EditApplicabilityModal from '../components/EditApplicabilityModal';
import { FieldType } from '../IndicatorBuilder';
import ParentField from './ParentField';
import RadioField from './RadioField';
import Section from './Section';
import TableField from './TableField';
import TextField from './TextField';
import { tokens } from 'context/theme.context';

type EditApplicabilityFormValues = {
  applicability: string;
  options: string[];
};

type FieldGroupsProps = {
  items: FieldType[];
  droppableId: string;
  level?: number;
  deleteMainSection?: (index: number) => void;
  mainIndex?: number;
  index: number;
  sectionNumber: string;
  parentObject: any;
  parentProperty: string;
  respond?: boolean;
  selectedField?: FieldType;
  showMissingItems: boolean;
  setSelectedField?: (value: FieldType) => void;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
  mainSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  mainSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
};

export enum FIELD_TYPES {
  TEXT = 'text',
  NUMERIC = 'numeric',
  RADIO = 'radio',
  YES_OR_NO = 'y/n',
  PARENT = 'parent',
  TABLE = 'table',
  SECTION = 'section',
  SUB_SECTION = 'sub-section',
}

export const getFieldObject = (field_type: FIELD_TYPES): FieldType => {
  let newField: FieldType;

  switch (field_type) {
    case FIELD_TYPES.TEXT:
      newField = {
        type: 'field',
        field_name: 'New Field - Text',
        field_code: '1',
        response_type: 'text',
      };
      break;
    case FIELD_TYPES.NUMERIC:
      newField = {
        type: 'field',
        field_name: 'New Field - Numeric',
        field_code: '1',
        response_type: 'numeric',
      };
      break;
    case FIELD_TYPES.RADIO:
      newField = {
        type: 'field',
        field_name: 'New Field - Single Choice',
        field_code: '1',
        response_type: 'radio',
        options: ['Option 1', 'Option 2'],
      };
      break;
    case FIELD_TYPES.YES_OR_NO:
      newField = {
        type: 'field',
        field_name: 'New Field - Yes or No',
        field_code: '1',
        response_type: 'y/n',
      };
      break;
    case FIELD_TYPES.PARENT:
      newField = {
        type: 'parent',
        field_name: 'Parent Field',
        field_code: '1',
        children: [
          {
            type: 'field',
            field_name: 'New Field - Text',
            field_code: '1',
            response_type: 'text',
          },
        ],
      };
      break;
    case FIELD_TYPES.TABLE:
      newField = {
        type: 'table',
        field_name: 'Table Field',
        field_code: '1',
        table_type: 'simple-table',
        columns: ['Option 1', 'Option 2', 'Option 3'],
        rows: [
          {
            type: 'field',
            field_name: 'Row 1',
            field_code: '1',
          },
          {
            type: 'field',
            field_name: 'Row 2',
            field_code: '1',
          },
        ],
      };
      break;
    case FIELD_TYPES.SECTION:
      newField = {
        type: 'section',
        section_number: '1',
        section_name: 'New Section',
        section_body: [
          {
            type: 'field',
            field_name: 'New Field',
            field_code: '1',
            response_type: 'text',
          },
        ],
      };
      break;
    case FIELD_TYPES.SUB_SECTION:
      newField = {
        type: 'sub-section',
        section_number: '1',
        section_name: 'New Subsection',
        section_content: [
          {
            type: 'field',
            field_name: 'New Field',
            field_code: '1',
            response_type: 'text',
          },
        ],
      };
      break;
  }

  return newField;
};

// PROBLEM HERE
const FieldGroups: React.FC<FieldGroupsProps> = ({
  items,
  droppableId,
  level = 0,
  deleteMainSection,
  mainIndex,
  index,
  sectionNumber,
  parentObject,
  parentProperty,
  respond,
  selectedField,
  showMissingItems,
  setMissingItems,
  setSelectedField,
  mainSetTotalFieldsCount,
  mainSetTotalFieldsWithReponseCount,
  childSetTotalFieldsCount,
  childSetTotalFieldsWithReponseCount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fields, setFields] = useState(items);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [editApplicabilityFormValues] = useState<EditApplicabilityFormValues>({
    applicability: '',
    options: [],
  });
  // const [selectedField, setSelectedField] = useState<FieldType>();
  const [isEditApplicabilityModalOpen, setIsEditApplicabilityModalOpen] = useState(false);
  const [totalFieldsCount, setTotalFieldsCount] = useState<number>(0);
  const [totalFieldsWithReponseCount, setTotalFieldsWithReponseCount] = useState<number>(0);

  const [fieldsCount, setFieldsCount] = useState<number[]>([]);
  const [fieldsWithReponseCount, setFieldsWithReponseCount] = useState<number[]>([]);

  useEffect(() => {
    setTotalFieldsCount(
      fieldsCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
    setTotalFieldsWithReponseCount(
      fieldsWithReponseCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
  }, [fieldsCount, fieldsWithReponseCount]);

  useEffect(() => {
    setFields(items);
  }, [items]);

  useEffect(() => {
    if (childSetTotalFieldsCount && childSetTotalFieldsWithReponseCount) {
      childSetTotalFieldsCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsCount;
        return newValue;
      });
      childSetTotalFieldsWithReponseCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsWithReponseCount;
        return newValue;
      });
    }
  }, [totalFieldsCount, totalFieldsWithReponseCount, childSetTotalFieldsCount, childSetTotalFieldsWithReponseCount]);

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const newList = Array.from(fields);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setFields(newList);
    parentObject[parentProperty] = newList;
  };

  const deleteField = (index: number) => {
    setDeleteIndex(index);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (deleteIndex !== null) {
      const newList = Array.from(fields);
      newList.splice(deleteIndex, 1);
      setFields(newList);
      parentObject[parentProperty] = newList;
      setDeleteIndex(null);
    }
    setIsConfirmationDialogOpen(false);
  };

  const handleEditApplicability = () => {
    setIsEditApplicabilityModalOpen(true);
  };

  const handleEditApplicabilityFormSubmit = (values: EditApplicabilityFormValues) => {
    setIsEditApplicabilityModalOpen(false);
  };

  const addField = (index: number, field_type: FIELD_TYPES) => {
    let newField: FieldType = getFieldObject(field_type);

    const newList = Array.from(fields);
    newList.splice(index + 1, 0, newField);
    setFields(newList);
    parentObject[parentProperty] = newList;
  };

  const handleChange = (field: any, property: keyof FieldType, data: any) => {
    field[property] = data;
  };

  const displayField = (field: FieldType, index: number) => {
    if (field.type === 'field') {
      switch (field.response_type) {
        case 'radio':
        case 'y/n':
          return (
            <RadioField
              field={field}
              deleteField={() => deleteField(index)}
              addField={(field_type: FIELD_TYPES) => addField(index, field_type)}
              editApplicability={handleEditApplicability}
              respond={respond}
              handleChange={handleChange}
              missing={showMissingItems}
              setMissingItems={setMissingItems}
              index={index}
              childSetTotalFieldsCount={setFieldsCount}
              childSetTotalFieldsWithReponseCount={setFieldsWithReponseCount}
            />
          );

        default:
          return (
            <TextField
              field={field}
              deleteField={() => deleteField(index)}
              addField={(field_type: FIELD_TYPES) => addField(index, field_type)}
              editApplicability={handleEditApplicability}
              respond={respond}
              handleChange={handleChange}
              missing={showMissingItems}
              setMissingItems={setMissingItems}
              index={index}
              childSetTotalFieldsCount={setFieldsCount}
              childSetTotalFieldsWithReponseCount={setFieldsWithReponseCount}
            />
          );
      }
    } else if (field.type === 'parent') {
      return (
        <ParentField
          field={field}
          droppableId={`${droppableId}-${index}`}
          index={index}
          level={level + 1}
          deleteField={() => deleteField(index)}
          addField={(field_type: FIELD_TYPES) => addField(index, field_type)}
          editApplicability={handleEditApplicability}
          respond={respond}
          showMissingItems={showMissingItems}
          setMissingItems={setMissingItems}
          childSetTotalFieldsCount={setFieldsCount}
          childSetTotalFieldsWithReponseCount={setFieldsWithReponseCount}
          // handleChange={handleChange}
        />
      );
    } else if (field.type === 'conditional-field') {
      return (
        <ConditionalField
          field={field}
          droppableId={`${droppableId}-${index}`}
          index={index}
          level={level + 1}
          deleteField={() => deleteField(index)}
          displayItem={displayField}
          respond={respond}
          selectedField={selectedField}
          showMissingItems={showMissingItems}
          setMissingItems={setMissingItems}
          setSelectedField={setSelectedField}
        />
      );
    } else if (field.type === 'table') {
      return (
        <TableField
          field={field}
          droppableId={`${droppableId}-${index}`}
          level={level + 1}
          simple={field.table_type === 'simple-table'}
          addField={(field_type: FIELD_TYPES) => addField(index, field_type)}
          deleteField={() => deleteField(index)}
          editApplicability={handleEditApplicability}
          respond={respond}
          handleChange={handleChange}
          index={index}
          showMissingItems={showMissingItems}
          setMissingItems={setMissingItems}
          childSetTotalFieldsCount={setFieldsCount}
          childSetTotalFieldsWithReponseCount={setFieldsWithReponseCount}
        />
      );
    } else if (field.type === 'section' || field.type === 'sub-section' || field.section_name) {
      return (
        <Section
          section={field as any}
          droppableId={`${droppableId}-${index}`}
          index={index}
          level={level + 1}
          subSection={field.type === 'sub-section'}
          mainSection={level === 0}
          deleteSection={level === 0 ? () => deleteMainSection!(mainIndex!) : () => undefined}
          addField={(field_type: FIELD_TYPES) => addField(index, field_type)}
          sectionNumber={level === 0 ? sectionNumber : sectionNumber + '.' + (index + 1)}
          respond={respond}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          showMissingItems={showMissingItems}
          setMissingItems={setMissingItems}
          mainIndex={mainIndex}
          mainSetTotalFieldsCount={level === 0 ? mainSetTotalFieldsCount : undefined}
          mainSetTotalFieldsWithReponseCount={level === 0 ? mainSetTotalFieldsWithReponseCount : undefined}
          childSetTotalFieldsCount={setFieldsCount}
          childSetTotalFieldsWithReponseCount={setFieldsWithReponseCount}
        />
      );
    }
  };

  return (
    <Box width="100%">
      {respond ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            sx={{
              border: '1px solid ' + colors.hoverBackground,
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            {fields.map((field, index) => {
              return <Fragment key={index}>{displayField(field, index)}</Fragment>;
            })}
          </Box>
        </>
      ) : (
        <DraggableList
          list={fields}
          droppableId={droppableId}
          displayItem={displayField}
          handleDragEnd={handleDragEnd}
          hideDragIcon={level === 0}
          // itemBackgroundColor={level > 0 ? colors.hoverBackground : undefined}
          style={{
            marginBlock: level > 0 ? '10px' : 0,
            padding: level > 0 ? '10px' : 0,
            borderRadius: '10px',
            border: level === 0 ? undefined : '1px solid ' + colors.hoverBackground,
          }}
        />
      )}
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        setOpen={setIsConfirmationDialogOpen}
        message="Are you sure you want to delete this field?"
        onConfirm={handleConfirmDelete}
      />

      <EditApplicabilityModal
        open={isEditApplicabilityModalOpen}
        setOpen={setIsEditApplicabilityModalOpen}
        onSubmit={handleEditApplicabilityFormSubmit}
        initialValues={editApplicabilityFormValues}
      />
    </Box>
  );
};

export default FieldGroups;
