/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'model/interface';
import { AssessmentIndicatorModel, Province } from 'model/Entities';
import { Box, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  PrimaryButton,
  RowAction,
} from 'components';
import {
  createFacilityGroup,
  deleteFacilityGroupWithAssignedFacility,
  getFacilitiesGroup,
  updateFacilityGroup,
} from 'api/facility-group';
import { create_facility_group_schema, provincial_create_facility_group_schema } from 'model/schema';
import { getAvailableProvince, getFieldAssessorsGroup } from 'api/facility';
import { getGroupIndicatorAssessors, updateGroupIndicatorAssessor } from 'api/facility-group-indicator-assessor';
import { useContext, useEffect, useState } from 'react';

import CustomTable from 'components/CustomTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FacilityTableViewModal from './FacilityTableViewModal';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { UserContext } from 'context/user.context';
import { getAssessmentIndicators } from 'api/assessment-indicator';
import { tokens } from 'context/theme.context';
import { tranformFormErrors } from 'utils';
import { useSnackbar } from 'notistack';

//MUI ICONS FOR ACTIONS
type GroupInput = {
  group_name: string;
  province_code: string;
  assessor_id: number | string;
};

interface GroupFieldInput extends FieldInput {
  field_name: keyof GroupInput;
}

type FacilityGroupProps = {
  //   selectedBatch: number;
};

interface AssessorDropdown {
  value: any;
  key: string;
}

const FacilityGroup: React.FC<FacilityGroupProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const [refresh, setRefresh] = useState(0);
  const [modalHeader, setModalHeader] = useState<string>('');
  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openFacilityViewModal, setOpenFacilityViewModal] = useState<boolean>(false);
  const [openFacilityRenameModal, setOpenFacilityRenameModal] = useState<boolean>(false);
  const [selectedGroupID, setSelectedGroupID] = useState<number>(0);
  const [selectedGroupName, setSelectedGroupName] = useState<string>('');

  const [facilityGroupSchema, setFacilityGroupSchema] = useState<any>(provincial_create_facility_group_schema);
  const [assessorFormFields, setAssessorFormFields] = useState<GroupFieldInput[]>([]);
  const [groupAssessors, setGroupAssessors] = useState<any>({});

  const [assessorId, setAssessorId] = useState<number>(0);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [provinceCode, setProvinceCode] = useState<string>('');
  const [province, setProvince] = useState<Province>();

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const [groupInitialValues, setGroupInitialValues] = useState<GroupInput>({
    group_name: '',
    province_code: user.provincial_account ? user.province_code : '',
    assessor_id: 0,
  });

  const [assessors, setAssessors] = useState<AssessorDropdown[]>([]);

  const getAssessors = async () => {
    setAssessors([]);

    try {
      if (provinceCode) {
        await getFieldAssessorsGroup(provinceCode).then((res) => {
          if (res.data.length) {
            res.data.forEach((data: any) => {
              const add: AssessorDropdown = {
                value: data?.id,
                key: data?.first_name + ' ' + data?.last_name + ' (' + data?.username + ')',
              };
              setAssessors((prevArray) => [...(prevArray ?? []), add]);
            });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProvince = async (region_code: string) => {
    try {
      const dataProvince = await getAvailableProvince(region_code);
      setProvinces(dataProvince.data);
    } catch (e) {
      console.log(e);
    }
  };

  const updateSelectedFacilityGroup = (data: any) => {
    setModalHeader('Update Assessors');
    setProvinceCode(data.province_code);
    setIsUpdate(true);
    setSelectedGroupID(data.id);
    setGroupInitialValues({
      group_name: data.group_name,
      province_code: data.province_code,
      assessor_id: data.assessor_id,
    });
  };

  const deleteSelectedFacilityGroup = (data: any) => {
    setSelectedGroupID(data.id);
    setOpenDeleteConfirmation(true);
  };

  const viewFacilityToGroup = (data: any) => {
    setOpenFacilityViewModal(true);
    provinces.forEach((p) => {
      if (p.province_code === data.province_code) {
        setProvince(p);
      }
    });
    setSelectedGroupID(data.id);
    setSelectedGroupName(data.group_name);
    setAssessorId(data.assessor_id);
  };

  const renameFacilityGroup = (data: any) => {
    setOpenFacilityRenameModal(true);
    setSelectedGroupID(data.id);
    setModalHeader('Rename Facility Group');
    setGroupInitialValues({
      group_name: data.group_name,
      province_code: data.province_code,
      assessor_id: 0,
    });
  };

  const actions = [
    {
      label: 'View Facilities',
      action: viewFacilityToGroup,
      startIcon: <RemoveRedEyeIcon />,
    },
    {
      label: 'Rename Facility Group',
      action: renameFacilityGroup,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Update Assessors',
      action: updateSelectedFacilityGroup,
      startIcon: <SystemUpdateAltIcon />,
    },
    {
      label: 'Delete Facility Group',
      action: deleteSelectedFacilityGroup,
      startIcon: <DeleteOutlinedIcon />,
    },
  ];

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'group_name',
      sortable: false,
      headerName: 'Group Name',
      flex: 1,
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'City / Province Name',
      flex: 1,
      renderCell: (params) => (
        <>
          {provinces.map(function (p) {
            if (p.province_code === params.row.province_code) {
              return p.province_name;
            }

            return null;
          })}
        </>
      ),
    },
    {
      field: 'facilities',
      sortable: false,
      headerName: 'Total Facilities',
      flex: 0.5,
      renderCell: (params) => {
        return (
          <CustomGridCell>
            {
              params.row.facilities.filter((facility: any) => {
                return facility.facility !== null;
              }).length
            }
          </CustomGridCell>
        );
      },
    },
    {
      field: 'facility_group_indicator_assessor',
      sortable: false,
      headerName: 'With Assessor',
      flex: 0.5,
      renderCell: (params) => {
        return (
          <CustomGridCell>
            {
              params.row.facility_group_indicator_assessor ? 'Yes': 'No'
            }
          </CustomGridCell>
        );
      },
    },
    // {
    //   field: 'assessor_id',
    //   sortable: false,
    //   headerName: 'Assessor',
    //   flex: 1,
    //   renderCell: (params) => <CustomGridCell>{params.row.assessor?.assessor_name}</CustomGridCell>,
    // },
    // {
    //   field: 'assessor_username',
    //   sortable: false,
    //   headerName: 'Username',
    //   flex: 0.8,
    //   renderCell: (params) => <CustomGridCell>{params.row.assessor?.username}</CustomGridCell>,
    // },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const getAllIndicators = async () => {
    const { data } = await getAssessmentIndicators({ length: 10000 });
    setAssessorFormFields(
      data.rows.map((indicator: AssessmentIndicatorModel) => {
        return {
          field_name: indicator.id + '',
          display_name: indicator.name,
          type: 'autocomplete',
          options: assessors.map((u) => {
            return { key: u.key, value: u.value };
          }),
          span: 2,
        };
      })
    );
  };

  const formFields: GroupFieldInput[] = [
    {
      field_name: 'group_name',
      display_name: 'Group Name',
      type: 'text',
      span: 4,
    },
    {
      field_name: 'province_code',
      display_name: 'City / Province',
      type: 'select',
      options: provinces.map((p) => {
        return { key: p.province_name, value: p.province_code };
      }),
      span: 4,
      disabled: openFacilityRenameModal,
      hidden: user.provincial_account,
      onChange: (value, setFieldValue) => {
        const selected = provinces.find((p) => p.province_code === value);
        setProvinceCode(selected?.province_code + '');
        setFieldValue && setFieldValue('assessor_id', '');
      },
    },
  ];

  // Functions
  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  const getFacilityGroup = (query?: ApiQuery) => {
    setLoading(true);
    getFacilitiesGroup({
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setRowCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    return;
  };

  const handleDelete = (id: number) => {
    deleteFacilityGroupWithAssignedFacility(id)
      .then((res: any) => {
        enqueueSnackbar('Group successfully deleted!', {
          variant: 'success',
        });
        getFacilityGroup();
      })
      .catch((error) => console.log(error));
    return;
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    if (selectedGroupID !== 0) {
      if (isUpdate) {
        updateGroupIndicatorAssessor(selectedGroupID, data)
          .then((res: any) => {
            setButtonLoading(false);
            successFormSubmit(true, formikHelpers);
          })
          .catch((error) => {
            setButtonLoading(false);
            formikHelpers.setErrors(tranformFormErrors(error.response.data));
          });
        return;
      }

      updateFacilityGroup(selectedGroupID, data)
        .then((res: any) => {
          setButtonLoading(false);
          successFormSubmit(true, formikHelpers);
        })
        .catch((error) => {
          setButtonLoading(false);
          formikHelpers.setErrors(tranformFormErrors(error.response.data));
        });
      return;
    }

    createFacilityGroup(data)
      .then((res: any) => {
        setButtonLoading(false);
        successFormSubmit(false, formikHelpers);
      })
      .catch((error) => {
        setButtonLoading(false);
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
        setButtonLoading(false);
      });
    return;
  };

  const successFormSubmit = (from_edit: boolean, { setErrors }: FormikHelpers<any>) => {
    if (modalHeader === 'Create Group') {
      enqueueSnackbar('Group successfully created!', {
        variant: 'success',
      });
    } else if (modalHeader === 'Update Assessors') {
      enqueueSnackbar('Group Assessors successfully Updated!', {
        variant: 'success',
      });
    } else if (modalHeader === 'Rename Facility Group') {
      enqueueSnackbar('Group successfully Renamed!', {
        variant: 'success',
      });
    }

    getFacilityGroup({ page: 1, length: 10 });
    setOpenModal(false);
  };

  const handleCreateGroupSchema = () => {
    if (user.provincial_account) {
      setFacilityGroupSchema(provincial_create_facility_group_schema);
    } else {
      setFacilityGroupSchema(create_facility_group_schema);
    }
  };

  useEffect(() => {
    if (user.provincial_account) {
      setProvinceCode(user.province_code);
    }
  }, []);

  useEffect(() => {
    getAssessors();
  }, [provinceCode]);

  useEffect(() => {
    if (assessors) {
      getAllIndicators();
    }
  }, [assessors]);

  useEffect(() => {
    if (user?.region_code) {
      getProvince(user.region_code);
    }
  }, [user]);

  useEffect(() => {
    setGroupAssessors({});
    if (selectedGroupID) {
      getGroupIndicatorAssessors(selectedGroupID).then((res: any) => {
        setGroupAssessors(
          res.data.reduce((result: any, assessor: any) => {
            return { ...result, [assessor.indicator_id]: assessor.assessor_id };
          }, {})
        );
      });
    }
  }, [selectedGroupID]);

  useEffect(() => {
    if (!isUpdate) setSelectedGroupID(0);
  }, [isUpdate]);

  return (
    <Box>
      <CustomContainer m="20px 0 0">
        <Box>
          <Typography variant="h3" component="h3" color={colors.text} fontWeight="bold">
            Facility Group
          </Typography>
        </Box>

        <CustomTable
          searchKeys="Group Name"
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          getData={getFacilityGroup}
          forceRefresh={refresh}
          headerComponent={
            <Box gap="15px" display="flex">
              <PrimaryButton
                onClick={() => {
                  setOpenModal(true);
                  setModalHeader('Create Group');
                  setGroupInitialValues({
                    group_name: '',
                    province_code: user.provincial_account ? user.province_code : '',
                    assessor_id: '',
                  });
                  setSelectedGroupID(0);
                  handleCreateGroupSchema();
                }}
                label={'Create Group'}
              />
            </Box>
          }
        />
      </CustomContainer>

      {/* Update Facility Group Assessor */}
      <CustomModal header={modalHeader} open={isUpdate} setOpen={setIsUpdate} width={1000}>
        <CustomForm
          initialValues={groupAssessors}
          onSubmit={handleSubmit}
          fields={assessorFormFields}
          loading={buttonLoading}
        />
      </CustomModal>

      {/* Create Facility Group Assessor */}
      <CustomModal header={modalHeader} open={openModal} setOpen={setOpenModal} width={500}>
        <CustomForm
          initialValues={groupInitialValues}
          onSubmit={handleSubmit}
          fields={formFields}
          schema={facilityGroupSchema}
          loading={buttonLoading}
        />
      </CustomModal>

      {/* Rename Facility Group */}
      <CustomModal header={modalHeader} open={openFacilityRenameModal} setOpen={setOpenFacilityRenameModal}>
        <CustomForm
          initialValues={groupInitialValues}
          onSubmit={handleSubmit}
          fields={formFields}
          schema={facilityGroupSchema}
          loading={buttonLoading}
        />
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure want to delete this assessment group?"
        onConfirm={() => handleDelete(selectedGroupID)}
      />

      {province && (
        <FacilityTableViewModal
          open={openFacilityViewModal}
          setOpen={setOpenFacilityViewModal}
          refreshGroupTable={refreshTable}
          groupName={selectedGroupName}
          groupId={selectedGroupID}
          assessorId={assessorId}
          provinceName={province?.province_name}
          provinceCode={province?.province_code}
        />
      )}
    </Box>
  );
};

export default FacilityGroup;
