import ArticleContent, { imagePath } from '../ArticleContent';

const RemoveFacilitiesFromGroupArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            {
              step: '1. Inside the "View Facilities", select the facility/facilities you want to remove.',
              image: `${imagePath}/facility-group-3.png`,
            },
            { step: '2. Click the "Remove Selected Facilities" button.', image: `${imagePath}/facility-group-6.png` },
            {
              step: '3. Can also remove facilities one by one by clicking "Remove" button.',
            },
          ],
        },
      ]}
    />
  );
};

export default RemoveFacilitiesFromGroupArticle;
