/* eslint-disable react-hooks/exhaustive-deps */

import * as XLSX from 'xlsx';

import {
  AssessmentBatchModel,
  AssessmentCommentModel,
  AssessmentSectionModel,
  FacilityAssessmentModel,
} from 'model/Entities';
import { Box, InputBase, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import {
  CustomContainer,
  CustomModal,
  CustomTextField,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'components';
import React, { useContext, useEffect, useState } from 'react';
import {
  createAssessmentComment,
  deleteAssessmentComment,
  updateAllAssessmentCommentStatus,
  updateAssessmentComment,
  updateAssessmentCommentStatus,
} from 'api/assessment-comment';
import {
  createFacilityReportEntryComment,
  removeFacilityReportEntryComment,
  setFacilityReportEntryCommentStatus,
  updateAllFacilityReportEntryComment,
  updateFacilityReportEntryComment,
} from 'api/facility-report-entry-comment';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AssessmentTable from './AssessmentTable';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { ConfirmationDialog } from 'components';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { UserContext } from 'context/user.context';
import { exportAsPDF } from 'utils/pdf_generator';
import { flatSections } from 'components/IndicatorViewer';
import { getAssessmentBatch } from 'api/assessment-batch';
import moment from 'moment';
import { styled } from '@mui/system';
import { tokens } from 'context/theme.context';

type AssessmentSectionProps = {
  assessment?: FacilityAssessmentModel;
  section: AssessmentSectionModel;
  comments?: AssessmentCommentModel[];
  refreshComment?: () => void;
  fromReportingTool?: boolean;
  fromPreview?: boolean;
};

const AssessmentSection: React.FC<AssessmentSectionProps> = ({
  assessment,
  section,
  comments,
  refreshComment,
  fromReportingTool,
  fromPreview,
}) => {
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const [editedComment, setEditedComment] = useState<string>('');
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(null);

  const { user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [search, setSearch] = useState<string>('');
  const [displayedComments, setDisplayedComments] = useState<any[]>([]);
  const [fieldsWithComments, setFieldsWithComments] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedField, setClickedField] = useState<any | null>(null);
  const [clickedFieldColumns, setClickedFieldColumns] = useState<any | null>([]);
  const [commentValue, setCommentValue] = useState('');
  const [batch, setBatch] = useState<AssessmentBatchModel>();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getHighlightedText = (text: string, searckKey: string) => {
    var parts = text?.split(new RegExp(`(${searckKey})`, 'gi'));
    return parts?.map((part, index) => (
      <React.Fragment key={index}>
        {part.toLowerCase() === searckKey.toLowerCase() ? (
          <span style={{ backgroundColor: '#e8bb49' }}>{part}</span>
        ) : (
          part
        )}
      </React.Fragment>
    ));
  };

  function exportToexcel(data: any[], filename: string) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  const handleDownload = async () => {
    const responses = section.section_body.flatMap((section: any) => {
      return flatSections(section);
    });
    exportToexcel(responses, assessment?.facility.facility_name ?? 'responses');
  };

  const handleDownloadPdf = async () => {
    const responses = section.section_body.flatMap((section: any) => {
      return flatSections(section);
    });
    batch && exportAsPDF(responses, batch.batch_name, assessment?.facility.facility_name ?? '');
  };

  const handleBodyClick = (field: any, columns?: any) => {
    setClickedFieldColumns(columns);
    setClickedField(field);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedCommentId) {
      try {
        if (fromReportingTool) await removeFacilityReportEntryComment(selectedCommentId);
        else await deleteAssessmentComment(selectedCommentId);
        refreshComment && refreshComment();
      } catch (error) {
        console.error('Error deleting comment:', error);
      } finally {
        setOpenDeleteConfirmation(false);
        setSelectedCommentId(null);
      }
    }
  };

  const handleSubmitComment = async () => {
    if (assessment) {
      try {
        const data = {
          comment: commentValue,
          field_code: clickedField.field_code,
          field_name: clickedField.field_name,
        };
        setLoading(true);
        if (fromReportingTool)
          await createFacilityReportEntryComment({ ...data, facility_report_entry_id: assessment.id! });
        else await createAssessmentComment({ ...data, assessment_id: assessment.id });

        setCommentValue('');
        refreshComment && refreshComment();
      } finally {
        setLoading(false);
      }
    }
  };

  const handleResolve = async (commentId: number, status: string) => {
    fromReportingTool
      ? await setFacilityReportEntryCommentStatus(commentId, status)
      : await updateAssessmentCommentStatus(commentId, status);

    refreshComment && refreshComment();
  };

  const handleResolveAll = () => {
    assessment &&
      assessment.id &&
      (fromReportingTool
        ? updateAllFacilityReportEntryComment(assessment.id, clickedField.field_code).then(() => {
            refreshComment && refreshComment();
          })
        : updateAllAssessmentCommentStatus(assessment.id, clickedField.field_code).then(() => {
            refreshComment && refreshComment();
          }));
  };

  const handleUpdateComment = async (comment_id: number) => {
    fromReportingTool
      ? await updateFacilityReportEntryComment(comment_id, editedComment)
      : await updateAssessmentComment(comment_id, editedComment);

    setIsEditing(null);
    setEditedComment('');
    refreshComment && refreshComment();
  };

  useEffect(() => {
    if (clickedField) {
      setDisplayedComments(
        comments ? comments.filter((comment) => comment.field_code === clickedField.field_code) : []
      );
    } else {
      setDisplayedComments([]);
    }
  }, [clickedField, comments]);

  useEffect(() => {
    if (comments) {
      setFieldsWithComments(comments.map((comment) => comment.field_code));
    }
  }, [comments]);

  useEffect(() => {
    assessment?.batch_id &&
      getAssessmentBatch(assessment.batch_id).then((batch) => {
        setBatch(batch.data);
      });
  }, [assessment]);

  const renderSectionItem = (field: any, level: number) => {
    if (field.type === 'section') {
      return (
        <Box marginBottom="20px">
          <Typography variant="h5" color={colors.text} fontWeight="bold" marginBottom="10px">
            {`Section ${field.section_number}. ${field.section_name}`}
          </Typography>

          {field.section_description && (
            <Typography
              variant="body1"
              color={colors.text}
              marginBottom="30px"
              style={{ textIndent: '25px', whiteSpace: 'pre-wrap' }}
            >
              {field.section_description}
            </Typography>
          )}

          {field.section_body.map((child: any, index: number) => {
            return <Box key={index}>{renderSectionItem(child, level + 1)}</Box>;
          })}
        </Box>
      );
    }

    if (field.type === 'sub-section') {
      return (
        <Box marginBottom="20px">
          <Typography variant="h6" color={colors.text} fontWeight="bold" marginBottom="10px">
            {`Section ${field.section_number}. ${field.section_name}`}
          </Typography>
          <Box
            style={{
              paddingInline: '10px',
            }}
          >
            {field.section_content.map((child: any, index: number) => {
              return <Box key={index}>{renderSectionItem(child, level + 1)}</Box>;
            })}
          </Box>
        </Box>
      );
    }

    if (field.type === 'parent') {
      return (
        <Box>
          {search && !field.field_name.toLowerCase().includes(search.toLowerCase()) ? (
            <></>
          ) : (
            <Box
              display="grid"
              gridTemplateColumns={field.field_code ? '45px 1fr' : '1fr'}
              sx={{
                borderBottom: '0.5px solid ' + colors.border,
                padding: '5px',
                '&:hover': {
                  background: colors.hoverBackground,
                },
              }}
            >
              {field.field_code && (
                <Typography variant="subtitle1" color={colors.text} fontWeight="600">
                  {getHighlightedText(field.field_code, search)}.
                </Typography>
              )}
              <Typography variant="subtitle1" color={colors.text} fontWeight="600">
                {getHighlightedText(field.field_name, search)}
              </Typography>
            </Box>
          )}

          <Box
            style={{
              paddingLeft: '10px',
              borderLeftColor: 'gray',
              borderLeftWidth: 1.5,
            }}
          >
            {field.children.map((child: any, index: number) => {
              return <Box key={index}>{renderSectionItem(child, level + 1)}</Box>;
            })}
          </Box>
        </Box>
      );
    }

    if (field.type === 'table') {
      return (
        <Box sx={{ paddingInline: '5px' }}>
          <Box display="grid" gridTemplateColumns="45px 1fr">
            <Typography variant="subtitle1" color={colors.text} fontWeight="bold">
              {getHighlightedText(field.field_code, search)}
            </Typography>
            <Typography variant="subtitle1" color={colors.text} fontWeight="bold">
              {getHighlightedText(field.field_name, search)}
            </Typography>
          </Box>
          <Box style={{ marginBottom: '10px', paddingLeft: '20px' }}>
            <AssessmentTable
              isSimpleTable={field.table_type === 'simple-table'}
              headers={field.columns}
              rows={field.rows}
              handleBodyClick={(row) => handleBodyClick(row, field.columns)}
              search={search}
              getHighlightedText={getHighlightedText}
              fieldsWithComments={fieldsWithComments}
              level={level}
            />
          </Box>
        </Box>
      );
    }

    if (field.type === 'field' || field.type === 'conditional-field') {
      if (search && !field.field_name.toLowerCase().includes(search.toLowerCase())) {
        return <></>;
      }
      return (
        <>
          <Box
            onClick={() => handleBodyClick(field)}
            display="grid"
            gridTemplateColumns="70% 1fr"
            columnGap="30px"
            sx={{
              borderBottom: '0.5px solid ' + colors.border,
              padding: '5px',
              '&:hover': { background: colors.hoverBackground },
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="grid" gridTemplateColumns="45px 1fr">
                <Typography variant="body1" color={colors.text}>
                  {getHighlightedText(field.field_code, search)}.
                </Typography>
                <Box>
                  <Typography variant="body1" color={colors.text} style={{ whiteSpace: 'pre-wrap' }}>
                    {getHighlightedText(field.field_name, search)}
                  </Typography>
                  {field.field_description && (
                    <Typography variant="body2" p={1} style={{ whiteSpace: 'pre-wrap' }}>
                      {field.field_description}
                    </Typography>
                  )}
                </Box>
              </Box>
              {field.remarks || fieldsWithComments.includes(field.field_code) ? (
                <ModeCommentOutlinedIcon style={{ color: colors.main }} />
              ) : (
                <></>
              )}
            </Box>
            <Box>
              <Typography
                variant="body1"
                color={colors.text}
                sx={
                  field.response === 'Yes' ||
                  field.response === 'No' ||
                  field.response === 'N/A' ||
                  field.response === 'Not Applicable'
                    ? {
                        width: field.response === 'N/A' || field.response === 'Not Applicable' ? '100px' : '40px',
                        borderRadius: '10px',
                        padding: '2px 10px',
                        textAlign: 'center',
                        fontSize: '11px',
                        backgroundColor:
                          field.response === 'Yes' ? '#6fd8bd' : field.response === 'No' ? '#e64647' : '#898989',
                        color: field.response === 'Yes' ? '#3d3d3d' : 'white',
                      }
                    : undefined
                }
              >
                {field.response === 'N/A' ? 'Not Applicable' : field.response}
              </Typography>
            </Box>
          </Box>
          {field.type === 'conditional-field' ? (
            <>
              {field.children.map((child: any, index: number) => {
                return <Box key={index}>{renderSectionItem(child, level + 1)}</Box>;
              })}
            </>
          ) : (
            <></>
          )}
        </>
      );
    }
  };

  const getResponse = () => {
    console.log(clickedFieldColumns);
    const responses = clickedField.response;
    const finalResponse: any = {};
    // Object.keys((key))
    if (clickedFieldColumns) {
      for (const [key, value] of Object.entries(responses)) {
        const col = clickedFieldColumns.find((col: any) => col.name === key);
        finalResponse[`${clickedField.field_code}-${col?.code} - ${key}`] = value;
      }

      return JSON.stringify(orderResponses(finalResponse), null, '\t');
    }
    return '';
  };

  const orderResponses = (unordered: any) => {
    const ordered = Object.keys(unordered)
      .sort()
      .reduce((obj: any, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});
    return ordered;
  };

  const renderSection = () => {
    return (
      <Box sx={{ width: '100%', maxWidth: 900, margin: '20px auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '35%',
              background: 'transparent',
              border: '1px solid ' + colors.dark,
              borderRadius: '6px',
              height: '40px',
            }}
          >
            <InputBase
              sx={{
                ml: 1.5,
                flex: 1,
                fontSize: '12px',
                textAlign: 'center',
              }}
              placeholder="Input to Highlight a text"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          {!fromPreview && (
            <RegularButton
              label={`Download Section ${section.section_number} Responses`}
              onClick={handleClick}
              disabled={assessment && ['open', 'assigned', 'started'].includes(assessment.status)}
            />
          )}
          <Menu
            id="assessment-details-download-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDownload}>as Excel</MenuItem>
            <MenuItem onClick={handleDownloadPdf}>as PDF</MenuItem>
          </Menu>
        </Box>

        <br />

        <Typography variant="h4" color={colors.text} fontWeight="bold" marginBottom="20px">
          {getHighlightedText(`Section ${section.section_number}. ${section.section_name}`, search)}
        </Typography>

        {section.section_description && (
          <Typography
            variant="body1"
            color={colors.text}
            marginBottom="30px"
            style={{ textIndent: '25px', whiteSpace: 'pre-wrap' }}
          >
            {getHighlightedText(section.section_description, search)}
          </Typography>
        )}

        <Box>
          {section.section_body.map((field: any, index: number) => {
            return <Box key={index}>{renderSectionItem(field, 1)}</Box>;
          })}
        </Box>

        {clickedField && clickedField.field_code && (
          <CustomModal
            open={isModalOpen}
            setOpen={setIsModalOpen}
            header={`Section ${section.section_number}. ${section.section_name}`}
            onClose={() => {
              setClickedField(null);
              setIsEditing(null);
            }}
            width={750}
          >
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="grid" gridTemplateColumns="100px 1fr" width="100%">
                <Typography variant="body1">Field Code</Typography>
                <Typography variant="body1">{clickedField.field_code}</Typography>
                <Typography variant="body1">Field Name</Typography>
                <Typography variant="body1">{clickedField.field_name}</Typography>
                {clickedField.applicable && (
                  <>
                    <Typography variant="body1">Applicable</Typography>
                    <Typography variant="body1">{clickedField.applicable.join(', ')}</Typography>
                  </>
                )}
                {clickedField.not_applicable && (
                  <>
                    <Typography variant="body1">Not Applicable</Typography>
                    <Typography variant="body1">{clickedField.not_applicable.join(', ')}</Typography>
                  </>
                )}
                {clickedField.response &&
                (typeof clickedField.response === 'string' || Object.keys(clickedField.response).length) ? (
                  <>
                    {typeof clickedField.response === 'string' ? (
                      <>
                        <Typography variant="body1">Response</Typography>
                        <Typography variant="body1">{JSON.stringify(clickedField.response, null)}</Typography>
                      </>
                    ) : (
                      <Box sx={{ gridColumn: 'span 2' }}>
                        <CustomTextField
                          label="Response"
                          fieldName="Response"
                          value={getResponse()}
                          multiline
                          rows={Object.keys(clickedField.response).length + 2}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <Typography variant="body1" color="error" sx={{ gridColumn: 'span 2' }}>
                    No Response
                  </Typography>
                )}
                {clickedField.remarks && (
                  <>
                    <Typography
                      variant="body1"
                      style={{ borderTop: `1px solid ${colors.border}`, marginTop: '10px', paddingTop: '10px' }}
                    >
                      FA Remarks
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ borderTop: `1px solid ${colors.border}`, marginTop: '10px', paddingTop: '10px' }}
                    >
                      "{clickedField.remarks}"
                    </Typography>
                  </>
                )}
              </Box>
              {displayedComments.filter((comment) => comment.status === 'open').length > 1 && (
                <Box alignSelf="flex-end">
                  <ProtectedComponent requiredAuth={['REVIEW_FRESAA_QUESTIONNAIRE']}>
                    <RegularButton
                      label="Resolve All"
                      onClick={handleResolveAll}
                      startIcon={<RadioButtonUncheckedOutlinedIcon />}
                      color="primary"
                    />
                  </ProtectedComponent>
                </Box>
              )}

              <Box display="flex" flexDirection="column" gap="15px" mt={2}>
                {displayedComments.map((comment, index) => (
                  <CommentBox key={index}>
                    <Box display="flex" alignItems="flex-start" gap="10px" width="100%">
                      <AccountCircleOutlinedIcon sx={{ fontSize: '30px' }} />
                      <Box width="100%">
                        <Box width="100%" display="flex" justifyContent="space-between" alignItems="flex-start">
                          <Box width="100%">
                            <Box width="100%" display="flex" justifyContent="space-between">
                              <Typography style={{ fontSize: '11px', color: '#888888' }}>
                                {moment(comment.created_at).calendar()} {comment.status === 'resolved' && '- Resolved'}
                              </Typography>
                            </Box>
                            <Typography fontWeight="bold">
                              {comment.user.first_name + ' ' + comment.user.last_name}
                            </Typography>
                          </Box>
                          <ProtectedComponent
                            requiredAuth={[
                              'REVIEW_FRESAA_QUESTIONNAIRE',
                              'ANSWER_FRESAA_QUESTIONNAIRE',
                              'MANAGE_FACILITY',
                            ]}
                          >
                            <RowAction
                              actions={[
                                {
                                  label: comment.status === 'open' ? 'Resolve' : 'Reopen',
                                  action:
                                    comment.status === 'open'
                                      ? () => handleResolve(comment.id, 'resolved')
                                      : () => handleResolve(comment.id, 'open'),
                                  startIcon:
                                    comment.status === 'open' ? (
                                      <CheckCircleOutlinedIcon />
                                    ) : (
                                      <RadioButtonUncheckedOutlinedIcon />
                                    ),
                                  hidden: !user?.authorities?.includes('REVIEW_FRESAA_QUESTIONNAIRE'),
                                },
                                {
                                  label: 'Update',
                                  action: () => {
                                    setIsEditing(comment.id);
                                    setEditedComment(comment.comment);
                                  },
                                  startIcon: <DriveFileRenameOutlineIcon />,
                                  hidden: comment.user_id !== user.id,
                                },

                                {
                                  label: 'Delete',
                                  action: () => {
                                    setSelectedCommentId(comment.id);
                                    setOpenDeleteConfirmation(true);
                                  },
                                  startIcon: <DeleteOutlinedIcon />,
                                  hidden: comment.user_id !== user.id,
                                },
                              ]}
                            />
                          </ProtectedComponent>
                        </Box>
                        {isEditing === comment.id ? (
                          <Box display="flex" flexDirection="row" alignItems="center" gap="15px" mt={1}>
                            <InputBase
                              value={editedComment}
                              onChange={(e) => setEditedComment(e.target.value)}
                              placeholder="Update comment"
                              fullWidth
                              sx={{
                                p: 2,
                                backgroundColor: colors.background,
                                color: theme.palette.text.primary,
                                borderRadius: '5px',
                              }}
                            />
                            <RegularButton label="Update" onClick={() => handleUpdateComment(comment.id)} />
                          </Box>
                        ) : (
                          <Typography>{comment.comment}</Typography>
                        )}
                      </Box>
                    </Box>
                  </CommentBox>
                ))}
              </Box>

              <ProtectedComponent
                requiredAuth={['REVIEW_FRESAA_QUESTIONNAIRE', 'ANSWER_FRESAA_QUESTIONNAIRE', 'MANAGE_FACILITY']}
              >
                <InputBase
                  multiline
                  rows={4}
                  value={commentValue}
                  onChange={(e) => setCommentValue(e.target.value)}
                  placeholder="Add a comment"
                  fullWidth
                  sx={{
                    p: 2,
                    backgroundColor: colors.background,
                    color: theme.palette.text.primary,
                    borderRadius: '5px',
                  }}
                />

                <Box display="flex" justifyContent="flex-end">
                  <RegularButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitComment()}
                    disabled={!commentValue.trim()}
                    loading={loading}
                    label="Comment"
                  />
                </Box>
              </ProtectedComponent>
            </Box>
          </CustomModal>
        )}
        <ConfirmationDialog
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          message="Are you sure you want to delete this comment?"
          onConfirm={handleDeleteConfirm}
          cancelButtonLabel="No"
          confirmButtonLabel="Yes"
        />
      </Box>
    );
  };
  return (
    <CustomContainer>
      {/* <Box height="calc(100vh - 170px)" width="100%" display="flex" overflow="auto"> */}
      <Box width="100%" display="flex">
        {renderSection()}
      </Box>
    </CustomContainer>
  );
};

export default AssessmentSection;

const CommentBox = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
}));
