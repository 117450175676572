/* eslint-disable react-hooks/exhaustive-deps */

import * as XLSX from 'xlsx';

import { Box, Chip, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import {
  ConditionalHideComponent,
  ConfirmationDialog,
  CustomIconButton,
  Dropdown,
  ProtectedComponent,
  RegularButton,
} from 'components';
import { FacilityAssessmentModel, UserModel } from 'model/Entities';
import { approveAssessment, getFacilityAssessment, updateAssessor } from 'api/assessment';
import { displayName, formatDateTime } from 'utils';
import { useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadIcon from '@mui/icons-material/Download';
import { DropdownOptionProps } from 'components/Dropdown';
import { Link } from 'react-router-dom';
import LoadingScreen from 'screens/Helper/loading';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { UserContext } from 'context/user.context';
import { exportAsPDF } from 'utils/pdf_generator';
import { flatSections } from 'components/IndicatorViewer';
import { getFieldAssessors } from 'api/user';
import saveAs from 'file-saver';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

type FacilityAssessmentDetailsProps = {
  selectedFacility?: FacilityAssessmentModel;
  handleClose: () => void;
  refreshTable: () => void;
};

const FacilityAssessmentDetails: React.FC<FacilityAssessmentDetailsProps> = ({
  selectedFacility,
  handleClose,
  refreshTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [assessorOptions, setAssessorOptions] = useState<DropdownOptionProps[]>([]);
  const [assessor, setAssessor] = useState('');
  const [openConfirmApproveModal, setOpenConfirmApproveModal] = useState(false);
  const [status, setStatus] = useState<string>('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUnclick = () => {
    setAnchorEl(null);
  };

  const getAssessors = () => {
    if (selectedFacility) {
      setAssessorOptions([{ label: 'No available user' }]);
      let usergroup = JSON.stringify(['FA']);

      getFieldAssessors(usergroup, selectedFacility.facility_id).then((res) => {
        if (res.data.length) {
          setAssessorOptions(
            res.data.map((assessor: UserModel) => {
              return {
                label: `${assessor.first_name} ${assessor.last_name} (${assessor.username})`,
                action: () => {
                  if (selectedFacility.assessor_id !== assessor.id) {
                    updateAssessor(selectedFacility.id!, assessor.id!).then(() => {
                      setAssessor(assessor.first_name + ' ' + assessor.last_name);
                      refreshTable();
                    });
                  }
                },
              };
            })
          );
        }
      });
    }
  };

  const updatePeople = () => {
    if (selectedFacility?.assessor) {
      setAssessor(selectedFacility.assessor.first_name + ' ' + selectedFacility.assessor.last_name);
    } else {
      setAssessor('Unassigned');
    }
  };

  const handleApprove = async (id: number) => {
    try {
      const res = await approveAssessment(id);
      enqueueSnackbar('Assessment successfully approved!', {
        variant: 'success',
      });
      refreshTable();
      if (res.data) {
        setStatus(res.data.status);
      }
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, {
        variant: 'error',
      });
    }
  };

  const handleDownload = async (id: number) => {
    setDownloadLoading(true);
    getFacilityAssessment(id)
      .then((res) => {
        const responses = JSON.parse(res.data.responses).flatMap((section: any) => {
          return section.section_body.flatMap((body: any) => {
            return flatSections(body);
          });
        });
        exportToExcel(responses, 'responses');
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const handleDownloadPdf = async (id: number) => {
    setDownloadLoading(true);

    getFacilityAssessment(id)
      .then((res) => {
        let responses = JSON.parse(res.data.responses).flatMap((section: any) => {
          return section.section_body.flatMap((body: any) => {
            return flatSections(body);
          });
        });

        exportAsPDF(responses, selectedFacility?.assessment_batch.batch_name ?? '', selectedFacility?.facility.facility_name!);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const exportToExcel = (exportRes: any, fileName: string) => {
    const worksheet = XLSX.utils.json_to_sheet(exportRes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, fileName + '.xlsx');
  };

  useEffect(() => {
    if (selectedFacility) {
      getAssessors();
      updatePeople();
      setStatus(selectedFacility?.status);
    }
  }, [selectedFacility]);

  let chipColor = '';
  let statusLabel = selectedFacility?.status;

  switch (status) {
    case 'assigned':
      chipColor = '#a86e05';
      statusLabel = 'Assigned';
      break;
    case 'open':
      chipColor = colors.text;
      statusLabel = 'Open';
      break;
    case 'started':
      chipColor = '#f86b02';
      statusLabel = 'Started';
      break;
    case 'submitted':
      chipColor = '#b7a300';
      statusLabel = 'Submitted';
      break;
    case 'ongoing-approval':
      chipColor = '#0393d3';
      statusLabel = 'Ongoing Approval';
      break;
    case 'pho-approved':
      chipColor = '#419b44';
      statusLabel = 'PHO Approved';
      break;
    case 'completed':
      chipColor = '#419b44';
      statusLabel = 'Completed';
      break;
    default:
      chipColor = 'default';
      break;
  }

  return (
    <>
      {selectedFacility && (
        <Box>
          <Box sx={{ backgroundColor: colors.secondary_background }} p="25px" borderRadius={2}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Box display="flex">
                <Typography
                  variant="h4"
                  component="span"
                  color={colors.accent}
                  fontWeight="bold"
                  sx={{ marginRight: '10px' }}
                >
                  {selectedFacility.facility.facility_name}
                </Typography>
                <Chip
                  label={statusLabel}
                  size="small"
                  variant="outlined"
                  style={{ borderColor: chipColor, color: chipColor }}
                />
              </Box>
              <CustomIconButton icon={<CloseIcon />} onClick={handleClose} />
            </Box>
            <Typography variant="h6" color={colors.text} sx={{ gridColumn: 'span 2' }}>
              {selectedFacility.facility.facility_type.type}
            </Typography>
            <Typography variant="subtitle1" color={colors.text}>
              {selectedFacility.facility.barangay_name}
              {', '}
              {selectedFacility.facility.municipality.municipality_name}
              {', '}
              {selectedFacility.facility.province.province_name}
            </Typography>

              <Box display="grid" gridTemplateColumns="150px 1fr" columnGap="10px" alignItems="center">
                <Typography variant="h6" color={colors.text} fontWeight="bold" mt="20px">
                  Assessment
                </Typography>
                <Typography variant="body1" color={colors.text} mt="20px">
                  {selectedFacility.assessment_batch.batch_name} - {selectedFacility.assessment_indicator.name}
                </Typography>
                <Typography variant="h6" color={colors.text} fontWeight="bold" sx={{ gridColumn: 'span 2' }} mt="20px">
                  People
                </Typography>

                <Typography variant="body1" color={colors.text}>
                  Assessor
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body1" color={colors.text}>
                    {assessor ? assessor : 'Unassigned'}
                  </Typography>
                  <>
                    {(selectedFacility.status === 'open' || selectedFacility.status === 'assigned') && (
                      <ProtectedComponent requiredAuth={['MANAGE_FRESAA_QUESTIONNAIRE']}>
                        <Dropdown
                          isIcon
                          optionList={assessorOptions}
                          icon={<MoreHorizIcon />}
                          tooltip="Update Assessor"
                        />
                      </ProtectedComponent>
                    )}
                  </>
                </Box>
                <Typography variant="body1" color={colors.text}>
                  Approver
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {displayName(selectedFacility.approver)}
                </Typography>
                {/*<Typography variant="body1" color={colors.text}>
                PHO Approver
              </Typography>
              <Typography variant="body1" color={colors.text}>
                {displayName(selectedFacility.approver)}
              </Typography> */}

                <Typography variant="h6" color={colors.text} fontWeight="bold" sx={{ gridColumn: 'span 2' }} mt="25px">
                  Dates
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  Started
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {formatDateTime(selectedFacility.started_at)}
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  Submitted
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {formatDateTime(selectedFacility.submitted_at)}
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  Approved
                </Typography>
                <Typography variant="body1" color={colors.text}>
                  {formatDateTime(selectedFacility.approved_at)}
                </Typography>
              </Box>

              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="10px" marginTop="20px">
                <Link to={'' + selectedFacility.id} style={{ textDecoration: 'none' }}>
                  <RegularButton
                    label="View Details"
                    onClick={() => undefined}
                    styles={{ width: '100%' }}
                    startIcon={<RemoveRedEyeIcon />}
                  />
                </Link>

              <RegularButton
                loading={downloadLoading}
                label="Download Responses"
                onClick={handleClick}
                styles={{ width: '100%' }}
                disabled={['open', 'assigned', 'started'].includes(status)}
                startIcon={downloadLoading ? <></> : <DownloadIcon />}
              />
              <Menu
                id="add-widget-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleUnclick}
              >
                <MenuItem onClick={() => handleDownload(selectedFacility.id!)}>as Excel</MenuItem>
                <MenuItem onClick={() => handleDownloadPdf(selectedFacility.id!)}>as PDF</MenuItem>
              </Menu>

                <ConditionalHideComponent hide={!user.user_group.validator}>
                  <RegularButton
                    label="Approve"
                    onClick={() => setOpenConfirmApproveModal(true)}
                    disabled={
                      ['open', 'assigned', 'started', 'completed'].includes(status) ||
                      selectedFacility.current_step !== user?.user_group.group_name
                    }
                    startIcon={<DoneAllIcon />}
                  />
                </ConditionalHideComponent>

                <ConfirmationDialog
                  message={'Approve this assessment?'}
                  open={openConfirmApproveModal}
                  setOpen={setOpenConfirmApproveModal}
                  onConfirm={() => {
                    handleApprove(selectedFacility.id!);
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
    </>
  );
};

export default FacilityAssessmentDetails;
