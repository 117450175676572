import 'styles/Login.css';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { InputAdornment } from '@mui/material';
import { isFresaarProject } from 'utils';
import { login } from 'api/user';
import styled from 'styled-components';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const validateForm = () => {
    if (!username) {
      setError('Username is required.');
      return false;
    }

    if (!password) {
      setError('Password is required.');
      return false;
    }

    return true;
  };

  const loginUser = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);
      const response = await login({ username, password });
      if (response.data.accessToken) {
        localStorage.setItem('access_token', response.data.accessToken);
        localStorage.setItem('refresh_token', response.data.refreshToken);
        if (locationState) {
          const { redirectTo } = locationState as any;
          navigate(`${redirectTo.pathname}${redirectTo.search}`);
        } else {
          const filteredArray = [
            'VIEW_REGIONAL_DASHBOARD',
            'VIEW_PROVINCIAL_DASHBOARD',
            'VIEW_NATIONAL_DASHBOARD',
            'VIEW_MUNICIPAL_DASHBOARD',
          ].filter((value) => response.data.authorities.includes(value));

          if (filteredArray.length) navigate('/');
          else if (response.data.authorities.includes('MANAGE_ALL_ACCOUNTS')) navigate('/user-management');
          else if (response.data.authorities.includes('MANAGE_FACILITY')) navigate('/facility-reports');
          else navigate('/monitor');
        }
      } else {
        setError('Login Failed');
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        setError(error.response.data.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate('/');
    }

    if (locationState) {
      const { redirectTo } = locationState as any;
      if (redirectTo) {
        setError('User is not signed in.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="full-screen-container">
        <div className={'login-container' + (isFresaarProject() ? ' fresaar' : '')}>
          {isFresaarProject() ? (
            <div className="logo-container">
              <img alt="LOGO" width="100%" src={`../../assets/fresaar-logo.png`} />
            </div>
          ) : (
            <div className="logo-container">
              <img alt="LOGO" width="100%" src={`../../assets/fresaa-logo.png`} />
            </div>
          )}
          <div className="login-title">
            <strong>{isFresaarProject() ? 'FRESAAR' : 'FRESAA'}</strong>
            <p>
              Facility Readiness and Essential Service Availability Assessment
              {isFresaarProject() ? ' and Reporting Tool' : ''}
            </p>
          </div>

          <div className="error-msg">
            <span> {error} </span>
          </div>

          <div className="login-form">
            <div className="input-group">
              <input
                required
                type="username"
                name="username"
                className="input"
                id="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <label className="user-label">Username</label>
            </div>
            <div className="input-group">
              <PasswordInput
                required
                type={showPassword ? 'text' : 'password'}
                name="password"
                className="input"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className="user-label">Password</label>
              <InputAdornment position="end">
                <PasswordIcon onClick={togglePasswordVisibility}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </PasswordIcon>
              </InputAdornment>
            </div>

            <button className="login-button" type="submit" onClick={loginUser}>
              <span> {loading ? 'Logging in ...' : 'Login'} </span>
            </button>
          </div>
          <div className="cphi-container">
            <p>Developed by</p>
            <img alt="CPHI-LOGO" className="cphi-logo" src={`../../assets/cphi_logo.png`} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
const PasswordInput = styled.input`
  position: relative;
`;

const PasswordIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
`;
