import ArticleContent, { imagePath } from '../ArticleContent';

const UpdateUserArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            {
              step: '1. Select action on the right side of the user, then select "Edit" option.',
              image: `${imagePath}/update-user-1.png`,
            },
            {
              step: '2. Edit the form.',
            },
            {
              step: '3. Click Submit.',
              image: `${imagePath}/update-user-2.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default UpdateUserArticle;
