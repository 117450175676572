/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput, SelectOption } from 'model/interface';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  FacilityLocationFilter,
  PrimaryButton,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'components';
import { Municipality, Province, Region, UserGroupModel, UserModel } from 'model/Entities';
import { changeNullToBlank, tranformFormErrors } from 'utils';
import { deleteUser, getAccounts, registerUser, updateUser } from 'api/user';
import { getAvailableMunicipality, getAvailableProvince, getAvailableRegion } from 'api/facility';
import {
  provincial_create_user_schema,
  provincial_update_user_schema,
  regional_create_user_schema,
  regional_update_user_schema,
} from 'model/schema';
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import Header from 'components/Header';
import { UserContext } from 'context/user.context';
import UserGroupValidators from './UserGroupValidators';
import { getAllUserGroups } from 'api/user-group';
import { useSnackbar } from 'notistack';

export type UserInput = {
  username: string;
  password: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  title: string;
  user_group_name: string;
  email: string;
  mobile_number: string;
  region_code: string;
  province_code: string;
  municipality_code: string;
  facility_id?: string;
};

export interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  username: '',
  password: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  title: '',
  user_group_name: '',
  email: '',
  mobile_number: '',
  region_code: '',
  province_code: '',
  municipality_code: '',
  facility_id: '',
};

const initialHiddentFields = {
  region_code: true,
  province_code: true,
  municipality_code: true,
  username: false,
  password: false,
  user_group_name: false,
};

const UserManagement = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState<UserModel[]>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [userSchema, setUserSchema] = useState<any>(provincial_create_user_schema);
  const [userModalHeader, setUserModalHeader] = useState('Create User');
  const [userInitialValues, setUserInitialValues] = useState<UserInput>(initialValues);

  const [userGroupsOptions, setUserGroupsOptions] = useState<SelectOption[]>([]);
  const [userGroups, setUserGroups] = useState<UserGroupModel[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);
  const [hiddenFields, setHiddenFields] = useState(initialHiddentFields);
  const [otherFilters, setOtherFilters] = useState<any>({});

  const [selectedUser, setSelectedUser] = useState<any>({}); //didnt use the setSelectedUser because it doesnt have API to call for the mean time
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  // Actions
  const editAction = (data: any) => {
    showUserModal(true, data);
  };

  const deleteAction = (data: any) => {
    setSelectedUser(data);
    setOpenDeleteConfirmation(true);
  };

  const handleDelete = (user: any) => {
    deleteUser(user.id)
      .then((res: any) => {
        enqueueSnackbar(`User successfully deleted!`, {
          variant: 'success',
        });
        refreshTable();
      })
      .catch((error) => {
        enqueueSnackbar('Cannot delete user.', {
          variant: 'error',
        });
      });
  };

  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
  };

  const actions = [
    {
      label: 'Edit',
      action: editAction,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete',
      action: deleteAction,
      startIcon: <DeleteOutlinedIcon />,
    },
  ];
  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'username',
      sortable: false,
      headerName: 'Username',
      flex: 1.5,
    },
    {
      field: 'first_name',
      sortable: false,
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.title} {params.row.first_name} {params.row.middle_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'user_group_id',
      sortable: false,
      headerName: 'User Group',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{params.row.user_group.description}</CustomGridCell>,
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {[
            params.row.facility?.facility_name,
            params.row.municipality?.municipality_name,
            params.row.province?.province_name,
            !user.region_code ? params.row.region?.region_name : '',
          ]
            .filter((item) => !!item)
            .join(', ')}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        user?.user_group.group_name === 'RMTM' &&
        params.row.user_group.description === 'Regional Monitoring Team Leader' ? (
          <></>
        ) : (
          <RowAction actions={actions} data={params.row} />
        ),
    },
  ];

  // Form Fields
  const userFields: UserFieldInput[] = [
    {
      field_name: 'username',
      display_name: 'Username',
      type: 'text',
      hidden: hiddenFields.username,
    },
    {
      field_name: 'password',
      display_name: 'Password',
      type: 'text',
      hidden: hiddenFields.password,
    },
    {
      field_name: 'title',
      display_name: 'Title (e.g. Dr.)',
      type: 'text',
    },
    {
      field_name: 'first_name',
      display_name: 'First Name',
      type: 'text',
    },
    {
      field_name: 'middle_name',
      display_name: 'Middle Name',
      type: 'text',
    },
    {
      field_name: 'last_name',
      display_name: 'Last Name',
      type: 'text',
    },
    {
      field_name: 'mobile_number',
      display_name: 'Mobile Number',
      type: 'text',
    },
    {
      field_name: 'email',
      display_name: 'Email',
      type: 'email',
    },
    {
      field_name: 'user_group_name',
      display_name: 'User Group',
      type: 'select',
      hidden: hiddenFields.user_group_name,
      options: userGroupsOptions,
      onChange: (value, setFieldValue) => {
        const selectedGroup = userGroups.find((group) => group.group_name === value);
        setHiddenFields({
          ...hiddenFields,
          region_code: !user.national_account || selectedGroup!.level === 'National',
          province_code: ['National', 'Regional'].includes(selectedGroup!.level),
          municipality_code: selectedGroup!.level !== 'Municipal',
        });
        if (setFieldValue) {
          setFieldValue('province_code', '');
          setFieldValue('municipality_code', '');
        }
      },
    },
    {
      field_name: 'region_code',
      display_name: 'Region',
      type: 'select',
      options: regions.map((r) => {
        return { key: r.region_name, value: r.region_code };
      }),
      hidden: hiddenFields.region_code,
      onChange: (value, setFieldValue) => {
        getProvince(value);
        setMunicipalities([]);
        if (setFieldValue) {
          setFieldValue('province_code', '');
          setFieldValue('municipality_code', '');
        }
      },
    },
    {
      field_name: 'province_code',
      display_name: 'Province',
      type: 'select',
      options: provinces.map((p) => {
        return { key: p.province_name, value: p.province_code };
      }),
      hidden: hiddenFields.province_code,
      onChange: (value, setFieldValue) => {
        getMunicipality(value);
        setFieldValue && setFieldValue('municipality_code', '');
      },
    },
    {
      field_name: 'municipality_code',
      display_name: 'Municipality',
      type: 'select',
      hidden: hiddenFields.municipality_code,
      options: municipalities.map((p) => {
        return { key: p.municipality_name, value: p.municipality_code };
      }),
    },
  ];

  // Functions
  const getUserAccounts = (query?: ApiQuery) => {
    setLoading(true);
    getAccounts({ ...query })
      .then((res) => {
        setRows(res.data.rows);
        setUserCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getRegions = async () => {
    try {
      const res = await getAvailableRegion();
      setRegions(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getProvince = async (region_code: string) => {
    try {
      const dataProvince = await getAvailableProvince(region_code);
      setProvinces(dataProvince.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getMunicipality = async (province_code: string) => {
    try {
      if (province_code) {
        const dataProvince = await getAvailableMunicipality({
          province_code: province_code,
        });
        setMunicipalities(dataProvince.data);
      } else {
        setMunicipalities([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true); //when button submit is clicked turns on the loading animation
    if (data.id) {
      updateUser(data.id, data)
        .then((res: any) => {
          setButtonLoading(false); //Whenever Update update user is a success, set the loading to false
          successFormSubmit(true, formikHelpers);
        })
        .catch((error) => {
          setButtonLoading(false); //whwnever there is an errpr, set the loading to false
          formikHelpers.setErrors(tranformFormErrors(error.response.data));
        });
      return;
    }

    registerUser(data)
      .then((res: any) => {
        setButtonLoading(false);
        successFormSubmit(false, formikHelpers);
      })
      .catch((error) => {
        setButtonLoading(false);
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      });
  };

  const successFormSubmit = (from_edit: boolean, { resetForm, setErrors }: FormikHelpers<any>) => {
    enqueueSnackbar(`User successfully ${from_edit ? 'updated' : 'created'}!`, {
      variant: 'success',
    });
    if (from_edit) {
      setOpenUserModal(false);
    } else {
      resetForm();
    }

    refreshTable();
  };

  const showUserModal = (from_edit?: boolean, data?: UserModel) => {
    updateSchema(from_edit);

    if (from_edit && data) {
      if (data.user_group.level !== 'Regional' && !user.region_code) {
        getProvince(data.region_code);
      }

      if (data.user_group.level === 'Municipal') {
        getMunicipality(data.province_code);
      }

      setHiddenFields({
        ...hiddenFields,
        region_code: true,
        municipality_code: data.user_group.level !== 'Municipal',
        province_code: data.user_group.level === 'Regional' || data.user_group.level === 'Facility',
        username: true,
        user_group_name: true,
      });

      const userData: any = changeNullToBlank(data);
      setUserInitialValues({
        ...userData,
        user_group_name: userData.user_group.group_name,
      });
      setUserModalHeader('Update User');
    } else {
      setUserModalHeader('Create User');
      setUserInitialValues(initialValues);
      setHiddenFields(initialHiddentFields);
    }

    setOpenUserModal(true);
  };

  const updateSchema = (edit?: boolean) => {
    if (user.provincial_account) {
      edit ? setUserSchema(provincial_update_user_schema) : setUserSchema(provincial_create_user_schema);
    } else {
      edit ? setUserSchema(regional_update_user_schema) : setUserSchema(regional_create_user_schema);
    }
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'User Management' }]);
  }, []);

  useEffect(() => {
    if (user) {
      if (user.region_code) {
        getProvince(user.region_code);
      } else {
        getRegions();
        setHiddenFields({
          ...initialHiddentFields,
          region_code: false,
        });
      }

      getAllUserGroups().then((res) => {
        const groups = res.data.rows.filter((group: UserGroupModel) => group.level !== 'Facility');
        setUserGroups(groups);

        if (user.provincial_account) {
          const provincialUserGroups = groups
            .filter((group: UserGroupModel) => ['Facility', 'Municipal', 'Provincial'].includes(group.level))
            .map((userGroup: UserGroupModel) => ({
              value: userGroup.group_name,
              key: userGroup.description,
            }));
          setUserGroupsOptions(provincialUserGroups);
          getMunicipality(user.province_code);
        } else {
          const userGroups = groups.map((userGroup: UserGroupModel) => ({
            value: userGroup.group_name,
            key: userGroup.description,
          }));

          setUserGroupsOptions(userGroups);
        }
      });
    }
  }, [user]);

  return (
    <>
      <Box>
        <Header title="User Management" />
        <CustomContainer>
          <CustomTable
            columns={columns}
            rows={rows}
            rowCount={userCount}
            loading={loading}
            getData={getUserAccounts}
            forceRefresh={refresh}
            otherFilters={otherFilters}
            searchKeys="Name and Username"
            filterComponent={
              <Box display="flex" gap="10px" width="100%" alignItems="center">
                <FacilityLocationFilter
                  hideFacilityType
                  showFacilityDropdown
                  handleChangeFilter={(data) => {
                    setOtherFilters({ ...otherFilters, ...data });
                  }}
                  otherFilters={otherFilters}
                />
                <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
              </Box>
            }
            headerComponent={<PrimaryButton onClick={() => showUserModal()} label="Create User" />}
          />
        </CustomContainer>
        <CustomModal header={userModalHeader} open={openUserModal} setOpen={setOpenUserModal}>
          <CustomForm
            initialValues={userInitialValues}
            onSubmit={handleSubmit}
            fields={userFields}
            schema={userSchema}
            loading={buttonLoading}
          />
        </CustomModal>
        <ConfirmationDialog
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          message="Are you sure want to remove this user?"
          onConfirm={() => handleDelete(selectedUser)}
        />
      </Box>
      <ProtectedComponent requiredAuth={['MANAGE_NATIONAL_REGIONAL_ACCOUNT']}>
        <UserGroupValidators />
      </ProtectedComponent>
    </>
  );
};

export default UserManagement;
