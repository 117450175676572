import * as React from 'react';

import { Tab, Tabs, useTheme } from '@mui/material';

import TabPanel from './CustomTabPanels';
import { tokens } from 'context/theme.context';
import { useEffect } from 'react';

export type TabProps = {
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  label: string;
  content?: JSX.Element | any;
};

type CustomTabsProps = {
  tabs: TabProps[];
  selected?: number;
  setSelected?: (newValue: number) => void;
  tabBackgroundColor?: any;
  tabMinWidth?: string;
};

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabs,
  selected,
  setSelected,
  tabBackgroundColor,
  tabMinWidth = '200px',
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [value, setValue] = React.useState<number>(selected ?? 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelected && setSelected(newValue);
  };

  useEffect(() => {
    if (tabs.length && value >= tabs.length) setValue(0);
  }, [value, tabs]);

  return (
    <>
      {tabs.length > 1 && (
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          hidden={tabs.length === 1}
          sx={{
            marginBottom: '20px',
            backgroundColor: tabBackgroundColor ?? colors.secondary_background,
            borderRadius: '8px',
            '& .MuiButtonBase-root.MuiTab-root': {
              minHeight: '50px',
              minWidth: tabMinWidth,
              fontSize: '14px',
              textTransform: 'none',
              justifyContent: 'left',
            },
            '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
              color: colors.accent,
            },
            '& .MuiButtonBase-root.MuiTab-root:not(:last-child)::before': {
              content: `""`,
              display: 'block',
              position: 'absolute',
              right: '0',
              height: '50%',
              top: '25%',
              borderRight: '2px solid ' + colors.light,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: colors.accent,
            },
            '& .MuiTabScrollButton-root': {
              backgroundColor: colors.primary,
            },
            '& .MuiTabScrollButton-root.Mui-disabled': {
              opacity: 0.5,
              color: colors.primary,
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} icon={tab.icon} label={tab.label} iconPosition="start" />
          ))}
        </Tabs>
      )}
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};

export default CustomTabs;
