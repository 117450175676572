import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { ChangeEvent } from 'react';

type CustomCheckboxProps = {
  label: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  disabled?: boolean;
  value?: boolean;
  fieldName: string;
  span?: number;
  handleChangeCallback?: (value: boolean) => void;
};

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  handleChange,
  disabled,
  value,
  fieldName,
  span,
  handleChangeCallback,
}) => {
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleChange && handleChange(event);
    handleChangeCallback && handleChangeCallback(checked);
  };

  return (
    <FormGroup
      style={{
        gridColumn: span ? 'span ' + span : undefined,
        paddingTop: '1rem',
      }}
    >
      <FormControlLabel
        sx={{ '& .MuiCheckbox-root': { padding: '0px 9px' } }}
        control={<Checkbox disabled={disabled} checked={value} onChange={handleCheckboxChange} name={fieldName} />}
        label={label}
      />
    </FormGroup>
  );
};

export default CustomCheckbox;
