import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Dropdown } from 'components';
import { FIELD_TYPES } from '../fields/FieldGroups';

type AddFieldDropdownProps = {
  addField: (field_type: FIELD_TYPES) => void;
};

const AddFieldDropdown: React.FC<AddFieldDropdownProps> = ({ addField }) => {
  const handleAddField = (field_type: FIELD_TYPES) => {
    addField(field_type);
  };

  return (
    <Dropdown
      isIcon
      icon={<AddBoxOutlinedIcon color="primary" />}
      tooltip="Add field below"
      tooltipPlacement="top"
      optionList={[
        {
          label: 'Text',
          action: () => handleAddField(FIELD_TYPES.TEXT),
        },
        {
          label: 'Numeric',
          action: () => handleAddField(FIELD_TYPES.NUMERIC),
        },
        {
          label: 'Parent',
          action: () => handleAddField(FIELD_TYPES.PARENT),
        },
        {
          label: 'Radio',
          action: () => handleAddField(FIELD_TYPES.RADIO),
        },
        {
          label: 'Yes or No',
          action: () => handleAddField(FIELD_TYPES.YES_OR_NO),
        },
        {
          label: 'Table',
          action: () => handleAddField(FIELD_TYPES.TABLE),
        },
      ]}
    />
  );
};

export default AddFieldDropdown;
