import ArticleContent, { imagePath } from '../ArticleContent';

const UpdateFacilityArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click the "Update Facility".', image: `${imagePath}/update-facility-1.png` },
            { step: '2. Edit form.' },
            { step: '3. Click "Submit".', image: `${imagePath}/update-facility-2.png` },
          ],
        },
      ]}
    />
  );
};

export default UpdateFacilityArticle;
