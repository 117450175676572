/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Chip, useTheme } from '@mui/material';
import {
  CounterBox,
  CustomContainer,
  CustomDrawer,
  CustomGridCell,
  CustomSwitch,
  DropdownFilter,
  FacilityLocationFilter,
  Header,
  RegularButton,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { batchApproveAssessments, getActiveAssessments, getActiveAssessmentsByStatus } from 'api/assessment';
import { getStatusChip, isFresaarProject } from 'utils';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FacilityAssessmentDetails from './AssessmentDetails/FacilityAssessmentDetails';
import { FacilityAssessmentModel } from 'model/Entities';
import FacilityReportEntries from 'screens/AssessmentFiles/FacilityReportEntries';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Link } from 'react-router-dom';
import { UserContext } from 'context/user.context';
import { getActiveLink } from 'api/mobile-link';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

const MonitorScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<FacilityAssessmentModel[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [viewForMyApproval, setViewForMyApproval] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<FacilityAssessmentModel>();
  const [clearSelected, setClearSelected] = useState(0);
  const [downloadLink, setDownloadLnk] = useState('.');

  const [otherFilters, setOtherFilters] = useState<any>({});
  const [statData, setStatData] = useState({
    started: 0,
    submitted: 0,
    ongoing_approval: 0,
    completed: 0,
  });
  const [statusFilter, setStatusFilter] = useState('');
  const [refresh, setRefresh] = useState(0);

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'facility_id',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          <Link
            to={`${params.row.id}`}
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
          >
            {params.row.facility?.facility_name}
          </Link>
        </CustomGridCell>
      ),
      // renderCell: (params) => <CustomGridCell>{params.row.facility.facility_name}</CustomGridCell>,
    },
    {
      field: 'facility_type',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility.facility_type.type}
          {params.row.facility.with_sbf && (
            <Chip label="with SBF" size="small" variant="outlined" sx={{ marginLeft: '5px', fontSize: '10px' }} />
          )}
        </CustomGridCell>
      ),
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility.municipality.municipality_name}
          {', '}
          {params.row.facility.province.province_name}
          {', '}
          {params.row.facility.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'assessor_id',
      sortable: false,
      headerName: 'Assessor',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.assessor?.title} {params.row.assessor?.first_name} {params.row.assessor?.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'batch_id',
      sortable: false,
      headerName: 'Batch',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.assessment_batch?.batch_name} - {params.row.assessment_indicator?.name}
        </CustomGridCell>
      ),
    },
    {
      field: 'current_step',
      sortable: false,
      headerName: 'Pending Approval',
      flex: 1,
    },
  ];

  // Functions
  const getAssessments = async (query?: ApiQuery) => {
    try {
      setLoading(true);
      const res = await getActiveAssessments({ page: 1, length: 10, ...query });
      setRows(res.data.rows);
      setRowCount(res.data.count);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectRow = (selected: GridSelectionModel) => {
    if (viewForMyApproval) {
      setSelectedRows(selected);
    }

    if (
      (viewForMyApproval && selected.length === 1) ||
      (!viewForMyApproval && selected.length && selectedRow !== selected[0])
    ) {
      const selectedID = selected[0] as number;
      setSelectedRow(selectedID);
      setSelectedFacility(rows.find((row) => row.id === selectedID));
    } else {
      setSelectedRow(undefined);
      setSelectedFacility(undefined);
    }
  };

  const closeFacilityDetails = () => {
    setSelectedRow(undefined);
    setSelectedFacility(undefined);
    setClearSelected((prev: number) => prev + 1);
  };

  const getAssessmentsByStatus = (query: ApiQuery) => {
    setLoading(true);
    getActiveAssessmentsByStatus(query)
      .then((res) => {
        setStatData(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const retrieveDownloadLink = async () => {
    const downloadLink = await getActiveLink();
    setDownloadLnk(downloadLink.data.link);
  };

  const handleApproveSelected = () => {
    console.log(selectedRows);
    batchApproveAssessments(selectedRows)
      .then((res: any) => {
        enqueueSnackbar('Assessments successfully approved!', {
          variant: 'success',
        });
        refreshTable();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
        });
      });

    return;
  };

  useEffect(() => {
    getAssessmentsByStatus(otherFilters);
  }, [otherFilters]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Monitor' }]);
    retrieveDownloadLink();
  }, []);

  const clearFilter = () => {
    //set all values to empty string
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
    setStatusFilter('');
  };

  return (
    <Box>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title={`Monitor Active Assessments`} subtitle={'Click the row to see more details.'} mb="0" />
          <Link style={{ textDecoration: 'none' }} to={downloadLink}>
            <RegularButton
              onClick={() => undefined}
              label={'Mobile App Download Link'}
              startIcon={<FileDownloadOutlinedIcon />}
            />
          </Link>
        </Box>
      </Box>

      <Box marginY="20px" display="grid" columnGap="20px" gridTemplateColumns="repeat(4, 1fr)">
        <CounterBox count={statData.started} total={rowCount} title="Started" />
        <CounterBox count={statData.submitted} total={rowCount} title="Submitted" />
        <CounterBox count={statData.ongoing_approval} total={rowCount} title="Ongoing Approval" />
        <CounterBox count={statData.completed} total={rowCount} title="Completed" />
      </Box>

      <CustomDrawer
        gridTemplateColumns="1fr 400px"
        showChild={!!selectedFacility}
        parentComponent={
          <CustomContainer>
            <CustomTable
              searchKeys="Facility Name"
              columns={selectedFacility ? columns.filter((col, index) => index < 4) : columns}
              rows={rows}
              rowCount={rowCount}
              loading={loading}
              getData={getAssessments}
              isRowSelectable
              handleSelectRow={handleSelectRow}
              clearSelected={clearSelected}
              otherFilters={otherFilters}
              forceRefresh={refresh}
              hideSearch={!!selectedFacility}
              checkboxSelection={viewForMyApproval}
              headerComponent={
                <Box>
                  <Header
                    title={`Facility Assessments${isFresaarProject() ? ' (External)' : ''}`}
                    subtitle={'Click the row to see more details.'}
                    mb="0"
                  />
                  {user?.user_group.validator && (
                    <Box display="flex" gap="10px">
                      <CustomSwitch
                        label1="All"
                        label2="For my approval"
                        value={viewForMyApproval}
                        handleChange={(event) => {
                          setClearSelected((prev: number) => prev + 1);
                          setSelectedRows([]);
                          setSelectedRow(undefined);
                          setSelectedFacility(undefined);

                          const value = event.target.checked;
                          setViewForMyApproval(value);
                          setOtherFilters({
                            ...otherFilters,
                            for_approval: value ? 1 : undefined,
                          });
                        }}
                      />
                      {viewForMyApproval && (
                        <RegularButton
                          onClick={() => handleApproveSelected()}
                          label="Approve selected facilities"
                          startIcon={<DoneAllIcon />}
                          disabled={!selectedRows.length}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              }
              filterComponent={
                <Box display="flex" gap="10px" width="100%" alignItems="center">
                  <DropdownFilter
                    maxWidth="200px"
                    label="Status"
                    handleChange={(e) => {
                      setStatusFilter(e.target.value);
                      setOtherFilters({
                        ...otherFilters,
                        status: e.target.value,
                      });
                    }}
                    value={statusFilter}
                    options={[
                      { key: 'Open', value: 'open' },
                      { key: 'Assigned', value: 'assigned' },
                      { key: 'Started', value: 'started' },
                      { key: 'Submitted', value: 'submitted' },
                      { key: 'Ongoing Approval', value: 'ongoing-approval' },
                      { key: 'Completed', value: 'completed' },
                    ]}
                  />

                  <FacilityLocationFilter
                    otherFilters={otherFilters}
                    handleChangeFilter={(data) => setOtherFilters({ ...otherFilters, ...data })}
                  />

                  <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
                </Box>
              }
            />
          </CustomContainer>
        }
        childComponent={
          <FacilityAssessmentDetails
            selectedFacility={selectedFacility}
            handleClose={closeFacilityDetails}
            refreshTable={refreshTable}
          />
        }
      />
      <Box mt="20px">
        <FacilityReportEntries fromActiveReports />
      </Box>
    </Box>
  );
};

export default MonitorScreen;
