import styled from 'styled-components';
import { forwardRef, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Typography } from '@mui/material';

export interface TableTypeI {
  section?: number;
  section_name?: string;
  standard?: string;
  criteria: {
    field_name: string;
    document_review?: any;
    document_review_note?: string;
    observe?: any;
    interview?: string[];
  }[];
}

export interface TableTypeII {
  equipment_type: string;
  type_comment?: string;
  equipments: {
    name: string;
    variant?: string[];
  }[];
}

/////////////////////////
//  Styled components  //
/////////////////////////
const PCFTable = styled.table`
  width: 100%;
  margin: 0 auto;
  font-size: 10pt;
  font-family: Times New Roman;
  text-align: left;
  // table-layout: fixed;
  border-collapse: collapse;
`;

const TData = styled.td`
  border: 1px solid black;
  margin: none;
  vertical-align: top;
  padding: 0.5em;
`;

export const Header = styled.h4`
  font-family: Times New Roman;
  margin: 0;
`;

const Comment = styled.p`
  font-family: Times New Roman;
  margin: 0;
`;

const SectionHolder = styled.p`
  text-indent: 2em;
  margin: 0;
`;

const StandardHolder = styled(SectionHolder)`
  text-indent: 4em;
`;

const StyledUL = styled.ul`
  margin: 0;
  padding-left: 1.5em;
`;

export const PageStyle = `@page {
  margin: 0.5in;
}`;

//////////////////////////////////
//  Part I table head template  //
//////////////////////////////////
const THeadV1: React.FC = () => {
  return (
    <thead>
      <tr>
        <TData style={{ textAlign: 'center' }}>
          <b>CRITERIA</b>
        </TData>
        <TData style={{ textAlign: 'center' }}>
          <b>INDICATOR/EVIDENCE</b>
        </TData>
        <TData style={{ textAlign: 'center' }}>
          <b>COMPLIED</b>
        </TData>
        <TData style={{ textAlign: 'center' }}>
          <b>REMARKS</b>
        </TData>
      </tr>
    </thead>
  );
};

///////////////////////////////////
//  Part II table head template  //
///////////////////////////////////
const THeadV2: React.FC = () => {
  return (
    <thead>
      <tr>
        <TData style={{ textAlign: 'center' }}>
          <Header>EQUIPMENT/INSTRUMENT</Header>
          <Header>(Functional)</Header>
        </TData>
        <TData style={{ textAlign: 'center' }}>
          <Header>COMPLIED</Header>
        </TData>
        <TData style={{ textAlign: 'center' }}>
          <Header>REMARKS</Header>
        </TData>
      </tr>
    </thead>
  );
};

//////////////////////////////
//  Standard Row component  //
//////////////////////////////
type TStandardRowProps = {
  section?: number;
  section_name?: string;
  standard?: string;
};

const TStandardRow: React.FC<TStandardRowProps> = (props) => {
  const { section, section_name, standard } = props;
  return (
    <tr>
      <TData colSpan={4}>
        {section ? (
          <SectionHolder>
            <Header>
              {`Section ${section}:`} {section_name}
            </Header>
          </SectionHolder>
        ) : (
          <></>
        )}
        {standard ? (
          <StandardHolder>
            <Header>{standard ? `Standard: ${standard}` : <></>}</Header>
          </StandardHolder>
        ) : (
          <></>
        )}
      </TData>
    </tr>
  );
};

///////////////////////////////
//  Equipment row component  //
///////////////////////////////
type TEquipmentRowProps = {
  equipmentType: string;
  equipmentComment?: string;
};

const TEquipmentRow: React.FC<TEquipmentRowProps> = (props) => {
  const { equipmentType, equipmentComment } = props;
  return (
    <tr>
      <TData colSpan={3} style={{ textAlign: 'center' }}>
        <Header>{equipmentType}</Header>
        <Comment>{equipmentComment ? equipmentComment : ''}</Comment>
      </TData>
    </tr>
  );
};

////////////////////////////
//  Part I Row component  //
////////////////////////////
type RowDataV1Props = {
  id: number;
  fieldName: string;
  documentReview?: string[] | string[][];
  documentReviewNote?: string;
  observe?: string[];
  interview?: string[];
};

const RowDataV1: React.FC<RowDataV1Props> = (props) => {
  const { id, fieldName, documentReview, documentReviewNote, observe, interview } = props;
  return (
    <tr>
      <TData>
        {id}. {fieldName}
      </TData>
      <TData>
        {documentReview ? <b>Document Review</b> : ''}
        {documentReview ? (
          <StyledUL>
            {/* checks if the document has child elements (array) */}
            {documentReview.map((doc, idx) => {
              if (Array.isArray(doc))
                return (
                  <StyledUL>
                    {doc.map((cdoc) => (
                      <li>{cdoc}</li>
                    ))}
                  </StyledUL>
                );
              return <li key={idx}>{doc}</li>;
            })}
          </StyledUL>
        ) : (
          <></>
        )}
        {documentReviewNote ? <Comment>{'*' + documentReviewNote}</Comment> : <></>}

        {observe ? <b>Observe Review</b> : ''}
        {observe ? (
          <StyledUL>
            {observe.map((obs, idx) => {
              if (Array.isArray(obs))
                return (
                  <StyledUL>
                    {obs.map((cobs) => (
                      <li>{cobs}</li>
                    ))}
                  </StyledUL>
                );
              return <li key={idx}>{obs}</li>;
            })}
          </StyledUL>
        ) : (
          <></>
        )}

        {interview ? <b>Interview</b> : ''}
        {interview ? (
          <StyledUL>
            {interview.map((obs, idx) => (
              <li key={idx}>{obs}</li>
            ))}
          </StyledUL>
        ) : (
          <></>
        )}
      </TData>
      <TData></TData>
      <TData></TData>
    </tr>
  );
};

/////////////////////////////
//  Part II Row component  //
/////////////////////////////
type RowDataV2Props = {
  name: string;
  variant?: string[];
};

const RowDataV2: React.FC<RowDataV2Props> = (props) => {
  const { name, variant } = props;
  return (
    <>
      <tr>
        <TData>
          <SectionHolder>{name}</SectionHolder>
        </TData>
        <TData></TData>
        <TData></TData>
      </tr>
      {variant ? (
        variant.map((data, id) => (
          <tr key={id}>
            <TData>
              <StandardHolder>{data}</StandardHolder>
            </TData>
            <TData></TData>
            <TData></TData>
          </tr>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

///////////////////////////////////////
//  Part I PCF Table implementation  //
///////////////////////////////////////
type TableProps = {
  tableData?: TableTypeI[];
  toPrint?: boolean;
  setToPrint?: (state: boolean) => void;
};

export const CustomPCFTable: React.FC<TableProps> = (props) => {
  const { tableData, toPrint, setToPrint } = props;
  const [table, setTable] = useState<HTMLDivElement | null>(null);

  const pdfGen = useReactToPrint({
    content: () => table,
    documentTitle: 'AKLAN PCF',
    pageStyle: PageStyle,
  });

  useEffect(() => {
    if (toPrint) {
      pdfGen();
      if (setToPrint) setToPrint(false);
    }
  }, [toPrint]);

  return (
    <PCFTable ref={(el: any) => setTable(el)}>
      <THeadV1 />
      <tbody>
        {tableData ? (
          tableData.map((tdata, index) => {
            return (
              <>
                <TStandardRow
                  key={-index}
                  section={tdata.section}
                  section_name={tdata.section_name}
                  standard={tdata.standard}
                />

                {tdata.criteria.map((td, idx) => (
                  <RowDataV1
                    key={++idx}
                    id={++idx}
                    fieldName={td.field_name}
                    documentReview={td.document_review}
                    documentReviewNote={td.document_review_note}
                    observe={td.observe}
                    interview={td.interview}
                  />
                ))}
              </>
            );
          })
        ) : (
          <></>
        )}
      </tbody>
    </PCFTable>
  );
};

//////////////////////////////////////////////////
//  Part II Equipment PCF Table implementation  //
//////////////////////////////////////////////////
type EquipmentProps = {
  tableData: TableTypeII[];
  toPrint?: boolean;
  setToPrint?: (state: boolean) => void;
};

export const CustomPCFEquipmentTable: React.FC<EquipmentProps> = (props) => {
  const { tableData, toPrint, setToPrint } = props;
  const [table, setTable] = useState<HTMLDivElement | null>(null);

  const pdfGen = useReactToPrint({
    content: () => table,
    documentTitle: 'AKLAN PCF',
    pageStyle: PageStyle,
  });

  useEffect(() => {
    if (toPrint) {
      pdfGen();
      if (setToPrint) setToPrint(false);
    }
  }, [toPrint]);

  return (
    <PCFTable ref={(el: any) => setTable(el)}>
      <THeadV2 />
      <tbody>
        {tableData ? (
          tableData.map((data, dataID) => (
            <>
              <TEquipmentRow key={-dataID} equipmentType={data.equipment_type} equipmentComment={data.type_comment} />
              {data.equipments.map((equipment, eqID) => (
                <RowDataV2 key={eqID} name={equipment.name} variant={equipment.variant} />
              ))}
            </>
          ))
        ) : (
          <></>
        )}
      </tbody>
    </PCFTable>
  );
};

/*
  3 4 7 0

  41 36 40 2

  0 2 2 62
*/
