import { Box, useTheme } from '@mui/material';

import { tokens } from 'context/theme.context';

type CustomContainerProps = {
  p?: string;
  m?: string;
  children: React.ReactNode;
};

const CustomContainer: React.FC<CustomContainerProps> = ({ p = '25px', m, children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display="flex"
      gap="25px"
      flexDirection="column"
      alignItems="flex-start"
      sx={{ backgroundColor: colors.secondary_background }}
      p={p}
      m={m}
      borderRadius={2}
    >
      {children}
    </Box>
  );
};

export default CustomContainer;
