import { Box, Button } from '@mui/material';
import {
  ConfirmationDialog,
  CustomAutoCompleteMultipleSelect,
  CustomGridCell,
  CustomModal,
  CustomTable,
  FacilityLocationFilter,
  PrimaryButton,
  RegularButton,
} from 'components';
import { FacilityGroupModel, FacilityModel } from 'model/Entities';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import {
  createAssessmentBatch,
  createAssessmentBatchForAll,
  getFacilitiesNotIn,
} from 'api/assessment-batch-facilities';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { getFacilitiesGroup } from 'api/facility-group';
import { useSnackbar } from 'notistack';

type FacilityAssessmentAddModalProps = {
  open: boolean;
  selectedBatch: number;
  setOpen: (open: boolean) => void;
  refreshBatchTable: () => void;
};

const FacilityAssessmentAddModal: React.FC<FacilityAssessmentAddModalProps> = ({
  open,
  setOpen,
  selectedBatch,
  refreshBatchTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<FacilityModel[]>([]);
  const [facilityCount, setFacilityCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>({});
  const [selectedFacilities, setSelectedFacilities] = useState<GridSelectionModel>([]);
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedFacilityGroups, setSelectedFacilityGroups] = useState<any[]>([]);

  const [facilityGroups, setFacilityGroups] = useState<FacilityGroupModel[]>([]);
  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'facility_code',
      sortable: false,
      headerName: 'Facility Code',
      flex: 1,
    },
    {
      field: 'facility_name',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
    },
    {
      field: 'facility_type_id',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.facility_type.type}</CustomGridCell>,
    },

    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.municipality.municipality_name}
          {', '}
          {params.row.province.province_name}
          {', '}
          {params.row.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 0.7,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={() => {
            assignFacilityToAssessment(selectedBatch, [params.row.id]);
          }}
        >
          Add
        </Button>
      ),
    },
  ];

  // Functions
  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
  };

  const getAvailableFacilities = (query?: ApiQuery) => {
    setLoading(true);
    getFacilitiesNotIn(selectedBatch, { page: 1, length: 10, ...query })
      .then((res) => {
        setRows(res.data.rows);
        setFacilityCount(res.data.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const assignFacilityToAssessment = async (batch_id: number, data: any) => {
    try {
      setLoadingAdd(true);
      data === 'all'
        ? await createAssessmentBatchForAll(batch_id)
        : await createAssessmentBatch(batch_id, { facility_ids: data });
      let noun = data === 'all' ? 'Facilities' : 'Facility';
      enqueueSnackbar(`${noun} successfully added to group!`, {
        variant: 'success',
      });
      refreshBatchTable();
      setRefresh((prev: number) => prev + 1);
    } finally {
      setLoadingAdd(false);
    }
  };

  const populateFacilitiesGroup = (query?: ApiQuery) => {
    getFacilitiesGroup({
      page: 1,
      length: 10000,
      ...query,
    }).then((res) => {
      setFacilityGroups(
        res.data.rows.map((u: any) => {
          return { key: u.group_name, value: u.id };
        })
      );
    });
  };

  useEffect(() => {
    populateFacilitiesGroup();
  }, [refresh]);

  const handleFacilityGroupsChange = (event: any, newFacilityGroups: any[]) => {
    const selectedFacilityGroups = newFacilityGroups.map((group) => group.value);
    setSelectedFacilityGroups(selectedFacilityGroups);
    setOtherFilters({ ...otherFilters, facility_group: selectedFacilityGroups });
    setRefresh((prev) => prev + 1);
  };

  return (
    <>
      <CustomModal header={`Add Facility`} open={open} setOpen={setOpen} width={1080}>
        <CustomTable
          searchKeys="Facility Name"
          columns={columns}
          rows={rows}
          rowCount={facilityCount}
          loading={loading}
          getData={getAvailableFacilities}
          checkboxSelection
          isRowSelectable
          disableSelectionOnClick
          handleSelectRow={setSelectedFacilities}
          tableHeight={400}
          otherFilters={otherFilters}
          forceRefresh={refresh}
          filterComponent={
            <>
              <Box width="405px" mb="20px">
                <CustomAutoCompleteMultipleSelect
                  options={facilityGroups}
                  label={'Facility Groups'}
                  fieldName={'facility_group'}
                  value={selectedFacilityGroups}
                  handleChange={handleFacilityGroupsChange}
                />
              </Box>
              <Box display="flex" gap="10px" width="100%" alignItems="center">
                <FacilityLocationFilter
                  handleChangeFilter={(data) => {
                    setOtherFilters({ ...otherFilters, ...data });
                  }}
                  otherFilters={otherFilters}
                />
                <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
              </Box>
            </>
          }
          headerComponent={
            <Box gap="15px" display="flex">
              <PrimaryButton
                onClick={() => {
                  assignFacilityToAssessment(selectedBatch, selectedFacilities);
                }}
                label={'Add selected facilities'}
                disabled={!selectedFacilities.length}
                loading={loadingAdd}
              />
              <PrimaryButton
                onClick={() => {
                  setOpenModal(true);
                }}
                label={'Add all facilities'}
                loading={loadingAdd}
              />
            </Box>
          }
        />
      </CustomModal>
      <ConfirmationDialog
        open={openModal}
        setOpen={setOpenModal}
        message="Are you sure want to add all facilities?"
        onConfirm={() => assignFacilityToAssessment(selectedBatch, 'all')}
      />
    </>
  );
};

export default FacilityAssessmentAddModal;
