import { ApiQuery } from 'model/interface';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'assessment-batch-facility';

interface ABFCreatePayload {
  facility_ids: number[];
}

interface ABFDeletePayload {
  assessment_batch_facility_ids: number[];
}

export const createAssessmentBatch = (batch_id: number, data: ABFCreatePayload) => {
  return axios.post(`${baseURL}/${path}/${batch_id}`, data);
};

export const createAssessmentBatchForAll = (batch_id: number) => {
  return axios.post(`${baseURL}/${path}/add-all/${batch_id}`);
};

export const deleteAssesmentBatch = (data: ABFDeletePayload) => {
  return axios.delete(`${baseURL}/${path}`, { data });
};

export const deleteAllFacilitiesInBatch = (batch_id: number) => {
  return axios.delete(`${baseURL}/${path}/${batch_id}`);
};

export const getByBatchId = (batch_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/added/${batch_id}`, { params: query });
};

export const getFacilitiesNotIn = (batch_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-added/${batch_id}`, { params: query });
};

export const getFacilityAssessmentIndicators = (batch_id: number, indicator_id: number) => {
  return axios.get(`${baseURL}/${path}/facility-assessment/${batch_id}/${indicator_id}`);
};

export const getIndividualFacilityAssessmentIndicators = (
  batch_id: number,
  indicator_id: number,
  facility_id: number
) => {
  return axios.get(`${baseURL}/${path}/facility-assessment/${batch_id}/${indicator_id}/${facility_id}`);
};

export const getBatchDashboardData = (batch_id: number, indicator_id: number, facility_id?: number) => {
  return axios.get(
    `${baseURL}/${path}/facility-assessment-dashboard/${batch_id}/${indicator_id}${
      facility_id ? '/' + facility_id : ''
    }`
  );
};
