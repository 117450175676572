import 'react-pro-sidebar/dist/css/styles.css';

import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { CustomIconButton, ProtectedComponent } from 'components';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, ProSidebar } from 'react-pro-sidebar';
import { ReactNode, useContext, useEffect, useState } from 'react';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MonitorIcon from '@mui/icons-material/Monitor';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import { UserContext } from 'context/user.context';
import { isFresaarProject } from 'utils';
import { tokens } from 'context/theme.context';

type ItemProps = {
  title: string;
  to: string;
  icon: ReactNode;
  selected: boolean;
  showTooltip: boolean;
};

type MenuItemProps = {
  section_name: string;
  requiredAuth?: string[];
  subitems: SubItemProps[];
};

type SubItemProps = {
  title: string;
  link: string;
  icon: ReactNode;
  requiredAuth?: string[];
  hidden?: boolean;
};

const Item: React.FC<ItemProps> = ({ title, to, icon, selected, showTooltip }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return showTooltip ? (
    <Tooltip title={title} placement="right" arrow>
      <MenuItem
        active={selected}
        style={{
          color: colors.text,
        }}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    </Tooltip>
  ) : (
    <MenuItem
      active={selected}
      style={{
        color: colors.text,
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const menu_items: MenuItemProps[] = [
  {
    section_name: '',
    subitems: [
      {
        title: 'Dashboard',
        link: '/',
        icon: <HomeOutlinedIcon />,
        requiredAuth: [
          'VIEW_REGIONAL_DASHBOARD',
          'VIEW_PROVINCIAL_DASHBOARD',
          'VIEW_NATIONAL_DASHBOARD',
          'VIEW_MUNICIPAL_DASHBOARD',
        ],
      },
      {
        title: 'Monitor',
        link: '/monitor',
        icon: <MonitorIcon />,
        requiredAuth: ['VIEW_FRESAA_BATCHES', 'REVIEW_FRESAA_QUESTIONNAIRE', 'ANSWER_FRESAA_QUESTIONNAIRE'],
      },
      {
        title: isFresaarProject() ? 'Assessments and Reports' : 'Assessments Completed',
        link: '/assessment-files',
        icon: <AssessmentOutlinedIcon />,
        requiredAuth: [
          'VIEW_REGIONAL_DASHBOARD',
          'VIEW_PROVINCIAL_DASHBOARD',
          'VIEW_NATIONAL_DASHBOARD',
          'VIEW_MUNICIPAL_DASHBOARD',
        ],
      },
      // Facility Staff
      {
        title: 'Reports',
        link: '/facility-reports',
        icon: <SummarizeOutlinedIcon />,
        requiredAuth: ['MANAGE_FACILITY'],
        hidden: !isFresaarProject(),
      },
      {
        title: 'Assessments',
        link: '/facility-assessments',
        icon: <SummarizeOutlinedIcon />,
        requiredAuth: ['MANAGE_FACILITY'],
      },
    ],
  },
  {
    section_name: 'Management',
    requiredAuth: [
      'MANAGE_ALL_ACCOUNTS',
      'MANAGE_NATIONAL_REGIONAL_ACCOUNT',
      'MANAGE_CITY_PROVINCIAL_ACCOUNT',
      'MANAGE_FACILITIES',
      'MANAGE_FRESAA_BATCHES',
      'CREATE_DELETE_REGIONAL_FRESAA_QUESTIONNAIRE',
    ],
    subitems: [
      {
        title: 'User',
        link: '/user-management',
        icon: <PeopleOutlinedIcon />,
        requiredAuth: ['MANAGE_ALL_ACCOUNTS', 'MANAGE_NATIONAL_REGIONAL_ACCOUNT', 'MANAGE_CITY_PROVINCIAL_ACCOUNT'],
      },
      {
        title: 'Facility',
        link: '/facilities',
        icon: <MapsHomeWorkOutlinedIcon />,
        requiredAuth: ['MANAGE_FACILITIES', 'MANAGE_PROVINCIAL_FACILITIES'],
      },
      {
        title: 'Assessments',
        link: '/assessments',
        icon: <TabletAndroidIcon />,
        requiredAuth: ['MANAGE_FRESAA_BATCHES', 'VIEW_FRESAA_BATCHES', 'CREATE_DELETE_REGIONAL_FRESAA_QUESTIONNAIRE'],
      },
      {
        title: 'Reporting Tool',
        link: '/reports',
        icon: <SummarizeOutlinedIcon />,
        requiredAuth: ['MANAGE_REPORTS', 'VIEW_FRESAA_BATCHES'],
        hidden: !isFresaarProject(),
      },
      {
        title: 'Indicators',
        link: '/indicators',
        icon: <ListAltIcon />,
        requiredAuth: ['MANAGE_FRESAA_BATCHES'],
      },
    ],
  },
];

const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(UserContext);

  const initialCollapsedState = localStorage.getItem('isCollapsed') === 'true';
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsedState);

  useEffect(() => {
    localStorage.setItem('isCollapsed', isCollapsed.toString());
  }, [isCollapsed]);

  const isTabSelected = (link: string) => {
    return (link === '/' && location.pathname === link) || (link !== '/' && location.pathname.startsWith(link));
  };

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `${colors.secondary_background} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: `${theme.palette.mode === 'dark' ? colors.primary : colors.secondary} !important`,
        },
        '& .pro-menu-item.active': {
          color: `${theme.palette.mode === 'dark' ? colors.secondary : '#407774'} !important`,
        },
        '& .pro-menu-item.active p': {
          fontWeight: 'bold',
          letterSpacing: 0.5,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {isCollapsed ? (
            <MenuItem
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                localStorage.setItem('collapseStatus', JSON.stringify(!isCollapsed));
              }}
              icon={<MenuOutlinedIcon />}
              style={{
                color: colors.text,
              }}
            />
          ) : (
            <MenuItem
              icon={<img alt="profile-user" width="25px" height="25px" src={`../../assets/DOH.png`} />}
              className="carego-logo"
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography style={{ color: colors.text, fontWeight: 'bold', letterSpacing: '2px' }}>
                  {isFresaarProject() ? 'FRESAAR' : 'FRESAA'}
                </Typography>
                <CustomIconButton
                  icon={<MenuOutlinedIcon />}
                  color={null}
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                    localStorage.setItem('collapseStatus', JSON.stringify(!isCollapsed));
                  }}
                />
              </Box>
            </MenuItem>
          )}

          {!isCollapsed ? (
            <Box mt="10px" mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="130px"
                  height="auto"
                  src={
                    user && user?.region?.region_logo && user?.region?.region_logo !== '?'
                      ? theme.palette.mode === 'light'
                        ? user.region.region_logo
                        : user.region.region_logo_dark
                      : `../../assets/DOH.png`
                  }
                  style={{ cursor: 'pointer' }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="h4" color={colors.text} fontWeight="bold" sx={{ m: '15px 0 2px' }}>
                  {[user?.title, user?.first_name, user?.last_name].filter((item) => !!item).join(' ')}
                </Typography>
                <Typography sx={{ color: colors.accent, fontSize: '13px' }}>{user?.user_group.description}</Typography>
              </Box>
            </Box>
          ) : (
            <Box m="25px 0">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="30px"
                  height="auto"
                  src={user?.region.region_logo ?? `../../assets/DOH.png`}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          )}

          <Box>
            {menu_items.map((item, index) => (
              <Box key={index}>
                <ProtectedComponent requiredAuth={item.requiredAuth}>
                  <Typography
                    variant="h6"
                    color={colors.text}
                    sx={
                      isCollapsed
                        ? {
                            textAlign: 'center',
                            m: '15px 0 5px 0',
                            fontSize: '10px',
                          }
                        : { m: '15px 0 5px 20px' }
                    }
                  >
                    {item.section_name}
                  </Typography>
                </ProtectedComponent>
                {item.subitems
                  .filter((item) => !item.hidden)
                  .map((subitem) => (
                    <ProtectedComponent requiredAuth={subitem.requiredAuth} key={subitem.link}>
                      <Item
                        title={subitem.title}
                        to={subitem.link}
                        icon={subitem.icon}
                        selected={isTabSelected(subitem.link)}
                        showTooltip={isCollapsed}
                      />
                    </ProtectedComponent>
                  ))}
              </Box>
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
