/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Collapse } from '@mui/material';
import { ConditionalHideComponent, CustomIconButton, RegularButton } from 'components';
import FieldGroups, { FIELD_TYPES, getFieldObject } from './FieldGroups';
import { useEffect, useState } from 'react';

import AddFieldDropdown from '../components/AddFieldDropdown';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditField from '../components/EditField';
import EditFieldDropdown from '../components/EditFieldDropdown';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FieldType } from '../IndicatorBuilder';

type ParentFieldProps = {
  field: FieldType;
  droppableId: string;
  index: number;
  level?: number;
  deleteField: () => void;
  addField: (field_type: FIELD_TYPES) => void;
  editApplicability: () => void;
  respond?: boolean;
  selectedField?: FieldType;
  showMissingItems: boolean;
  setSelectedField?: (value: FieldType) => void;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
  childSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
};

const ParentField: React.FC<ParentFieldProps> = ({
  field,
  droppableId,
  index,
  level,
  deleteField,
  addField,
  editApplicability,
  respond,
  selectedField,
  showMissingItems,
  setSelectedField,
  setMissingItems,
  childSetTotalFieldsCount,
  childSetTotalFieldsWithReponseCount,
}) => {
  const [open, setOpen] = useState(true);
  const [edit, setEdit] = useState(false);
  const [bodyCount, setBodyCount] = useState(0);
  const [children, setChildren] = useState<FieldType[]>([]);

  const [totalFieldsCount, setTotalFieldsCount] = useState<number>(0);
  const [totalFieldsWithReponseCount, setTotalFieldsWithReponseCount] = useState<number>(0);
  const [fieldsCount, setFieldsCount] = useState<number[]>([]);
  const [fieldsWithReponseCount, setFieldsWithReponseCount] = useState<number[]>([]);

  useEffect(() => {
    setBodyCount(field.children!.length);
    setChildren(field.children!);
  }, [field]);

  useEffect(() => {
    setTotalFieldsCount(
      fieldsCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
    setTotalFieldsWithReponseCount(
      fieldsWithReponseCount.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue ?? 0);
      }, 0)
    );
  }, [fieldsCount, fieldsWithReponseCount]);

  useEffect(() => {
    if (childSetTotalFieldsCount && childSetTotalFieldsWithReponseCount) {
      childSetTotalFieldsCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsCount;
        return newValue;
      });
      childSetTotalFieldsWithReponseCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = totalFieldsWithReponseCount;
        return newValue;
      });
    }
  }, [totalFieldsCount, totalFieldsWithReponseCount]);

  const addChildField = (field_type: FIELD_TYPES) => {
    let newField: FieldType = getFieldObject(field_type);

    children.push(newField);
    field.children = children;
    setChildren([...children]);
  };

  const handleEditSaveClick = (value: string) => {
    setEdit(false);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap="20px">
        <EditField edit={edit} handleSave={(value) => handleEditSaveClick(value)} variant="h6" field={field} />

        <ConditionalHideComponent hide={respond}>
          <Box display="flex" gap="2px">
            <EditFieldDropdown
              optionList={[
                {
                  label: 'Edit Field',
                  action: () => setEdit(true),
                  hidden: edit,
                },
                {
                  label: 'Edit Applicability',
                  action: editApplicability,
                },
                {
                  label: 'divider',
                },
                {
                  label: 'Add child field - Text',
                  action: () => addChildField(FIELD_TYPES.TEXT),
                },
                {
                  label: 'Add child field - Numeric',
                  action: () => addChildField(FIELD_TYPES.NUMERIC),
                },
                {
                  label: 'Add child field - Radio',
                  action: () => addChildField(FIELD_TYPES.RADIO),
                },
                {
                  label: 'Add child field - Yes or No',
                  action: () => addChildField(FIELD_TYPES.YES_OR_NO),
                },
                {
                  label: 'Add child field - Parent',
                  action: () => addChildField(FIELD_TYPES.PARENT),
                },
              ]}
            />

            <AddFieldDropdown addField={addField} />
            {!bodyCount && (
              <>
                <CustomIconButton
                  icon={<DeleteOutlinedIcon />}
                  tooltip="Remove field"
                  onClick={deleteField}
                  color="error"
                />

                <AddFieldDropdown addField={addField} />
                {!bodyCount && (
                  <CustomIconButton
                    icon={<DeleteOutlinedIcon />}
                    tooltip="Remove field"
                    onClick={deleteField}
                    color="error"
                  />
                )}
              </>
            )}
          </Box>
        </ConditionalHideComponent>
      </Box>
      <Box mt="10px">
        {!respond && (
          <RegularButton
            startIcon={open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            label={open ? 'Hide' : 'Show'}
            onClick={() => setOpen(!open)}
            size="small"
            variant="text"
            color="inherit"
          />
        )}
        <Collapse in={open || respond} timeout="auto">
          <FieldGroups
            items={children}
            droppableId={droppableId}
            level={level}
            sectionNumber=""
            parentObject={field}
            parentProperty="children"
            respond={respond}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            index={index}
            showMissingItems={showMissingItems}
            setMissingItems={setMissingItems}
            childSetTotalFieldsCount={setFieldsCount}
            childSetTotalFieldsWithReponseCount={setFieldsWithReponseCount}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default ParentField;
