import ArticleContent, { imagePath } from '../ArticleContent';

// medyo mahirap iexplain sa part na to, pwedeng gumamit ng ReactPlayer dito para mas simple
// - huberto
const AddUpdateValidationWorkflow = () => {
  return (
    <ArticleContent
      contents={[
        {
          title: 'Adding a Validator',
          steps: [
            { step: '1. Click "Add Validation Step".', image: `${imagePath}/add-validation-workflow-1.png` },
            { step: '2. Select the validator to be added.', image: `${imagePath}/add-validation-workflow-1.png` },
            {
              step: '3. Update the flow to the prefered sequence (refer to "Updating flow" article below).',
              image: `${imagePath}/add-validation-workflow-3.png`,
            },
            {
              step: "NOTE: The figure's position can be adjusted by dragging the small square.",
              image: `${imagePath}/add-validation-workflow-4.png`,
            },
            { step: '', image: `${imagePath}/add-validation-workflow-5.png` },
          ],
        },
        {
          title: 'Updating flow',
          steps: [
            {
              step: '1. On the action button, click "Update workflow',
              image: `${imagePath}/update-validation-workflow-1.png`,
            },
            {
              step: '2. The page will redirect to the editor where the flow of the figures can be updated. Kindly drag and drop the arrows to adjust the prefered flow.',
            },
            {
              step: 'NOTE: To delete the "arrows" for updating the flow, simply select the line and hit backspace.',
              image: `${imagePath}/update-validation-workflow-3.png`,
            },
            {
              step: '3. Edit the flow by draging the line to the prefered sequence.',
            },
            { step: '4. Click "Save".', image: `${imagePath}/update-validation-workflow-4.png` },
          ],
        },
      ]}
    />
  );
};

export default AddUpdateValidationWorkflow;
