import { Box, useTheme } from '@mui/material';
import { CustomModal, RegularButton } from 'components';
import { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { tokens } from 'context/theme.context';

type FacilityTableModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleFileInputChange: any;
  uploadFacilities: any;
};

const FacilityImportModal: React.FC<FacilityTableModalProps> = ({
  open,
  setOpen,
  handleFileInputChange,
  uploadFacilities,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file.name);
    } else {
      setSelectedFile(null);
    }
    handleFileInputChange(event);
  };

  useEffect(() => {
    if (!open) {
      setSelectedFile(null);
    }
  }, [open]);

  return (
    <CustomModal header={`Import Facilities `} open={open} setOpen={setOpen} width={500}>
      <div style={{ marginTop: -30 }}>
        <h4>
          1. Download the Excel template
          <a
            href={process.env.PUBLIC_URL + '/files/facility_template.xlsx'}
            download="facility_template.xlsx"
            style={{ color: colors.accent, paddingLeft: '5px' }}
          >
            here
          </a>
        </h4>
        <h4>2. Make sure all PSGC columns are 10-digit number.</h4>
        <h4>3. Health Facility Type should be one of the following:</h4>
        <ul>
          <li>Infirmary</li>
          <li>Safe Birthing Facility</li>
          <li>Birthing Home</li>
          <li>Rural Health Unit</li>
          <li>City Health Office</li>
          <li>Barangay Health Station</li>
          <li>Nutrition or Health Outpost</li>
          <li>Auxiliary Facility</li>
        </ul>
        <h4>4. Upload the file, then click "Import"</h4>

        <Box>
          <h3>Select File here</h3>
          <Button variant="contained" component="label" size="small" startIcon={<CloudUploadIcon />}>
            {selectedFile ? selectedFile : 'Upload File'}
            <input type="file" onChange={handleFileSelection} ref={fileInputRef} style={{ display: 'none' }} />
          </Button>
        </Box>

        <div style={{ marginTop: 50, position: 'relative' }}>
          {/* content */}
          <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
            <RegularButton label={'Import'} color="success" onClick={uploadFacilities} />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default FacilityImportModal;
