/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import FieldGroups from './FieldGroups';
import { FieldType } from '../IndicatorBuilder';

type ParentFieldProps = {
  field: FieldType;
  droppableId: string;
  level?: number;
  index: number;
  deleteField: () => void;
  displayItem: (item: any, index: number) => any;
  respond?: boolean;
  selectedField?: FieldType;
  showMissingItems: boolean;
  setSelectedField?: (value: FieldType) => void;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
};

const ConditionalField: React.FC<ParentFieldProps> = ({
  field,
  droppableId,
  index,
  level,
  deleteField,
  displayItem,
  respond,
  selectedField,
  showMissingItems,
  setSelectedField,
  setMissingItems,
}) => {
  field.type = 'field';

  return (
    <Box>
      {displayItem(field, 0)}
      <Box>
        <FieldGroups
          items={field.children!}
          droppableId={droppableId}
          index={index}
          level={level}
          sectionNumber=""
          parentObject={field}
          parentProperty="children"
          respond={respond}
          selectedField={selectedField}
          showMissingItems={showMissingItems}
          setSelectedField={setSelectedField}
          setMissingItems={setMissingItems}
        />
      </Box>
    </Box>
  );
};

export default ConditionalField;
