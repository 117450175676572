import { Box, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { ColorModeContext, tokens } from 'context/theme.context';
import { ConfirmationDialog, CustomIconButton, CustomModal, ProtectedComponent, SubmitButton } from 'components';
import { Link, useNavigate } from 'react-router-dom';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { Dropdown } from 'components';
import HomeIcon from '@mui/icons-material/Home';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { UserContext } from 'context/user.context';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import { logout } from 'api/user';
import { uploadLogo } from 'api/region';
import { useSnackbar } from 'notistack';

const Topbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { breadcrumb } = useContext(BreadcrumbContext);
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [logoLight, setLogoLight] = useState<any>();
  const [logoDark, setLogoDark] = useState<any>();
  const [selectedFileLight, setSelectedFileLight] = useState('');
  const [selectedFileDark, setSelectedFileDark] = useState('');
  const { user } = useContext(UserContext);

  const logoutUser = () => {
    logout().then(() => {
      setUser(undefined);
      navigate('/login');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('authorities');
    });
  };

  const handleLogoUpload = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFileLight(''); // Reset selected file for light mode
    setSelectedFileDark(''); // Reset selected file for dark mode
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    let logos = {
      region_logo: logoLight,
      region_logo_dark: logoDark,
    };

    uploadLogo(user?.id ?? 0, logos)
      .then(() => {
        enqueueSnackbar(`Logo successfully uploaded!`, {
          variant: 'success',
        });
        setLoading(false);
        setDisabled(false);
        setOpenModal(false);
        event.preventDefault();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setDisabled(false);
      });

    setLoading(true);
    setDisabled(true);
    event.preventDefault();
  };
  const handleUploadLogoLight = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      // Add a null check here
      let reader = new FileReader();
      reader.onload = function (e) {
        if (e.target) {
          setLogoLight(e.target.result);
          setSelectedFileLight(file.name); // Set the selected file name
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const navigateHelpdesk = () => {
    navigate('/helpdesk');
  };

  const navigateToValidationWorkflow = () => {
    navigate('/validation-workflow');
  };

  const navigateToActivityLogs = () => {
    navigate('/logs');
  };
  const handleUploadLogoDark = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      // Add a null check here
      let reader = new FileReader();
      reader.onload = function (e) {
        if (e.target) {
          setLogoDark(e.target.result);
          setSelectedFileDark(file.name); // Set the selected file name
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" m="15px 30px">
        {/* BREADCRUMB */}
        <Box display="flex" alignItems="flex-start" gap={1}>
          <HomeIcon sx={{ color: colors.text }} />
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Typography
              variant="subtitle1"
              color={colors.text}
              sx={{
                cursor: 'pointer',
                borderBottom: `1px solid ${colors.text}`,
              }}
            >
              Home
            </Typography>
          </Link>
          {breadcrumb?.map((item, index) => {
            return (
              <Box display="flex" gap={1} key={index}>
                <Typography variant="subtitle1" color={colors.text}>
                  {'>'}
                </Typography>
                {item.link ? (
                  <Link to={item.link} style={{ textDecoration: 'none' }}>
                    <Typography
                      variant="subtitle1"
                      color={colors.text}
                      sx={
                        item.link
                          ? {
                              cursor: 'pointer',
                              borderBottom: `1px solid ${colors.text}`,
                            }
                          : {}
                      }
                    >
                      {item.label}
                    </Typography>
                  </Link>
                ) : (
                  <Typography variant="subtitle1" color={colors.text}>
                    {item.label}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>

        {/* ICONS */}
        <Box display="flex" gap={4}>
          <Box display="flex" alignItems="center" gap={2} mr={3}>
            <AccountBalanceIcon />

            <Typography variant="h5" color={colors.text} fontWeight="bold">
              {[user?.region?.region_name, user?.province?.province_name].filter((loc) => !!loc).join(' - ')}
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <CustomIconButton
              icon={theme.palette.mode === 'light' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
              color={null}
              onClick={colorMode.toggleColorMode}
              tooltip={theme.palette.mode === 'light' ? 'Dark Mode' : 'Light Mode'}
              placement="bottom"
            />
            <ProtectedComponent requiredAuth={['MANAGE_NATIONAL_REGIONAL_ACCOUNT', 'VIEW_ACTIVITY_LOG']}>
              <Dropdown
                isIcon
                optionList={[
                  {
                    label: 'Upload Logo',
                    action: handleLogoUpload,
                    startIcon: <CloudUploadIcon />,
                  },
                  {
                    label: 'Help desk',
                    action: navigateHelpdesk,
                    startIcon: <HelpIcon />,
                  },
                  {
                    label: 'Validation Workflow',
                    action: navigateToValidationWorkflow,
                    startIcon: <PublishedWithChangesIcon />,
                  },
                  {
                    label: 'Activity Logs',
                    action: navigateToActivityLogs,
                    startIcon: <WorkHistoryOutlinedIcon />,
                  },
                ]}
                icon={<SettingsOutlinedIcon />}
                tooltip="View Actions"
                tooltipPlacement={'bottom'}
              />
            </ProtectedComponent>
            <CustomIconButton
              icon={<LogoutIcon />}
              color={null}
              onClick={() => setOpenDeleteConfirmation(true)}
              tooltip="Logout"
              placement="bottom"
            />
          </Box>
        </Box>
      </Box>

      <CustomModal header="Upload Logo" open={openModal} setOpen={setOpenModal} width={500} onClose={handleCloseModal}>
        <Typography>
          You may upload separate logos for light and dark themes. You may upload one logo for both themes.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box display={'flex'} marginTop={'1rem'} justifyContent={'space-around'}>
            <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexDirection={'column'}>
              <img src={logoLight} width="auto" height="50rem" alt="logoLight preview" hidden={!logoLight} />
              <Typography>Light Mode Logo</Typography>
              <Button
                variant="contained"
                component="label"
                size="small"
                startIcon={<CloudUploadIcon />}
                sx={{ textTransform: 'none' }}
              >
                {selectedFileLight ? selectedFileLight : 'Upload Logo'}
                <input type="file" onChange={handleUploadLogoLight} accept="image/*" style={{ display: 'none' }} />
              </Button>
            </Box>
            <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'} flexDirection={'column'}>
              <img src={logoDark} width="auto" height="50rem" alt="logoDark preview" hidden={!logoDark} />
              <Typography>Dark Mode Logo</Typography>
              <Button
                variant="contained"
                component="label"
                size="small"
                startIcon={<CloudUploadIcon />}
                sx={{ textTransform: 'none' }}
              >
                {selectedFileDark ? selectedFileDark : 'Upload Logo'}
                <input type="file" onChange={handleUploadLogoDark} accept="image/*" style={{ display: 'none' }} />
              </Button>
            </Box>
          </Box>
          <div style={{ marginTop: 60, position: 'relative' }}>
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <SubmitButton label={'Submit'} loading={loading} disabled={disabled} />
            </div>
          </div>
        </form>
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure you want to Logout?"
        onConfirm={() => logoutUser()}
      />
    </>
  );
};

export default Topbar;
