module.exports = {
  section_number: 1,
  section_name: 'AHDP Monthly Accomplishment Report',
  section_body: [
    {
      type: 'table',
      field_name: 'Indicators',
      field_code: '1',
      columns: [
        {
          name: '10-14 YO',
          type: 'parent',
          children: [
            { name: 'Male', type: 'numeric', code: '1' },
            { name: 'Female', type: 'numeric', code: '2' },
          ],
        },
        {
          name: '15-19 YO',
          type: 'parent',
          children: [
            { name: 'Male', type: 'numeric', code: '3' },
            { name: 'Female', type: 'numeric', code: '4' },
          ],
        },
      ],
      rows: [
        {
          type: 'field',
          field_name: '# of Adolescent reached by HYO',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent assessed using HEADSSS',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent referred using HEADSSS and accessed services',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent with Anemia',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent with Dental Caries',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent with STI',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent with HIV',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent with Rubella',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent with Tetanus',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent who had Attempted Suicide',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent who committed Suicide',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent who had been to Road Traffic Accident',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent experienced Drowning',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent experienced Sexual Violence',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of of Binge Drinkers (BD)',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Current Tobacco users (CTU)',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent who are regular drug users or drug dependents',
          field_code: '1',
        },
      ],
    },
    {
      type: 'table',
      field_name: 'Indicators',
      field_code: '1',
      columns: [
        {
          name: '10-14 YO',
          type: 'numeric',
          code: '1',
        },
        {
          name: '15-19 YO',
          type: 'numeric',
          code: '2',
        },
      ],
      rows: [
        {
          type: 'field',
          field_name: '# of Teenage pregnancy',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent Maternal Death',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '# of Adolescent Live Birth',
          field_code: '1',
        },
      ],
    },
  ],
};
