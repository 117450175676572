/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Chip, useTheme } from '@mui/material';
import {
  CustomContainer,
  CustomGridCell,
  CustomIconButton,
  CustomSwitch,
  DropdownFilter,
  FacilityLocationFilter,
  ProtectedComponent,
  RegularButton,
  RowAction,
} from 'components';
import { FacilityReportEntry, ReportEntryModel } from 'model/Entities';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import {
  batchApproveFacilityReportEntries,
  getActiveReportEntryFacilityEntries,
  getFacilityReportEntriesByReportEntryId,
  getFacilityReportEntryIndicators,
} from 'api/facility-report-entry';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CloseIcon from '@mui/icons-material/Close';
import CustomTable from 'components/CustomTable';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Header from 'components/Header';
import { Link } from 'react-router-dom';
import { UserContext } from 'context/user.context';
import { getStatusChip } from 'utils';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';
import { DropdownOptionProps } from 'components/Dropdown';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import saveAs from 'file-saver';

type FacilityReportEntriesProps = {
  selectedReport?: ReportEntryModel;
  fromActiveReports?: boolean;
  handleClose?: () => void;
  facilityView?: boolean;
};

const FacilityReportEntries: React.FC<FacilityReportEntriesProps> = ({
  selectedReport,
  fromActiveReports,
  handleClose,
  facilityView,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [rows, setRows] = useState<FacilityReportEntry[]>([]);
  const [facilityReportEntriesCount, setFacilityReportEntriesCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>({});
  const [refresh, setRefresh] = useState(0);
  const [statusFilter, setStatusFilter] = useState('');
  const { user } = useContext(UserContext);
  const [viewForMyApproval, setViewForMyApproval] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [clearSelected, setClearSelected] = useState(0);

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'id',
      sortable: false,
      headerName: 'ID',
      flex: 0.2,
    },
    {
      field: 'report_entry',
      sortable: false,
      headerName: 'Period',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          <Link
            to={`/facility-reports/view/${params.row.id}`}
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
          >
            {params.row.report_entry.month}
          </Link>
        </CustomGridCell>
      ),
      hide: !facilityView,
    },
    {
      field: 'report',
      sortable: false,
      headerName: 'Report',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.report_entry.report?.name} ({params.row.report_entry.month})
        </CustomGridCell>
      ),
      hide: facilityView,
    },
    {
      field: 'facility_id',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
      hide: facilityView,
      renderCell: (params) => (
        <CustomGridCell>
          <Link
            to={`/facility-reports/view/${params.row.id}`}
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
          >
            {params.row.facility?.facility_name}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'facility_type',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      hide: facilityView,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility?.facility_type.type}
          {params.row.facility?.with_sbf && (
            <Chip label="with SBF" size="small" variant="outlined" sx={{ marginLeft: '5px', fontSize: '10px' }} />
          )}
        </CustomGridCell>
      ),
    },

    {
      field: 'address',
      sortable: false,
      headerName: 'Location',
      flex: 2,
      hide: facilityView,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility?.municipality.municipality_name}
          {', '}
          {params.row.facility?.province.province_name}
          {', '}
          {params.row.facility?.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'current_step',
      sortable: false,
      headerName: 'Pending Approval',
      flex: 1,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const exportAsCsv = (csvBlob: Blob, fileName: string) => {
    saveAs(csvBlob, fileName);
  };
  
  const downloadResponses = (data: any) => {
    console.log(data);
    getFacilityReportEntryIndicators(data.report_entry_id, data.id ).then((response) => {
      if (!response.data.csvFormatted)
        return enqueueSnackbar('Report Entry Indicators Unavailable', { variant: 'warning' });
      const blobData = new Blob([response.data.csvFormatted], { type: 'text/csv' });
      exportAsCsv(blobData, data.report_entry.report.name + ' ' + data.report_entry.month + ' - ' + data.facility.facility_name + '.csv');
    });
  };
  
  const actions: DropdownOptionProps[] = [
    {
      label: 'Download Responses',
      action: downloadResponses,
      startIcon: <DownloadOutlinedIcon />,
    },
  ];
  // Functions
  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
    setStatusFilter('');
  };

  const handleApproveSelected = () => {
    batchApproveFacilityReportEntries(selectedRows)
      .then((res: any) => {
        enqueueSnackbar('Facility Report Entries successfully approved!', {
          variant: 'success',
        });
        refreshTable();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: 'error',
        });
      });

    return;
  };

  useEffect(() => {
    refreshTable();
  }, [selectedReport]);

  //Functions
  const getFacilityReportEntries = async (query?: ApiQuery) => {
    setLoading(true);
    const _query = { ...query };

    try {
      const res = fromActiveReports
        ? await getActiveReportEntryFacilityEntries(_query)
        : await getFacilityReportEntriesByReportEntryId(selectedReport?.id!, facilityView ? 'report' : 'report_entry', {
            ...query,
            status: facilityView ? undefined : 'completed',
          });
      setRows(res.data.rows);
      setFacilityReportEntriesCount(res.data.count);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectRow = (selected: GridSelectionModel) => {
    if (viewForMyApproval) {
      setSelectedRows(selected);
    }
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Facility Report Entries' }]);
  }, []);

  const reportName = selectedReport?.report
    ? ` - ${selectedReport?.report.name} (${selectedReport.month})`
    : ` - ${selectedReport?.name}`;

  return (
    <Box>
      <ProtectedComponent
        requiredAuth={['MANAGE_FACILITY', 'MANAGE_REPORTS', 'VIEW_FRESAA_BATCHES', 'REVIEW_FRESAA_QUESTIONNAIRE']}
      >
        <CustomContainer p="25px">
          <CustomTable
            searchKeys="Report Name, Facility Name, or Location"
            columns={columns}
            rows={rows}
            rowCount={facilityReportEntriesCount}
            loading={loading}
            getData={getFacilityReportEntries}
            isRowSelectable
            handleSelectRow={handleSelectRow}
            clearSelected={clearSelected}
            checkboxSelection={viewForMyApproval}
            otherFilters={otherFilters}
            headerComponent={
              <>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
                  <Header title={`Facility Reports${selectedReport ? reportName : ''} (Internal)`} mb="0" />
                  {handleClose && <CustomIconButton icon={<CloseIcon />} onClick={handleClose} />}
                </Box>
                <Box>
                  {user?.user_group.validator ? (
                    <Box display="flex" gap="10px">
                      <CustomSwitch
                        label1="All"
                        label2="For my approval"
                        value={viewForMyApproval}
                        handleChange={(event) => {
                          setClearSelected((prev: number) => prev + 1);
                          setSelectedRows([]);
                          const value = event.target.checked;
                          setViewForMyApproval(value);
                          setOtherFilters({
                            ...otherFilters,
                            for_approval: value ? 1 : undefined,
                          });
                        }}
                      />
                      {viewForMyApproval && (
                        <RegularButton
                          onClick={() => handleApproveSelected()}
                          label="Approve selected reports"
                          startIcon={<DoneAllIcon />}
                          disabled={!selectedRows.length}
                        />
                      )}
                    </Box>
                  ) : undefined}
                </Box>
              </>
            }
            forceRefresh={refresh}
            filterComponent={
              !facilityView ? (
                <Box display="flex" gap="10px" width="100%" alignItems="center">
                  <DropdownFilter
                    maxWidth="200px"
                    label="Status"
                    handleChange={(e) => {
                      setStatusFilter(e.target.value);
                      setOtherFilters({
                        ...otherFilters,
                        status: e.target.value,
                      });
                    }}
                    value={statusFilter}
                    options={[
                      { key: 'Open', value: 'open' },
                      { key: 'Started', value: 'started' },
                      { key: 'Submitted', value: 'submitted' },
                      { key: 'Ongoing Approval', value: 'ongoing-approval' },
                      { key: 'Completed', value: 'completed' },
                    ]}
                  />

                  <FacilityLocationFilter
                    handleChangeFilter={(data) => {
                      setOtherFilters({ ...otherFilters, ...data });
                    }}
                    otherFilters={otherFilters}
                  />
                  <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
                </Box>
              ) : (
                <></>
              )
            }
          />
        </CustomContainer>
      </ProtectedComponent>
    </Box>
  );
};

export default FacilityReportEntries;
