import { CustomForm, CustomModal } from 'components';

import { Box } from '@mui/material';
import React from 'react';

type EditApplicabilityFormValues = {
  applicability: string;
  options: string[];
};

type EditApplicabilityModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (values: EditApplicabilityFormValues) => void;
  initialValues: EditApplicabilityFormValues;
};

const EditApplicabilityModal: React.FC<EditApplicabilityModalProps> = ({ open, setOpen, onSubmit, initialValues }) => {
  return (
    <CustomModal header="Edit Applicability" open={open} setOpen={setOpen} width={500}>
      <Box>
        <CustomForm
          onSubmit={onSubmit}
          fields={[
            {
              field_name: 'applicability',
              display_name: 'Applicability',
              type: 'select',
              span: 4,
              options: [
                { value: 'applicable', key: 'Applicable' },
                { value: 'not_applicable', key: 'Not Applicable' },
              ],
            },
            {
              field_name: 'facilityType',
              display_name: 'Facility Type',
              type: 'multiselect',
              span: 4,
              options: [
                { value: 'Infirmary', key: 'Infirmary' },
                { value: 'Safe Birthing Facility', key: 'Safe Birthing Facility' },
                { value: 'Rural Health Unit', key: 'Rural Health Unit' },
                { value: 'City Health Office', key: 'City Health Office' },
                { value: 'Barangay Health Station', key: 'Barangay Health Station' },
              ],
            },
          ]}
          initialValues={initialValues}
          submitButtonText="Save"
        />
      </Box>
    </CustomModal>
  );
};

export default EditApplicabilityModal;
