import styled from 'styled-components';
import React from 'react';

const TableWrapper = styled.div`
  margin: 20px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
const TableCell = styled.td`
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;
const TableCells = styled.td`
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;
// const TableHeaderCell = styled.th`
//   /* border: 1px solid */
//   padding: 8px;

//   border-left: 1px solid #ddd;
//   border-right: 1px solid #ddd;
//   border-bottom: 1px solid #ddd;
//   text-align: center;
// `;
const TableHeaderCells = styled.th`
  /* border: 1px solid */
  padding: 8px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  text-align: center;
`;
const TableHeaderCell = styled.th`
  /* border: 1px solid */
  padding: 8px;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  text-align: center;
`;
const TableHeaderCellz = styled.th`
  /* border: 1px solid */
  padding: 8px;

  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  text-align: center;
`;
type TableData = string[][];

interface SubColumn {
  title: string;
}

interface DashboardTableProps {
  combinedData: {
    tableData: TableData;
    mockColumnData: (SubColumn | string)[];
  };
}
const calculatePercentageColor = (content: string) => {
  const hasPercentage = content.includes('%');

  if (hasPercentage) {
    const percentage = parseFloat(content.replace('%', '')); // Remove '%' and convert to float

    if (!isNaN(percentage)) {
      // Check if it's a valid number
      if (percentage >= 90) {
        return 'green';
      } else if (percentage >= 50 && percentage < 90) {
        return 'yellow';
      } else {
        return 'red';
      }
    }
  }

  return ''; // Return empty string if content doesn't contain a valid percentage
};
const DashboardTable2: React.FC<DashboardTableProps> = ({ combinedData }) => {
  const { tableData, mockColumnData } = combinedData;

  return (
    <TableWrapper>
      <Table>
        <thead>
          <tr>
            <TableHeaderCells colSpan={mockColumnData.length + 4}>
              Rural Health Units and BHS in Province
            </TableHeaderCells>
          </tr>
          <tr>
            {mockColumnData.map((header, colIndex) => (
              <React.Fragment key={colIndex}>
                {typeof header === 'object' ? (
                  <>
                    <TableHeaderCellz>{(header as SubColumn).title}</TableHeaderCellz>
                  </>
                ) : (
                  <TableHeaderCell colSpan={2}>{header}</TableHeaderCell>
                )}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              {rowData.map((cellText, colIndex) => {
                const backgroundColor = calculatePercentageColor(cellText);
                return (
                  <TableCell key={colIndex} style={{ backgroundColor }}>
                    {cellText}
                  </TableCell>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default DashboardTable2;
