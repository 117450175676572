import { Box, Button, Chip } from '@mui/material';
import {
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  CustomTable,
  FacilityLocationFilter,
  PrimaryButton,
  RegularButton,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';

import { ApiQuery } from 'model/interface';
import { FacilityModel } from 'model/Entities';
import FacilityTableAddModal from './FacilityTableAddModal';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getGroupWithFacility } from 'api/facility-group';
import { removeFacilityFromGroup } from 'api/facility';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type FacilityTableViewModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupName: string;
  groupId: number;
  assessorId: number;
  provinceName: string;
  provinceCode: string;
  refreshGroupTable: () => void;
};

const FacilityTableViewModal: React.FC<FacilityTableViewModalProps> = ({
  open,
  setOpen,
  groupName,
  groupId,
  assessorId,
  provinceName,
  provinceCode,
  refreshGroupTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<FacilityModel[]>([]);
  const [facilityCount, setFacilityCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  //open dialogue confirmation box for single select
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  //open dialogue confirmation box for multiple selects
  const [openDeleteConfirmationAll, setOpenDeleteConfirmationAll] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [openFacilityModal, setOpenFacilityModal] = useState<boolean>(false);
  const [selectedFacility, setSelectedFacility] = useState(0);
  const [otherFilters, setOtherFilters] = useState<any>({});

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'facility_code',
      sortable: false,
      headerName: 'Facility Code',
      flex: 1,
    },
    {
      field: 'facility_name',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
    },
    {
      field: 'facility_type',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.facility_type}</CustomGridCell>,
    },

    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.municipality}
          {', '}
          {params.row.province}
          {', '}
          {params.row.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 0.7,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data) => (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={() => {
            deleteFac(data);
          }}
        >
          Remove
        </Button>
      ),
    },
  ];

  // Functions
  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
  };

  const deleteFac = (data: any) => {
    setSelectedFacility(data.id);
    setOpenDeleteConfirmation(true);
  };
  const getAssignedFacilities = (query?: ApiQuery) => {
    setLoading(true);
    getGroupWithFacility(groupId, {
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setFacilityCount(res.data.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeFacility = (facilities: any) => {
    const facilitiesArr = JSON.stringify(facilities);

    removeFacilityFromGroup({ facilities: facilitiesArr }).then((res) => {
      enqueueSnackbar('Facility successfully remove from the group!', {
        variant: 'success',
      });
      getAssignedFacilities();
      refreshGroupTable();
    });
  };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  return (
    <>
      <CustomModal
        header={`Facilities in ${groupName}`}
        open={open}
        setOpen={setOpen}
        width={1080}
        headerComponent={<Chip label={provinceName} size="small" variant="outlined" />}
      >
        <CustomTable
          searchKeys="Facility Code or Facility Name"
          columns={columns}
          rows={rows}
          rowCount={facilityCount}
          loading={loading}
          getData={getAssignedFacilities}
          forceRefresh={refresh}
          checkboxSelection
          isRowSelectable
          disableSelectionOnClick
          handleSelectRow={setSelectionModel}
          tableHeight={400}
          otherFilters={otherFilters}
          filterComponent={
            <Box display="flex" gap="10px" width="100%" alignItems="center">
              <FacilityLocationFilter
                hideProvinceDefault
                defaultProvince={provinceCode}
                handleChangeFilter={(data) => {
                  setOtherFilters({ ...otherFilters, ...data });
                }}
                otherFilters={otherFilters}
              />
              <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
            </Box>
          }
          headerComponent={
            <Box gap="15px" display="flex">
              <RegularButton
                startIcon={<RemoveCircleIcon />}
                onClick={() => {
                  setOpenDeleteConfirmationAll(true);
                }}
                label={'Remove selected facilities'}
                color="error"
                disabled={!selectionModel.length}
              />
              <PrimaryButton
                onClick={() => {
                  setOpenFacilityModal(true);
                }}
                label={'Add Facility'}
              />
            </Box>
          }
        />
      </CustomModal>
      <FacilityTableAddModal
        open={openFacilityModal}
        setOpen={setOpenFacilityModal}
        selectedGroup={groupId}
        groupName={groupName}
        provinceName={provinceName}
        provinceCode={provinceCode}
        assessorId={assessorId}
        refreshGroupTable={refreshGroupTable}
        refreshAssignedFacilityTable={refreshTable}
      />

      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure want to remove this facility?"
        onConfirm={() => removeFacility(selectedFacility)}
      />

      <ConfirmationDialog
        open={openDeleteConfirmationAll}
        setOpen={setOpenDeleteConfirmationAll}
        message="Are you sure want to remove these facilities?"
        onConfirm={() => removeFacility(selectionModel)}
      />
    </>
  );
};

export default FacilityTableViewModal;
