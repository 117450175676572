import ArticleContent, { imagePath } from '../ArticleContent';

const CreateIndicatorArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click "Add Indicators".', image: `${imagePath}/create-indicator-1.png` },
            { step: '2. Fill in the form, and click "Submit"', image: `${imagePath}/create-indicator-2.png` },
          ],
        },
      ]}
    />
  );
};

export default CreateIndicatorArticle;
