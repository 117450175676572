/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */

import { ApiQuery, FieldInput } from 'model/interface';
import { Box, Typography, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomForm,
  CustomGridCell,
  CustomModal,
  CustomTable,
  Header,
  PrimaryButton,
  ProtectedComponent,
  RowAction,
} from 'components';
import { ReportEntryModel, ReportToolQueryResponse } from 'model/Entities';
import {
  createReportEntry,
  createReportEntryAssessAll,
  deleteReportEntry,
  duplicateReportEntry,
  getReportEntriesByReportID,
  updateReportEntryByID,
  updateReportEntryStatus,
} from 'api/report-entry';
import { formatDate, formatDateBoxDate, getStatusChip } from 'utils';
import { useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { DropdownOptionProps } from 'components/Dropdown';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import { UserContext } from 'context/user.context';
import { create_report_entry_schema } from 'model/schema';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';
import saveAs from 'file-saver';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { getReportEntryIndicators } from 'api/facility-report-entry';

type ReportEntriesProps = {
  reportID: number;
  reportDetails?: ReportToolQueryResponse;
  heandleClose: () => void;
};

interface ReportEntryFormField extends FieldInput {
  field_name: keyof ReportEntryModel;
}

interface ReportEntriesResponse {
  count: number;
  rows: ReportEntryModel[];
}

const initialValues = {
  assess_all_facilities: false,
  deadline: '',
  month: '',
};

const ReportEntries: React.FC<ReportEntriesProps> = (prop: ReportEntriesProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const [formInitialValues, setFormInitialValues] = useState(initialValues);
  const [reportEntryTargetID, setReportEntryTargetID] = useState<number>();
  const [isConfirmDiagOpen, setIsConfirmDiagOpen] = useState(false);
  const [rows, setRows] = useState<ReportEntryModel[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const { user } = useContext(UserContext);

  const createNewReportEntryHandler = async (reportEntryData: ReportEntryModel) => {
    try {
      if (!showModal) throw 'Invalid Operation';
      reportEntryData.report_id = prop.reportID;

      // for checking whether to perform assess all facilities or normal creation
      formInitialValues.assess_all_facilities
        ? await createReportEntryAssessAll(reportEntryData)
        : await createReportEntry(reportEntryData);
      await getReportEntryDataHandler();

      setShowModal(false);
      enqueueSnackbar('Report Entry Added', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(`Report Entry Failed to add (${err})`, { variant: 'error' });
      setShowModal(false);
    }
  };

  const deleteReportEntryHandler = async (reportEntryData: ReportEntryModel) => {
    try {
      if (!reportEntryData.id) throw 'No ID Selected';
      setReportEntryTargetID(reportEntryData.id);
      setIsConfirmDiagOpen(true);
    } catch (err) {
      enqueueSnackbar(`Cannot Delete Report Entry (${err})`, { variant: 'error' });
      setShowModal(false);
    }
  };

  const getReportEntryDataHandler = async (query?: ApiQuery) => {
    setLoading(true);
    try {
      const response = await getReportEntriesByReportID(prop.reportID, { page: 1, length: 10, ...query });
      const responseData: ReportEntriesResponse = response.data;

      setRows(responseData.rows);
      setRowCount(responseData.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const updateReportEntryHandler = async (reportEntryData: ReportEntryModel) => {
    try {
      if (!showModal) throw 'Invalid Operation';
      if (!reportEntryTargetID || reportEntryTargetID < 0) throw 'Invalid Entry ID';

      await updateReportEntryByID(reportEntryTargetID, reportEntryData);
      await getReportEntryDataHandler();

      setShowModal(false);
      setIsEdit(false);
      enqueueSnackbar('Report Entry Updated!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(`Report Entry Failed to add (${err})`, { variant: 'error' });
      setShowModal(false);
    }
  };

  const duplicateReportEntryHandler = async (reportEntryData: ReportEntryModel) => {
    duplicateReportEntry(reportEntryData).then((response) => {
      enqueueSnackbar('Report Entry Duplicated', { variant: 'success' });
      getReportEntryDataHandler();
    });

    closeCustomModal();
  };

  const closeCustomModal = () => {
    setShowModal(false);
    setIsDuplicating(false);
    setFormInitialValues(initialValues);
  };

  const exportAsCsv = (csvBlob: Blob, fileName: string) => {
    saveAs(csvBlob, fileName);
  };

  const updateStatus = async (data: ReportEntryModel, status: 'open' | 'active' | 'closed') => {
    if (!data.id) return;
    await updateReportEntryStatus(data.id, status);
    await getReportEntryDataHandler();
  };

  const dropdownDeleteAction = (data: ReportEntryModel) => {
    if (!data.id) return;
    deleteReportEntryHandler(data);
  };

  const dropdownUpdateAction = (data: ReportEntryModel) => {
    if (!data.id) return;

    setFormInitialValues({
      deadline: formatDateBoxDate(new Date(data.deadline)),
      month: data.month,
      assess_all_facilities: false,
    });

    setReportEntryTargetID(data.id);
    setIsEdit(true);
    setShowModal(true);
  };

  const dropdownDuplicateAction = (data: any) => {
    setIsDuplicating(true);
    setFormInitialValues(data);
    setShowModal(true);
  };

  const downloadResponses = (data: any) => {
    console.log(data);
    getReportEntryIndicators(data.id).then((response) => {
      if (!response.data.csvFormatted)
        return enqueueSnackbar('Report Entry Indicators Unavailable', { variant: 'warning' });
      const blobData = new Blob([response.data.csvFormatted], { type: 'text/csv' });
      exportAsCsv(blobData, data.report.name + ' ' + data.month + '.csv');
    });
  };

  const actions: DropdownOptionProps[] = [
    {
      label: 'Update',
      action: dropdownUpdateAction,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete',
      action: dropdownDeleteAction,
      startIcon: <DeleteOutlinedIcon />,
    },
    {
      label: 'Activate',
      action: (data) => updateStatus(data, 'active'),
      startIcon: <ToggleOnOutlinedIcon />,
      hideCallback: (data: ReportEntryModel) => data.status !== 'open',
    },
    {
      label: 'Reopen',
      action: (data: any) => updateStatus(data, 'open'),
      hideCallback: (data: ReportEntryModel) => data.status === 'open',
      startIcon: <LockOpenIcon />,
    },
    {
      label: 'Close',
      action: (data: any) => updateStatus(data, 'closed'),
      hideCallback: (data: ReportEntryModel) => data.status === 'closed',
      startIcon: <CloseIcon />,
    },
    {
      label: 'Duplicate',
      action: dropdownDuplicateAction,
      startIcon: <ContentCopyIcon />,
    },
    {
      label: 'Download Responses',
      action: downloadResponses,
      startIcon: <DownloadOutlinedIcon />,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'month',
      sortable: false,
      headerName: 'Period',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          <Link
            to={`/reports/${params.row.id}`}
            style={{
              color: colors.accent,
              textDecoration: 'none',
              width: '100%',
            }}
          >
            {params.row.month}
          </Link>
        </CustomGridCell>
      ),
    },
    {
      field: 'deadline',
      sortable: false,
      headerName: 'Deadline',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.deadline)}</CustomGridCell>,
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      hide: !user?.authorities?.includes('MANAGE_REPORTS'),
      renderCell: (params) => <RowAction actions={actions} data={params.row} />,
    },
  ];

  const displayName =
    prop.reportDetails?.frequency === 'yearly'
      ? 'Year'
      : prop.reportDetails?.frequency === 'quarterly'
      ? 'Quarter'
      : 'Month';

  const formFields: ReportEntryFormField[] = [
    {
      field_name: 'month',
      display_name: displayName,
      required: true,
      type: 'text',
      span: 4,
    },
    {
      field_name: 'deadline',
      display_name: 'Deadline',
      required: true,
      type: 'date',
      span: 4,
    },
    {
      field_name: 'assess_all_facilities',
      display_name: 'Assess All Facilities',
      type: 'checkbox',
      span: 4,
      hidden: isEdit || isDuplicating,
      onChange(value, setFieldValue) {
        const initialValuesCopy = formInitialValues;
        initialValuesCopy.assess_all_facilities = value;
        setFormInitialValues(initialValuesCopy);
      },
    },
  ];

  useEffect(() => {
    getReportEntryDataHandler();
  }, [prop]);

  return (
    <>
      <Box>
        <CustomContainer>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
            <Header title={'Report Entry'} subtitle={prop.reportDetails ? prop.reportDetails.name : ''} mb="0" />
          </Box>
          <CustomTable
            searchKeys="Period"
            getData={getReportEntryDataHandler}
            loading={loading}
            columns={columns}
            rowCount={rowCount}
            rows={rows}
            forceRefresh={0}
            headerComponent={
              <ProtectedComponent requiredAuth={['MANAGE_REPORTS']}>
                <PrimaryButton
                  label={'Add Report Entry'}
                  onClick={() => {
                    setShowModal(true);
                    setIsEdit(false);
                  }}
                />
              </ProtectedComponent>
            }
          />
        </CustomContainer>
      </Box>
      <CustomModal
        header={isDuplicating ? '' : isEdit ? 'Update Report Entry' : 'Create Report Entry'}
        open={showModal}
        headerComponent={
          isDuplicating ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
              }}
            >
              <Typography variant="h3" component="h2">
                Duplicate Report Entry
              </Typography>
              <Typography component="h2">
                Duplicating <b>{formInitialValues.month}</b> Report Entry will also copy the added facilities
              </Typography>
            </Box>
          ) : (
            ''
          )
        }
        onClose={closeCustomModal}
        setOpen={() => {}}
        width={500}
      >
        <CustomForm
          fields={formFields}
          initialValues={formInitialValues}
          schema={create_report_entry_schema}
          onSubmit={
            isEdit
              ? updateReportEntryHandler
              : isDuplicating
              ? duplicateReportEntryHandler
              : createNewReportEntryHandler
          }
        />
      </CustomModal>
      <ConfirmationDialog
        open={isConfirmDiagOpen}
        setOpen={setIsConfirmDiagOpen}
        message={'Delete this entry?'}
        onConfirm={() => {
          if (reportEntryTargetID)
            deleteReportEntry(reportEntryTargetID)
              .then((response) => {
                getReportEntryDataHandler();
                setIsConfirmDiagOpen(false);
                enqueueSnackbar('Deleted report entry', { variant: 'success' });
              })
              .catch((err) => {
                enqueueSnackbar('Failed to delete report entry', { variant: 'error' });
                setIsConfirmDiagOpen(false);
              });
        }}
      />
    </>
  );
};

export default ReportEntries;
