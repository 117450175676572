import { Handle, Position, useStore } from 'reactflow';
import { Typography, useTheme } from '@mui/material';

import { tokens } from 'context/theme.context';

const connectionNodeIdSelector = (state: { connectionNodeId: any }) => state.connectionNodeId;
type Props = {
  id: string;
  data: any;
};

const CustomNode: React.FC<Props> = ({ id, data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const connectionNodeId = useStore(connectionNodeIdSelector);

  const isConnecting = !!connectionNodeId;
  const isTarget = connectionNodeId && connectionNodeId !== id;

  return (
    <div className="customNode">
      <div
        className="customNodeBody"
        style={{
          borderStyle: isTarget ? 'dashed' : 'solid',
          backgroundColor: isTarget ? colors.lightGreen3 : '#ccd9f6',
          border: '2px solid black',
          padding: '10px',
          width: '150px',
          minHeight: '80px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          position: 'relative',

          // width: 150px;
          // height: 80px;
          // border: 2px solid black;
          // position: relative;
          // overflow: hidden;
          // border-radius: 10px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // font-weight: bold;
        }}
      >
        {/* If handles are conditionally rendered and not present initially, you need to update the node internals https://reactflow.dev/docs/api/hooks/use-update-node-internals/ */}
        {/* In this case we don't need to use useUpdateNodeInternals, since !isConnecting is true at the beginning and all handles are rendered initially. */}
        {!isConnecting && <Handle className="customHandle" position={Position.Right} type="source" />}

        <Handle className="customHandle" position={Position.Left} type="target" isConnectableStart={false} />
        <Typography textAlign="center"> {data?.label}</Typography>
      </div>
    </div>
  );
};

export default CustomNode;
