/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button } from '@mui/material';
import {
  ConfirmationDialog,
  CustomGridCell,
  CustomModal,
  CustomTable,
  FacilityLocationFilter,
  RegularButton,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import {
  createReportEntry,
  createReportEntryToAllAvailable,
  deleteAllFacilityReportEntries,
  deleteReportEntry,
  getFacilitiesNotInEntry,
  getFacilityReportEntriesByReportEntryId,
} from 'api/facility-report-entry';
import { useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { FacilityModel } from 'model/Entities';
import { useSnackbar } from 'notistack';

type FacilityReportEntryAddModalProps = {
  open: boolean;
  selectedReportEntry: number;
  remove: boolean;
  setOpen: (open: boolean) => void;
  refreshTable: () => void;
};

const FacilityReportEntryModal: React.FC<FacilityReportEntryAddModalProps> = ({
  open,
  setOpen,
  selectedReportEntry,
  remove,
  refreshTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addAll, setAddAll] = useState(false);
  const [removeAll, setRemoveAll] = useState(false);
  const [rows, setRows] = useState<FacilityModel[]>([]);
  const [facilityCount, setFacilityCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>({});
  const [selectedFacilities, setSelectedFacilities] = useState<GridSelectionModel>([]);
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(0);

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'facility_code',
      sortable: false,
      headerName: 'Facility Code',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>{remove ? params.row.facility.facility_code : params.row.facility_code}</CustomGridCell>
      ),
    },
    {
      field: 'facility_name',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>{remove ? params.row.facility.facility_name : params.row.facility_name}</CustomGridCell>
      ),
    },
    {
      field: 'facility_type_id',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {remove ? params.row.facility.facility_type.type : params.row.facility_type.type}
        </CustomGridCell>
      ),
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {remove ? params.row.facility.municipality.municipality_name : params.row.municipality.municipality_name}
          {', '}
          {remove ? params.row.facility.province.province_name : params.row.province.province_name}
          {', '}
          {remove ? params.row.facility.barangay_name : params.row.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 0.7,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color={remove ? 'error' : 'success'}
          onClick={() => {
            // bug on removing facility here (selected facilities still set)
            if (remove) {
              setSelectedFacilities([params.row.id]);
              setOpenModal(true);
              return;
            }
            createReportEntryForFacility([params.row.id]);
          }}
        >
          {remove ? 'Remove' : 'Add'}
        </Button>
      ),
    },
  ];

  // Functions
  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
  };

  // modified for dialog cancel button
  const handleClose = (open: boolean) => {
    setSelectedFacilities([]);
    setOpenModal(open);
    setRemoveAll(false);
  };

  const getAvailableFacilities = async (query?: ApiQuery) => {
    setLoading(true);
    setRows([]);
    try {
      if (remove) {
        const res = await getFacilityReportEntriesByReportEntryId(selectedReportEntry, 'report_entry', {
          page: 1,
          length: 10,
          ...query,
        });
        setRows(res.data.rows);
        setFacilityCount(res.data.count);
        setLoading(false);
      } else {
        const res = await getFacilitiesNotInEntry(selectedReportEntry, { page: 1, length: 10, ...query });
        setRows(res.data.rows);
        setFacilityCount(res.data.count);
        setLoading(false);
      }
    } catch (err) {
      setRows([]);
      setLoading(false);
    }
  };

  const createReportEntryForFacility = async (facilities: number[]) => {
    try {
      setLoadingAdd(true);
      addAll
        ? await createReportEntryToAllAvailable(selectedReportEntry)
        : await createReportEntry(selectedReportEntry, { facilities });
      enqueueSnackbar(
        `Successfully created report entry for the ${selectedFacilities.length > 1 ? 'facilities' : 'facility'}`,
        { variant: 'success' }
      );
      refreshTable();
      setRefresh((prev: number) => prev + 1);
    } finally {
      setAddAll(false);
      setLoadingAdd(false);
    }
  };

  const removeReportEntryForFacility = async (facilities: number[]) => {
    try {
      setLoadingAdd(true);
      removeAll ? await deleteAllFacilityReportEntries(selectedReportEntry) : await deleteReportEntry({ facilities });
      enqueueSnackbar(`Successfully removed ${selectedFacilities.length > 1 ? 'facilities' : 'facility'}`, {
        variant: 'success',
      });

      refreshTable();
      setRefresh((prev: number) => prev + 1);
    } finally {
      setRemoveAll(false);
      setLoadingAdd(false);
    }
  };

  return (
    <>
      <CustomModal
        header={remove ? 'Remove Facility' : 'Add Facility'}
        open={open}
        setOpen={setOpen}
        width={1080}
        onClose={() => setSelectedFacilities([])}
      >
        <CustomTable
          searchKeys="Facility Name, Report Name, or Location"
          columns={columns}
          rows={rows}
          rowCount={facilityCount}
          loading={loading}
          getData={getAvailableFacilities}
          checkboxSelection
          isRowSelectable
          disableSelectionOnClick
          handleSelectRow={setSelectedFacilities}
          tableHeight={400}
          otherFilters={otherFilters}
          forceRefresh={refresh}
          filterComponent={
            <Box display="flex" gap="10px" width="100%" alignItems="center">
              <FacilityLocationFilter
                handleChangeFilter={(data) => {
                  setOtherFilters({ ...otherFilters, ...data });
                }}
                otherFilters={otherFilters}
              />
              <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
            </Box>
          }
          headerComponent={
            <Box gap="15px" display="flex">
              <RegularButton
                onClick={() => {
                  if (remove) return setOpenModal(true);
                  createReportEntryForFacility(selectedFacilities as number[]);
                }}
                label={remove ? 'Remove selected facilities' : 'Add selected facilities'}
                disabled={!selectedFacilities.length}
                loading={loadingAdd}
                color={remove ? 'error' : 'success'}
              />
              <RegularButton
                onClick={() => {
                  if (remove) setRemoveAll(true);
                  else setAddAll(true);
                  setOpenModal(true);
                }}
                label={remove ? 'Remove all facilities' : 'Add all facilities'}
                loading={loadingAdd}
                color={remove ? 'error' : 'success'}
              />
            </Box>
          }
        />
      </CustomModal>
      <ConfirmationDialog
        open={openModal}
        setOpen={handleClose}
        message={`Are you sure want to ${remove ? 'remove' : 'add'} facility/facilities?`}
        onConfirm={async () => {
          remove
            ? await removeReportEntryForFacility(selectedFacilities as number[])
            : await createReportEntryForFacility(selectedFacilities as number[]);
          setSelectedFacilities([]);
        }}
      />
    </>
  );
};

export default FacilityReportEntryModal;
