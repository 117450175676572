/* eslint-disable react-hooks/exhaustive-deps */
import {
  ConditionalHideComponent,
  ConfirmationDialog,
  CustomIconButton,
  CustomModal,
  CustomRadioGroup,
  DraggableList,
  RegularButton,
  RowAction,
} from 'components';
import React, { useEffect, useState } from 'react';

import AddFieldDropdown from '../components/AddFieldDropdown';
import { Box } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditField from '../components/EditField';
import EditFieldDropdown from '../components/EditFieldDropdown';
import EditTextField from '../components/EditTextField';
import { FIELD_TYPES } from './FieldGroups';
import { FieldType } from '../IndicatorBuilder';

type RadioFieldProps = {
  field: FieldType;
  deleteField: () => void;
  addField: (field_type: FIELD_TYPES) => void;
  editApplicability: () => void;
  respond?: boolean;
  missing?: boolean;
  handleChange: (field: any, property: keyof FieldType, data: any) => void;
  setMissingItems: React.Dispatch<React.SetStateAction<FieldType[]>>;
  index: number;
  childSetTotalFieldsCount?: React.Dispatch<React.SetStateAction<number[]>>;
  childSetTotalFieldsWithReponseCount?: React.Dispatch<React.SetStateAction<number[]>>;
};

type RadioFieldOptionProps = {
  option: any;
  index: number;
  handleDeleteOption: (index: number) => void;
  handleAddNewOption: (index: number) => void;
  handleSaveOption: (index: number, newValue: string) => void;
};

const RadioFieldOption: React.FC<RadioFieldOptionProps> = ({
  option,
  index,
  handleDeleteOption,
  handleAddNewOption,
  handleSaveOption,
}) => {
  const [edit, setEdit] = useState(false);
  const [newName, setNewName] = useState(option.key);

  const handleEditSaveClick = (value: string) => {
    setNewName(value);
    setEdit(false);
    handleSaveOption(index, value);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <EditTextField
        fieldName="Edit Option"
        edit={edit}
        handleSave={(value) => handleEditSaveClick(value)}
        initialValue={newName}
      />
      <RowAction
        actions={[
          {
            label: 'Edit',
            action: () => setEdit(true),
            hidden: edit,
          },
          {
            label: 'Delete',
            action: () => handleDeleteOption(index),
          },
        ]}
      />
    </Box>
  );
};

const RadioField: React.FC<RadioFieldProps> = ({
  field,
  deleteField,
  addField,
  editApplicability,
  respond,
  missing,
  handleChange,
  setMissingItems,
  index,
  childSetTotalFieldsCount,
  childSetTotalFieldsWithReponseCount,
}) => {
  const [edit, setEdit] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [editOptionsModalOpen, setEditOptionsModalOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [deletedOptionIndex, setDeletedOptionIndex] = useState<number | null>(null);
  const [value, setValue] = useState('');

  const handleDragEndModal = (result: any) => {
    if (!result.destination) return;

    const updatedOptions = Array.from(options);
    const [removed] = updatedOptions.splice(result.source.index, 1);
    updatedOptions.splice(result.destination.index, 0, removed);

    setOptions(updatedOptions);
  };

  useEffect(() => {
    if (field) {
      respond && setValue(field.response ?? '');
      const options_temp = field.response_type === 'y/n' ? ['Yes', 'No'] : field.options!;
      setOptions(
        options_temp.map((option: string) => {
          return { key: option, value: option };
        })
      );
    }
  }, [field]);

  useEffect(() => {
    if (!value && value !== '0' && missing) {
      setMissingItems((prev) => [...prev, field]);
    }
    if (childSetTotalFieldsCount && childSetTotalFieldsWithReponseCount) {
      childSetTotalFieldsCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = 1;
        return newValue;
      });
      childSetTotalFieldsWithReponseCount((prev: any) => {
        const newValue = [...prev];
        newValue[index] = !value ? 0 : 1;
        return newValue;
      });
    }
  }, [value, missing]);

  const handleDeleteOption = (index: number) => {
    setDeletedOptionIndex(index);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsConfirmationDialogOpen(false);

    if (deletedOptionIndex !== null) {
      const updatedOptions = [...options];
      updatedOptions.splice(deletedOptionIndex, 1);
      setOptions(updatedOptions);

      const updatedOptionsData = [...options];
      updatedOptionsData.splice(deletedOptionIndex, 1);
      setOptions(updatedOptionsData);

      setDeletedOptionIndex(null);
    }
  };

  const handleAddNewOption = () => {
    const newOption = {
      key: `New Option ${options.length + 1}`,
      value: `New Option ${options.length + 1}`,
    };

    setOptions([...options, newOption]);
  };

  const handleEditSaveClick = (value: string) => {
    setEdit(false);
  };

  const handleSaveOption = (index: number, newValue: string) => {
    const updatedOptions = options.map((option, i) => (index === i ? { key: newValue, value: newValue } : option));
    setOptions(updatedOptions);
  };

  useEffect(() => {
    field.response = value;
    handleChange(field, 'response', value);
  }, [value]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <EditField
          missing={!value && missing}
          edit={edit}
          handleSave={(value) => handleEditSaveClick(value)}
          field={field}
        />

        <ConditionalHideComponent hide={respond}>
          <Box display="flex" gap="2px">
            <EditFieldDropdown
              optionList={[
                {
                  label: 'Edit Field',
                  action: () => setEdit(true),
                  hidden: edit,
                },
                {
                  label: 'Edit Options',
                  action: () => setEditOptionsModalOpen(true),
                  hidden: field.response_type === 'y/n',
                },
                {
                  label: 'Edit Applicability',
                  action: editApplicability,
                },
              ]}
            />
            <AddFieldDropdown addField={addField} />
            <CustomIconButton
              icon={<DeleteOutlinedIcon />}
              tooltip="Remove field"
              onClick={deleteField}
              color="error"
            />
          </Box>
        </ConditionalHideComponent>
      </Box>
      <Box width="100%">
        <CustomRadioGroup
          label=""
          fieldName="field_name"
          options={options}
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
          lock={!respond}
          value={value}
          missing={missing}
          handleChange={(value) => setValue(value)}
        />
      </Box>
      <CustomModal header="Edit Options" open={editOptionsModalOpen} setOpen={setEditOptionsModalOpen} width={500}>
        <Box paddingBottom="20px">
          <RegularButton label="Add new option" onClick={handleAddNewOption} />
        </Box>
        <DraggableList
          list={options}
          droppableId="edit-options"
          handleDragEnd={handleDragEndModal}
          dragIconPaddingTop="5px"
          displayItem={(option, index) => (
            <RadioFieldOption
              index={index}
              option={option}
              handleAddNewOption={handleAddNewOption}
              handleDeleteOption={handleDeleteOption}
              handleSaveOption={handleSaveOption}
            />
          )}
          style={{ padding: '2px' }}
          fromModal
        />
      </CustomModal>

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        setOpen={setIsConfirmationDialogOpen}
        message="Are you sure you want to delete this field?"
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default RadioField;
