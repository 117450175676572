import { Button, CircularProgress, useTheme } from '@mui/material';

import { ReactNode } from 'react';
import { tokens } from 'context/theme.context';

type SubmitButtonProps = {
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  icon?: ReactNode; // New prop for icon
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  loading,
  disabled,
  icon, // New prop for icon
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Button
      type="submit"
      variant="contained"
      disabled={disabled || loading}
      startIcon={loading ? undefined : icon} // Use the icon prop as the startIcon
      sx={{
        backgroundColor: colors.primary,
        textTransform: 'none',
        minWidth: 100,
      }}
    >
      {!loading ? label || 'Submit' : <CircularProgress color="inherit" size="1.2rem" />}
    </Button>
  );
};

export default SubmitButton;
