import { ApiQuery } from 'model/interface';
import { ReportModel } from 'model/Entities';
import axios from 'utils/custom_axios';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'report';

export const getReport = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAllReports = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getAllReportsByFacility = (facility_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/facility/${facility_id}`, { params: query });
};

export const createReport = (batch: ReportModel) => {
  return axios.post(`${baseURL}/${path}`, batch);
};

export const updateReport = (id: number, batch: ReportModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, batch);
};

export const updateReportStatus = (id: number, status: 'monthly' | 'quarterly' | 'yearly') => {
  return axios.put(`${baseURL}/${path}/${status}/${id}`);
};

export const deleteReport = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
