import ArticleContent, { imagePath } from '../ArticleContent';

const AddFacilitiesToBatchArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            {
              step: '1. Select the row of the chosen assessment (make sure that the assessment has a status of "open").',
              image: `${imagePath}/add-facilities-to-assessment-1.png`,
            },
            {
              step: '2. Click the "Facilities to be assessed" tab.',
            },
            {
              step: '3. Click the "Add Facilities" button, and then a modal will appear after the button is clicked.',
              image: `${imagePath}/add-facilities-to-assessment-2.png`,
            },
            {
              step: '4. Select the facilities to be added, then click "Add selected facilities".',
            },
            {
              step: 'Note: A single facility can also be added by selecting the "Add" button on the right side.',
              image: `${imagePath}/add-facilities-to-assessment-2.png`,
            },
            {
              step: '5. The added facilities can be seen on the table after the action',
              image: `${imagePath}/facilities-in-assessment.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default AddFacilitiesToBatchArticle;
