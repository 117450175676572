import { Autocomplete, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { tokens } from 'context/theme.context';

type CustomAutoCompleteSelectProps = {
  options: any[];
  label: string;
  value?: any; // Make the value prop required
  fieldName: string;
  span?: number;
  touched?: boolean;
  error?: any;
  handleChange?: (event: any, value: any) => void;
  handleChangeCallback?: (value: any) => void;
  disabled?: boolean;
  required?: boolean;
  size?: 'small' | 'medium' | undefined;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  width?: string;
  keyLabel?: string;
  type?: string;
};

const CustomAutoCompleteSelect: React.FC<CustomAutoCompleteSelectProps> = ({
  options,
  label,
  value,
  fieldName,
  span,
  handleChange,
  handleChangeCallback,
  disabled,
  touched,
  error,
  required,
  size = 'small',
  setFieldValue,
  width,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedOption, setSelectedOption] = useState<any>(
    value ? options.find((option) => option.value === value) : undefined
  );

  const handleSelectChange = (_event: any, value: any) => {
    setSelectedOption(value);
    _event.target.id = { fieldName };
    handleChange && handleChange(_event, value);
    if (value) {
      setFieldValue && setFieldValue(fieldName, value.value);
    } else {
      setFieldValue && setFieldValue(fieldName, null);
    }
    handleChangeCallback && handleChangeCallback(value);
  };

  useEffect(() => {
    if (value) setSelectedOption(value ? options.find((option) => option.value === value) : undefined);
  }, [options, value]);

  return (
    <Autocomplete
      id={fieldName}
      options={options}
      value={selectedOption || null}
      getOptionLabel={(option) => option.key}
      onChange={handleSelectChange}
      filterSelectedOptions
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          placeholder={label}
          error={!!touched && !!error}
          helperText={touched && error}
          required={required}
          size={size}
          sx={{
            '.MuiFormLabel-root.Mui-focused': { color: colors.light },
            '.MuiFilledInput-root': { borderRadius: 2, borderBottom: 'none' },
            '.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before': { border: 'none' },
            '.MuiFilledInput-root:before': { border: 'none' },
            '.Mui-disabled:before': { border: 'none !important' },
            '.MuiFilledInput-root:after': {
              borderBottom: `1px solid ${colors.primary}`,
              borderRadius: 2,
              height: '100%',
            },
            '.MuiFilledInput-input': { height: '22px' },
            '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-18px' },
            gridColumn: span ? 'span ' + span : undefined,
            width: width && width,
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={{
        gridColumn: span ? 'span ' + span : undefined,
        '& .MuiAutocomplete-tag': {
          height: '25px',
        },
      }}
    />
  );
};

export default CustomAutoCompleteSelect;
