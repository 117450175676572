import { ApiQuery } from 'model/interface';
import { FacilityModel } from 'model/Entities';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'facility';

export const getFacility = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getFacilities = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getFacilitiesNotInBatch = (batch_id: number, query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}/not-in-batch/${batch_id}`, { params: query });
};

export const getAllFacilities = () => {
  return axios.get(`${baseURL}/${path}/list/all`);
};

export const createFacility = (facility: FacilityModel) => {
  return axios.post(`${baseURL}/${path}`, facility);
};

export const updateFacility = (id: number, facility: FacilityModel) => {
  return axios.put(`${baseURL}/${path}/${id}`, facility);
};

export const deleteFacility = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getFieldAssessors = (type: string, facility_id: number) => {
  return axios.get(`${baseURL}/${path}/list/dropdown`, {
    params: { type, facility_id },
  });
};

export const getFieldAssessorsGroup = (province_code?: string) => {
  return axios.get(`${baseURL}/${path}/facility-group/get-assessors/${province_code}`);
};

export const getFacilityNotSelected = (query: ApiQuery, group_id: number) => {
  return axios.get(`${baseURL}/${path}/facility-group/not-selected/${group_id}`, {
    params: query,
  });
};

export const getAllFacilityTypes = () => {
  return axios.get(`${baseURL}/${path}/list/types`);
};

export const assignFacility = (data: any, group_id: number) => {
  return axios.post(`${baseURL}/${path}/facility-group/${group_id}`, data);
};

export const removeFacilityFromGroup = (data: any) => {
  return axios.delete(`${baseURL}/${path}/facility-group/remove-group-in-facility`, { data: data });
};

export const facilityImportCSV = (data: any) => {
  return axios.post(`${baseURL}/${path}/import-csv`, data);
};

export const getAvailableRegion = () => {
  return axios.get(`${baseURL}/${path}/location/regions`);
};

export const getAvailableProvince = (region_code?: string) => {
  return axios.get(`${baseURL}/${path}/location/provinces`, {
    params: { region_code },
  });
};

export const getAvailableMunicipality = (data: { region_code?: string; province_code?: string }) => {
  return axios.get(`${baseURL}/${path}/location/municipalities`, {
    params: data,
  });
};
