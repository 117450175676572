/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Chip, Typography, useTheme } from '@mui/material';
import {
  ConditionalHideComponent,
  CustomContainer,
  CustomDrawer,
  CustomGridCell,
  CustomTable,
  DropdownFilter,
  FacilityLocationFilter,
  Header,
  PrimaryButton,
  ProtectedComponent,
  RegularButton,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { formatDate, getStatusChip } from 'utils';
import { getFacilityReportEntriesByReportEntryId, getReportEntryDetailsByFacility } from 'api/facility-report-entry';
import { useContext, useEffect, useState } from 'react';

import { ApiQuery } from 'model/interface';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import ClearIcon from '@mui/icons-material/Clear';
import { DetailedReportFacilityDetails } from 'model/Entities';
import FacilityAssessmentDetails from './FacilityReportEntryDetails';
import FacilityReportEntryModal from './FaciilityReportEntryModal';
import { getReportEntrySummary } from 'api/report-entry';
import { tokens } from 'context/theme.context';
import { useParams } from 'react-router-dom';
import BackButton from 'components/buttons/BackButton';

type FacilityReportEntryScreenProps = {
  facilityID?: number;
};

const FacilityReportEntryScreen: React.FC<FacilityReportEntryScreenProps> = ({ facilityID }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { id } = useParams();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [clearSelected, setClearSelected] = useState(0);
  const [otherFilters, setOtherFilters] = useState<any>({});
  const [rows, setRows] = useState<DetailedReportFacilityDetails[]>([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [selectedReportDetails, setSelectedReportDetails] = useState<DetailedReportFacilityDetails>();
  const [selectedRow, setSelectedRow] = useState<number>(NaN);
  const [rowCount, setRowCount] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [subTitleDeadline, setSubtitleDeadline] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [openModal, setOpenModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  const getReportEntryDetails = async (query?: ApiQuery) => {
    if (facilityID) {
      // get facility reports not yet completed
      const details = await getReportEntryDetailsByFacility(facilityID, { ...query, ...otherFilters });
      const reportEntryDetails = details.data;

      setRowCount(reportEntryDetails.count);
      setRows(reportEntryDetails.rows);
    } else if (id) {
      const details = await getFacilityReportEntriesByReportEntryId(parseInt(id), 'report_entry', {
        ...query,
        ...otherFilters,
      });
      const reportEntryDetails = details.data;

      setRowCount(reportEntryDetails.count);
      setRows(reportEntryDetails.rows);
    }
  };

  const closeFacilityDetails = () => {
    setSelectedRow(NaN);
    setClearSelected((prev: number) => prev + 1);
  };

  const handleSelectedRow = (selected: GridSelectionModel) => {
    if (selected.length && selectedRow !== selected[0]) {
      setSelectedRow(selected[0] as number);
      const facilityReportEntry = rows.find((obj) => obj.id === selected[0]);

      if (facilityReportEntry) return setSelectedReportDetails(facilityReportEntry);
      return closeFacilityDetails();
    }
    closeFacilityDetails();
  };

  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }

    setOtherFilters({ ...otherFilters });
    setStatusFilter('');
  };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  useEffect(() => {
    if (id) {
      getReportEntrySummary(parseInt(id)).then((response) => {
        const data = response.data;
        const titleLabel = `${data.report.name} - ${data.month}`;

        setBreadcrumb([{ label: 'Reports', link: '/reports' }, { label: titleLabel }]);
        setSubtitleDeadline(formatDate(data.deadline));
        setTitle(titleLabel);
      });
    } else {
      setBreadcrumb([{ label: 'Reports' }]);
    }
  }, [id]);

  const columns: GridColDef[] = [
    {
      field: 'facility_id',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.facility.facility_name}</CustomGridCell>,
    },
    {
      field: 'facility_type',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility.facility_type.type}
          {params.row.facility.with_sbf && (
            <Chip label="with SBF" size="small" variant="outlined" sx={{ marginLeft: '5px', fontSize: '10px' }} />
          )}
        </CustomGridCell>
      ),
    },
    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.facility.municipality.municipality_name}
          {', '}
          {params.row.facility.province.province_name}
          {', '}
          {params.row.facility.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'assessor_id',
      sortable: false,
      headerName: 'Submitted by',
      flex: 1,
      renderCell: (params) => {
        return (
          <CustomGridCell>
            {params.row.assessor
              ? `${params.row.assessor.title ?? ''} ${params.row.assessor.first_name} ${params.row.assessor.last_name}`
              : '-'}
          </CustomGridCell>
        );
      },
    },
  ];

  const columnsForFacilityStaff: GridColDef[] = [
    {
      field: 'id',
      sortable: false,
      headerName: 'ID',
      flex: 0.2,
    },
    {
      field: 'report',
      sortable: false,
      headerName: 'Report',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.report_entry.report.name}</CustomGridCell>,
    },
    {
      field: 'report_entry',
      sortable: false,
      headerName: 'Period',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.report_entry.month}</CustomGridCell>,
    },
    {
      field: 'status',
      sortable: false,
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{getStatusChip(params.row.status, theme, colors)}</CustomGridCell>,
    },
    {
      field: 'deadline',
      sortable: false,
      headerName: 'Deadline',
      flex: 0.5,
      renderCell: (params) => <CustomGridCell>{formatDate(params.row.report_entry.deadline)}</CustomGridCell>,
    },
    {
      field: 'assessor_id',
      sortable: false,
      headerName: 'Submitted by',
      flex: 0.8,
      renderCell: (params) => {
        return (
          <CustomGridCell>
            {params.row.assessor
              ? `${params.row.assessor.title ?? ''} ${params.row.assessor.first_name} ${params.row.assessor.last_name}`
              : '-'}
          </CustomGridCell>
        );
      },
    },
  ];

  return (
    <ConditionalHideComponent hide={false}>
      {!facilityID ?  <BackButton label="Back to Reporting Tool tab" /> : undefined}
      <CustomDrawer
        gridTemplateColumns="1fr 400px"
        showChild={!Number.isNaN(selectedRow)}
        parentComponent={
          <CustomContainer>
            <CustomTable
              searchKeys="Facility Name or Location"
              columns={facilityID ? columnsForFacilityStaff : columns}
              clearSelected={clearSelected}
              rows={rows}
              rowCount={rowCount}
              loading={false}
              isRowSelectable
              handleSelectRow={handleSelectedRow}
              getData={getReportEntryDetails}
              forceRefresh={refresh}
              otherFilters={otherFilters}
              headerComponent={
                <Box>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb="10px">
                    <Header
                      title={title ? title : facilityID ? 'Reports to be completed' : 'Facility Report Entries'}
                      subtitle="Click the row to see more details"
                      mb="0"
                    />
                    {subTitleDeadline && (
                      <Typography fontWeight="bold">{`Deadline: ${
                        subTitleDeadline ? subTitleDeadline : 'Not available'
                      }`}</Typography>
                    )}
                  </Box>
                  {facilityID ? undefined : (
                    <ProtectedComponent requiredAuth={['MANAGE_REPORTS']}>
                      <Box>
                        <PrimaryButton
                          onClick={() => {
                            setRemoveModal(false);
                            setOpenModal(true);
                          }}
                          label="Add Facility"
                        />
                        <RegularButton
                          label="Remove Facility"
                          styles={{ marginLeft: '1em' }}
                          color="error"
                          startIcon={<ClearIcon />}
                          onClick={() => {
                            setRemoveModal(true);
                            setOpenModal(true);
                          }}
                        />
                      </Box>
                    </ProtectedComponent>
                  )}
                </Box>
              }
              filterComponent={
                <Box display="flex" gap="10px" width="100%" alignItems="center">
                  <DropdownFilter
                    maxWidth="200px"
                    label="Status"
                    handleChange={(e) => {
                      setStatusFilter(e.target.value);
                      setOtherFilters({
                        ...otherFilters,
                        status: e.target.value,
                      });
                    }}
                    value={statusFilter}
                    options={[
                      { key: 'Open', value: 'open' },
                      { key: 'Started', value: 'started' },
                      { key: 'Submitted', value: 'submitted' },
                      { key: 'Ongoing Approval', value: 'ongoing-approval' },
                      { key: 'Completed', value: 'completed', hidden: !!facilityID },
                    ]}
                  />
                  <FacilityLocationFilter
                    otherFilters={otherFilters}
                    handleChangeFilter={(data) => setOtherFilters({ ...otherFilters, ...data })}
                    hideFacilityType={!!facilityID}
                  />
                  <RegularButton label="Clear" onClick={() => clearFilter()} />
                </Box>
              }
            />
            {!facilityID && (
              <FacilityReportEntryModal
                open={openModal}
                remove={removeModal}
                refreshTable={refreshTable}
                selectedReportEntry={parseInt(id ? id : '')}
                setOpen={setOpenModal}
              />
            )}
          </CustomContainer>
        }
        childComponent={
          <FacilityAssessmentDetails handleClose={closeFacilityDetails} selectedFacility={selectedReportDetails} />
        }
      />
    </ConditionalHideComponent>
  );
};

export default FacilityReportEntryScreen;
