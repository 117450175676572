import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Typography } from '@mui/material';
import { UserContext } from 'context/user.context';
import styled from 'styled-components';

function NotFoundScreen() {
  const { user } = useContext(UserContext);
  return (
    <Container>
      <SentimentVeryDissatisfiedIcon style={{ fontSize: '100px', alignSelf: 'center', color: 'red' }} />
      <Typography variant="h2" component="h1" gutterBottom>
        404 Page Not Found!
      </Typography>
      <Typography variant="body1" align="center">
        The Page you are looking for doesn't exist or <br /> an other error occured.
      </Typography>
      <br />
      <Link to={user?.user_group.group_name === 'FA' ? '/monitor' : '/'} style={{ textDecoration: 'none' }}>
        <button
          style={{
            padding: '10px 20px',
            background: '#6fd8bd',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {user?.user_group.group_name === 'FA' ? 'Go to Monitor' : 'Go to Home'}
        </button>
      </Link>
    </Container>
  );
}

export default NotFoundScreen;

const Container = styled.div`
  height: 75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;
