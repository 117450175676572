import ArticleContent, { imagePath } from '../ArticleContent';

const CloseBatchArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            {
              step: '1. Select the row of the active assessment to be closed.',
              image: `${imagePath}/close-batch-1.png`,
            },
            { step: '2. Select the "Assessments" tab on the upper-right part.' },
            { step: '3. Click the "Close Assessments" button.', image: `${imagePath}/close-batch-2.png` },
            {
              step: '4. Upon clicking the button, the status of the assessment will be updated to "closed".',
              image: `${imagePath}/closed-assessment.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default CloseBatchArticle;
