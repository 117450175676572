/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Menu, MenuItem } from '@mui/material';
import { CustomForm, CustomModal } from 'components';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import React, { useState } from 'react';
import { createWidget, deleteWidget, reorderWidgets, updateWidget } from 'api/dashboard';

import AddIcon from '@mui/icons-material/Add';
import Column from './Column';
import { ConfirmationDialog } from 'components';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FieldInput } from 'model/interface';
import { FormikHelpers } from 'formik';
import { RegularButton } from 'components';
import { WidgetModel } from 'model/Entities';

interface RowProps {
  initialColumns: ColumnType[];
  layout: string;
  onDelete: () => void;
  group_id: number;
}

type ColumnType = {
  id: string;
  list: any[];
};

const Row: React.FC<RowProps> = ({ initialColumns, layout, onDelete, group_id }) => {
  const [columns, setColumns] = useState<ColumnType[]>(initialColumns);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [widgetTypeToAdd, setWidgetTypeToAdd] = useState<string>('');

  const [formValues, setFormValues] = useState<any>({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [editingWidget, setEditingWidget] = useState<any | null>(null);
  const [modalMode, setModalMode] = useState<'edit' | 'duplicate' | 'add' | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddWidget = (widgetType: string) => {
    setWidgetTypeToAdd(widgetType);
    setModalOpen(true);
    setModalMode('add');
    setFormValues({ title: '' });
  };

  const handleDuplicate = (widget: any) => {
    startEditingWidget(widget);
    setWidgetTypeToAdd(widget.type);
    setModalMode('duplicate');
    setFormValues(typeof widget.configuration === 'string' ? JSON.parse(widget.configuration) : widget.configuration);
  };

  const handleSubmit = async (data: any, { resetForm }: FormikHelpers<any>) => {
    const widgetToAdd: WidgetModel = {
      group_id,
      type: widgetTypeToAdd ? widgetTypeToAdd : editingWidget?.type,
      title: data.title,
      configuration: { ...data },
    };
    if (modalMode === 'edit') {
      await updateWidget(editingWidget.id, widgetToAdd);
      widgetToAdd.id = editingWidget.id;
      const columnIndex = columns.findIndex((col) => col.list.includes(editingWidget));
      const widgetIndex = columns[columnIndex].list.findIndex((widget) => widget === editingWidget);
      columns[columnIndex].list[widgetIndex] = widgetToAdd;
    } else {
      if (columns.length > 0) {
        const { data } = await createWidget(widgetToAdd);
        widgetToAdd.id = data.id;
        const selected_column = [...columns].sort((a, b) => a.list.length - b.list.length)[0];
        selected_column.list.push(widgetToAdd);
        saveWidgetsLayout(columns);
      }
    }
    setColumns([...columns]);
    resetForm();
    setModalOpen(false);
  };

  const saveWidgetsLayout = (columns: ColumnType[]) => {
    reorderWidgets(
      group_id,
      columns.map((col) => col.list.map((item) => item.id))
    );
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (destination === undefined || destination === null) {
      return;
    }
    if (source.droppableId === destination.droppableId && destination.index === source.index) return null;

    const start = columns.find((col) => col.id === source.droppableId);
    const end = columns.find((col) => col.id === destination.droppableId);

    if (start && end) {
      if (start === end) {
        const newList = start.list.filter((_: any, idx: number) => idx !== source.index);
        newList.splice(destination.index, 0, start.list[source.index]);
        start.list = newList;
      } else {
        const newStartList = start.list.filter((_: any, idx: number) => idx !== source.index);
        const newEndList = end.list;
        newEndList.splice(destination.index, 0, start.list[source.index]);

        start.list = newStartList;
        end.list = newEndList;
      }
      setColumns([...columns]);
      saveWidgetsLayout(columns);
    }
  };

  const handleDeleteConfirm = async () => {
    onDelete();
    setOpenDeleteConfirmation(false);
  };

  const startEditingWidget = (widget: any) => {
    setEditingWidget(widget);
    setModalMode('edit');
    setFormValues(typeof widget.configuration === 'string' ? JSON.parse(widget.configuration) : widget.configuration);
    setModalOpen(true);
  };

  const getGridTemplateColumns = () => {
    switch (layout) {
      case '4-4-4':
        return '1fr 1fr 1fr';
      case '3-3-3-3':
        return '1fr 1fr 1fr 1fr';
      case '6-6':
        return '1fr 1fr';
      case '8-4':
        return '2fr 1fr';
      case '4-8':
        return '1fr 2fr';

      default:
        return '1fr 1fr 1fr';
    }
  };

  const field_codes = [
    { value: '001', key: '001 - Type of Facility' },
    { value: '002', key: '002 - BEMONC certified only' },
    { value: '003', key: '003 - Konsulta Package Provider' },
  ];
  const formFields: FieldInput[] = [
    {
      field_name: 'title',
      display_name: 'Title',
      type: 'text',
      span: 4,
    },
    {
      field_name: 'dimension',
      display_name: 'Dimension',
      type: 'select',
      span: 4,
      options: field_codes,
      hidden: ['scorecard'].includes(widgetTypeToAdd ? widgetTypeToAdd : editingWidget?.type),
    },
    {
      field_name: 'breakdown_dimension',
      display_name: 'Breakdown Dimension',
      type: 'select',
      span: 4,
      options: field_codes,
      hidden: !['bar', 'line'].includes(widgetTypeToAdd ? widgetTypeToAdd : editingWidget?.type),
    },
    {
      field_name: 'metrics',
      display_name: 'Metrics',
      type: 'multiselect',
      span: 4,
      options: field_codes,
      hidden: !['bar', 'line'].includes(widgetTypeToAdd ? widgetTypeToAdd : editingWidget?.type),
    },
    {
      field_name: 'metric',
      display_name: 'Metric',
      type: 'select',
      span: 4,
      options: field_codes,
      hidden: !['pie', 'scorecard'].includes(widgetTypeToAdd ? widgetTypeToAdd : editingWidget?.type),
    },
  ];

  const confirmDeleteWidget = async (
    widget_id: number,
    column_id: string,
    setShowDeleteConfirmation: (val: boolean) => void
  ) => {
    const column = columns.find((col) => col.id === column_id);
    if (column) {
      await deleteWidget(widget_id);
      column.list = column.list.filter((item) => item.id !== widget_id);
      setColumns([...columns]);
      saveWidgetsLayout(columns);
    }
    setShowDeleteConfirmation(false);
  };

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <Box sx={{ padding: '20px' }}>
        <Box display="flex" gap="10px" mb="10px">
          <RegularButton onClick={handleClick} label="Add Widget" startIcon={<AddIcon />} />
          <RegularButton
            onClick={() => setOpenDeleteConfirmation(true)}
            label="Delete Widget Group"
            color="error"
            startIcon={<DeleteOutlinedIcon />}
          />
        </Box>
        <Menu id="add-widget-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => handleAddWidget('pie')}>Pie Chart</MenuItem>
          <MenuItem onClick={() => handleAddWidget('bar')}>Bar Graph</MenuItem>
          <MenuItem onClick={() => handleAddWidget('line')}>Line Chart</MenuItem>
          <MenuItem onClick={() => handleAddWidget('scorecard')}>Scorecard</MenuItem>
        </Menu>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: getGridTemplateColumns(),
            minHeight: '250px',
            gap: '20px',
            position: 'relative',
          }}
        >
          {columns.map((col) => (
            <Column
              list={col.list}
              column_id={col.id}
              key={col.id}
              duplicateWidget={handleDuplicate}
              startEditingWidget={startEditingWidget}
              confirmDeleteWidget={confirmDeleteWidget}
            />
          ))}
        </Box>
      </Box>

      <CustomModal
        open={modalOpen}
        header={
          modalMode === 'duplicate'
            ? `Duplicate ${widgetTypeToAdd.toUpperCase()}`
            : modalMode === 'edit'
            ? `Edit ${editingWidget?.type.toUpperCase()}`
            : `Add Widget: ${widgetTypeToAdd.toUpperCase()}`
        }
        setOpen={setModalOpen}
        onClose={() => {
          setEditingWidget(null);
          setWidgetTypeToAdd('');
          setModalMode(null);
        }}
        width={550}
      >
        <CustomForm initialValues={formValues} onSubmit={handleSubmit} fields={formFields} />
      </CustomModal>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure you want to delete this Group?"
        onConfirm={handleDeleteConfirm}
        cancelButtonLabel="No"
        confirmButtonLabel="Yes"
      />
    </DragDropContext>
  );
};

export default Row;
