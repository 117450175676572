/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'context/breadcrumb.context';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HelpDeskSidebar from './HelpDeskSidebar';
import { Link } from 'react-router-dom';
import { categories } from './categories';
import styled from 'styled-components';
import { tokens } from 'context/theme.context';
import { useParams } from 'react-router-dom';

function Category() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { categoryCode } = useParams<{ categoryCode: string }>();
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [categoryName, setCategoryName] = useState<string>('');

  useEffect(() => {
    const cat = categories.find((cat) => cat.categoryCode === categoryCode);
    setCategoryName(cat?.categoryName ?? '');

    const categoryData = categories.find((cat) => cat.categoryCode === categoryCode);
    setSelectedCategory(categoryData);
  }, [categoryCode]);

  useEffect(() => {
    if (selectedCategory) {
      setBreadcrumb([{ label: 'Help Desk', link: '/helpdesk' }, { label: categoryName }]);
    }
  }, [selectedCategory]);

  const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
      color: ${colors.primary};
    }
  `;

  return selectedCategory ? (
    <Con>
      <HelpDeskSidebar />
      <Container>
        <CategoryTitle variant="h2">{selectedCategory.category_name}</CategoryTitle>
        <ArticlesContainer>
          {selectedCategory.articles.map((article: any, index: number) => (
            <ArticleBox key={index}>
              <StyledLink to={`${window.location.pathname}/${article.article_code}`}>
                <FiberManualRecordIcon color="primary" />
                <Typography variant="h4" marginLeft="10px">
                  {article.article_title}
                </Typography>
              </StyledLink>
            </ArticleBox>
          ))}
        </ArticlesContainer>
      </Container>
    </Con>
  ) : (
    <>Category Not Found</>
  );
}

const Container = styled(Box)`
  padding: 3rem;
`;

const ArticlesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const ArticleBox = styled(Box)`
  margin: 1rem 0;
  padding-left: 1rem;
  position: relative;
`;

const CategoryTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Con = styled.div`
  display: flex;

  height: 100%;
`;

export default Category;
