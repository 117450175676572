import { ApiQuery } from 'model/interface';
import { AssessmentIndicatorModel } from 'model/Entities';
import axios from 'utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const path = 'assessment-indicator';

export const getAssessmentIndicator = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const getAssessmentIndicators = (query: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createAssessmentIndicators = (indicator: AssessmentIndicatorModel) => {
  return axios.post(`${baseURL}/${path}`, indicator);
};

export const duplicateAssessmentIndicators = (indicator_id: number) => {
  return axios.post(`${baseURL}/${path}/duplicate/${indicator_id}`);
};

export const updateAssessmentIndicator = (id: number, indicators: string) => {
  return axios.put(`${baseURL}/${path}/${id}`, { indicators });
};

export const editAssessmentIndicator = (id: number, name: string, validation_workflow_id: number) => {
  return axios.put(`${baseURL}/${path}/edit/${id}`, { name, validation_workflow_id });
};

export const deleteAssessmentIndicator = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
