import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { Lock } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { UserContext } from 'context/user.context';
import styled from 'styled-components';

const ForbiddenScreen = () => {
  const { user } = useContext(UserContext);
  return (
    <Container>
      <Lock style={{ fontSize: '100px', alignSelf: 'center', color: 'red' }} />
      <Typography variant="h2" component="h1" gutterBottom>
        403 Forbidden
      </Typography>

      <Typography variant="body1" align="center">
        The Page you are trying to reach is <br /> forbidden for some reason
      </Typography>
      <br />
      <Link to={user?.user_group.group_name === 'FA' ? '/monitor' : '/'} style={{ textDecoration: 'none' }}>
        <button
          style={{
            padding: '10px 20px',
            background: '#6fd8bd',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {user?.user_group.group_name === 'FA' ? 'Go to Monitor' : 'Go to Home'}
        </button>
      </Link>
    </Container>
  );
};

export default ForbiddenScreen;

const Container = styled.div`
  height: 75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;
