import ArticleContent, { imagePath } from '../ArticleContent';

const DeleteUserArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click Delete button.', image: `${imagePath}/delete-user-1.png` },
            {
              step: '2. Click "Yes" to confirm, or "Cancel" to cancel the action',
              image: `${imagePath}/delete-user-2.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default DeleteUserArticle;
