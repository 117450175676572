import { Box, Table, Typography, useTheme } from '@mui/material';

import { Fragment } from 'react';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import { NotApplicable } from '.';
import { tokens } from 'context/theme.context';

type ComplexTableProps = {
  rows: any[];
  headers: any[];
  headerCount: number;
  headerWidth: number;
  handleBodyClick: (field: any) => void;
  search: string;
  getHighlightedText: (text: string, searckKey: string) => any;
  fieldsWithComments: string[];
};

const ComplexTable: React.FC<ComplexTableProps> = ({
  headers,
  headerCount,
  headerWidth,
  rows,
  handleBodyClick,
  search,
  getHighlightedText,
  fieldsWithComments,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const displayResponse = (response: string, response_type: string) => {
    return (
      <Typography
        variant="body1"
        color={colors.text}
        sx={
          response === 'Yes' || response === 'No' || response === 'N/A'
            ? {
                width: response === 'N/A' ? '100px' : '40px',
                borderRadius: '10px',
                padding: '2px 10px',
                textAlign: 'center',
                fontSize: '11px',
                backgroundColor: response === 'Yes' ? '#6fd8bd' : response === 'No' ? '#e64647' : '#898989', // N/A
                color: response === 'Yes' ? '#3d3d3d' : 'white',
              }
            : undefined
        }
        style={{
          textAlign: 'center',
          margin: '0 auto',
        }}
      >
        {response === 'N/A' ? 'Not Applicable' : response}
      </Typography>
    );
  };

  return (
    <Box sx={{ overflowX: 'auto', border: '1px solid ' + colors.border }}>
      <Table
        sx={{
          border: '1px solid ' + colors.border,
          '& th, & td': {
            border: '1px solid ' + colors.border,
            padding: '5px',
          },
          '& tr:hover th, & tr:hover td': {
            backgroundColor: colors.solidHoverBackground,
          },
          '& th:first-child, & td:first-child': {
            position: 'sticky',
            left: 0,
            backgroundColor: colors.secondary_background,
            zIndex: 1,
          },
          '& tbody tr:nth-of-type(odd)': {
            backgroundColor: colors.solidHoverBackground,
            '& th:first-child, & td:first-child': {
              backgroundColor: colors.solidHoverBackground,
            },
          },
        }}
      >
        <thead>
          <tr>
            <th></th>
            {headers.map((item: any, index: number) => {
              return (
                <th style={{ width: `${headerWidth}%` }} key={index} colSpan={item.children ? item.children.length : 1}>
                  {item.name}
                </th>
              );
            })}
          </tr>
          {headers.find((item) => item.children) && (
            <tr>
              <th></th>
              {headers.map((item: any, index: number) => {
                return (
                  <Fragment key={index}>
                    {item.children ? (
                      <>
                        {item.children.map((child: any, index: number) => {
                          return (
                            <th style={{ width: `${headerWidth}%`, minWidth: '70px' }} key={index}>
                              {child.name}
                            </th>
                          );
                        })}
                      </>
                    ) : (
                      <th></th>
                    )}
                  </Fragment>
                );
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {rows
            .filter((row) => row.field_name.toLowerCase().includes(search.toLowerCase()))
            .map((row: any, index: number) => {
              return (
                <tr key={index} onClick={() => handleBodyClick(row)}>
                  <td style={{ minWidth: '340px' }}>
                    <Box display="flex" justifyContent="space-between" gap="20px" width="100%">
                      <Box display="grid" gridTemplateColumns="45px 1fr">
                        <Typography variant="body1" color={colors.text}>
                          {getHighlightedText(row.field_code, search)}.
                        </Typography>
                        <Typography variant="body1" color={colors.text} style={{ whiteSpace: 'pre-wrap' }}>
                          {getHighlightedText(row.field_name, search)}
                        </Typography>
                      </Box>
                      {(row.remarks || fieldsWithComments.includes(row.field_code)) && (
                        <ModeCommentOutlinedIcon style={{ color: colors.main }} />
                      )}
                    </Box>
                    {row.field_description && (
                      <Typography
                        variant="body2"
                        color={colors.text}
                        p={1}
                        style={{ textIndent: '30px', whiteSpace: 'pre-wrap' }}
                      >
                        {row.field_description}
                      </Typography>
                    )}
                  </td>
                  <>
                    {row.indicator_not_applicable ? (
                      <td colSpan={headerCount}>
                        <NotApplicable color={colors} />
                      </td>
                    ) : (
                      <>
                        {headers.map((item: any, index0: number) => {
                          if (item.children) {
                            return (
                              <Fragment key={index0}>
                                {item.children.map((_child: any, index: number) => {
                                  const key = item.name + ' | ' + _child.name;
                                  return (
                                    <td key={`${index0}-${index}`} style={{ textAlign: 'center' }}>
                                      {row.response && displayResponse(row.response[key], _child.type)}
                                    </td>
                                  );
                                })}
                              </Fragment>
                            );
                          }
                          return (
                            <td style={{ textAlign: 'center' }} key={index0}>
                              {row.response && displayResponse(row.response[item.name], item.type)}
                              {/* {row.response ? row.response[item.name] : ''} */}
                            </td>
                          );
                        })}
                      </>
                    )}
                  </>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Box>
  );
};

export default ComplexTable;
