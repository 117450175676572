import { Box, Table, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';

import { PCFItem } from 'data/DashboardTemplates';
import styled from 'styled-components';
import { tokens } from 'context/theme.context';
import { useReactToPrint } from 'react-to-print';

export interface TableTypeI {
  section?: string;
  section_name?: string;
  standard?: string;
  criteria: {
    item_number: number;
    field_name: string;
    document_review?: any;
    document_review_note?: string;
    observe?: any;
    interview?: string[];
  }[];
}

export interface TableTypeII {
  equipment_type: string;
  type_comment?: string;
  equipment: {
    name: string;
    // field_code: string[];
    variant?: string[];
  }[];
}

/////////////////////////
//  Styled components  //
/////////////////////////

export const Header = styled.h4`
  margin: 0;
`;

const Comment = styled.p`
  margin: 0;
  margin-top: 10px;
`;

const StyledUL = styled.ul`
  margin: 0;
  padding-left: 1.5em;
`;

const StyledText = styled.p`
  font-family: 'Times New Roman';
  margin: 0;
`;

export const PageStyle = `@page {
  margin: 0.5in;
}`;

///////////////////////////////////////
//  Part I PCF Table implementation  //
///////////////////////////////////////
type TableProps = {
  tableData?: TableTypeI[];
  toPrint?: boolean;
  setToPrint?: (state: boolean) => void;
  responses: any;
  pcfItems: PCFItem[];
};

type RowDataV1Props = {
  id: number;
  fieldName: string;
  documentReview?: string[] | string[][];
  documentReviewNote?: string;
  observe?: string[];
  interview?: string[];
};

export const CustomPCFTable: React.FC<TableProps> = (props) => {
  const { tableData, toPrint, setToPrint, responses, pcfItems } = props;
  const [table, setTable] = useState<HTMLDivElement | null>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const pdfGen = useReactToPrint({
    content: () => table,
    documentTitle: 'AKLAN PCF',
    pageStyle: PageStyle,
  });

  useEffect(() => {
    if (toPrint) {
      pdfGen();
      if (setToPrint) setToPrint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toPrint]);

  const getIndicatorFromFresaa = (item_number: number, remarks?: boolean) => {
    if (responses) {
      const pcf = pcfItems.find((item) => item.item_number === item_number);
      if (pcf) {
        const fields = pcf.items;
        if (remarks) {
          const result = fields.reduce((result: any, field_code) => {
            result[field_code] = responses[field_code];
            return result;
          }, {});
          return (
            <div>
              {Object.keys(result).map((key) => (
                <p key={key} style={{ margin: 0 }}>
                  {key}: {result[key]}
                </p>
              ))}
            </div>
          );
        }
        const result = fields.reduce((result, field_code) => {
          return (
            result +
            (!responses[field_code] ||
            responses[field_code] === pcf.required_response ||
            responses[field_code] === 'N/A'
              ? 1
              : 0)
          );
        }, 0);
        return result === fields.length ? 'Yes' : 'No';
      }
    }
    return '';
  };

  ////////////////////////////
  //  Part I Row component  //
  ////////////////////////////

  const PCFRowData: React.FC<RowDataV1Props> = (props) => {
    const { id, fieldName, documentReview, documentReviewNote, observe, interview } = props;
    return (
      <tr>
        <td>
          <Box display="grid" width="100%" gridTemplateColumns="20px 1fr">
            <p> {id}.</p>
            <p> {fieldName}</p>
          </Box>
        </td>
        <td>
          {documentReview ? <b>Document Review</b> : ''}
          {documentReview ? (
            <StyledUL>
              {/* checks if the document has child elements (array) */}
              {documentReview.map((doc, idx) => {
                if (Array.isArray(doc))
                  return (
                    <StyledUL key={idx}>
                      {doc.map((cdoc, i) => (
                        <li key={i}>{cdoc}</li>
                      ))}
                    </StyledUL>
                  );
                return <li key={idx}>{doc}</li>;
              })}
            </StyledUL>
          ) : (
            <></>
          )}

          {observe ? <b>Observe Review</b> : ''}
          {observe ? (
            <StyledUL>
              {observe.map((obs, idx) => {
                if (Array.isArray(obs))
                  return (
                    <StyledUL key={idx}>
                      {obs.map((cobs, i) => (
                        <li key={i}> {cobs}</li>
                      ))}
                    </StyledUL>
                  );
                return <li key={idx}>{obs}</li>;
              })}
            </StyledUL>
          ) : (
            <></>
          )}

          {interview ? <b>Interview</b> : ''}
          {interview ? (
            <StyledUL>
              {interview.map((obs, idx) => (
                <li key={idx}>{obs}</li>
              ))}
            </StyledUL>
          ) : (
            <></>
          )}

          {documentReviewNote ? <Comment>{'*' + documentReviewNote}</Comment> : <></>}
        </td>
        <td>{getIndicatorFromFresaa(id)}</td>
        <td></td>
        {/* <td>,cmv sdkn fksa lksafbg lkjs fagljsabd fljn sdafjklbsdklfj sadlkjf gasldjnf salj fsaljdf fjldhas</td> */}
      </tr>
    );
  };

  return (
    <Table
      ref={(el: any) => setTable(el)}
      sx={{
        '& td': {
          border: '1px solid black',
          padding: '7px 15px',
          wordWrap: 'break-word',
          verticalAlign: 'top',
          fontFamily: 'Times New Roman',
        },
        '& thead td': {
          fontWeight: 'bold',
          textAlign: 'center',
        },
        '& tr:hover td': {
          backgroundColor: colors.solidHoverBackground + ' !important',
        },
      }}
    >
      <thead>
        <tr>
          <td style={{ width: '35%' }}>CRITERIA</td>
          <td style={{ width: '35%' }}>INDICATOR/EVIDENCE</td>
          <td style={{ width: '15%' }}>COMPLIED</td>
          <td style={{ width: '15%' }}>REMARKS</td>
        </tr>
      </thead>
      <tbody>
        {tableData ? (
          tableData.map((tdata, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td colSpan={4}>
                    {tdata.section ? (
                      <StyledText style={{ fontWeight: 'bold' }}>
                        {`${tdata.section}. ${tdata.section_name}`}
                      </StyledText>
                    ) : (
                      <></>
                    )}
                    {tdata.standard ? (
                      <Box paddingLeft="20px">
                        <StyledText style={{ fontWeight: '600' }}>{`Standard: ${tdata.standard}`}</StyledText>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>

                {tdata.criteria.map((td, idx) => (
                  <PCFRowData
                    key={idx + index + 1}
                    id={td.item_number}
                    fieldName={td.field_name}
                    documentReview={td.document_review}
                    documentReviewNote={td.document_review_note}
                    observe={td.observe}
                    interview={td.interview}
                  />
                ))}
              </Fragment>
            );
          })
        ) : (
          <></>
        )}
      </tbody>
    </Table>
  );
};

/////////////////////////////
//  Part II Row component  //
/////////////////////////////
type EquipmentRowDataProps = {
  name: string;
  required_response?: string;
  variant?: string[];
  responses: any;
  equipmentFields: any;
};

const EquipmentRowData: React.FC<EquipmentRowDataProps> = (props) => {
  const { name, variant, responses, equipmentFields, required_response = 'Available & Functional' } = props;

  const getIndicatorFromFresaa = (field_code: string, remarks?: boolean) => {
    if (responses) {
      return responses[field_code] === required_response ? 'Yes' : 'No';
    }
    return '';
  };

  return (
    <>
      <tr>
        <td style={{ paddingLeft: '20px' }}>{name}</td>
        <td>{variant ? '' : getIndicatorFromFresaa(equipmentFields[name]?.field_code)}</td>
        <td></td>
      </tr>
      {variant ? (
        variant.map((variant_name, id) => (
          <tr key={id}>
            <td style={{ paddingLeft: '40px' }}>{variant_name}</td>
            <td>{getIndicatorFromFresaa(equipmentFields[variant_name]?.field_code)}</td>
            <td></td>
          </tr>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

//////////////////////////////////////////////////
//  Part II Equipment PCF Table implementation  //
//////////////////////////////////////////////////
type EquipmentProps = {
  tableData: TableTypeII[];
  toPrint?: boolean;
  setToPrint?: (state: boolean) => void;
  responses: any;
  equipmentFields: any;
};

export const CustomPCFEquipmentTable: React.FC<EquipmentProps> = (props) => {
  const { tableData, toPrint, setToPrint, responses, equipmentFields } = props;
  const [table, setTable] = useState<HTMLDivElement | null>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const pdfGen = useReactToPrint({
    content: () => table,
    documentTitle: 'AKLAN PCF',
    pageStyle: PageStyle,
  });

  useEffect(() => {
    if (toPrint) {
      pdfGen();
      if (setToPrint) setToPrint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toPrint]);

  return (
    <Table
      ref={(el: any) => setTable(el)}
      sx={{
        '& td': {
          border: '1px solid black',
          padding: '7px 15px',
          wordWrap: 'break-word',
          verticalAlign: 'top',
          fontFamily: 'Times New Roman',
        },
        '& thead td': {
          fontWeight: 'bold',
          textAlign: 'center',
        },
        '& tr:hover td': {
          backgroundColor: colors.solidHoverBackground + ' !important',
        },
      }}
    >
      <thead>
        <tr>
          <td style={{ width: '70%' }}>EQUIPMENT/INSTRUMENT</td>
          <td style={{ width: '15%' }}>COMPLIED</td>
          <td style={{ width: '15%' }}>REMARKS</td>
        </tr>
      </thead>
      <tbody>
        {tableData ? (
          tableData.map((data, dataID) => (
            <Fragment key={dataID}>
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  <Header>{data.equipment_type}</Header>
                  {data.type_comment && <Comment>{data.type_comment}</Comment>}
                </td>
              </tr>
              {data.equipment.map((equipment, eqID) => (
                <EquipmentRowData
                  key={eqID}
                  name={equipment.name}
                  variant={equipment.variant}
                  responses={responses}
                  equipmentFields={equipmentFields}
                />
              ))}
            </Fragment>
          ))
        ) : (
          <></>
        )}
      </tbody>
    </Table>
  );
};

/*
  3 4 7 0

  41 36 40 2

  0 2 2 62
*/
