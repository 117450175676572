module.exports = {
  section_number: 1,
  section_name: 'VAWC Program',
  section_body: [
    {
      type: 'table',
      field_name: 'Adolescent Friendly Health Facility (AFHF) Certifiers Checklist',
      field_code: '1',
      columns: [
        {
          name: 'Number of VAWC Cases',
          type: 'parent',
          children: [
            { name: 'Physical Abuse', type: 'numeric', code: '1' },
            { name: 'Sexual Abuse', type: 'numeric', code: '2' },
            { name: 'Emotional Abuse', type: 'numeric', code: '3' },
            { name: 'Verbal Abuse', type: 'numeric', code: '4' },
            { name: 'Spiritual Abuse', type: 'numeric', code: '5' },
            { name: 'Financial Abuse', type: 'numeric', code: '6' },
            { name: 'Others', type: 'numeric', code: '6' },
          ],
        },
      ],
      rows: [
        {
          type: 'field',
          field_name: '0-4 years old',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '5-9 years old',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '10-14 years old',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '15-19 years old',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '10-59 years old',
          field_code: '1',
        },
        {
          type: 'field',
          field_name: '60 years old and above',
          field_code: '1',
        },
      ],
    },
  ],
};
