import { createContext, useMemo, useState } from "react";

export type BreadcrumbTabProps = {
  label: string;
  link?: string;
};

export const BreadcrumbContext = createContext({
  breadcrumb: [] as BreadcrumbTabProps[],
  setBreadcrumb: (breadcrumb: BreadcrumbTabProps[]) => {},
});

export const useBreadcrumb = () => {
  const [breadcrumb, setBreadcrumb] = useState();

  const userMemo = useMemo(() => ({ breadcrumb, setBreadcrumb }), [breadcrumb]);
  return [userMemo, setBreadcrumb];
};
