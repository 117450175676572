import { Chip } from '@mui/material';
import { UserModel } from 'model/Entities';
import moment from 'moment';
const project = process.env.REACT_APP_PROJECT;

export const isFresaarProject = () => {
  return project === 'fresaar';
};

export const formatDate = (date: string) => {
  return date ? moment(date).format('ll') : '-';
};

export const formatDateTime = (date?: string) => {
  return date ? moment(date).format('lll') : '-';
};

export const formatedDateNow = () => {
  const currentDate = new Date();
  return {
    month: currentDate.getMonth() + 1,
    day: currentDate.getDate(),
    year: currentDate.getFullYear(),
  };
};

export const formatDateBoxDate = (date: Date) => {
  const day = date.getDate();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${year}-${month}-${day > 10 ? day : '0' + day.toString()}`;
};

export const calculateAge = (birthday: string) => {
  var diff_ms = Date.now() - new Date(birthday).getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const tranformFormErrors = (errors: any) => {
  for (var key in errors) {
    errors[key] = errors[key].msg;
  }

  return errors;
};

export const changeNullToBlank: any = (obj: any) => {
  for (var key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    }
  }

  return obj;
};

export const capitalizeWord = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const numberToLetters = (number: number) => {
  let letters = '';
  while (number > 0) {
    number--;
    letters = String.fromCharCode((number % 26) + 65) + letters;
    number = Math.floor(number / 26);
  }
  return letters;
};

export const addLeadingZeroes = (number: number, length: number) => {
  return String(number).padStart(length, '0');
};

export const displayName = (user?: UserModel) => {
  if (user) {
    return [user.title, user.first_name, user.last_name].filter((item) => !!item).join(' ');
  }

  return '-';
};

export const getStatusChip = (status: string, theme: any, colors: any) => {
  let chipColor = '';
  let statusLabel = capitalizeWord(status);
  switch (status) {
    case 'assigned':
      chipColor = '#a86e05';
      break;
    case 'closed':
      chipColor = '#a86e05';
      break;
    case 'open':
      chipColor = colors.text;
      break;
    case 'started':
      chipColor = '#f86b02';
      break;
    case 'submitted':
      chipColor = '#b7a300';
      break;
    case 'ongoing-approval':
      chipColor = '#0393d3';
      statusLabel = 'Ongoing Approval';
      break;
    case 'completed':
    case 'approved':
      chipColor = '#419b44';
      break;
    case 'active':
      chipColor = '#419b44';
      break;
    default:
      chipColor = 'default';
      break;
  }

  return (
    <Chip label={statusLabel} size="small" variant="outlined" style={{ borderColor: chipColor, color: chipColor }} />
  );
};

export const countFields = (
  field: any,
  setTotalCount: any,
  setTotalCountWithResponse: any,
  setEnd?: any,
  setMissed?: any
) => {
  if (setEnd && field.field_code) {
    setEnd(field.field_code);
  }

  if (field.type === 'section') {
    field.total_count = 0;
    field.total_count_with_response = 0;
    field.section_body.forEach((item: any) => {
      countFields(item, setTotalCount, setTotalCountWithResponse, setEnd, setMissed);
    });
  }

  if (field.type === 'sub-section') {
    field.section_content.forEach((item: any) => {
      countFields(item, setTotalCount, setTotalCountWithResponse, setEnd, setMissed);
    });
  }

  if (field.type === 'table') {
    if (field.table_type === 'simple-table') {
      field.rows.forEach((item: any) => {
        countFields(item, setTotalCount, setTotalCountWithResponse, setEnd, setMissed);
      });
    } else {
      field.rows.forEach((row: any) => {
        field.columns.forEach((column: any) => {
          countTableFields(column, row.response, setTotalCount, setTotalCountWithResponse, '', setMissed, row);
        });
      });
    }
  }

  if (field.type === 'parent') {
    field.children.forEach((item: any) => {
      countFields(item, setTotalCount, setTotalCountWithResponse, setEnd, setMissed);
    });
  }

  if (field.type === 'conditional-field') {
    setTotalCount && setTotalCount((prev: any) => prev + 1);
    if (field.response !== undefined && field.response !== null && field.response.toString().trim() !== '') {
      setTotalCountWithResponse && setTotalCountWithResponse((prev: any) => prev + 1);
    } else {
      setMissed && setMissed((oldArray: any) => [...oldArray, field.field_code]);
    }

    field.children.forEach((item: any) => {
      countFields(item, setTotalCount, setTotalCountWithResponse, 0, setMissed);
    });
  }

  if (field.type === 'field') {
    setTotalCount && setTotalCount((prev: any) => prev + 1);
    if (field.response !== undefined && field.response !== null && field.response.toString().trim() !== '') {
      setTotalCountWithResponse && setTotalCountWithResponse((prev: any) => prev + 1);
    } else {
      setMissed && setMissed((oldArray: any) => [...oldArray, field.field_code]);
    }
  }
};

function countTableFields(
  column: any,
  response: any,
  setTotalCount: any,
  setTotalCountWithResponse: any,
  parent_name: any,
  setMissed: any,
  row: any
) {
  if (column.type === 'parent') {
    column.children.forEach((child: any) => {
      countTableFields(child, response, setTotalCount, setTotalCountWithResponse, column.name, setMissed, row);
    });
  } else {
    setTotalCount && setTotalCount((prev: any) => prev + 1);
    let key = column.name;
    if (parent_name) {
      key = parent_name + ' | ' + column.name;
    }

    if (response && response[key] !== null && response[key] !== undefined && response[key] !== '') {
      setTotalCountWithResponse && setTotalCountWithResponse((prev: any) => prev + 1);
    } else {
      setMissed && setMissed((oldArray: any) => [...oldArray, row.field_code + '-' + column.code]);
    }
  }
}

export const formatNumber = (number: number | string, decimalPlaces?: number) => {
  decimalPlaces = decimalPlaces === undefined ? 2 : decimalPlaces;
  return parseFloat(number + '').toLocaleString('en-US', {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
};

export const formatArray = (data: string[], separator?: string) => {
  return data.filter((item) => !!item).join(separator ?? ', ');
};
