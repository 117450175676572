const section1 = require('./sections/section_1');
const section2 = require('./sections/section_2');
const section3 = require('./sections/section_3');
const section4 = require('./sections/section_4');
const section5 = require('./sections/section_5');
const section6 = require('./sections/section_6');
const section7 = require('./sections/section_7');

module.exports = [section1, section2, section3, section4, section5, section6, section7];
// module.exports = [section7];
