/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'context/user.context';

type ProtectedComponentProps = {
  requiredAuth?: string[];
  requiredRole?: string[];
  children: React.ReactNode;
};

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ requiredAuth, requiredRole, children }) => {
  const { user } = useContext(UserContext);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    checkUserAuthority();
  }, [user]);

  const checkUserAuthority = () => {
    if (user) {
      if (!requiredAuth && !requiredRole) {
        setVerified(true);
      } else {
        if (requiredRole) {
          const verified_roles = requiredRole.filter((r) => user.user_group.group_name === r);

          setVerified(!!verified_roles.length);
        } else if (requiredAuth) {
          const verified_auths = requiredAuth.filter((r) => user.authorities?.includes(r));

          setVerified(!!verified_auths.length);
        }
      }
    }
  };

  return verified ? <>{children}</> : <></>;
};

export default ProtectedComponent;
