import { Box, Switch, Typography, styled, useTheme } from '@mui/material';

import { tokens } from 'context/theme.context';

type CustomSwitchProps = {
  label1: string;
  label2: string;
  image1?: string;
  image2?: string;
  value: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const CustomSwitch: React.FC<CustomSwitchProps> = ({ label1, label2, image1, image2, value, handleChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const MaterialUISwitch = styled(Switch)(() => ({
    width: 65,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',

        transform: 'translateX(25px)',
        '& .MuiSwitch-thumb:before': image2 && {
          backgroundImage: `url(${image2})`,
          backgroundSize: '50%',
          backgroundPosition: 'center 8px',
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: colors.dark,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: colors.primary,
      width: 32,
      height: 32,
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: image1 && `url(${image1})`,
        backgroundSize: '50% 50%',
        filter: 'invert(100%)',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: colors.dark,
      borderRadius: 20 / 2,
    },
  }));

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2">{label1}</Typography>
      <MaterialUISwitch checked={value} onChange={handleChange} />
      <Typography variant="body2">{label2}</Typography>
    </Box>
  );
};

export default CustomSwitch;
