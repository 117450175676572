/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from 'react';

import { createTheme } from '@mui/material/styles';

export const tokens = (mode: string) => ({
  ...(mode === 'dark'
    ? {
        primary: '#069045',
        secondary: '#53a669',
        accent: '#299044',
        blueAccent: '#008ece',
        redAccent: '#db4f4a',
        background: '#161616',
        secondary_background: '#232426',
        text: '#e0e0e0',
        white_text: '#e0e0e0',

        dark: '#3d3d3d',
        main: '#666666',
        light: '#e0e0e0',
        border: '#515151',
        hoverBackground: 'rgba(255, 255, 255, 0.08)',
        solidHoverBackground: '#343537',

        lightGreen: '#1e9b57',
        lightGreen2: '#37a66a',
        lightGreen3: '#50b17c',
        lightGreen4: '#ebf5ef',
      }
    : {
        primary: '#069045',
        secondary: '#53a669',
        accent: '#299044',
        blueAccent: '#008ece',
        redAccent: '#db4f4a',
        background: '#F8F8F8',
        secondary_background: '#fff',
        text: '#141414',
        white_text: '#e0e0e0',

        dark: '#a3a3a3',
        main: '#666666',
        light: '#141414',
        border: '#e0e0e0',
        hoverBackground: 'rgba(0, 0, 0, 0.04)',
        solidHoverBackground: '#ebf5ef',

        lightGreen: '#1e9b57',
        lightGreen2: '#37a66a',
        lightGreen3: '#50b17c',
        lightGreen4: '#ebf5ef',
      }),
});

// mui theme settings
export const themeSettings = (mode: string) => {
  // const colors = tokens(mode);
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === 'dark'
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary,
            },
            secondary: {
              main: colors.secondary,
            },
            neutral: {
              dark: colors.dark,
              main: colors.main,
              light: colors.light,
            },
            background: {
              default: colors.background,
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary,
            },
            secondary: {
              main: colors.secondary,
            },
            neutral: {
              dark: colors.dark,
              main: colors.main,
              light: colors.light,
            },
            background: {
              default: colors.background,
            },
          }),
    },
    typography: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 12,
      h1: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 40,
      },
      h2: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 32,
      },
      h3: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 24,
      },
      h4: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 20,
      },
      h5: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 16,
      },
      h6: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const theme_mode = localStorage.getItem('theme_mode');
  const [mode, setMode] = useState(theme_mode ?? 'light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem('theme_mode', mode === 'light' ? 'dark' : 'light');
        setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode) as any), [mode]);
  return [theme, colorMode];
};
