import ArticleContent, { imagePath } from '../ArticleContent';

const ReopenBatchArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Select the row of the active assessment.', image: `${imagePath}/close-batch-1.png` },
            { step: '2. Select the Assessments Tab on the upper-right part.' },
            { step: '3. Click the "Reopen Assessments" button.', image: `${imagePath}/reopen-batch-1.png` },
          ],
        },
      ]}
    />
  );
};

export default ReopenBatchArticle;
