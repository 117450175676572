/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'model/interface';
import { ConfirmationDialog, CustomContainer, CustomForm, CustomModal, PrimaryButton, RowAction } from 'components';
import { changeNullToBlank, tranformFormErrors } from 'utils';
import {
  createUserGroupValidator,
  deleteUserGroupValidator,
  getAllUserGroupValidators,
  updateUserGroupValidator,
} from 'api/user-group-validator';
import { useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import Header from 'components/Header';
import { UserContext } from 'context/user.context';
import { UserGroupModel } from 'model/Entities';
import { create_user_group_validator_schema } from 'model/schema';
import { useSnackbar } from 'notistack';

export interface UserGroupFieldInput extends FieldInput {
  field_name: keyof UserGroupModel;
}

type UserGroupValidatorProps = {};

const initialValues: UserGroupModel = {
  group_name: '',
  description: '',
  level: '',
};

const UserGroupValidators: React.FC<UserGroupValidatorProps> = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState<UserGroupModel[]>([]);
  const [userGroupValidatorCount, setUserCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [openUserGroupValidatorModal, setOpenUserGroupValidatorModal] = useState(false);
  const [userGroupValidatorModalHeader, setUserModalHeader] = useState('Create Validator');
  const [userGroupValidatorInitialValues, setUserGroupValidatorInitialValues] = useState<UserGroupModel>(initialValues);
  const [selectedUserGroupValidator, setSelectedUserGroupValidator] = useState<any>({}); //didnt use the setSelectedUser because it doesnt have API to call for the mean time
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
  };

  // Actions
  const editAction = (data: any) => {
    showUserGroupValidatorModal(true, data);
  };

  const deleteAction = (data: any) => {
    setSelectedUserGroupValidator(data);
    setOpenDeleteConfirmation(true);
  };

  const handleDelete = (user: any) => {
    deleteUserGroupValidator(user.id)
      .then((res: any) => {
        enqueueSnackbar(`User group validator successfully deleted!`, {
          variant: 'success',
        });
        refreshTable();
      })
      .catch((error) => {
        enqueueSnackbar('Cannot delete user group validator.', {
          variant: 'error',
        });
      });
  };

  const actions = [
    {
      label: 'Update',
      action: editAction,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete',
      action: deleteAction,
      startIcon: <DeleteOutlinedIcon />,
    },
  ];
  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'group_name',
      sortable: false,
      headerName: 'Group Name',
      flex: 1.5,
    },
    {
      field: 'description',
      sortable: false,
      headerName: 'Description',
      flex: 2,
    },
    {
      field: 'level',
      sortable: false,
      headerName: 'Access Level',
      flex: 1,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        user?.user_group.group_name === 'RMTM' &&
        params.row.user_group.description === 'Regional Monitoring Team Leader' ? (
          <></>
        ) : (
          <RowAction
            actions={actions}
            data={params.row}
            disabled={['MHO/PHN', 'CHD', 'PHO', 'DMO'].includes(params.row.group_name)}
          />
        ),
    },
  ];

  // Form Fields
  const userGroupValidatorFields: UserGroupFieldInput[] = [
    {
      field_name: 'group_name',
      display_name: 'Group Name',
      type: 'text',
      disabled: isEdit,
    },
    {
      field_name: 'level',
      display_name: 'Access Level',
      type: 'select',
      options: [
        { value: 'National', key: 'National' },
        { value: 'Regional', key: 'Regional' },
        { value: 'Provincial', key: 'Provincial' },
        { value: 'Municipal', key: 'Municipal' },
      ],
    },
    {
      field_name: 'description',
      display_name: 'Description',
      type: 'text',
      span: 4,
    },
  ];

  //Functions
  const getUserGroupValidators = (query?: ApiQuery) => {
    setLoading(true);
    getAllUserGroupValidators({
      page: 1,
      length: 10,
      ...query,
    })
      .then((res) => {
        setRows(res.data.rows);
        setUserCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    if (data.id) {
      updateUserGroupValidator(data.id, data)
        .then((res: any) => {
          setButtonLoading(false);
          successFormSubmit(true, formikHelpers);
        })
        .catch((error) => {
          setButtonLoading(false);
          enqueueSnackbar(error.response.data.message ?? 'Unable to update user group.', { variant: 'error' });
          formikHelpers.setErrors(tranformFormErrors(error.response.data));
        });
      return;
    }

    createUserGroupValidator(data)
      .then((res: any) => {
        setButtonLoading(false);
        successFormSubmit(false, formikHelpers);
      })
      .catch((error) => {
        setButtonLoading(false);
        enqueueSnackbar(error.response.data?.message ?? 'Unable to create user group.', { variant: 'error' });
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      });
  };

  const successFormSubmit = (from_edit: boolean, { resetForm, setErrors }: FormikHelpers<any>) => {
    enqueueSnackbar(`User group validator successfully ${from_edit ? 'updated' : 'created'}!`, {
      variant: 'success',
    });
    setOpenUserGroupValidatorModal(false);
    resetForm();
    refreshTable();
  };

  const showUserGroupValidatorModal = (from_edit?: boolean, data?: any) => {
    if (from_edit && data) {
      data = changeNullToBlank(data);
      setUserGroupValidatorInitialValues({
        ...data,
      });
      setIsEdit(true);
      setUserModalHeader('Update Validator');
    } else {
      setIsEdit(false);
      setUserModalHeader('Create Validator');
      setUserGroupValidatorInitialValues(initialValues);
    }

    setOpenUserGroupValidatorModal(true);
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Group Validators' }]);
  }, []);

  return (
    <Box mt="20px">
      <CustomContainer>
        <CustomTable
          searchKeys="Group Name or Description"
          columns={columns}
          rows={rows}
          rowCount={userGroupValidatorCount}
          loading={loading}
          getData={getUserGroupValidators}
          forceRefresh={refresh}
          headerComponent={<Header title="Group Validators" mb="0" />}
          filterComponent={<PrimaryButton onClick={() => showUserGroupValidatorModal()} label="Create Validator" />}
        />
      </CustomContainer>
      <CustomModal
        header={userGroupValidatorModalHeader}
        open={openUserGroupValidatorModal}
        setOpen={setOpenUserGroupValidatorModal}
      >
        <CustomForm
          initialValues={userGroupValidatorInitialValues}
          onSubmit={handleSubmit}
          fields={userGroupValidatorFields}
          schema={create_user_group_validator_schema}
          loading={buttonLoading}
        />
      </CustomModal>
      <ConfirmationDialog
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        message="Are you sure want to remove this group validator?"
        onConfirm={() => handleDelete(selectedUserGroupValidator)}
      />
    </Box>
  );
};

export default UserGroupValidators;
