import ArticleContent, { imagePath } from '../ArticleContent';

const UpdateAssessmentArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click "Update" button.', image: `${imagePath}/update-assessment-1.png` },
            { step: '2. Fill out the form.' },
            { step: '3. Click Submit.', image: `${imagePath}/update-assessment-2.png` },
          ],
        },
      ]}
    />
  );
};

export default UpdateAssessmentArticle;
