import ArticleContent, { imagePath } from '../ArticleContent';

const DeleteAssessmentArticle = () => {
  return (
    <ArticleContent
      contents={[
        {
          steps: [
            { step: '1. Click "Delete" button.', image: `${imagePath}/delete-assessment-1.png` },
            {
              step: '2. Click "Yes" to confirm or "Cancel" to cancel the action.',
              image: `${imagePath}/delete-assessment-2.png`,
            },
          ],
        },
      ]}
    />
  );
};

export default DeleteAssessmentArticle;
