import { Box, Button, Chip } from '@mui/material';
import {
  CustomGridCell,
  CustomModal,
  CustomTable,
  FacilityLocationFilter,
  PrimaryButton,
  RegularButton,
} from 'components';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { assignFacility, getFacilityNotSelected } from 'api/facility';

import { ApiQuery } from 'model/interface';
import { FacilityModel } from 'model/Entities';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

type FacilityTableAddModalProps = {
  open: boolean;
  selectedGroup: number;
  groupName: string;
  assessorId: number;
  provinceName: string;
  provinceCode: string;
  setOpen: (open: boolean) => void;
  refreshGroupTable: () => void;
  refreshAssignedFacilityTable: () => void;
};

const FacilityTableAddModal: React.FC<FacilityTableAddModalProps> = ({
  open,
  setOpen,
  selectedGroup,
  groupName,
  assessorId,
  provinceName,
  provinceCode,
  refreshGroupTable,
  refreshAssignedFacilityTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<FacilityModel[]>([]);
  const [facilityCount, setFacilityCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [otherFilters, setOtherFilters] = useState<any>({});
  const [selectedFacilities, setSelectedFacilities] = useState<GridSelectionModel>([]);
  const [refresh, setRefresh] = useState(0);

  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'facility_code',
      sortable: false,
      headerName: 'Facility Code',
      flex: 1,
    },
    {
      field: 'facility_name',
      sortable: false,
      headerName: 'Facility Name',
      flex: 1.5,
    },
    {
      field: 'facility_type_id',
      sortable: false,
      headerName: 'Facility Type',
      flex: 1,
      renderCell: (params) => <CustomGridCell>{params.row.facility_type.type}</CustomGridCell>,
    },

    {
      field: 'province_code',
      sortable: false,
      headerName: 'Location',
      flex: 1.5,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.municipality.municipality_name}
          {', '}
          {params.row.province.province_name}
          {', '}
          {params.row.barangay_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      flex: 0.7,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={() => {
            assignFacilityToGroup(selectedGroup, [params.row.id]);
          }}
        >
          Add
        </Button>
      ),
    },
  ];

  // Functions
  const clearFilter = () => {
    for (let key in otherFilters) {
      otherFilters[key] = '';
    }
    setOtherFilters({ ...otherFilters });
  };

  const getAvailableFacilities = (query?: ApiQuery) => {
    setLoading(true);
    getFacilityNotSelected({ page: 1, length: 10, ...query }, selectedGroup)
      .then((res) => {
        setRows(res.data.rows);
        setFacilityCount(res.data.count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const assignFacilityToGroup = async (group_id: number, facilities: any) => {
    try {
      setLoadingAdd(true);
      const facilitiesArr = JSON.stringify(facilities);
      const data = {
        facilities: facilitiesArr,
        assessorId: assessorId,
      };

      await assignFacility(data, group_id);
      enqueueSnackbar('Facility successfully added to group!', { variant: 'success' });
      refreshGroupTable();
      refreshAssignedFacilityTable();
      setSelectedFacilities([]);
      setRefresh((prev: number) => prev + 1);
    } finally {
      setLoadingAdd(false);
    }
  };

  return (
    <CustomModal
      header={`Add Facilities to ${groupName}`}
      open={open}
      setOpen={setOpen}
      width={1080}
      headerComponent={<Chip label={provinceName} size="small" variant="outlined" />}
    >
      <CustomTable
        searchKeys="Facility Code or Facility Name"
        columns={columns}
        rows={rows}
        rowCount={facilityCount}
        loading={loading}
        getData={getAvailableFacilities}
        checkboxSelection
        isRowSelectable
        disableSelectionOnClick
        handleSelectRow={setSelectedFacilities}
        tableHeight={400}
        otherFilters={otherFilters}
        forceRefresh={refresh}
        filterComponent={
          <Box display="flex" gap="10px" width="100%" alignItems="center">
            <FacilityLocationFilter
              hideProvinceDefault
              defaultProvince={provinceCode}
              handleChangeFilter={(data) => {
                setOtherFilters({ ...otherFilters, ...data });
              }}
              otherFilters={otherFilters}
            />
            <RegularButton label="Clear" onClick={() => clearFilter()} color="secondary" />
          </Box>
        }
        headerComponent={
          <Box gap="15px" display="flex">
            <PrimaryButton
              onClick={() => {
                assignFacilityToGroup(selectedGroup, selectedFacilities);
              }}
              label={'Add selected facilities'}
              disabled={!selectedFacilities.length}
              loading={loadingAdd}
            />
          </Box>
        }
      />
    </CustomModal>
  );
};

export default FacilityTableAddModal;
